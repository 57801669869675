import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { editUser, getUser } from 'actions/index';
import FormUser from './FormUser';

class EditUserContainer extends Component {
  static propTypes = {
    fetchUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onEditUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const {
      match: { params },
      fetchUser,
    } = this.props;
    const { id } = params;
    fetchUser(id);
  }

  onSubmit = data => {
    const {
      onEditUser,
      match: { params },
    } = this.props;
    const { id } = params;
    onEditUser(id, data);
  };

  render() {
    const { loading, user } = this.props;
    const initialValues = user
      ? {
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
          email: user.email,
          role_id: user.role_id,
          username: user.username,
        }
      : {};
    return (
      <FormUser onSubmit={this.onSubmit} loading={loading} initialValues={initialValues} isEdit />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.user.loading,
    user: state.user.current,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onEditUser: (id, data) => dispatch(editUser(id, data)),
    fetchUser: id => dispatch(getUser(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditUserContainer);
