import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Col,
  Row,
  Form,
  InputGroup,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from 'react-bootstrap';
import { extraMeasurments } from 'constants/glassTypeOptions';
import { create, all } from 'mathjs';

const config = {};
const math = create(all, config);

class FormInput extends Component {
  addValue = ({ name, value }) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, parseInt(value || 0, 10) + 1);
  };

  subtractValue = ({ name, value }) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, parseInt(value || 0, 10) - 1);
  };

  render() {
    const IGUS = [
      'igu-window',
      'igu-door-lite',
      'igu-sky-lite-temp-or-lami',
      'igu-lami-temp-lami',
      'igu-spandrel',
      'igu-patio-door-or-panel',
      'IGU (VIRACON)',
      'bullet-proof-igu',
      'curved-igu',
    ];

    const {
      singleName,
      glassType,
      glassTypeDetail,
      name,
      name2,
      name3,
      name4,
      glasCheckName,
      glasCheck2Name,
      glasCheck,
      glasCheck2,
      type,
      label,
      value,
      value2,
      value3,
      value4,
      isInvalidDetails,
      detailName,
      detailValue,
      error,
      angles,
      details,
      options,
      onChange,
      isInvalid,
      validate,
      onBlur,
      isDoubtful,
      isDoubtfulName,
      doubtfulValue,
      setFieldValue,
      placeholder,
      style,
    } = this.props;

    const handleChangeGlassDlo = (evt, type) => {
      const { value } = evt.target;
      let new_Value2;
      let new_value;

      if (value2 == '') {
        new_Value2 = 0;
      } else {
        new_Value2 = value2;
      }
      let expresion = /^(\d+(?:(?: \d+)*\/\d+)?)$/g;
      let validate = expresion.test(value);

      const currentMeasurment = extraMeasurments.find(e => e.value == new_Value2);

      if (currentMeasurment && validate) {
        new_value = math.add(math.fraction(value), math.fraction(currentMeasurment.extra_height));
      } else {
        new_value = math.fraction(currentMeasurment.extra_height);
      }

      const integer = math.floor(new_value);
      const decimal = math.fraction(new_value - integer);
      const valuenew = `${integer} ${decimal.n}/${decimal.d}`;

      if (decimal.n == 0) {
        setFieldValue(name3, `${integer}`);
      } else {
        setFieldValue(name3, valuenew);
      }
    };

    const handleChangeGlassExtra = (evt, type) => {
      setFieldValue(evt.target.name, evt.target.value);
      let new_value;
      let dlo_width_value = value;

      if (dlo_width_value == '') {
        dlo_width_value = 0;
      } else {
        dlo_width_value = value;
      }

      let expresion = /^(\d+(?:(?: \d+)*\/\d+)?)$/g;
      let validate = expresion.test(dlo_width_value);
      const currentMeasurment = extraMeasurments.find(e => e.value == evt.target.value);

      if (validate) {
        new_value = math.add(
          math.fraction(dlo_width_value),
          math.fraction(currentMeasurment.extra_height),
        );
      } else {
        new_value = math.fraction(currentMeasurment.extra_height);
      }
      const integer = math.floor(new_value);
      const decimal = math.fraction(new_value - integer);
      const valuenew = `${integer} ${decimal.n}/${decimal.d}`;

      if (decimal.n == 0) {
        setFieldValue(name3, `${integer}`);
      } else {
        setFieldValue(name3, valuenew);
      }
    };

    return (
      <Form.Group controlId={name}>
        {details ? (
          <Form.Group as={Row} controlId={detailName} className="mt-md-3">
            <Form.Label column xs={5} md={4} className="input-text font-weight-bold">
              {label}
            </Form.Label>
            <Col xs={7} md={8} className="mb-md-2">
              <Field
                as="select"
                className={
                  !detailValue || detailValue == ''
                    ? 'form-control'
                    : 'form-control is-valid-select'
                }
                name={detailName}
                value={detailValue}
                onChange={onChange}
                onBlur={onBlur}
              >
                <option value="">Details</option>
                {details.map((option, key) => (
                  <option value={option.value} key={key}>
                    {option.label}
                  </option>
                ))}
              </Field>
            </Col>
          </Form.Group>
        ) : type === 'label' ? (
          <Form.Label className="mt-3 mb-0 text-tabs font-weight-bold">{label}</Form.Label>
        ) : (
          <Form.Label className="input-text font-weight-bold">{label}</Form.Label>
        )}

        {type === 'ndhandle' && (
          <InputGroup controlId={name}>
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              type={'text'}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              placeholder="Notes"
              type={'text'}
              name={name3}
              value={value3}
              onChange={onChange}
              onBlur={onBlur}
            />
          </InputGroup>
        )}
        {type === 'handleTypeOptions' && (
          <InputGroup controlId={name}>
            <Field
              as="select"
              className={value == '' ? 'form-control' : 'form-control is-valid-select'}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            >
              <option value="">Choose a option...</option>
              {options.map((option, key) => (
                <option value={option.value} key={key}>
                  {option.label}
                </option>
              ))}
            </Field>
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              placeholder="Notes"
              type={'text'}
              name={name2}
              value={value2}
              onChange={onChange}
              onBlur={onBlur}
            />
          </InputGroup>
        )}

        {type === 'BGA2' && (
          <InputGroup controlId={name}>
            <Field
              as="select"
              className={value == '' ? 'form-control' : 'form-control is-valid-select'}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            >
              <option value="">Choose a option...</option>
              {options.map((option, key) => (
                <option value={option.value} key={key}>
                  {option.label}
                </option>
              ))}
            </Field>
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              placeholder="Notes"
              type={'text'}
              name={name2}
              value={value2}
              onChange={onChange}
              onBlur={onBlur}
            />
          </InputGroup>
        )}

        {type === 'BGA' && (
          <InputGroup controlId={name}>
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              type={'text'}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              placeholder="Notes"
              type={'text'}
              name={name2}
              value={value2}
              onChange={onChange}
              onBlur={onBlur}
            />
          </InputGroup>
        )}

        {type === 'glass' && (
          <InputGroup controlId={name}>
            <InputGroup.Prepend
              className={!glasCheck ? 'background-deactivated' : 'background-activated'}
            >
              <InputGroup.Checkbox
                style={{ background: '#3d404e' }}
                onChange={evt => {
                  const { checked } = evt.target;
                  setFieldValue(glasCheckName, checked);
                }}
                name={glasCheckName}
                checked={glasCheck}
              />
            </InputGroup.Prepend>
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              type={'text'}
              onChange={evt => {
                const { value, name } = evt.target;
                setFieldValue(name, value);
                if (value !== '') {
                  setFieldValue(glasCheckName, true);
                } else {
                  setFieldValue(glasCheckName, false);
                }

                handleChangeGlassDlo(evt);
              }}
              placeholder="DLO"
              name={name}
              value={value}
              onBlur={onBlur}
            />
            <Field
              as="select"
              className={value2 == '' ? 'form-control' : 'form-control is-valid-select'}
              name={name2}
              value={value2}
              onChange={evt => {
                handleChangeGlassExtra(evt);
              }}
              onBlur={onBlur}
            >
              <option value="">+DLO...</option>
              {options.map((option, key) => (
                <option value={option.value} key={key}>
                  {option.label}
                </option>
              ))}
            </Field>
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              placeholder="GO"
              type={'text'}
              name={name3}
              value={value3}
              onChange={onChange}
              onBlur={onBlur}
            />
            <InputGroup.Prepend
              className={!glasCheck2 ? 'background-deactivated' : 'background-activated'}
            >
              <InputGroup.Checkbox
                style={{ background: '#3d404e' }}
                onChange={evt => {
                  const { checked } = evt.target;
                  setFieldValue(glasCheck2Name, checked);
                }}
                name={glasCheck2Name}
                checked={glasCheck2}
              />
            </InputGroup.Prepend>
            <Form.Control
              className="ml-2 mr-2"
              as={Field}
              placeholder="Notes"
              type={'text'}
              name={name4}
              value={value4}
              onChange={evt => {
                setFieldValue(name4, evt.target.value);
                if (evt.target.value !== '') {
                  setFieldValue(glasCheck2Name, true);
                } else {
                  setFieldValue(glasCheck2Name, false);
                }
              }}
              onBlur={onBlur}
            />
          </InputGroup>
        )}
        {type === 'WxH' && (
          <Row>
            <InputGroup controlId={name}>
              <Col xs={3}>
                <Form.Control
                  as={Field}
                  placeholder="Width"
                  type={'text'}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Col>
              x
              <Col xs={3}>
                <Form.Control
                  as={Field}
                  placeholder="Height"
                  type={'text'}
                  name={name2}
                  value={value2}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Col>
            </InputGroup>
          </Row>
        )}
        {type === 'outage' && (
          <InputGroup controlId={name}>
            <Form.Control
              as={Field}
              type="text"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
            <p className="ml-2 mr-2"> </p>
            <Form.Control
              as={Field}
              placeholder="Notes"
              type="text"
              name={name2}
              value={value2}
              onChange={onChange}
              onBlur={onBlur}
            />
          </InputGroup>
        )}
        {type === 'joints' && (
          <InputGroup controlId={name}>
            <Field
              as="select"
              className={value == '' ? 'form-control' : 'form-control is-valid-select'}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            >
              <option value="">Choose option...</option>
              {options.map((option, key) => (
                <option value={option.value} key={key}>
                  {option.label}
                </option>
              ))}
            </Field>
            <p className="ml-2 mr-2"> </p>
            <Form.Control
              as={Field}
              placeholder="Notes"
              type="text"
              name={name2}
              value={value2}
              onChange={onChange}
              onBlur={onBlur}
            />
          </InputGroup>
        )}
        {(type === 'number' || type === 'text') && (
          <InputGroup controlId={name} style={style}>
            <Form.Control
              as={Field}
              type={type}
              onChange={onChange}
              name={name}
              isInvalid={isInvalid}
              onBlur={onBlur}
              value={value}
              placeholder={placeholder}
            />
            {type === 'number' && <InputGroup.Text id="basic-addon2">Inches</InputGroup.Text>}
            {angles && (
              <Col xs="5" sm="5" md="5" lg="5">
                <Field
                  as="select"
                  className={value2 == '' ? 'form-control' : 'form-control is-valid-select'}
                  onChange={onChange}
                  name={name2}
                  value={value2}
                  onBlur={onBlur}
                >
                  <option value="">Level</option>
                  {angles.map((option, key) => (
                    <option value={option.value} key={key}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </Col>
            )}
          </InputGroup>
        )}
        {type === 'select' &&
          (isDoubtful ? (
            <>
              <InputGroup controlId={name}>
                <InputGroup.Prepend
                  className={!doubtfulValue ? 'background-deactivated' : 'background-activated'}
                >
                  <InputGroup.Checkbox
                    style={{ background: '#3d404e' }}
                    onChange={evt => {
                      const { checked } = evt.target;
                      setFieldValue(isDoubtfulName, checked);
                    }}
                    name={isDoubtfulName}
                    checked={doubtfulValue}
                  />
                </InputGroup.Prepend>

                <Field
                  as="select"
                  className={'form-control '}
                  name={name}
                  value={value}
                  onChange={evt => {
                    if (IGUS.includes(glassType)) {
                      if (singleName == 'exthick') {
                        setFieldValue(`${glassTypeDetail}inthick`, evt.target.value);
                        if (evt.target.value !== '') {
                          setFieldValue(`${glassTypeDetail}inthick_doubt`, true);
                        } else {
                          setFieldValue(`${glassTypeDetail}inthick_doubt`, false);
                        }
                      }
                      if (singleName == 'extreat') {
                        setFieldValue(`${glassTypeDetail}intreat`, evt.target.value);
                        if (evt.target.value !== '') {
                          setFieldValue(`${glassTypeDetail}intreat_doubt`, true);
                        } else {
                          setFieldValue(`${glassTypeDetail}intreat_doubt`, false);
                        }
                      }
                      if (singleName == 'excolor') {
                        setFieldValue(`${glassTypeDetail}incolor`, 'Clear');
                        if (evt.target.value !== '') {
                          setFieldValue(`${glassTypeDetail}incolor_doubt`, true);
                        } else {
                          setFieldValue(`${glassTypeDetail}incolor_doubt`, false);
                        }
                      }
                    }
                    setFieldValue(name, evt.target.value);
                    if (evt.target.value !== '') {
                      setFieldValue(isDoubtfulName, true);
                    } else {
                      setFieldValue(isDoubtfulName, false);
                    }
                  }}
                  onBlur={onBlur}
                >
                  <option value="">Choose option...</option>
                  {options.map((option, key) => (
                    <option value={option.value} key={key}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <Form.Control.Feedback type="invalid">{isInvalid && error}</Form.Control.Feedback>
              </InputGroup>
            </>
          ) : (
            <InputGroup controlId={name}>
              <Field
                as="select"
                className={value == '' ? 'form-control' : 'form-control is-valid-select'}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              >
                <option value="">Choose option...</option>
                {options.map((option, key) => (
                  <option value={option.value} key={key}>
                    {option.label}
                  </option>
                ))}
              </Field>
              <Form.Control.Feedback type="invalid">{isInvalid && error}</Form.Control.Feedback>
            </InputGroup>
          ))}

        {type === 'checkbox' && (
          <div key={`default-${name}`}>
            {options.map((option, key) => (
              <Form.Check
                as={Field}
                type="checkbox"
                id={name}
                label={option}
                value={option}
                key={key}
                onChange={onChange}
                name={name}
                isInvalid={isInvalid}
                onBlur={onBlur}
              />
            ))}
          </div>
        )}
        {type === 'radio' && (
          <div key={`default-${name}`}>
            {options.map((option, key) => (
              <Form.Check
                as={Field}
                type="radio"
                id={option}
                label={option}
                value={option}
                key={key}
                onChange={onChange}
                name={option}
                isInvalid={isInvalid}
                onBlur={onBlur}
              />
            ))}
          </div>
        )}
        {type === 'button' && (
          <InputGroup controlId={name}>
            <ButtonToolbar>
              <ToggleButtonGroup
                type="radio"
                name={name}
                value={value || options[0]}
                onChange={(_, event) => onChange(event)}
              >
                {options.map((option, index) => (
                  <ToggleButton value={option} variant="secondary" key={index}>
                    {option}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </ButtonToolbar>

            <Form.Control.Feedback type="invalid">{isInvalid && error}</Form.Control.Feedback>
          </InputGroup>
        )}
        {type === 'gpMeasurements' && <InputGroup controlId={name} />}
        {type === 'numberInputWithButtons' && (
          <Row>
            <Col xs={12} lg={5}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        this.subtractValue({ name, value });
                      }}
                    >
                      -
                    </Button>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="text-center"
                    type="number"
                    value={value}
                    name={name}
                    isInvalid={isInvalid}
                    onChange={e => {
                      const inputValue = e.target.value === '' ? 0 : e.target.value;
                      setFieldValue(name, inputValue);
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        this.addValue({ name, value });
                      }}
                    >
                      +
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        )}
      </Form.Group>
    );
  }
}
FormInput.propTypes = {
  detailName: PropTypes.string,
  glassType: PropTypes.string,
  glassTypeDetail: PropTypes.string,
  angles: PropTypes.array,
  details: PropTypes.array,
  detailValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDoubtful: PropTypes.bool,
  isDoubtfulName: PropTypes.string,
  label: PropTypes.string.isRequired,
  singleName: PropTypes.string,
  name: PropTypes.string.isRequired,
  name2: PropTypes.string,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  type: PropTypes.string.isRequired,
  validate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  doubtfulValue: PropTypes.bool,
};
export default FormInput;
