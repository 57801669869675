import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';
import Contact from 'components/Contact';
export default class HomeContainer extends Component {
  render() {
    return (
      <Container>
        <Contact />
      </Container>
    );
  }
}
