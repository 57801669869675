import React, { useState } from 'react';

import * as Yup from 'yup';

import { Row, Col, Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  ContainerTitle,
  Title,
  InfoText,
  Label,
  AdditionalInfoText,
  ButtonSave,
  ButtonTurnOff,
  ButtonCancel,
} from './Components';
import ModalComponent from '../../../components/ModalComponent';

const validationSchemaCompany = Yup.object().shape({
  email_notification: Yup.string().email('Invalid email address'),
});

const CompanyEmailForm = ({ updateCompany, company }) => {
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [switchCompanyEmail, setSwitchCompanyEmail] = useState(company.email_notification_enabled);

  const handleSwitchEdit = () => {
    setEdit(!edit);
  };

  const enableNotifications = () => {
    updateCompany({
      ...company,
      email_notification_enabled: true,
    });
    setSwitchCompanyEmail(true);
  };

  const disabledNotifications = () => {
    updateCompany({
      ...company,
      email_notification_enabled: false,
    });

    setSwitchCompanyEmail(false);
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          ...company,
          email_notification: company.email_notification,
        }}
        validationSchema={validationSchemaCompany}
        onSubmit={values => {
          updateCompany({
            ...company,
            email_notification: values.email_notification,
          });
          setEdit(false);
        }}
      >
        {({ values, handleChange, handleBlur, errors, touched, resetForm }) => (
          <FormikForm>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <ContainerTitle style={{ marginBottom: 16 }}>
                  <Title>Company’s email notifications</Title>
                  {switchCompanyEmail ? (
                    <ButtonCancel onClick={openModal}>Turn off</ButtonCancel>
                  ) : (
                    <ButtonSave onClick={enableNotifications}>Turn on</ButtonSave>
                  )}
                </ContainerTitle>

                <InfoText style={{ marginBottom: 16 }}>
                  You can add your company email to which you will receive notifications. In case
                  you decide not to add it, the notifications will arrive by default to the account
                  owner's email.
                </InfoText>
                <div style={{ marginBottom: 16 }}>
                  <div
                    style={{
                      padding: 8,
                      background: '#F8FBFF',
                      borderRadius: 8,
                      gap: 10,
                      display: 'inline-flex',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      style={{ width: 18, height: 18, marginTop: 2, color: '#173264' }}
                    />
                    <div
                      style={{
                        width: 612,
                        color: '#425268',
                        fontSize: 14,
                        fontFamily: 'Lato',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                      }}
                    >
                      If you don’t setup your company’s email all the notifications will be send to
                      company’s owners.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} md={5} lg={3} xl={3}>
                <Form.Group controlId="email_notification">
                  <Label>Company’s email</Label>
                  <Form.Control
                    disabled={!edit}
                    as={Field}
                    name="email_notification"
                    type="email"
                    placeholder="address@gmail.com"
                    value={values.email_notification}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email_notification &&
                      touched.email_notification &&
                      errors.email_notification}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                {edit ? (
                  <>
                    <ButtonSave type="submit" style={{ marginRight: 16 }}>
                      Save
                    </ButtonSave>
                    <ButtonCancel
                      onClick={() => {
                        handleSwitchEdit();
                        resetForm({ ...company, email_notification: company.email_notification });
                      }}
                    >
                      Cancel
                    </ButtonCancel>
                  </>
                ) : (
                  <ButtonTurnOff
                    onClick={() => {
                      handleSwitchEdit();
                    }}
                  >
                    Edit
                  </ButtonTurnOff>
                )}
              </Col>
              <Col xs={12} style={{ marginBottom: 32 }}>
                <AdditionalInfoText>
                  This email can be modified later in the notification settings.
                </AdditionalInfoText>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
      <ModalComponent
        size="sm"
        show={showModal}
        onClickCancel={disabledNotifications}
        onClickSubmit={closeModal}
        closeButton={false}
        variantCancel="danger"
        variantSubmit="secondary"
        textCancel="Turn off"
        textSubmit="Cancel"
        heightButtons={42}
        title={
          <div
            style={{
              color: '#007BFF',
              fontSize: 20,
              fontFamily: 'Lato',
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            Are you sure you want to turn off your company’s email notifications?
          </div>
        }
        body={
          <div
            style={{
              color: '#2F3033',
              fontSize: 12,
              fontFamily: 'Lato',
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            If you proceed, the specified email address will no longer receive any notifications.
          </div>
        }
      />
    </>
  );
};

export default CompanyEmailForm;

CompanyEmailForm.propTypes = {
  company: PropTypes.shape({
    app_manager: PropTypes.string.isRequired,
    email_notification: PropTypes.string.isRequired,
    email_notification_enabled: PropTypes.bool.isRequired,
  }).isRequired,
  updateCompany: PropTypes.func.isRequired,
};
