import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import * as Yup from 'yup';
import { CustomButton } from '../Elements/CustomButton';
import { inquiryFormData } from './data';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters')
    .required('Name is required'),

  message: Yup.string()
    .min(2, 'Message must be at least 2 characters')
    .required('Message is required'),
});
let initialValues = {
  email: '',
  name: '',
  message: '',
};

const InquiryForm = ({ createInquiry, error }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values, { resetForm }) => {
      createInquiry(values);
      if (!error) resetForm(initialValues);
    }}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        {inquiryFormData.map((item, index) => (
          <FormGroup key={index} className="py-md-1">
            <FormLabel className="landing-p dark-gray-color">{item.title}</FormLabel>
            <FormControl
              name={item.name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[item.name]}
              type={item.type}
              as={item.type}
              style={{
                borderRadius: '6px',
                minHeight: item.type === 'textarea' ? '150px' : null,
                verticalAlign: 'top',
              }}
              placeholder={item.placeholder}
            />
            <div className="text-danger landing-p">
              {errors[item.name] && touched[item.name] && errors[item.name]}
            </div>
          </FormGroup>
        ))}
        <CustomButton
          size="lg"
          text="Send"
          variant="primary"
          type="submit"
          className="w-100 my-md-4"
          font="landing-h8"
          handleClick={handleSubmit}
        />
      </form>
    )}
  </Formik>
);

InquiryForm.propTypes = {
  createInquiry: PropTypes.func,
};
export default InquiryForm;
