import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const userState = {
  isAuthenticated: false,
  loginCredentials: {
    email: '',
    password: '',
    remember: false,
  },
  loading: false,
  token: null,
  profile: null,
  requireLogout: false,
  company: null,
  calendars: [],
  cronofyProfile: null,
  settings: {
    profile: null,
    company: null,
  },
};

export default {
  auth: handleActions(
    {
      [ActionTypes.USER_LOGIN]: draft => {
        draft.loading = true;
      },
      [ActionTypes.LOGIN](state, action) {
        return {
          ...state,
          loginCredentials: {
            email: action.payload.email,
            password: action.payload.password,
            remember: action.payload.remember,
          },
          loadingLogin: true,
        };
      },
      [ActionTypes.USER_LOGIN_SUCCESS]: (draft, { payload }) => {
        draft.isAuthenticated = true;
        draft.profile = payload.profile;
        draft.company = payload.company;

        draft.token = payload.token;
        draft.loading = false;
      },
      [ActionTypes.USER_LOGIN_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.USER_LOGOUT]: draft => {
        draft.loading = true;
      },

      [ActionTypes.USER_LOGOUT_SUCCESS](state) {
        let res = {};
        if (!state.loginCredentials.remember) {
          res = { email: '', password: '' };
        }
        return {
          ...userState,
          loginCredentials: { ...state.loginCredentials, ...res },
        };
      },

      [ActionTypes.UPDATE_PROFILE_SUCCESS]: (draft, { payload }) => {
        draft.profile = { ...draft.profile, ...payload.profile };
      },
      [ActionTypes.UPDATE_COMPANY_SUCCESS]: (draft, { payload }) => {
        draft.company = { ...draft.company, ...payload.company };
        draft.settings.company = { ...draft.settings.company, ...payload.company };
      },
      [ActionTypes.REQUIRE_LOGOUT]: draft => {
        draft.requireLogout = true;
      },
      [ActionTypes.RECOVER_PASSWORD]: draft => {
        draft.loading = true;
      },
      [ActionTypes.RECOVER_PASSWORD_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.RECOVER_PASSWORD_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.RESET_PASSWORD]: draft => {
        draft.loading = true;
      },
      [ActionTypes.RESET_PASSWORD_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.RESET_PASSWORD_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_PROFILE]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_PROFILE_SUCCESS]: (draft, { payload: { profile } }) => {
        draft.settings.profile = profile;
        draft.loading = false;
      },
      [ActionTypes.GET_PROFILE_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_COMPANY]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_COMPANY_SUCCESS]: (draft, { payload: { company } }) => {
        draft.settings.company = company;
        draft.company = company;
        draft.loading = false;
      },
      [ActionTypes.GET_COMPANY_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.FIND_COMPANY]: draft => {
        draft.loading = true;
      },
      [ActionTypes.FIND_COMPANY_SUCCESS]: (draft, { payload: { company } }) => {
        draft.company = company;
        draft.loading = false;
      },
      [ActionTypes.FIND_COMPANY_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CLEAN_COMPANY]: draft => {
        draft.company = null;
      },
      [ActionTypes.CRONOFY_GET_CALENDARS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CRONOFY_GET_CALENDARS_SUCCESS]: (draft, { payload: { calendars, profile } }) => {
        draft.loading = false;
        draft.calendars = calendars;
        draft.cronofyProfile = profile;
      },
      [ActionTypes.CRONOFY_GET_CALENDARS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CRONOFY_REVOKE_AUTHORIZATION]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CRONOFY_REVOKE_AUTHORIZATION_SUCCESS]: draft => {
        draft.loading = false;
        draft.cronofyProfile = null;
      },
      [ActionTypes.CRONOFY_REVOKE_AUTHORIZATION_FAILURE]: draft => {
        draft.loading = false;
      },
    },
    userState,
  ),
};
