// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getUsers,
  getUser,
  createUser,
  editUser,
  deleteUser,
  getSalesman,
  getCustomer,
  getOwners,
} = createActions({
  [ActionTypes.GET_USERS]: params => params,
  [ActionTypes.GET_USER]: id => ({ id }),
  [ActionTypes.CREATE_USER]: (data, redirectPath) => ({ data, redirectPath }),
  [ActionTypes.EDIT_USER]: (id, data, redirectPath) => ({ id, data, redirectPath }),
  [ActionTypes.DELETE_USER]: id => ({ id }),
  [ActionTypes.GET_SALESMAN]: () => ({}),
  [ActionTypes.GET_CUSTOMER]: () => ({}),
  [ActionTypes.GET_OWNERS]: ({ status }) => ({ status }),
});
