export const whyChooseGlaziersFirstTitle = 'Why Choose Glaziers Tool?';
export const whyGlaziersData = [
  {
    title: 'Proven Performance',
    description:
      'Benefit from a time-tested solution trusted by thousands of glaziers who have experienced tangible results.',
  },
  {
    title: 'Industry-Tailored',
    description:
      'Enjoy specialized features designed explicitly for the glass industry, providing unparalleled precision and expertise.',
  },
  {
    title: 'Evolving Excellence',
    description:
      'Glaziers Tool continually improves and adapts to stay ahead of industry demands and user expectations.',
  },
  {
    title: 'Customer-Centric Approach',
    description:
      'We value user feedback, incorporating it into regular updates to ensure Glaziers Tool remains the ultimate solution for glaziers.',
  },
];

export const whyGlaziersSecondTitle =
  'Revolutionize your glass-related projects with Glaziers Tool';
export const whyChooseGlaziersDescription =
  'Experience unparalleled efficiency, accurate measurements, and time-saving convenience.';
