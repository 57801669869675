import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login, checkSubdomain, findCompany, cleanCompany } from 'actions/index';
import LoginUsersView from './LoginUsersView';
import LoginCompaniesView from './LoginCompaniesView';
import config from 'config';

class LoginContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onLogin: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { cleanCompany } = this.props;

    cleanCompany();
  }

  addSubdomain = values => {
    const { subdomain } = values;

    window.location.hostname = subdomain + '.' + window.location.hostname;
  };

  submitLogin = values => {
    const { onLogin } = this.props;
    const { email, password, remember } = values;

    onLogin(email, password, remember);
  };

  render() {
    const {
      onLogin,
      loading,
      loginCredentials,
      subdomainValid,
      subdomainLoading,
      checkSubdomain,
      findCompany,
      company,
      cleanCompany,
    } = this.props;

    /*if (window.location.hostname == config.HOST) {
      return (
        <LoginCompaniesView
          findCompany={findCompany}
          cleanCompany={cleanCompany}
          company={company}
          loading={loading}
          subdomainValid={subdomainValid}
          subdomainLoading={subdomainLoading}
          checkSubdomain={checkSubdomain}
          submit={this.addSubdomain}
        />
      );
    } else {*/
    return (
      <LoginUsersView
        loading={loading}
        loginCredentials={loginCredentials}
        submit={this.submitLogin}
      />
    );
    //}
  }
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    loginCredentials: state.auth.loginCredentials,
    company: state.auth.company,
    subdomainLoading: state.registerCompany.subdomainLoading,
    subdomainValid: state.registerCompany.subdomainValid,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    findCompany: email => dispatch(findCompany(email)),
    onLogin: (email, password, remember) => dispatch(login(email, password, remember)),
    checkSubdomain: subdomain => dispatch(checkSubdomain(subdomain)),
    cleanCompany: () => dispatch(cleanCompany()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
