/**
 * Configuration
 * @module config
 */

const config = {
  name: process.env.REACT_APP_NAME || 'GT - DASHBOARD',
  description: process.env.REACT_APP_DESCRIPTION || 'GT - DASHBOARD',
  BASE_URL: process.env.REACT_APP_BASE_URL,
  CRONOFY_CLIENT_ID: process.env.REACT_APP_CRONOFY_CLIENT_ID,
  BASE_URL_WEB: process.env.REACT_APP_BASE_URL_WEB,
  HOST: process.env.REACT_APP_HOST,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export default config;
