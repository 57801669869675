import BenefitsOneImg from './benefit-1.png';
import BenefitsTwoImg from './benefit-2.png';
import BenefitsThreeImg from './benefit-3.png';
import BenefitsFourImg from './benefit-4.png';

export const benefitsData = [
  {
    image: BenefitsOneImg,
    title: 'Anytime, Anywhere Access',
    description:
      'As a web-based app, Glaziers Tool is accessible from any device with an internet connection, allowing you to work on the go and collaborate remotely.',
  },
  {
    image: BenefitsTwoImg,
    title: 'All-in-One Solution',
    description:
      'Enjoy a comprehensive platform that combines essential tools and resources tailored specifically for glaziers, ensuring precision and expert guidance.',
  },
  {
    image: BenefitsThreeImg,
    title: 'Cost Savings & Waste Reduction',
    description:
      'Make informed decisions with accurate data to avoid overbuying materials and discover the best deals, ultimately saving time and money.',
  },
  {
    image: BenefitsFourImg,
    title: 'Simplified Ordering',
    description:
      'Easily identify all required materials in one place, streamlining the ordering process and enhancing customer service.',
  },
];
