// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  login,
  logout,
  findCompany,
  cleanCompany,
  recoverPassword,
  resetPassword,
  updateProfile,
  updateCompany,
  changePassword,
  getProfile,
  cronofyRegisterUser,
  cronofyGetCalendars,
  cronofyGetCalendarsSuccess,
  getCompany,
  cronofyRevokeAuthorization,
} = createActions({
  [ActionTypes.LOGIN]: (email, password, remember) => ({
    email,
    password,
    remember,
  }),
  [ActionTypes.LOGOUT]: () => ({}),
  [ActionTypes.FIND_COMPANY]: email => ({ email }),
  [ActionTypes.CLEAN_COMPANY]: () => ({}),
  [ActionTypes.RECOVER_PASSWORD]: email => ({ email }),
  [ActionTypes.RESET_PASSWORD]: (token_pass_recovery, password) => ({
    token_pass_recovery,
    password,
  }),
  [ActionTypes.GET_PROFILE]: () => ({}),
  [ActionTypes.GET_COMPANY]: () => ({}),

  [ActionTypes.UPDATE_PROFILE]: data => ({ data }),
  [ActionTypes.UPDATE_COMPANY]: data => ({ data }),

  [ActionTypes.CHANGE_PASSWORD]: data => ({ data }),

  [ActionTypes.CRONOFY_REGISTER_USER]: data => ({ data }),
  [ActionTypes.CRONOFY_GET_CALENDARS]: () => ({}),
  [ActionTypes.CRONOFY_GET_CALENDARS_SUCCESS]: data => data,
  [ActionTypes.CRONOFY_REVOKE_AUTHORIZATION]: data => ({ data }),
});
