/**
 * @module Sagas/User
 * @desc User
 */

import { put, call } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { showAlert } from 'actions';
import { push } from 'modules/history';

export function* getUsers(api, action) {
  const { payload } = action;

  const { status, data } = yield call(api.getUsers, payload);

  if (status === 200) {
    const { rows, pages, currentPage } = data;

    yield put({
      type: ActionTypes.GET_USERS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_USERS_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getUser(api, action) {
  const {
    payload: { id },
  } = action;
  const { status, data } = yield call(api.getUser, id);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_USER_SUCCESS,
      payload: { user: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_USER_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getSalesman(api) {
  const { status, data } = yield call(api.getSalesman);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SALESMAN_SUCCESS,
      payload: { salesmans: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SALESMAN_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getCustomer(api) {
  const { status, data } = yield call(api.getCustomer);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_CUSTOMER_SUCCESS,
      payload: { csrs: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_CUSTOMER_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getOwners(api, action) {
  const {
    payload: { status: ownerStatus },
  } = action;

  const { status, data } = yield call(api.getOwners, { status: ownerStatus });

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_OWNERS_SUCCESS,
      payload: { owners: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_OWNERS_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* createUser(api, action) {
  const {
    payload: { data, redirectPath = '/dashboard/users' },
  } = action;
  const response = yield call(api.createUser, data);
  const { status, data: responseData } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.CREATE_USER_SUCCESS,
    });
    yield put(showAlert('User Created', { variant: 'success' }));
    push(redirectPath);
  } else {
    const { errors } = responseData;
    yield put({
      type: ActionTypes.CREATE_USER_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* editUser(api, action) {
  const {
    payload: { id, data: user, redirectPath = '/dashboard/users' },
  } = action;
  const { status, data } = yield call(api.editUser, id, user);
  if (status === 200) {
    yield put({
      type: ActionTypes.EDIT_USER_SUCCESS,
      payload: { id, user },
    });
    yield put(showAlert('User Edited', { variant: 'success' }));
    push(redirectPath);
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.EDIT_USER_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* deleteUser(api, action) {
  const {
    payload: { id },
  } = action;
  const { status } = yield call(api.deleteUser, id);

  if (status === 200) {
    yield put({
      type: ActionTypes.DELETE_USER_SUCCESS,
      payload: { id },
    });
    yield put(showAlert('User Deleted', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.DELETE_USER_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
