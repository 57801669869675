export const CaulkTypes = [
  { label: '799-Clear (T)', value: '799-Clear (T)' },
  { label: '999-A-Clear (T)', value: '999-A-Clear (T)' },
  { label: '790-Black (S)', value: '790-Black (S)' },
  { label: '790-Black (T)', value: '790-Black (T)' },
  { label: '795-Black (S)', value: '795-Black (S)' },
  { label: '795-Black (T)', value: '795-Black (T)' },
  { label: '995-Black (S)', value: '995-Black (S)' },
  { label: '999-A-Black (T)', value: '999-A-Black (T)' },
  { label: 'Dymonic-Black (S)', value: 'Dymonic-Black (S)' },
  { label: 'Dymonic-Black (T)', value: 'Dymonic-Black (T)' },
  { label: '790-Bronze (S)', value: '790-Bronze (S)' },
  { label: '790-Bronze (T)', value: '790-Bronze (T)' },
  { label: '795-Bronze (S)', value: '795-Bronze (S)' },
  { label: '795-Bronze (T)', value: '795-Bronze (T)' },
  { label: '999-A-Bronze (T)', value: '999-A-Bronze (T)' },
  { label: 'Dymonic-Bronze (S)', value: 'Dymonic-Bronze (S)' },
  { label: 'Dymonic-Bronze (T)', value: 'Dymonic-Bronze (T)' },
  { label: '795-Anodized Aluminum (S)', value: '795-Anodized Aluminum (S)' },
  { label: '795-Anodized Aluminum (T)', value: '795-Anodized Aluminum (T)' },
  { label: '999-A-Aluminum (T)', value: '999-A-Aluminum (T)' },
  { label: 'Dymonic-Anodized Aluminum (S)', value: 'Dymonic-Anodized Aluminum (S)' },
  { label: 'Dymonic-Anodized Aluminum (T)', value: 'Dymonic-Anodized Aluminum (T)' },
  { label: '790-White (S)', value: '790-White (S)' },
  { label: '790-White (T)', value: '790-White (T)' },
  { label: '795-White (S)', value: '795-White (S)' },
  { label: '795-White (T)', value: '795-White (T)' },
  { label: '995-White (S)', value: '995-White (S)' },
  { label: '999-A-White (T)', value: '999-A-White (T)' },
  { label: 'Dymonic-White (S)', value: 'Dymonic-White (S)' },
  { label: 'Dymonic-White (T)', value: 'Dymonic-White (T)' },
  { label: 'Rockite (Bucket)', value: 'Rockite (Bucket)' },
  { label: 'Rockite (Gallon)', value: 'Rockite (Gallon)' },
  { label: 'Epoxy (Gallon)', value: 'Epoxy (Gallon)' },
  { label: 'Putty White (Pint) - Wood', value: 'Putty White (Pint) - Wood' },
  { label: 'Putty White (Gallon) - Wood', value: 'Putty White (Gallon) - Wood' },
  { label: 'Putty Grey (Pint) - Metal', value: 'Putty Grey (Pint) - Metal' },
  { label: 'Putty Grey (Gallon) - Metal', value: 'Putty Grey (Gallon) - Metal' },
  { label: '790-Adobe Tan (S)', value: '790-Adobe Tan (S)' },
  { label: '790-Adobe Tan (T)', value: '790-Adobe Tan (T)' },
  { label: '790-Blue Spruce (S)', value: '790-Blue Spruce (S)' },
  { label: '790-Blue Spruce (T)', value: '790-Blue Spruce (T)' },
  { label: '790-Charcoal (S)', value: '790-Charcoal (S)' },
  { label: '790-Charcoal (T)', value: '790-Charcoal (T)' },
  { label: '790-Dusty Rose (S)', value: '790-Dusty Rose (S)' },
  { label: '790-Dusty Rose (T)', value: '790-Dusty Rose (T)' },
  { label: '790-Gray (S)', value: '790-Gray (S)' },
  { label: '790-Gray (T)', value: '790-Gray (T)' },
  { label: '790-Limestone (S)', value: '790-Limestone (S)' },
  { label: '790-Limestone (T)', value: '790-Limestone (T)' },
  { label: '790-Natural Stone (S)', value: '790-Natural Stone (S)' },
  { label: '790-Natural Stone (T)', value: '790-Natural Stone (T)' },
  { label: '790-Precast White (S)', value: '790-Precast White (S)' },
  { label: '790-Precast White (T)', value: '790-Precast White (T)' },
  { label: '790-Rustic Brick (S)', value: '790-Rustic Brick (S)' },
  { label: '790-Rustic Brick (T)', value: '790-Rustic Brick (T)' },
  { label: '790-Sandstone (S)', value: '790-Sandstone (S)' },
  { label: '790-Sandstone (T)', value: '790-Sandstone (T)' },
  { label: '795-Adobe Tan (S)', value: '795-Adobe Tan (S)' },
  { label: '795-Adobe Tan (T)', value: '795-Adobe Tan (T)' },
  { label: '795-Blue Spruce (S)', value: '795-Blue Spruce (S)' },
  { label: '795-Blue Spruce (T)', value: '795-Blue Spruce (T)' },
  { label: '795-Champagne (S)', value: '795-Champagne (S)' },
  { label: '795-Champagne (T)', value: '795-Champagne (T)' },
  { label: '795-Charcoal (S)', value: '795-Charcoal (S)' },
  { label: '795-Charcoal (T)', value: '795-Charcoal (T)' },
  { label: '795-Dusty Rose (S)', value: '795-Dusty Rose (S)' },
  { label: '795-Dusty Rose (T)', value: '795-Dusty Rose (T)' },
  { label: '795-Gray (S)', value: '795-Gray (S)' },
  { label: '795-Gray (T)', value: '795-Gray (T)' },
  { label: '795-Limestone (S)', value: '795-Limestone (S)' },
  { label: '795-Limestone (T)', value: '795-Limestone (T)' },
  { label: '795-Natural Stone (S)', value: '795-Natural Stone (S)' },
  { label: '795-Natural Stone (T)', value: '795-Natural Stone (T)' },
  { label: '795-Rustic Brick (S)', value: '795-Rustic Brick (S)' },
  { label: '795-Rustic Brick (T)', value: '795-Rustic Brick (T)' },
  { label: '795-Sandstone (S)', value: '795-Sandstone (S)' },
  { label: '795-Sandstone (T)', value: '795-Sandstone (T)' },
  { label: '995-Gray (S)', value: '995-Gray (S)' },
  { label: '999-A-Light Bronze (T)', value: '999-A-Light Bronze (T)' },
  { label: 'Dymonic-Almond (S)', value: 'Dymonic-Almond (S)' },
  { label: 'Dymonic-Almond (T)', value: 'Dymonic-Almond (T)' },
  { label: 'Dymonic-Aluminum Stone (S)', value: 'Dymonic-Aluminum Stone (S)' },
  { label: 'Dymonic-Aluminum Stone (T)', value: 'Dymonic-Aluminum Stone (T)' },
  { label: 'Dymonic-Beige (S)', value: 'Dymonic-Beige (S)' },
  { label: 'Dymonic-Beige (T)', value: 'Dymonic-Beige (T)' },
  { label: 'Dymonic-Buff (S)', value: 'Dymonic-Buff (S)' },
  { label: 'Dymonic-Buff (T)', value: 'Dymonic-Buff (T)' },
  { label: 'Dymonic-Dark Bronze (S)', value: 'Dymonic-Dark Bronze (S)' },
  { label: 'Dymonic-Dark Bronze (T)', value: 'Dymonic-Dark Bronze (T)' },
  { label: 'Dymonic-Gray (S)', value: 'Dymonic-Gray (S)' },
  { label: 'Dymonic-Gray (T)', value: 'Dymonic-Gray (T)' },
  { label: 'Dymonic-Gray Stone (S)', value: 'Dymonic-Gray Stone (S)' },
  { label: 'Dymonic-Gray Stone (T)', value: 'Dymonic-Gray Stone (T)' },
  { label: 'Dymonic-Hartford Green (S)', value: 'Dymonic-Hartford Green (S)' },
  { label: 'Dymonic-Hartford Green (T)', value: 'Dymonic-Hartford Green (T)' },
  { label: 'Dymonic-Ivory (S)', value: 'Dymonic-Ivory (S)' },
  { label: 'Dymonic-Ivory (T)', value: 'Dymonic-Ivory (T)' },
  { label: 'Dymonic-Light Bronze (S)', value: 'Dymonic-Light Bronze (S)' },
  { label: 'Dymonic-Light Bronze (T)', value: 'Dymonic-Light Bronze (T)' },
  {
    label: 'Research',
    value: 'Research',
  },
  {
    label: 'Standard (per application)',
    value: 'Standard (per application)',
  },
  {
    label: 'Other (see notes)',
    value: 'Other (see notes)',
  },
];

export const TapeTypes = [
  { label: '1/8 440 tape', value: '1_over_8 440 tape' },
  { label: '1/4 440 tape', value: '1_over_4 440 tape' },
  { label: '1/2 440 tape', value: '1_over_2 440 tape' },
  { label: '3/4 440 tape', value: '3_over_4 440 tape' },
  { label: 'Foam tape', value: 'foam_tape' },
  { label: 'Double faced tape', value: 'double_faced_tape' },
  { label: 'Cladding tape', value: 'dladding_tape' },
  {
    label: 'Research',
    value: 'Research',
  },
  {
    label: 'Standard (per application)',
    value: 'Standard (per application)',
  },
  {
    label: 'Other (see notes)',
    value: 'Other (see notes)',
  },
];

export const EquipmentType = [
  { label: 'Swing Stage (SAG)', value: 'Swing Stage (SAG)' },
  { label: 'Swing Stage (SUB)', value: 'Swing Stage (SUB)' },
  { label: 'Scaffolding (SAG)', value: 'Scaffolding (SAG)' },
  { label: 'Scaffolding - HALF (SAG)', value: 'Scaffolding - HALF (SAG)' },
  { label: 'Scaffolding (SUB, RENT)', value: 'Scaffolding (SUB, RENT)' },
  { label: 'Scaffolding (SUB, SETUP)', value: 'Scaffolding (SUB, SETUP)' },
  { label: 'Scaffolding - BAKER (SUB.RENT)', value: 'Scaffolding - BAKER (SUB.RENT)' },
  { label: 'Scaffolding - BAKER (SUB.SETUP)', value: 'Scaffolding - BAKER (SUB.SETUP)' },
  { label: 'Scaffolding - Other: Size in Notes', value: 'Scaffolding - Other: Size in Notes' },
  { label: "Articulating Lift - 40'", value: "Articulating Lift - 40'" },
  { label: "Articulating Lift - 60'", value: "Articulating Lift - 60'" },
  { label: "Articulating Lift - 80'", value: "Articulating Lift - 80'" },
  { label: "Articulating Lift - 120'", value: "Articulating Lift - 120'" },
  { label: "Articulating Lift - 135'", value: "Articulating Lift - 135'" },
  {
    label: 'Articulating Lift - Other: Size in Notes',
    value: 'Articulating Lift - Other: Size in Notes',
  },
  { label: "STICK BOOM  - 40'", value: "STICK BOOM  - 40'" },
  { label: "STICK BOOM - 60'", value: "STICK BOOM - 60'" },
  { label: "STICK BOOM - 80'", value: "STICK BOOM - 80'" },
  { label: "STICK BOOM - 120'", value: "STICK BOOM - 120'" },
  { label: "STICK BOOM - 135'", value: "STICK BOOM - 135'" },
  { label: 'STICK BOOM - Other: Size in Notes', value: 'STICK BOOM - Other: Size in Notes' },
  { label: "Scissor Lift - 19'", value: "Scissor Lift - 19'" },
  { label: "Scissor Lift - 24-26'", value: "Scissor Lift - 24-26'" },
  { label: "Scissor Lift - 30-35'", value: "Scissor Lift - 30-35'" },
  { label: "Scissor Lift - 39-40'", value: "Scissor Lift - 39-40'" },
  { label: "Scissor Lift - 25-27' - 4WD ", value: "Scissor Lift - 25-27' - 4WD " },
  { label: "Scissor Lift - 36-49' - 4WD ", value: "Scissor Lift - 36-49' - 4WD " },
  { label: 'Scissor Lift - Other: Size in Notes', value: 'Scissor Lift - Other: Size in Notes' },
  { label: 'LADDER - STEP', value: 'LADDER - STEP' },
  { label: "LADDER - 3'", value: "LADDER - 3'" },
  { label: "LADDER - 6'", value: "LADDER - 6'" },
  { label: 'LADDER - Extension', value: 'LADDER - Extension' },
  { label: 'LADDER - Gorilla ', value: 'LADDER - Gorilla ' },
  { label: 'LADDER - Other: Size in Notes', value: 'LADDER - Other: Size in Notes' },
  {
    label: 'Glass Manipulator - Other: Size in Notes',
    value: 'Glass Manipulator - Other: Size in Notes',
  },
  { label: 'Single Tech Basket: Size in Notes', value: 'Single Tech Basket: Size in Notes' },
  { label: 'Towable Boom: Size in Notes', value: 'Towable Boom: Size in Notes' },
  { label: 'Generator', value: 'Generator' },
  { label: 'Power Cup', value: 'Power Cup' },
  { label: 'Betamax', value: 'Betamax' },
  { label: 'Crane', value: 'Crane' },
  { label: 'Lull Lift', value: 'Lull Lift' },
  { label: 'Fork Lift w/ Boom ', value: 'Fork Lift w/ Boom ' },
  { label: 'Chain Fall', value: 'Chain Fall' },
  { label: 'Traffic Control', value: 'Traffic Control' },
  { label: 'Street Closure Permit ', value: 'Street Closure Permit ' },
  { label: 'Research', value: 'Research' },
  { label: 'other (see notes)', value: 'other (see notes)' },
];

export const ChannelType = [
  { label: 'J-Channel 5/8 Chrome', value: 'J-Channel 5/8 Chrome' },
  { label: 'J-Channel 5/8 Gold', value: 'J-Channel 5/8 Gold' },
  { label: 'J-Channel 5/8 Brushed Nickel', value: 'J-Channel 5/8 Brushed Nickel' },
  { label: 'J-Channel 5/8 Oil Rubbed Bronze', value: 'J-Channel 5/8 Oil Rubbed Bronze' },
  { label: 'J-Channel 3/8 Chrome', value: 'J-Channel 3/8 Chrome' },
  { label: 'J-Channel 3/8 Gold', value: 'J-Channel 3/8 Gold' },
  { label: 'J-Channel 3/8 Brushed Nickel', value: 'J-Channel 3/8 Brushed Nickel' },
  { label: 'J-Channel 3/8 Oil Rubbed Bronze', value: 'J-Channel 3/8 Oil Rubbed Bronze' },
  { label: 'L-Channel Chrome', value: 'L-Channel Chrome' },
  { label: 'L-Channel Gold', value: 'L-Channel Gold' },
  { label: 'L-Channel Brushed Nickel', value: 'L-Channel Brushed Nickel' },
  { label: 'L-Channel Oil Rubbed Bronze', value: 'L-Channel Oil Rubbed Bronze' },
  {
    label: 'U-Channel 3/4 x 3/4 Clear Anodized/ Satin',
    value: 'U-Channel 3/4 x 3/4 Clear Anodized/ Satin',
  },
  {
    label: 'U-Channel 3/4 x 1-1/2 Clear Anodized/ Satin',
    value: 'U-Channel 3/4 x 1-1/2 Clear Anodized/ Satin',
  },
  {
    label: 'U-Channel 1 x 1 Clear Anodized/ Satin',
    value: 'U-Channel 1 x 1 Clear Anodized/ Satin',
  },
  {
    label: 'U-Channel 1 x 1 Polished Brite Silver',
    value: 'U-Channel 1 x 1 Polished Brite Silver',
  },
  { label: 'U-Channel 1 x 1 Brushed Stainless', value: 'U-Channel 1 x 1 Brushed Stainless' },
  {
    label: 'U-Channel 1 x 2 Clear Anodized/ Satin',
    value: 'U-Channel 1 x 2 Clear Anodized/ Satin',
  },
  {
    label: 'U-Channel 1 x 2 Polished Brite Silver',
    value: 'U-Channel 1 x 2 Polished Brite Silver',
  },
  { label: 'U-Channel 1 x 2 Brushed Stainless', value: 'U-Channel 1 x 2 Brushed Stainless' },
  { label: 'Tube - Chrome', value: 'Tube - Chrome' },
  { label: 'Tube - Brushed Nickel', value: 'Tube - Brushed Nickel' },
  { label: 'Tube - Oil Rubbed Bronze', value: 'Tube - Oil Rubbed Bronze' },
  {
    label: 'Glazing Rubber for U Channel, 1/4 Glass - Black',
    value: 'Glazing Rubber for U Channel, 1/4 Glass - Black',
  },
  {
    label: 'Glazing Rubber for U Channel, 3/8 Glass - Black',
    value: 'Glazing Rubber for U Channel, 3/8 Glass - Black',
  },
  {
    label: 'Glazing Rubber for U Channel, 1/2 Glass - Black',
    value: 'Glazing Rubber for U Channel, 1/2 Glass - Black',
  },
  {
    label: 'Research',
    value: 'Research',
  },
  {
    label: 'Standard (per application)',
    value: 'Standard (per application)',
  },
  {
    label: 'Other (see notes)',
    value: 'Other (see notes)',
  },
];

export const DamageWaiver = [
  {
    label: 'Removal/Reinstall of Glass Currently Installed',
    value: 'Removal/Reinstall of Glass Currently Installed ',
  },
  { label: 'Handling of Customers Materials', value: 'Handling of Customers Materials ' },
  { label: 'Adjacent Glass', value: 'Adjacent Glass' },
  { label: 'Other', value: 'Other' },
];

export const Disclaimers = [
  { label: 'Wood Bead', value: 'Wood Bead' },
  { label: 'Painted Frames (AFTERMARKET)', value: 'Painted Frames (AFTERMARKET)' },
  { label: 'TBD', value: 'TBD' },
  { label: 'Other', value: 'Other' },
];
