// @flow
/**
 * @module Actions/Event
 * @desc Event Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  registerCompany,
  registerCompanySaveData,
  registerCompanyChangeStep,
  getProducts,
  checkSubdomain,
  resetRegisterForm,
} = createActions({
  [ActionTypes.REGISTER_COMPANY]: () => ({}),
  [ActionTypes.REGISTER_COMPANY_SAVE_DATA]: params => params,
  [ActionTypes.REGISTER_COMPANY_CHANGE_STEP]: params => params,
  [ActionTypes.GET_PRODUCTS]: () => ({}),
  [ActionTypes.CHECK_SUBDOMAIN]: subdomain => ({ subdomain }),
  [ActionTypes.RESET_REGISTER_FORM]: () => ({}),
});
