import React, { Component } from 'react';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Field } from 'formik';
import { extraMeasurments } from 'constants/glassTypeOptions';
import { isRequired } from 'utils/rules';
import { create, all } from 'mathjs';

const config = {};
const math = create(all, config);

export default class GlassRectangle extends Component {
  render() {
    const { values, errors, handleChange, touched, handleBlur, prefix, setFieldValue } = this.props;

    const handleChangeGlassDlo = (evt, type) => {
      const { value } = evt.target;
      let new_value;
      const currentMeasurment = extraMeasurments.find(e => e.value == values[`extra_${type}`]);
      if (currentMeasurment && type == 'width') {
        new_value = math.add(math.fraction(value), math.fraction(currentMeasurment.extra_width));
      } else {
        new_value = math.add(math.fraction(value), math.fraction(currentMeasurment.extra_height));
      }
      const integer = math.floor(new_value);
      const decimal = math.fraction(new_value - integer);
      const valuenew = `${integer} ${decimal.n}/${decimal.d}`;
      if (decimal.n == 0) {
        setFieldValue(`${prefix}.go_${type}`, `${integer}`);
      } else {
        setFieldValue(`${prefix}.go_${type}`, valuenew);
      }
    };

    const handleChangeGlassExtra = (evt, type) => {
      const { value, name } = evt.target;
      setFieldValue(name, value);
      let new_value;
      let dlo_width_value = values[`dlo_${type}`];

      if (dlo_width_value == '') {
        dlo_width_value = 0;
      }

      let expresion = /^(\d+(?:(?: \d+)*\/\d+)?)$/g;
      let validate = expresion.test(dlo_width_value);
      const currentMeasurment = extraMeasurments.find(e => e.value == value);
      if (currentMeasurment && validate == true && type == 'width') {
        new_value = math.add(
          math.fraction(dlo_width_value),
          math.fraction(currentMeasurment.extra_width),
        );
      } else if (validate == true) {
        new_value = math.add(
          math.fraction(dlo_width_value),
          math.fraction(currentMeasurment.extra_height),
        );
      }
      const integer = math.floor(new_value);
      const decimal = math.fraction(new_value - integer);
      const valuenew = `${integer} ${decimal.n}/${decimal.d}`;

      if (decimal.n == 0) {
        setFieldValue(`${prefix}.go_${type}`, `${integer}`);
      } else {
        setFieldValue(`${prefix}.go_${type}`, valuenew);
      }
    };

    const setCheckboxValue = (name, value) => {
      if (value === '0' || value === 0 || value === '') {
        setFieldValue(name, false);
      } else {
        setFieldValue(name, true);
      }
    };

    return (
      <Row style={{ marginBottom: '40px' }}>
        <Col xs={12} md={12} sm={12} xl={12}>
          <Form.Label>Glass Sizes</Form.Label>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} className="mt-3">
          <Form.Check
            as={Field}
            type="switch"
            id={`${prefix}.dlo_avaliable`}
            name={`${prefix}.dlo_avaliable`}
            defaultValue={values.dlo_avaliable}
            onChange={handleChange}
            label="DLO"
          />
        </Col>
        {!!values.dlo_avaliable && (
          <>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.dlo_width`}>
                <Form.Label>DLO Width</Form.Label>
                <InputGroup.Prepend
                  className={
                    values.dlo_width_doubt === false || !values.dlo_width_doubt
                      ? 'background-deactivated'
                      : 'background-activated'
                  }
                >
                  <InputGroup.Checkbox
                    name={`${prefix}.dlo_width_doubt`}
                    id={`${prefix}.dlo_width_doubt`}
                    onChange={evt => {
                      const { checked } = evt.target;
                      setFieldValue(`${prefix}.dlo_width_doubt`, checked);
                    }}
                    checked={values.dlo_width_doubt || false}
                  />
                  <Form.Control
                    as={Field}
                    name={`${prefix}.dlo_width`}
                    type="text"
                    value={values.dlo_width}
                    onChange={evt => {
                      const { value, name } = evt.target;
                      setFieldValue(name, value);
                      setCheckboxValue(`${prefix}.dlo_width_doubt`, value);
                      setCheckboxValue(`${prefix}.go_width_doubt`, value);
                      let expresion = /^(\d+(?:(?: \d+)*\/\d+)?)$/g;
                      let validate = expresion.test(value);
                      if (validate) {
                        handleChangeGlassDlo(evt, 'width');
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </InputGroup.Prepend>
              </Form.Group>
            </Col>

            <Col xs={6} sm={6} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.dlo_height`}>
                <Form.Label>DLO Height</Form.Label>
                <InputGroup.Prepend
                  className={
                    values.dlo_height_doubt === false || !values.dlo_height_doubt
                      ? 'background-deactivated'
                      : 'background-activated'
                  }
                >
                  <InputGroup.Checkbox
                    name={`${prefix}.dlo_height_doubt`}
                    id={`${prefix}.dlo_height_doubt`}
                    onChange={evt => {
                      const { checked } = evt.target;
                      setFieldValue(`${prefix}.dlo_height_doubt`, checked);
                    }}
                    checked={values.dlo_height_doubt || false}
                  />
                  <Form.Control
                    as={Field}
                    name={`${prefix}.dlo_height`}
                    type="text"
                    value={values.dlo_height}
                    onChange={evt => {
                      const { value, name } = evt.target;
                      setFieldValue(name, value);
                      setCheckboxValue(`${prefix}.dlo_height_doubt`, value);
                      setCheckboxValue(`${prefix}.go_height_doubt`, value);
                      let expresion = /^(\d+(?:(?: \d+)*\/\d+)?)$/g;
                      let validate = expresion.test(value);
                      if (validate) {
                        handleChangeGlassDlo(evt, 'height');
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </InputGroup.Prepend>
              </Form.Group>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.extra_width`}>
                <Form.Label>DLO ADD WIDTH</Form.Label>
                <InputGroup.Prepend
                  className={
                    values.extra_width_doubt === false || !values.extra_width_doubt
                      ? 'background-deactivated'
                      : 'background-activated'
                  }
                >
                  <InputGroup.Checkbox
                    name={`${prefix}.extra_width_doubt`}
                    id={`${prefix}.extra_width_doubt`}
                    onChange={evt => {
                      const { checked } = evt.target;
                      setFieldValue(`${prefix}.extra_width_doubt`, checked);
                    }}
                    checked={values.extra_width_doubt || false}
                  />
                  <Field
                    as="select"
                    className={
                      errors.extra_width && touched.extra_width
                        ? 'form-control is-invalid is-invalid-select'
                        : 'form-control'
                    }
                    name={`${prefix}.extra_width`}
                    value={values.extra_width}
                    onChange={evt => {
                      const { value, name } = evt.target;
                      setCheckboxValue(`${prefix}.extra_width_doubt`, value);
                      setCheckboxValue(`${prefix}.go_width_doubt`, value);
                      handleChangeGlassExtra(evt, 'width');
                    }}
                    onBlur={handleBlur}
                  >
                    {extraMeasurments.map(({ label, value }, index) => (
                      <option value={value} prefix={index}>
                        {label}
                      </option>
                    ))}
                  </Field>
                </InputGroup.Prepend>
              </Form.Group>
            </Col>

            <Col xs={6} sm={6} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.extra_height`}>
                <Form.Label>DLO ADD HEIGHT</Form.Label>
                <InputGroup.Prepend
                  className={
                    values.extra_height_doubt === false || !values.extra_height_doubt
                      ? 'background-deactivated'
                      : 'background-activated'
                  }
                >
                  <InputGroup.Checkbox
                    name={`${prefix}.extra_height_doubt`}
                    id={`${prefix}.extra_height_doubt`}
                    onChange={evt => {
                      const { checked } = evt.target;
                      setFieldValue(`${prefix}.extra_height_doubt`, checked);
                    }}
                    checked={values.extra_height_doubt || false}
                  />
                  <Field
                    as="select"
                    className={
                      errors.extra_height && touched.extra_height
                        ? 'form-control is-invalid is-invalid-select'
                        : 'form-control'
                    }
                    name={`${prefix}.extra_height`}
                    value={values.extra_height}
                    onChange={evt => {
                      const { value, name } = evt.target;
                      setCheckboxValue(`${prefix}.extra_height_doubt`, value);
                      setCheckboxValue(`${prefix}.go_height_doubt`, value);
                      handleChangeGlassExtra(evt, 'height');
                    }}
                    onBlur={handleBlur}
                  >
                    {extraMeasurments.map(({ label, value }, index) => (
                      <option value={value} prefix={index}>
                        {label}
                      </option>
                    ))}
                  </Field>
                </InputGroup.Prepend>
              </Form.Group>
            </Col>
          </>
        )}
        <Col xs={12} sm={12} md={12} xl={12} className="mt-3">
          <Form.Check
            as={Field}
            type="switch"
            id={`${prefix}.go_avaliable`}
            name={`${prefix}.go_avaliable`}
            defaultValue={values.go_avaliable}
            onChange={handleChange}
            label="GO"
          />
        </Col>
        {!!values.go_avaliable && (
          <>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.go_width`}>
                <Form.Label>GO Width</Form.Label>
                <InputGroup.Prepend
                  className={
                    values.go_width_doubt === false || !values.go_width_doubt
                      ? 'background-deactivated'
                      : 'background-activated'
                  }
                >
                  <InputGroup.Checkbox
                    name={`${prefix}.go_width_doubt`}
                    id={`${prefix}.go_width_doubt`}
                    onChange={evt => {
                      const { checked } = evt.target;
                      setFieldValue(`${prefix}.go_width_doubt`, checked);
                    }}
                    checked={values.go_width_doubt || false}
                  />

                  <Form.Control
                    as={Field}
                    name={`${prefix}.go_width`}
                    placeholder="0"
                    value={values.go_width}
                    onChange={evt => {
                      const { value, name } = evt.target;
                      setFieldValue(`${prefix}.go_width`, value);
                      if (value) {
                        setFieldValue(`${prefix}.go_width_doubt`, true);
                      } else {
                        setFieldValue(`${prefix}.go_width_doubt`, false);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </InputGroup.Prepend>
              </Form.Group>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.go_height`}>
                <Form.Label>GO Height</Form.Label>
                <InputGroup.Prepend
                  className={
                    values.go_height_doubt === false || !values.go_height_doubt
                      ? 'background-deactivated'
                      : 'background-activated'
                  }
                >
                  <InputGroup.Checkbox
                    name={`${prefix}.go_height_doubt`}
                    id={`${prefix}.go_height_doubt`}
                    onChange={evt => {
                      const { checked } = evt.target;
                      setFieldValue(`${prefix}.go_height_doubt`, checked);
                    }}
                    checked={values.go_height_doubt || false}
                  />

                  <Form.Control
                    as={Field}
                    name={`${prefix}.go_height`}
                    placeholder="0"
                    value={values.go_height}
                    onChange={evt => {
                      const { value, name } = evt.target;
                      setFieldValue(`${prefix}.go_height`, value);
                      if (value) {
                        setFieldValue(`${prefix}.go_height_doubt`, true);
                      } else {
                        setFieldValue(`${prefix}.go_height_doubt`, false);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </InputGroup.Prepend>
              </Form.Group>
            </Col>
          </>
        )}
      </Row>
    );
  }
}
