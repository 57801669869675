import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { Button } from 'react-bootstrap';

const PlanBoxContainer = styled.div`
  border-radius: 4px;
  padding: 15px;
  text-align: -webkit-center;
  height: 290px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10px 20px 10px;
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-color: ${Colors.grayLight};
  width: 250px;
  height: 400px;
  margin: 10px;
  margin-top: 30px;
  margin-right: 40px;
`;

const PlanPrice = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 69px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${Colors.blue};
  margin-top: 20px;
  margin-bottom: 20px;

  position: relative;
`;

const ContainsContainer = styled.div`
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const Dollar = styled.span`
  font-size: 34px;
  position: absolute;
  top: 17px;
  left: -23px;
`;

export default class PlanBox extends Component {
  render() {
    const {
      product_id,
      key,
      selectPlan,
      product: { id, title, price, Features },
    } = this.props;

    return (
      <PlanBoxContainer>
        <PlanPrice>
          <Dollar>$</Dollar>
          {price}
        </PlanPrice>
        <h2 weight="bold">{title}</h2>

        <ContainsContainer>
          {Features.map(feature => (
            <h4 className="my-3" color={Colors.gray}>
              {feature.name}
            </h4>
          ))}
        </ContainsContainer>

        <Button
          size="lg"
          color={Colors.blue}
          variant={id == product_id ? 'outline-primary' : 'primary'}
          block
          style={{
            marginTop: '30px',
            borderRadius: '20px',
            borderRadius: '20px',
            height: '43px',
          }}
          onClick={() => selectPlan(id)}
          selected={id == product_id}
        >
          {id == product_id ? 'Choosen' : 'Subscribe'}
        </Button>
      </PlanBoxContainer>
    );
  }
}
