import { keyMirror } from 'modules/helpers';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  //
  STARTUP: undefined,
  EXCEPTION: undefined,
  LOGIN: undefined,
  LOGIN_SUCCESS: undefined,
  LOGIN_FAILURE: undefined,
  USER_LOGIN: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  LOGOUT: undefined,
  LOGOUT_SUCCESS: undefined,
  LOGOUT_FAILURE: undefined,

  FIND_COMPANY: undefined,
  FIND_COMPANY_SUCCESS: undefined,
  FIND_COMPANY_FAILURE: undefined,
  CLEAN_COMPANY: undefined,

  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  RECOVER_PASSWORD: undefined,
  RECOVER_PASSWORD_SUCCESS: undefined,
  RECOVER_PASSWORD_FAILURE: undefined,
  RESET_PASSWORD: undefined,
  RESET_PASSWORD_SUCCESS: undefined,
  RESET_PASSWORD_FAILURE: undefined,
  UPDATE_PROFILE: undefined,
  UPDATE_PROFILE_SUCCESS: undefined,
  UPDATE_COMPANY: undefined,
  UPDATE_COMPANY_SUCCESS: undefined,
  UPDATE_COMPANY_FAILURE: undefined,

  REQUIRE_LOGOUT: undefined,
  CHANGE_PASSWORD: undefined,
  CRONOFY_GET_CALENDARS: undefined,
  CRONOFY_GET_CALENDARS_SUCCESS: undefined,
  CRONOFY_GET_CALENDARS_FAILURE: undefined,
  CRONOFY_REGISTER_USER: undefined,
  CRONOFY_REVOKE_AUTHORIZATION: undefined,
  CRONOFY_REVOKE_AUTHORIZATION_SUCCESS: undefined,
  CRONOFY_REVOKE_AUTHORIZATION_FAILURE: undefined,

  // alert
  GET_ALERTS: undefined,
  GET_ALERTS_SUCCESS: undefined,
  GET_ALERTS_FAILURE: undefined,
  GET_ALERT: undefined,
  GET_ALERT_SUCCESS: undefined,
  GET_ALERT_FAILURE: undefined,
  GET_ALERTS_TYPES: undefined,
  GET_ALERTS_TYPES_SUCCESS: undefined,
  GET_ALERTS_TYPES_FAILURE: undefined,
  GET_ALERT_TYPE: undefined,
  GET_ALERT_TYPE_SUCCESS: undefined,
  GET_ALERT_TYPE_FAILURE: undefined,
  CREATE_ALERT_TYPE: undefined,
  CREATE_ALERT_TYPE_RESOLVED: undefined,
  EDIT_ALERT_TYPE: undefined,
  EDIT_ALERT_TYPE_RESOLVED: undefined,
  DELETE_ALERT_TYPE: undefined,
  DELETE_ALERT_TYPE_SUCCESS: undefined,
  DELETE_ALERT_TYPE_FAILURE: undefined,
  // user
  GET_USERS: undefined,
  GET_USERS_SUCCESS: undefined,
  GET_USERS_FAILURE: undefined,
  GET_USER: undefined,
  GET_USER_SUCCESS: undefined,
  GET_USER_FAILURE: undefined,
  CREATE_USER: undefined,
  CREATE_USER_SUCCESS: undefined,
  CREATE_USER_FAILURE: undefined,
  USER_LOGOUT: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,
  EDIT_USER: undefined,
  EDIT_USER_SUCCESS: undefined,
  EDIT_USER_FAILURE: undefined,
  GET_INSTALLERS_BY_ACCOUNT: undefined,
  GET_INSTALLERS_BY_ACCOUNT_SUCCESS: undefined,
  GET_INSTALLERS_BY_ACCOUNT_FAILURE: undefined,
  DELETE_USER: undefined,
  DELETE_USER_SUCCESS: undefined,
  DELETE_USER_FAILURE: undefined,
  GET_PROFILE: undefined,
  GET_PROFILE_SUCCESS: undefined,
  GET_PROFILE_FAILURE: undefined,
  GET_COMPANY: undefined,
  GET_COMPANY_SUCCESS: undefined,
  GET_COMPANY_FAILURE: undefined,
  // salesman
  GET_SALESMAN: undefined,
  GET_SALESMAN_SUCCESS: undefined,
  GET_SALESMAN_FAILURE: undefined,
  // customer
  GET_CUSTOMER: undefined,
  GET_CUSTOMER_SUCCESS: undefined,
  GET_CUSTOMER_FAILURE: undefined,
  // Owners
  GET_OWNERS: undefined,
  GET_OWNERS_SUCCESS: undefined,
  GET_OWNERS_FAILURE: undefined,
  // jobs
  GET_JOBS: undefined,
  GET_JOBS_SUCCESS: undefined,
  GET_JOBS_FAILURE: undefined,
  GET_JOB: undefined,
  GET_JOB_SUCCESS: undefined,
  GET_JOB_FAILURE: undefined,
  CREATE_JOB: undefined,
  CREATE_JOB_SUCCESS: undefined,
  CREATE_JOB_FAILURE: undefined,
  EDIT_JOB: undefined,
  EDIT_JOB_SUCCESS: undefined,
  EDIT_JOB_FAILURE: undefined,
  ACCEPT_JOB: undefined,
  ACCEPT_JOB_SUCCESS: undefined,
  ACCEPT_JOB_FAILURE: undefined,
  DELETE_JOB: undefined,
  DELETE_JOB_SUCCESS: undefined,
  DELETE_JOB_FAILURE: undefined,
  CREATE_JOB_PDF: undefined,
  CREATE_JOB_PDF_SUCCESS: undefined,
  CREATE_JOB_PDF_FAILURE: undefined,
  // Events
  GET_EVENTS: undefined,
  GET_EVENTS_SUCCESS: undefined,
  GET_EVENTS_FAILURE: undefined,
  GET_EVENT: undefined,
  GET_EVENT_SUCCESS: undefined,
  GET_EVENT_FAILURE: undefined,
  GET_EVENTS_BY_JOB: undefined,
  GET_EVENTS_BY_JOB_SUCCESS: undefined,
  GET_EVENTS_BY_JOB_FAILURE: undefined,
  CREATE_EVENT: undefined,
  CREATE_EVENT_SUCCESS: undefined,
  CREATE_EVENT_FAILURE: undefined,
  EDIT_EVENT: undefined,
  EDIT_EVENT_SUCCESS: undefined,
  EDIT_EVENT_FAILURE: undefined,
  DELETE_EVENT: undefined,
  DELETE_EVENT_SUCCESS: undefined,
  DELETE_EVENT_FAILURE: undefined,
  OPEN_EDIT_EVENT: undefined,
  SET_CURRENT_PAGE: undefined,
  OPEN_DELETE_EVENT: undefined,
  // register company
  REGISTER_COMPANY_SAVE_DATA: undefined,
  REGISTER_COMPANY_CHANGE_STEP: undefined,
  GET_PRODUCTS: undefined,
  GET_PRODUCTS_SUCCESS: undefined,
  GET_PRODUCTS_FAILURE: undefined,
  REGISTER_COMPANY: undefined,
  REGISTER_COMPANY_SUCCESS: undefined,
  REGISTER_COMPANY_FAILURE: undefined,
  CHECK_SUBDOMAIN: undefined,
  CHECK_SUBDOMAIN_SUCCESS: undefined,
  CHECK_SUBDOMAIN_FAILURE: undefined,
  RESET_REGISTER_FORM: undefined,
  // contact
  SEND_CONTACT: undefined,
  // inquiry
  CREATE_INQUIRY: undefined,
  CREATE_INQUIRY_SUCCESS: undefined,
  CREATE_INQUIRY_FAILURE: undefined,
});

export const Ticketing = [{ label: 'Glaspac LX', value: 'LX' }];

export const Timezone = [
  { label: 'US/Eastern', value: 'US/Eastern' },
  { label: 'US/Central', value: 'US/Central' },
  { label: 'US/Arizona', value: 'US/Arizona' },
  { label: 'US/Mountain', value: 'US/Mountain' },
  { label: 'US/Pacific', value: 'US/Pacific' },
  { label: 'US/Alaska', value: 'US/Alaska' },
  { label: 'US/Hawaii', value: 'US/Hawaii' },
];

export const States = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const Parkings = [
  { label: 'Loading Dock', value: 'Loading Dock' },
  { label: 'Parking Lot', value: 'Parking Lot' },
  { label: 'Street', value: 'Street' },
  { label: 'Reserved Space', value: 'Reserved Space' },
  { label: 'Other', value: 'Other' },
];

export const JobStatus = [
  { label: 'New Ticket', value: 'New Ticket' },
  { label: 'Research', value: 'Research' },
  { label: 'Submitted', value: 'Submitted' },
  { label: 'Quote', value: 'Quote' },
  { label: 'On Hold', value: 'On Hold' },
  { label: 'N/A', value: 'N/A' },
  { label: 'Void Cancel', value: 'Void Cancel' },
  { label: 'Void Duplicate', value: 'Void Duplicate' },
  { label: 'Void Lost', value: 'Void Lost' },
  // { label: 'Test Ticket', value: 'Test Ticket' },
];

export const Accepted = [
  { label: 'Accepted(All)', value: '' },
  { label: 'Accepted', value: true },
  { label: 'Not Accepted', value: false },
];

export const ElementsPerPage = [
  { label: 'Jobs Per Page(10)', value: 10 },
  { label: 'Jobs Per Page(25)', value: 25 },
  { label: 'Jobs Per Page(50)', value: 50 },
  { label: 'Jobs Per Page(100)', value: 100 },
];

export const Labors = [
  { label: 'Regular', value: 'Regular' },
  { label: 'Emergency Service', value: 'Emergency Service' },
  { label: 'Night', value: 'Night' },
  { label: 'Overtime', value: 'Overtime' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Weekend', value: 'Weekend' },
  { label: 'Shop Fab', value: 'Shop Fab' },
  { label: 'Contract Labor', value: 'Contract Labor' },
  { label: 'Contract Department Labor O.T', value: 'Contract Department Labor O.T' },
  { label: 'Sub Labor', value: 'Sub Labor' },
  { label: 'Delivery', value: 'Delivery' },
  { label: 'Delivery (Salesman)', value: 'Delivery (Salesman)' },
  { label: 'Field Measure', value: 'Field Measure' },
  { label: 'Field Measure OT', value: 'Field Measure OT' },
  { label: 'Pick Up', value: 'Pick Up' },
  { label: 'Pick Up Hardware', value: 'Pick Up Hardware' },
];

export const RoleID = [
  { label: 'Owner', value: '2' },
  { label: 'Sales', value: '3' },
  { label: 'CSR', value: '4' },
];

export const userRoleId = {
  OWNER: 2,
  SALES: 3,
  CSR: 4,
};

export const userRoleName = {
  OWNER: 'Owner',
  SALES: 'Sales',
  CSR: 'CSR',
};

export const statusUser = { ACTIVE: 'active', PENDING: 'pending' };

export const eventTypes = [
  { label: 'Appointment', value: 0 },
  { label: 'Call', value: 1 },
  { label: 'Notes', value: 2 },
  { label: 'Text Message', value: 3 },
  { label: 'Email', value: 4 },
];

export const MapEventType = {
  0: 'Setup an appointment',
  1: 'Made a call',
  2: 'Added a note',
  3: 'Added a text message',
  4: 'Added an email',
};

export const EVENT_TYPE = [
  { label: 'Appointment', value: 'appointment' },
  { label: 'Call', value: 'call' },
  { label: 'Notes', value: 'notes' },
  { label: 'Text message', value: 'text_message' },
  { label: 'Email', value: 'email' },
];

export const EVENTS_TYPES = {
  appointment: 'appointment',
  call: 'call',
  notes: 'notes',
  text_message: 'text_message',
  email: 'email',
};
