import app from './app';
import auth from './auth';
import user from './user';
import job from './job';
import event from './event';
import registerCompany from './registerCompany';
import inquiry from './inquiry';
export default {
  ...app,
  ...auth,
  ...user,
  ...job,
  ...event,
  ...registerCompany,
  ...inquiry,
};
