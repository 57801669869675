import React, { Component } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  Navbar,
  Spinner,
  ButtonGroup,
  Image,
} from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import { isRequired } from 'utils/rules';
import * as Yup from 'yup';
import { States, Ticketing, Timezone } from 'constants/index';
import Icon from 'components/Icon';

import PropTypes from 'prop-types';
import Owners from './Owners/OwnersContainer';

export default class SettingsView extends Component {
  render() {
    const {
      submitEditPassword,
      submitEditCompany,
      loading,
      company,
      subdomainLoading,
      subdomainValid,
      checkSubdomain,
    } = this.props;

    if (loading) {
      return (
        <Container>
          <Row>
            <Col className="w-100 d-flex align-items-center justify-content-center">
              <Spinner animation="border" role="status" />
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <>
        <Formik enableReinitialize onSubmit={submitEditCompany} initialValues={company}>
          {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => {
            const convertBase66 = files => {
              Array.from(files).forEach(file => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function() {
                  const base64 = reader.result;
                  setFieldValue('picture', base64);
                };
              });
            };

            const remove = () => {
              setFieldValue('picture', null);
            };

            return (
              <FormikForm className="ml-2">
                <Row>
                  {values.picture ? (
                    <div className="inputBox d-flex justify-content-center">
                      <div className="imageHolder">
                        <Image src={values.picture} alt="img" />
                        <p className="replaceImageText" onClick={remove}>
                          Remove
                        </p>
                      </div>
                    </div>
                  ) : (
                    <Col xs={12} sm={12} md={6} xl={3} className="mt-4">
                      <Form.Label className="input-text mr-4">Logo</Form.Label>
                      <input
                        name="picture"
                        className="input-text"
                        type="file"
                        onChange={e => convertBase66(e.target.files)}
                      />
                    </Col>
                  )}
                </Row>
                <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Form.Group controlId="name">
                      <Form.Label className="mt-3 input-text ">Name</Form.Label>
                      <Form.Control
                        as={Field}
                        name="name"
                        type="text"
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Form.Group controlId="url">
                      <Form.Label className="mt-3 input-text ">Website</Form.Label>
                      <Form.Control
                        as={Field}
                        name="url"
                        type="url"
                        placeholder="Website"
                        values={values.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={company.id !== 1}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Form.Group controlId="ticketing">
                      <Form.Label className="mt-3 input-text ">Your Ticketing System</Form.Label>
                      <Field
                        as="select"
                        className={
                          errors.state && touched.state
                            ? 'form-control is-invalid is-invalid-select'
                            : 'form-control'
                        }
                        name="ticketing"
                        value={values.ticketing}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={company.id !== 1}
                      >
                        <option value="">Choose option...</option>
                        {Ticketing.map(({ label, value }, index) => (
                          <option value={value} key={index}>
                            {label}
                          </option>
                        ))}
                      </Field>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Form.Group controlId="api_key">
                      <Form.Label className="mt-3 input-text ">API Key</Form.Label>
                      <Form.Control
                        as={Field}
                        name="api_key"
                        type="text"
                        placeholder="API Key"
                        value={values.api_key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={company.id !== 1}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Form.Group controlId="subdomain">
                      <Form.Label className="mt-3 input-text ">Subdomain</Form.Label>
                      <Form.Control
                        as={Field}
                        name="subdomain"
                        type="subdomain"
                        placeholder="subdomain"
                        values={values.subdomain}
                        validate={isRequired}
                        onBlur={handleBlur}
                        onChange={e => {
                          const value = event.target.value.replace(/ /g, '');
                          setFieldValue(event.target.name, value);
                          checkSubdomain(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched.subdomain &&
                          !subdomainValid &&
                          !subdomainLoading &&
                          'Subdomain is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Form.Group controlId="timezone">
                      <Form.Label className="mt-3 input-text ">Timezone</Form.Label>
                      <Field
                        as="select"
                        className="form-control"
                        name="timezone"
                        value={values.timezone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Choose option...</option>
                        {Timezone.map(({ label, value }, index) => (
                          <option value={value} key={index}>
                            {label}
                          </option>
                        ))}
                      </Field>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={12} xl={12}>
                    <Form.Group>
                      <Icon name="map-marker-alt" fontSize={20} color="#0084EA" />
                      <Form.Label
                        style={{ marginLeft: '10px', marginTop: '15px', color: '#0084EA' }}
                        className="text-button"
                      >
                        Location
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Form.Group controlId="address">
                      <Form.Label className="mt-3 input-text ">Address</Form.Label>
                      <Form.Control
                        as={Field}
                        name="address"
                        type="text"
                        placeholder="Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={3} xl={3}>
                    <Form.Group controlId="city">
                      <Form.Label className="mt-3 input-text ">City</Form.Label>
                      <Form.Control
                        as={Field}
                        name="city"
                        type="city"
                        placeholder="City"
                        values={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={2} xl={2}>
                    <Form.Group controlId="state_billing">
                      <Form.Label className="mt-3 input-text ">State</Form.Label>
                      <Field
                        as="select"
                        className={
                          errors.state && touched.state
                            ? 'form-control is-invalid is-invalid-select'
                            : 'form-control'
                        }
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.state && touched.state}
                      >
                        <option value="">Choose state...</option>
                        {States.map(({ label, value }, index) => (
                          <option value={value} key={index}>
                            {label}
                          </option>
                        ))}
                      </Field>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={1} xl={1}>
                    <Form.Group controlId="zip_code">
                      <Form.Label className="mt-3 input-text ">Zipcode</Form.Label>
                      <Form.Control
                        as={Field}
                        name="zip_code"
                        type="zip_code"
                        placeholder="Zipcode"
                        values={values.zip_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={12} sm={12} xl={12} className="mt-4 pr-4">
                    <ButtonGroup className="mr-4">
                      <Button variant="primary" size="lg" type="submit" className="button">
                        Submit
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </FormikForm>
            );
          }}
        </Formik>

        <Formik
          enableReinitialize
          onSubmit={submitEditPassword}
          initialValues={{ password: '', confirmPassword: '', currentPassword: '' }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required('Required')
              .min(8, 'The password must have more than eight characters'),
            confirmPassword: Yup.string()
              .required('Required')
              .test('passwords-match', 'Passwords dont match', function(value) {
                return this.parent.password === value;
              }),
          })}
        >
          {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
            <FormikForm>
              <Col xs={12} md={12} sm={12} xl={12} className="pl-0">
                <Navbar className="pl-0">
                  <Navbar.Brand className="text-button">Change password</Navbar.Brand>
                </Navbar>
              </Col>
              <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Col xs={12} sm={12} md={4} xl={4}>
                  <Form.Group controlId="currentPassword">
                    <Form.Label className="input-text">Current Password</Form.Label>
                    <Form.Control
                      as={Field}
                      name="currentPassword"
                      type="password"
                      placeholder="Current Password"
                      value={values.currentPassword}
                      isInvalid={!!errors.currentPassword && touched.currentPassword}
                      onChange={handleChange}
                      validate={isRequired}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.currentPassword && touched.currentPassword && errors.currentPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={4} xl={4}>
                  <Form.Group controlId="password">
                    <Form.Label className="input-text">New Password</Form.Label>
                    <Form.Control
                      as={Field}
                      name="password"
                      type="password"
                      placeholder="New Password"
                      value={values.password}
                      isInvalid={!!errors.password && touched.password}
                      onChange={handleChange}
                      validate={isRequired}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password && touched.password && errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={4} xl={4}>
                  <Form.Group controlId="confirmPassword">
                    <Form.Label className="input-text">Confirm Password</Form.Label>
                    <Form.Control
                      as={Field}
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      values={values.confirmPassword}
                      isInvalid={!!errors.confirmPassword && touched.confirmPassword}
                      onChange={handleChange}
                      validate={isRequired}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={12} sm={12} xl={12} className="mt-4 pr-4">
                  <ButtonGroup className="mr-4">
                    <Button variant="primary" size="lg" type="submit" className="button">
                      Save
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
        <Owners />
      </>
    );
  }
}

SettingsView.propTypes = {
  checkSubdomain: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  submitEditPassword: PropTypes.func.isRequired,
  subdomainLoading: PropTypes.bool.isRequired,
  submitEditCompany: PropTypes.func.isRequired,
  subdomainValid: PropTypes.bool.isRequired,
};
