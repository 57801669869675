import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

const INITIAL_LABORS = [{ labor_job: '', installers_job: '', hours_job: '', notes_job: '' }];

const INITIAL_JOB = {
  quote_number: '',
  salesman_id: 16,
  second_salesman_id: '',
  csr_id: '',
  csr_notes: '',
  job_description: '',
  job_description_updated: false,
  additional_note: '',
  additional_note_doubt: false,
  full_name_billing: '',
  address_line_1_billing: '',
  address_line_2_billing: '',
  city_billing: '',
  state_billing: '',
  zip_code_billing: '',
  full_name_job: '',
  address_line_1_job: '',
  address_line_2_job: '',
  city_job: '',
  state_job: '',
  zip_code_job: '',
  job_status: 'New Ticket',
  parking_job: '',
  labors: INITIAL_LABORS,
  notes_job: '',
  void_note: '',
  glassPieces: [],
  repairDoors: [],
  newDoors: [],
  sketchs: [],
  other: [],
};
export const appState = {
  list: [],
  count: 0,
  loading: false,
  current: INITIAL_JOB,
};

export default {
  job: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_JOBS]: draft => {
        draft.loading = true;
        draft.current = INITIAL_JOB;
      },
      [ActionTypes.GET_JOBS_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_JOBS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_JOB]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_JOB_SUCCESS]: (draft, { payload: { job } }) => {
        if (job.labors.length == 0) {
          draft.current = { ...job, labors: INITIAL_LABORS };
          draft.loading = false;
        } else {
          draft.current = job;
          draft.loading = false;
        }
      },
      [ActionTypes.GET_JOB_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_JOB]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_JOB_SUCCESS]: draft => {
        draft.loading = false;
        draft.current = INITIAL_JOB;
      },
      [ActionTypes.CREATE_JOB_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_JOB]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_JOB_SUCCESS]: (draft, { payload: { id, job } }) => {
        const jobIndex = draft.list.findIndex(item => item.id === id);
        draft.list[jobIndex] = Object.assign({}, draft.list[jobIndex], job);
        draft.loading = false;
        draft.current = INITIAL_JOB;
      },
      [ActionTypes.EDIT_JOB_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_JOB]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_JOB_SUCCESS]: (draft, { payload: { id } }) => {
        draft.list = draft.list.filter(item => item.id !== id);
        draft.count -= 1;
        draft.loading = false;
      },
      [ActionTypes.DELETE_JOB_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.ACCEPT_JOB]: draft => {
        draft.loading = true;
      },
      [ActionTypes.ACCEPT_JOB_SUCCESS]: (draft, { payload: { id, accepted } }) => {
        draft.list = draft.list.map(item => {
          if (item.id == id) {
            return { ...item, accepted };
          }
          return item;
        });
        draft.loading = false;
      },
      [ActionTypes.ACCEPT_JOB_FAILURE]: draft => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
