import React, { Component } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import config from 'config';
import styled from 'styled-components';
import imageCompression from 'browser-image-compression';
import { Row, Col, Image } from 'react-bootstrap';
const DropzoneContainer = styled.div`
  margin-bottom: 34px;
  width: 100%;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 0px;
  margin-top: 30px;
  flex-direction: column;
`;

const CloseButton = styled.i`
  font-size: 36px;
  color: #dc3545;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export default class DropZone extends Component {
  state = {
    status: 'empty',
    filesPositions: {},
  };

  getUploadParams = async ({ file, meta }) => {
    const { token, folder } = this.props;
    const fileName = file.name;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      const body = new FormData();
      body.append('file', compressedFile, fileName);

      return {
        url: `${config.BASE_URL}/uploads/${folder}`,
        headers: {
          jwt: token,
        },
        body,
      };
    } catch (error) {
      console.log(error);
    }
  };

  handlePreparing = id => {
    const { filesPositions } = this.state;
    const newFilesPositions = { ...filesPositions };

    const position = Object.keys(newFilesPositions).length;

    newFilesPositions[id] = position;

    this.setState({ filesPositions: newFilesPositions });
  };

  getPosition = id => {
    const { filesPositions } = this.state;
    return filesPositions[id] || 0;
  };

  handleChangeStatus = ({ xhr, cancel, meta: { id, name } }, status) => {
    if (status === 'preparing') {
      this.handlePreparing(id);
    }

    if (status === 'done') {
      const { handleSuccess } = this.props;
      handleSuccess(JSON.parse(xhr.response), this.getPosition(id));
    }

    if (status === 'removed') {
      const { response } = xhr;

      if (response) {
        const { handleRemove } = this.props;
        handleRemove(JSON.parse(xhr.response));
      } else {
        cancel();
      }
    }
  };

  onRemoveInitialFile = url => {
    const { handleRemove } = this.props;
    handleRemove({ url });
  };

  render() {
    const { height, token } = this.props;
    const { initialFiles, handlePosition } = this.props;

    return (
      <DropzoneContainer>
        <Dropzone
          autoUpload={true}
          getUploadParams={this.getUploadParams}
          onChangeStatus={this.handleChangeStatus}
          multiple={true}
          canCancel={false}
          inputContent={(files, extra) =>
            extra.reject ? 'Image, audio and video files only' : 'Drag Files or Click to Browse'
          }
          accept="image/png, image/jpeg, image/jpg"
          styles={{
            dropzone: {
              height: height || '400px',
            },
          }}
        />
        <Row className="p-3">
          {!!(initialFiles || []).length &&
            initialFiles.map((fileSrc, key) => (
              <Col xs={12} sm={6} md={4} xl={3} key={key} className="p-3">
                <CloseButton
                  className="fas fa-minus-circle"
                  onClick={() => this.onRemoveInitialFile(fileSrc)}
                />
                <Image style={{ maxHeight: '18rem' }} className="img-fluid" src={fileSrc} />
                <Row>
                  <Col className="py-4">
                    {key > 0 && (
                      <h4
                        onClick={() => handlePosition(key, key - 1)}
                        style={{ cursor: 'pointer' }}
                      >
                        <i
                          className="fas fa-angle-left icon-button button-rounded mr-1"
                          style={{ background: '#26B4FF' }}
                        />
                      </h4>
                    )}
                  </Col>
                  <Col className="py-4 text-center">
                    <h4>{`Image ${key + 1}`}</h4>
                  </Col>

                  <Col className="py-4 text-right">
                    {key + 1 < initialFiles.length && (
                      <h4
                        onClick={() => handlePosition(key, key + 1)}
                        style={{ cursor: 'pointer', textAlign: '-webkit-right' }}
                      >
                        <i
                          className="fas fa-angle-right icon-button button-rounded mr-1"
                          style={{ background: '#26B4FF' }}
                        />
                      </h4>
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
      </DropzoneContainer>
    );
  }
}
