import React from 'react';
import { Link } from 'react-router-dom';

export const Logo = () => (
  <Link to="/">
    <img
      src="logo.svg"
      alt="Glaziers Tool"
      style={{
        width: '220px',
        height: '48px',
      }}
    />
  </Link>
);
