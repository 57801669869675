import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { footerData } from './data'; // Assuming footerData is in a separate file
import { Logo } from '../Elements/Logo';

const FooterItem = ({ item }) => (
  <div className="px-1 px-sm-3">
    {item.isText ? (
      <p className="landing-h8">{item.title}</p>
    ) : (
      <a
        href={item.path}
        target="_blank"
        rel="noopener noreferrer"
        className="landing-h8 primary-color"
      >
        {item.title}
      </a>
    )}
  </div>
);

const Footer = () => (
  <Row className="py-md-4 d-flex align-items-center justify-content-center justify-content-md-between">
    <Col
      xs={12}
      md={6}
      className="d-flex justify-content-md-start justify-content-center py-4 py-md-2"
    >
      <Logo />
    </Col>
    <Col
      xs={12}
      md={6}
      className="d-flex justify-content-md-end justify-content-center py-4 py-md-2"
    >
      {footerData.map(item => (
        <FooterItem key={item.title} item={item} />
      ))}
    </Col>
  </Row>
);

FooterItem.propTypes = {
  item: PropTypes.object,
};

export default Footer;
