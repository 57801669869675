import React, { createRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'modules/history';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlus, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import Pagination from '../../../components/Pagination';
import { deleteEvent, openEditEvent, openDeleteEvent } from '../../../actions/event';
import NoEvents from '../components/NoEvents';
import EventItem from '../components/EventItem';
import FormEditEvent from '../components/FormEditEvent';
import FormCreateEvent from '../components/FormCreateEvent';

import {
  Title,
  ButtonStyle,
  ModalBodyStyle,
  ModalWarningStyle,
  QuoteNumberContainerStyle,
  QuoteNumberTextStyle,
  LinkContainer,
  IconStyleArrow,
} from '../StyledComponents';
import ModalComponent from '../../../components/ModalComponent';
import { userRoleId } from '../../../constants';

const headers = ['Event type', 'Date & Time', 'Notes'];
export default function EventListView({
  setReloadEvents,
  currentPage,
  setCurrentPage,
  jobIdParams,
}) {
  const refSubmit = createRef();
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState({
    state: false,
    type: null,
    address_line_1_job: null,
    address_line_2_job: null,
    city_job: null,
    quoteNumber: null,
  });

  const {
    loading,
    listEvent,
    quoteNumber,
    pages,
    openEdit,
    editingEventId,
    openDelete,
    company_id,
    current,
    owners,
    company,
    currentUser,
  } = useSelector(({ event, auth, job, user }) => ({
    loading: event.loading,
    listEvent: event.listEventByJob,
    quoteNumber: event.quote_number,
    pages: event.pages,
    openEdit: event.openEdit,
    editingEventId: event.editingEventId,
    openDelete: event.openDelete,
    company_id: auth.company.id,
    current: job.current,
    owners: user.owners,
    company: auth.company,
    currentUser: auth.profile,
  }));

  const handleChangePage = useCallback(
    newPage => {
      setCurrentPage(newPage);
    },
    [setCurrentPage],
  );

  const handleOpenEdit = useCallback(
    ({ id }) => {
      dispatch(openEditEvent({ openEdit: true, editingEventId: id }));
    },
    [dispatch],
  );

  const handleCloseEdit = useCallback(() => {
    dispatch(openEditEvent({ openEdit: false, editingEventId: null }));
  }, [dispatch]);

  const handleOpenDelete = useCallback(
    id => {
      dispatch(openDeleteEvent({ state: true, id }));
    },
    [dispatch],
  );

  const handleCloseDelete = useCallback(() => {
    dispatch(openDeleteEvent({ state: false, id: null }));
  }, [dispatch]);

  return (
    <div>
      <LinkContainer href="/dashboard/events" onClick={() => push('/dashboard/events')}>
        <IconStyleArrow icon={faArrowLeft} />
        <div className="landing-caption" style={{ color: '#425268' }}>
          Back
        </div>
      </LinkContainer>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex me-5">
          <Title className="me-5 pe-5">Event list for quote</Title>

          <QuoteNumberContainerStyle>
            <QuoteNumberTextStyle>{quoteNumber}</QuoteNumberTextStyle>
          </QuoteNumberContainerStyle>
        </div>
        <ButtonStyle
          variant="primary"
          style={{ display: currentUser.role_id !== userRoleId.OWNER ? 'none' : 'flex' }}
          onClick={() =>
            setOpenCreate({
              state: true,
              type: current.type,
              address_line_1_job: current.address_line_1_job,
              address_line_2_job: current.address_line_2_job,
              city_job: current.city_job,
              quote_number: quoteNumber,
            })
          }
        >
          <FontAwesomeIcon icon={faPlus} style={{ width: 24, height: 24 }} />
          <div className="landing-p">Add event</div>
        </ButtonStyle>
      </div>
      <Table>
        <thead className="table-borderless">
          <tr style={{ borderColor: '#ffffff' }}>
            {headers.map(header => (
              <th key={header}>
                <h4 style={{ color: '#425268' }}>{header}</h4>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        {listEvent.length === 0 ? (
          <NoEvents />
        ) : (
          listEvent.map(item => (
            <EventItem
              key={item.id}
              item={item}
              handleOpenEdit={handleOpenEdit}
              handleOpenDelete={handleOpenDelete}
              eventList={true}
            />
          ))
        )}
      </Table>
      <Pagination currentPage={currentPage} changePage={handleChangePage} totalPages={pages} />
      <ModalComponent
        show={openEdit}
        onHide={handleCloseEdit}
        title="Edit event"
        textCancel="Cancel"
        textSubmit="Save"
        onClickSubmit={() => refSubmit.current.click()}
        loading={loading}
        disabledCancel={currentUser.role_id !== userRoleId.OWNER ? true : false}
        disabledSubmit={currentUser.role_id !== userRoleId.OWNER ? true : false}
        body={
          <FormEditEvent
            refSubmit={refSubmit}
            id={editingEventId}
            jobId={jobIdParams}
            handleCloseEdit={handleCloseEdit}
            setReloadEvents={setReloadEvents}
          />
        }
      />

      <ModalComponent
        show={openDelete.state}
        onHide={handleCloseDelete}
        loading={loading}
        size="md"
        onClickSubmit={() => {
          dispatch(deleteEvent(openDelete.id));
          handleChangePage(1);
          handleCloseDelete();
        }}
        title="Delete event"
        textCancel="Cancel"
        variantSubmit="danger"
        textSubmit="Delete"
        body={
          <>
            <ModalBodyStyle>Are you sure you want to delete this event?</ModalBodyStyle>
            <ModalWarningStyle>This action cannot be undone</ModalWarningStyle>
          </>
        }
      />

      <ModalComponent
        show={openCreate.state}
        onHide={() =>
          setOpenCreate({
            state: false,
            type: null,
            address_line_1_job: null,
            address_line_2_job: null,
            city_job: null,
            quote_number: null,
          })
        }
        textCancel="Cancel"
        textSubmit="Save"
        onClickSubmit={() => refSubmit.current.click()}
        variantSubmit="primary"
        title="Add event"
        body={
          <FormCreateEvent
            refSubmit={refSubmit}
            id={jobIdParams}
            currentJob={current}
            company_id={company_id}
            openCreate={openCreate}
            company={company}
            participants={owners.map(owner => owner.email) || []}
            handleClose={() => {
              setOpenCreate({
                state: false,
                type: null,
                address_line_1_job: null,
                address_line_2_job: null,
                city_job: null,
                quote_number: null,
              });
              setTimeout(() => {
                setReloadEvents(reloadEvents => !reloadEvents);
              }, 1000);
            }}
          />
        }
      />
    </div>
  );
}

EventListView.propTypes = {
  currentPage: PropTypes.number.isRequired,
  jobIdParams: PropTypes.number.isRequired,

  setCurrentPage: PropTypes.func.isRequired,
  setReloadEvents: PropTypes.func.isRequired,
};
