import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getProfile,
  updateProfile,
  getCompany,
  changePassword,
  cronofyGetCalendars,
  checkSubdomain,
  updateCompany,
} from 'actions/index';
import { debounce } from 'lodash';

import Tabs from 'components/Tabs';
import SettingsView from './SettingsView';
import PDFDocumentsView from './PDFDocumentsView';
import NotificationsView from './Notifications/NotificationsView';

const TABS = [
  {
    title: 'Company',
    icon: 'building',
  },
  { title: 'PDF Documents', icon: 'file-pdf' },
  { title: 'Billing', icon: 'file-invoice-dollar' },
  { title: 'Notifications', icon: 'bell' },
];

class SettingsContainer extends Component {
  state = { activeTab: 'Company' };

  componentDidMount() {
    const { ongetProfile, ongetCompany, cronofyGetCalendars } = this.props;
    ongetProfile();
    ongetCompany();
    cronofyGetCalendars();
  }
  editUser = data => {
    const { onUpdateSettings } = this.props;
    onUpdateSettings(data);
  };

  editPassword = data => {
    const { onChangePassword } = this.props;
    onChangePassword(data);
  };

  checkSubdomainDebounce = debounce(async value => {
    this.props.checkSubdomain(value);
  }, 600);

  onChangeTabs = value => {
    this.setState({ activeTab: value });
  };

  render() {
    const {
      token,
      profile,
      company,
      cronofyProfile,
      calendars,
      cronofy_calendar_id,
      loading,
      subdomainLoading,
      subdomainValid,
      updateCompany,
    } = this.props;

    const { activeTab } = this.state;

    if (!profile || !company) return null;
    return (
      <>
        <Tabs tabs={TABS} active={activeTab} onChangeTabs={this.onChangeTabs} />

        {activeTab === 'Company' && (
          <SettingsView
            token={token}
            profile={profile}
            company={company}
            submitEditUser={this.editUser}
            submitEditPassword={this.editPassword}
            cronofyProfile={cronofyProfile}
            calendars={calendars}
            loading={loading}
            cronofy_calendar_id={cronofy_calendar_id}
            subdomainLoading={subdomainLoading}
            subdomainValid={subdomainValid}
            checkSubdomain={this.checkSubdomainDebounce}
            submitEditCompany={updateCompany}
          />
        )}

        {activeTab === 'PDF Documents' && (
          <PDFDocumentsView
            token={token}
            profile={profile}
            company={company}
            submitEditUser={this.editUser}
            submitEditPassword={this.editPassword}
            cronofyProfile={cronofyProfile}
            calendars={calendars}
            loading={loading}
            submitEditCompany={updateCompany}
          />
        )}

        {activeTab === 'Notifications' && (
          <NotificationsView updateCompany={updateCompany} company={company} />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.auth.loading,
    profile: state.auth.settings.profile,
    company: state.auth.settings.company,
    calendars: state.auth.calendars,
    cronofyProfile: state.auth.cronofyProfile,
    cronofy_calendar_id: state.auth.profile.cronofy_calendar_id,
    subdomainLoading: state.registerCompany.subdomainLoading,
    subdomainValid: state.registerCompany.subdomainValid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ongetProfile: () => dispatch(getProfile()),
    ongetCompany: () => dispatch(getCompany()),

    onUpdateSettings: data => dispatch(updateProfile(data)),
    updateCompany: data => dispatch(updateCompany(data)),
    onChangePassword: data => dispatch(changePassword(data)),
    cronofyGetCalendars: () => dispatch(cronofyGetCalendars()),
    checkSubdomain: subdomain => dispatch(checkSubdomain(subdomain)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
