import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions';

export function* sendContact(api, action) {
  const response = yield call(api.sendContact, action.payload.data);
  const { status, errors } = response;
  if (status === 200) {
    yield put(showAlert('Message sended', { variant: 'success' }));
  } else {
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
