import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getJobs,
  acceptJob,
  deleteJob,
  getSalesman,
  getCustomer,
  createEvent,
  createJobPdf,
  editJob,
  getOwners,
  getJob,
} from 'actions/index';
import JobListView from './JobListView';
import { statusUser } from '../../constants';

const elementsPerPage = 25;

class JobListContainer extends Component {
  constructor(props) {
    super(props);
    const { fetchJobs, fetchSalesman, fetchCustomer } = this.props;
  }

  static propTypes = {
    fetchJobs: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchSalesman: PropTypes.func.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    createEvent: PropTypes.func.isRequired,
    fetchOwners: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    onCreateJobPdf: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      fetchJobs,
      fetchSalesman,
      fetchCustomer,
      fetchOwners,
      profile: { id, role_code },
    } = this.props;
    const query = {
      page: 1,
      elementsPerPage,
    };

    if (role_code == 'sales') {
      query.salesman_id = id;
      query.job_status = 'New Ticket';
    }
    fetchJobs(query);

    fetchSalesman();
    fetchCustomer();
    fetchOwners();
  }
  fieldsForQuickEdit = list => {
    var res = [];
    if (list != undefined && list.length > 0) {
      for (var i = 0; i < list.length; i++) {
        var element = {};
        element['id'] = list[i]['id'];
        element['salesman_id'] = list[i]['salesman_id'];
        element['second_salesman_id'] = list[i]['second_salesman_id'];
        element['csr_id'] = list[i]['csr_id'];
        element['job_status'] = list[i]['job_status'];
        element['void_note'] = list[i]['void_note'];
        res = [...res, element];
      }
      return res;
    }
  };

  render() {
    const {
      list,
      pages,
      currentPage,
      loading,
      changeStatus,
      removeJob,
      fetchJobs,
      getJob,
      salesmans,
      csrs,
      createEvent,
      userId,
      onCreateJobPdf,
      onEditJob,
      profile,
      owners,
      company,
      current,
    } = this.props;

    var editList = this.fieldsForQuickEdit(list);

    return (
      <div>
        <JobListView
          profile={profile}
          list={list}
          currentPage={currentPage}
          pages={pages}
          loading={loading}
          changeStatus={changeStatus}
          removeJob={removeJob}
          fetchJobs={fetchJobs}
          getJob={getJob}
          salesmans={salesmans}
          csrs={csrs}
          owners={owners}
          createEvent={createEvent}
          userId={userId}
          onCreateJobPdf={onCreateJobPdf}
          onEditJob={onEditJob}
          editList={editList}
          company={company}
          current={current}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.job.list,
    currentPage: state.job.currentPage,
    pages: state.job.pages,
    loading: state.job.loading,
    salesmans: state.user.salesmans,
    csrs: state.user.csrs,
    userId: state.auth.profile.id,
    profile: state.auth.profile,
    owners: state.user.owners,
    current: state.job.current,
    company: state.auth.company,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchJobs: params => dispatch(getJobs(params)),
    changeStatus: (id, status) => dispatch(acceptJob(id, status)),
    removeJob: id => dispatch(deleteJob(id, { status })),
    fetchSalesman: () => dispatch(getSalesman()),
    fetchCustomer: () => dispatch(getCustomer()),
    createEvent: data => dispatch(createEvent(data)),
    onCreateJobPdf: id => dispatch(createJobPdf(id)),
    onEditJob: (id, data) => dispatch(editJob(id, data)),
    fetchOwners: () => dispatch(getOwners({ status: statusUser.ACTIVE })),
    getJob: id => dispatch(getJob(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(JobListContainer);
