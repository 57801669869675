import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { whyChooseGlaziersDescription, whyGlaziersSecondTitle } from './data';
import { CustomButton } from '../Elements/CustomButton';
import WhyGlaziersImage from './why-glaziers-tool.png';

const WhyGlaziersSecond = () => (
  <Row
    className="py-5 d-flex justify-content-center align-items-center"
    style={{
      backgroundImage: `url(${WhyGlaziersImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <Col xs={12} md={8} className="text-center mt-5 py-5">
      {/* for smaller devices */}
      <div className="d-block d-md-none">
        <h3 className="landing-h5 primary-color">{whyGlaziersSecondTitle}</h3>
      </div>
      {/* for larger devices */}
      <div className="d-none d-md-block">
        <h3 className="landing-h3 primary-color">{whyGlaziersSecondTitle}</h3>
      </div>

      <p className="landing-h8 dark-gray-color mt-4">{whyChooseGlaziersDescription}</p>
    </Col>

    <Col xs={12} md={12} className="d-flex justify-content-center py-4 my-4">
      <Link to="/register-company">
        <CustomButton
          className="px-5 py-4 py-md-3"
          path="register-company"
          font="landing-h8"
          size="lg"
          text="Start Now"
          variant="primary"
        />
      </Link>
    </Col>
  </Row>
);

export default WhyGlaziersSecond;
