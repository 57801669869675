import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createUser } from 'actions/index';
import FormUser from './FormUser';

class CreateUserContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onCreateUser: PropTypes.func.isRequired,
  };

  render() {
    const { onCreateUser, loading } = this.props;
    const initialValues = { email: '', first_name: '', last_name: '', role_id: '' };
    return <FormUser onSubmit={onCreateUser} loading={loading} initialValues={initialValues} />;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.user.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onCreateUser: data => dispatch(createUser(data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateUserContainer);
