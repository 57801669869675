import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageWithBackgroundImage from 'components/PageWithBackgroundImage';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Logo from 'components/Logo';
import { Colors } from 'modules/theme';
import Icon from 'components/Icon';
import { push } from 'modules/history';

export default class ResetPassView extends Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
  };

  submit = values => {
    const { resetPassword } = this.props;
    const { password } = values;

    resetPassword(password);
  };

  render() {
    const initialValues = {
      password: '',
      passwordConfirm: '',
    };

    return (
      <PageWithBackgroundImage>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.submit}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required('Required')
              .min(8, 'The password must have more than eight characters'),
            passwordConfirm: Yup.string().test('match', 'Passwords must match', function(
              passwordConfirm,
            ) {
              return passwordConfirm === this.parent.password;
            }),
          })}
        >
          {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
            <Container className="mt-5 mb-5 p-5">
              <Row className="mb-5 pl-5">
                <Col className="col col-lg-4 mt-5 mb-5 pb-5">
                  <Logo />
                </Col>
              </Row>

              <Row className="mt-5 mb-5 px-5">
                <Col className="mt-5">
                  <h2 className="align-items-center">
                    <Icon
                      fontSize={30}
                      name="chevron-left"
                      color={Colors.blue}
                      customStyle={{ paddingRight: '20px' }}
                      onClick={() => push('/login')}
                    />
                    Reset password
                  </h2>
                </Col>
              </Row>

              <Form onSubmit={handleSubmit}>
                <Row className="pt-4 pb-4 px-5">
                  <Col>
                    <Form.Group>
                      <Form.Control
                        style={{ height: '50px', fontSize: 18 }}
                        type="password"
                        name="password"
                        placeholder="Enter New password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                    <p
                      style={{
                        color: 'red',
                        fontSize: '10px',
                        marginTop: '2px',
                        marginBottom: 0,
                      }}
                    >
                      {errors.password && touched.password && errors.password}
                    </p>
                  </Col>
                </Row>
                <Row className="pt-4 pb-5 px-5">
                  <Col>
                    <Form.Group>
                      <Form.Control
                        style={{ height: '50px', fontSize: 18 }}
                        type="password"
                        name="passwordConfirm"
                        placeholder="Repeat Password"
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Text style={{ fontSize: 12, float: 'right' }} className="text-muted">
                        Please repeat the desired password to change.
                      </Form.Text>
                      <p
                        style={{
                          color: 'red',
                          fontSize: '10px',
                          marginTop: '2px',
                          marginBottom: 0,
                        }}
                      >
                        {errors.passwordConfirm &&
                          touched.passwordConfirm &&
                          errors.passwordConfirm}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="pt-4  px-5">
                  <Col className="d-flex">
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      type="submit"
                      style={{ height: '50px' }}
                    >
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: Colors.white,
                          fontSize: 20,
                          textAlign: 'center',
                          marginBottom: 'auto',
                        }}
                      >
                        Submit
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          )}
        </Formik>
      </PageWithBackgroundImage>
    );
  }
}
