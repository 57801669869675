import React, { Component } from 'react';
import { Field, FieldArray } from 'formik';
import { Row, Col, Form, Jumbotron, InputGroup, Button } from 'react-bootstrap';
import { CaulkTypes, TapeTypes, EquipmentType, ChannelType } from 'constants/reminderMaterials';

export default class GlassMaterialsView extends Component {
  addValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) + 1);
  };

  substractValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) - 1);
  };

  push = (form, field, key, value) => {
    const { setFieldValue, prefix } = this.props;
    const path = prefix.split('.');
    const { values } = form;

    let tmp = values;
    path.forEach(field => {
      tmp = tmp[field];
    });

    const array = tmp[field];
    setFieldValue(`${prefix}.${field}`, [...array, value]);
  };

  remove = (form, field, key) => {
    const { setFieldValue, prefix } = this.props;
    const path = prefix.split('.');
    const { values } = form;

    let tmp = values;
    path.forEach(field => {
      tmp = tmp[field];
    });

    const array = tmp[field];
    setFieldValue(
      `${prefix}.${field}`,
      array.filter((_, index) => index !== key),
    );
  };

  renderItem = (
    item,
    key,
    form,
    itemSize,
    arrayName,
    options,
    labels,
    errors = {},
    touched = {},
    type = 'select',
  ) => {
    const { handleChange, handleBlur, setFieldValue, prefix } = this.props;

    return (
      <Row key={key}>
        <Col xs={12} sm={3} md={3}>
          <Form.Group controlId={`${prefix}.${arrayName}.${key}.amount`}>
            <Form.Label className="mt-3 input-text">{labels[0]}</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    this.substractValue(`${prefix}.${arrayName}.${key}.amount`, item.amount)
                  }
                >
                  -
                </Button>
              </InputGroup.Prepend>
              <Form.Control
                as={Field}
                type="number"
                className="text-center"
                name={`${prefix}.${arrayName}.${key}.amount`}
                value={item.amount}
                isInvalid={!!errors.amount && touched.amount}
                onChange={e => {
                  if (e.target.value === '') {
                    setFieldValue(`${prefix}.${arrayName}.${key}.amount`, 0);
                  } else {
                    setFieldValue(`${prefix}.${arrayName}.${key}.amount`, e.target.value);
                  }
                }}
                placeholder={labels[0]}
                onBlur={handleBlur}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => this.addValue(`${prefix}.${arrayName}.${key}.amount`, item.amount)}
                >
                  +
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              {errors.amount && touched.amount && errors.amount}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {type === 'text' ? (
          <Col xs={12} sm={7} md={4}>
            <Form.Group controlId={`${prefix}.${arrayName}.${key}.value`}>
              <Form.Label className="mt-3 input-text">{labels[1]}</Form.Label>
              <Form.Control
                as={Field}
                type="text"
                name={`${prefix}.${arrayName}.${key}.value`}
                value={item.value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.value && touched.value && errors.value}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        ) : (
          <Col xs={12} sm={7} md={4}>
            <Form.Group controlId={`${prefix}.${arrayName}.${key}.value`}>
              <Form.Label className="mt-3 input-text">{labels[1]}</Form.Label>
              <Field
                as="select"
                className={
                  errors.value && touched.value
                    ? 'form-control is-invalid is-invalid-select'
                    : 'form-control'
                }
                name={`${prefix}.${arrayName}.${key}.value`}
                value={item.value}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {options.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
              <Form.Control.Feedback type="invalid">
                {errors.value && touched.value && errors.value}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
        <Col xs={10} sm={10} md={4}>
          <Form.Group controlId={`${prefix}.${arrayName}.${key}.notes`}>
            <Form.Label className="mt-3 input-text">Notes</Form.Label>
            <Form.Control
              as={Field}
              name={`${prefix}.${arrayName}.${key}.notes`}
              value={item.notes}
              isInvalid={!!errors.notes && touched.notes}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Form.Control.Feedback type="invalid">
              {errors.notes && touched.notes && errors.notes}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={1} md={1} style={{ paddingTop: '40px' }}>
          {itemSize - 1 === key ? (
            <i
              className="fas fa-plus icon-button button-rounded pointer"
              style={{ background: '#56D053', paddingLeft: '6px' }}
              onClick={() => this.push(form, arrayName, key, { amount: 0, value: '' })}
            />
          ) : (
            <i
              className="fas fa-trash icon-div button-rounded pointer"
              style={{ background: '#FF5B5B', paddingLeft: '6px' }}
              onClick={() => this.remove(form, arrayName, key)}
            />
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const { values, errors, handleChange, touched, handleBlur, setFieldValue, prefix } = this.props;

    return (
      <Jumbotron style={{ padding: '2rem 2rem', marginBottom: '40px', background: '#fff' }}>
        <Row>
          <Col xs={12} md={12} sm={12} xl={12} style={{ paddingBottom: '1rem' }}>
            <h3>Materials & Equipment</h3>
          </Col>
          <Col xs={12} md={12} sm={12} xl={12}>
            <FieldArray
              name="caulks"
              render={({ form }) => (
                <div>
                  {values.caulks.map((caulk, key) =>
                    this.renderItem(
                      caulk,
                      key,
                      form,
                      values.caulks.length,
                      'caulks',
                      CaulkTypes,
                      ['Quantity', 'CAULK'],
                      errors && errors.caulks ? errors.caulks[key] : {},
                      touched && touched.caulks ? touched.caulks[key] : {},
                    ),
                  )}
                </div>
              )}
            />
          </Col>
          <Col xs={12} md={12} sm={12} xl={12}>
            <FieldArray
              name="types"
              render={({ form }) => (
                <div>
                  {values.types.map((type, key) =>
                    this.renderItem(
                      type,
                      key,
                      form,
                      values.types.length,
                      'types',
                      TapeTypes,
                      ['Quantity', 'TAPE'],
                      errors && errors.types ? errors.types[key] : {},
                      touched && touched.types ? touched.types[key] : {},
                    ),
                  )}
                </div>
              )}
            />
          </Col>
          <Col xs={12} md={12} sm={12} xl={12}>
            <FieldArray
              name="equipaments"
              render={({ form }) => (
                <div>
                  {values.equipaments.map((equipament, key) =>
                    this.renderItem(
                      equipament,
                      key,
                      form,
                      values.equipaments.length,
                      'equipaments',
                      EquipmentType,
                      ['Quantity', 'EQUIPMENT'],
                      errors && errors.equipaments ? errors.equipaments[key] : {},
                      touched && touched.equipaments ? touched.equipaments[key] : {},
                    ),
                  )}
                </div>
              )}
            />
          </Col>
          <Col xs={12} md={12} sm={12} xl={12}>
            <FieldArray
              name="channels"
              render={({ form }) => (
                <div>
                  {values.channels.map((channel, key) =>
                    this.renderItem(
                      channel,
                      key,
                      form,
                      values.channels.length,
                      'channels',
                      ChannelType,
                      ['Quantity', 'CHANNEL'],
                      errors && errors.channels ? errors.channels[key] : {},
                      touched && touched.channels ? touched.channels[key] : {},
                    ),
                  )}
                </div>
              )}
            />
          </Col>

          <Col xs={12} md={12} sm={12} xl={12}>
            <FieldArray
              name="miscellaneous"
              render={({ form }) => (
                <div>
                  {values.miscellaneous.map((miscellaneous, key) =>
                    this.renderItem(
                      miscellaneous,
                      key,
                      form,
                      values.miscellaneous.length,
                      'miscellaneous',
                      [],
                      ['Quantity', 'Miscellaneous'],
                      errors && errors.miscellaneous ? errors.miscellaneous[key] : {},
                      touched && touched.miscellaneous ? touched.miscellaneous[key] : {},
                      'text',
                    ),
                  )}
                </div>
              )}
            />
          </Col>
        </Row>
      </Jumbotron>
    );
  }
}
