import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import config from 'config';
import styled from 'styled-components';

const SketchesPreviewContainer = styled.div`
  background-color: lightgrey;
  padding: 5px;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
`;

const SketchPreviewImgContainer = styled.div`
  height: 50px;
  min-width: 80px;
  max-width: 80px;
  margin-right: 5px;
  border: 1px solid grey;
  position: relative;
`;

const DeleteIcon = styled.i`
  font-size: 15px;
  color: red;
  cursor: pointer;
  position: absolute;
`;

const SketchPreviewImg = styled.img`
  height: 100%;
  width: 100%;
`;

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

const uploadImageAsync = async (name, uri, token, folder) => {
  const bodyFormData = new FormData();
  const file = dataURItoBlob(uri);
  bodyFormData.append('file', file, name);
  return axios.post(`${config.BASE_URL}/uploads/${folder}`, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      jwt: token,
    },
  });
};

export default class Sketch extends Component {
  sigPad = {};

  clear = () => {
    this.sigPad.clear();
  };

  save = () => {
    const { token, folder, handleSuccess } = this.props;
    const uri = this.sigPad.getCanvas().toDataURL('image/png');
    const name = `sketch-${Date.now()}.png`;
    uploadImageAsync(name, uri, token, folder)
      .then(data => {
        this.sigPad.clear();
        handleSuccess(data.data);
      })
      .catch(error => {
        console.log('Error:', error);
      });
  };

  remove = url => {
    const { handleRemove } = this.props;
    handleRemove({ url });
  };

  render() {
    const { sketchs } = this.props;
    return (
      <div>
        <SignatureCanvas
          canvasProps={{
            height: 300,
            className: 'sigCanvas',
          }}
          ref={ref => {
            this.sigPad = ref;
          }}
        />
        <div>
          <ButtonGroup className="mt-2">
            <Button variant="secondary" onClick={this.clear}>
              Clear
            </Button>
            <Button variant="primary" onClick={this.save}>
              Save
            </Button>
          </ButtonGroup>
        </div>
        <br />
        <SketchesPreviewContainer>
          {(sketchs || []).map((sketch, index) => (
            <SketchPreviewImgContainer>
              <DeleteIcon className="fas fa-minus-circle" onClick={() => this.remove(sketch)} />
              <SketchPreviewImg src={sketch} key={index} />
            </SketchPreviewImgContainer>
          ))}
        </SketchesPreviewContainer>
      </div>
    );
  }
}
