import React, { Component } from 'react';
import { Colors } from 'modules/theme';
import { Button, Row, Col, Container } from 'react-bootstrap';
import PageWithBackgroundImage from 'components/PageWithBackgroundImage';
import PlanBox from 'components/PlanBox';
import Icon from 'components/Icon';

export default class RegisterCompanyView extends Component {
  saveProductStep = step => {
    const { registerCompanySaveData, registerCompanyChangeStep, product_id } = this.props;
    registerCompanySaveData({ values: { product_id } });
    registerCompanyChangeStep({ step });
  };

  render() {
    const { products, product_id, selectPlan, saveCompany } = this.props;

    return (
      <PageWithBackgroundImage>
        <Container className="my-5">
          <Row className="mt-5 px-5">
            <Col>
              <h2 className="align-items-center">
                <Icon
                  fontSize={30}
                  name="chevron-left"
                  color={Colors.blue}
                  customStyle={{ paddingRight: '20px' }}
                />
                Sign up
              </h2>
            </Col>
          </Row>
          <Row className="mt-5 px-5">
            <Col>
              <h2>Choose a plan</h2>
            </Col>
          </Row>
          <Row className="px-5">
            <Col className="d-flex justify-content-center align-items-center  flex-wrap">
              {products.map(product => (
                <PlanBox
                  key={1}
                  product={product}
                  product_id={product_id}
                  selectPlan={selectPlan}
                />
              ))}
            </Col>
          </Row>
          <Row className="px-5">
            <Col className="d-flex">
              <Button
                variant="secondary"
                size="lg"
                block
                style={{ height: '50px' }}
                onClick={() => this.saveProductStep(1)}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                    color: Colors.white,
                    fontSize: 20,
                    textAlign: 'center',
                    marginBottom: 'auto',
                  }}
                >
                  Back
                </p>
              </Button>
            </Col>
            <Col className="d-flex">
              <Button
                variant="primary"
                size="lg"
                block
                style={{ height: '50px' }}
                onClick={saveCompany}
                onClick={() => {
                  this.saveProductStep(3);
                }}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                    color: Colors.white,
                    fontSize: 20,
                    textAlign: 'center',
                    marginBottom: 'auto',
                  }}
                >
                  Next
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </PageWithBackgroundImage>
    );
  }
}
