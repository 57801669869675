// @flow
/**
 * @module Actions/Job
 * @desc Job Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getJobs,
  getJob,
  createJob,
  editJob,
  deleteJob,
  acceptJob,
  createJobPdf,
} = createActions({
  [ActionTypes.GET_JOBS]: params => params,
  [ActionTypes.GET_JOB]: id => ({ id }),
  [ActionTypes.CREATE_JOB]: data => ({ data }),
  [ActionTypes.EDIT_JOB]: (id, data) => ({ id, data }),
  [ActionTypes.DELETE_JOB]: id => ({ id }),
  [ActionTypes.ACCEPT_JOB]: (id, accepted) => ({ id, accepted }),
  [ActionTypes.CREATE_JOB_PDF]: id => ({ id }),
});
