import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSalesman, getCustomer } from 'actions/user';
import { createJob } from 'actions/job';
import { Colors } from 'modules/theme';
import { Row, Col, Button, ButtonGroup, Navbar, Tab, Tabs } from 'react-bootstrap';
import { push } from 'modules/history';
import { Formik, Form as FormikForm } from 'formik';
import Icon from 'components/Icon';
import QuoteDetailsView from './QuoteDetailsView';
import BillingAddressView from './BillingAddressView';
import JobAddressView from './JobAddressView';
import GlassPiecesView from './GlassPiecesView';
import RepairDoorsView from './RepairDoorsView';
import NewDoorsView from './NewDoorsView';
import NotesView from './NotesView';

class CreateJobContainer extends Component {
  componentDidMount() {
    const { getSalesman, getCustomer } = this.props;
    getSalesman();
    getCustomer();
  }

  render() {
    const { salesmans, csrs, submitJob, token, job } = this.props;

    return (
      <Formik enableReinitialize onSubmit={submitJob} initialValues={job}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, submitForm }) => {
          const glassPiecesCount = values.glassPieces.length;
          const newDoorsCount = values.newDoors.length;
          const repairDoorsCount = values.repairDoors.length;
          const otherCount = values.other.length;

          return (
            <FormikForm>
              <Row className="mt-5 pb-4">
                <Col>
                  <h2 className="align-items-center">
                    <Icon
                      fontSize={25}
                      name="chevron-left"
                      color={Colors.blue}
                      customStyle={{ paddingRight: '20px' }}
                      onClick={() => push('/dashboard/jobs')}
                    />
                    Create Job
                  </h2>
                </Col>
                <ButtonGroup className="d-none d-md-flex mr-4">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    type="submit"
                    className="float-right button"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setFieldValue('job_status', 'Submitted');
                      submitForm();
                    }}
                    variant="primary"
                    size="lg"
                    type="button"
                    className="float-right ml-2 button"
                  >
                    Submit Job
                  </Button>
                </ButtonGroup>
              </Row>

              {/* Quote */}
              <QuoteDetailsView
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                salesmans={salesmans}
                csrs={csrs}
              />
              {/* Billing Address */}
              <BillingAddressView
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
              {/* Job Address */}
              <JobAddressView
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
              {/* NAVBAR */}
              <Row className="px-4 py-4 ml-1 mr-1" style={{ background: '#F8FBFF' }}>
                <Col xs={12} md={12} sm={12} xl={12} className="pl-0">
                  <Navbar>
                    <Navbar.Brand className="text-button">Select Job Info</Navbar.Brand>
                  </Navbar>
                </Col>
              </Row>
              <Row className="px-4 py-4 ml-1 mr-1" style={{ background: '#F8FBFF' }}>
                <Col xs={12} md={12} sm={12} xl={12}>
                  <Tabs defaultActiveKey="glassPieces" id="tabs" className="mt-3">
                    <Tab
                      eventKey="glassPieces"
                      title={
                        <>
                          <Row className="p-4">
                            <h3 className="input-text mr-2">Glass</h3>
                            {glassPiecesCount >= 1 && (
                              <p
                                className="icon-button button-rounded mr-1"
                                style={{ background: '#0084EA', fontWeight: 'bold' }}
                              >
                                {glassPiecesCount}
                              </p>
                            )}
                          </Row>
                        </>
                      }
                    >
                      <GlassPiecesView
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        token={token}
                      />
                    </Tab>
                    <Tab
                      eventKey="repairDoors"
                      values={values}
                      title={
                        <>
                          <Row className="p-4">
                            <h3 className="input-text mr-2">Repair</h3>
                            {repairDoorsCount >= 1 && (
                              <p
                                className="icon-button button-rounded mr-1"
                                style={{ background: '#0084EA', fontWeight: 'bold' }}
                              >
                                {repairDoorsCount}
                              </p>
                            )}
                          </Row>
                        </>
                      }
                    >
                      <RepairDoorsView
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        token={token}
                      />
                    </Tab>
                    <Tab
                      eventKey="newDoors"
                      values={values}
                      title={
                        <>
                          <Row className="p-4">
                            <h3 className="input-text mr-2">New</h3>
                            {newDoorsCount >= 1 && (
                              <p
                                className="icon-button button-rounded mr-1"
                                style={{ background: '#0084EA', fontWeight: 'bold' }}
                              >
                                {newDoorsCount}
                              </p>
                            )}
                          </Row>
                        </>
                      }
                    >
                      <NewDoorsView
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        token={token}
                      />
                    </Tab>
                    <Tab
                      eventKey="notes"
                      title={
                        <Row className="p-4">
                          <h3 className="input-text mr-2">Other</h3>
                          {otherCount >= 1 && (
                            <p
                              className="icon-button button-rounded mr-1"
                              style={{ background: '#0084EA', fontWeight: 'bold' }}
                            >
                              {otherCount}
                            </p>
                          )}
                        </Row>
                      }
                    >
                      <NotesView
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        token={token}
                      />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} xl={12} className="mt-4 pr-1">
                  <ButtonGroup className="mr-4 float-right">
                    <Button
                      variant="outline-primary"
                      size="lg"
                      type="submit"
                      className="float-right button"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        setFieldValue('job_status', 'Submitted');
                        submitForm();
                      }}
                      variant="primary"
                      size="lg"
                      type="button"
                      className="float-right ml-2 button"
                    >
                      Submit Job
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </FormikForm>
          );
        }}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.job.loading,
    salesmans: state.user.salesmans,
    csrs: state.user.csrs,
    job: state.job.current,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitJob: values => dispatch(createJob(values)),
    getSalesman: () => dispatch(getSalesman()),
    getCustomer: () => dispatch(getCustomer()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateJobContainer);
