import React, { Component } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
  Breadcrumb,
  Form,
  Navbar,
  Spinner,
  ButtonGroup,
  Image,
} from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import { isRequired, isEmail } from 'utils/rules';
import * as Yup from 'yup';
import config from 'config';
import Icon from 'components/Icon';

export default class PDFDocumentsView extends Component {
  render() {
    const { submitEditCompany, loading, token, profile, company } = this.props;

    if (loading) {
      return (
        <Container>
          <Row>
            <Col className="w-100 d-flex align-items-center justify-content-center">
              <Spinner animation="border" role="status" />
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <>
        <Formik enableReinitialize onSubmit={submitEditCompany} initialValues={company}>
          {({ values, handleChange, handleBlur, isValid, setFieldValue }) => {
            return (
              <FormikForm className="ml-4">
                <Row>
                  <Col xs={12} sm={12} md={12} xl={12}>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        className="label-check"
                        name="include_PDF"
                        type="checkbox"
                        label="Include Logo In PDF"
                        onChange={handleChange}
                        value={values.include_PDF}
                        checked={values.include_PDF}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12} xl={12}>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        className="label-check"
                        name="append_image_to_end"
                        type="checkbox"
                        label="Append Job Images to End of Document "
                        onChange={handleChange}
                        value={values.append_image_to_end}
                        checked={values.append_image_to_end}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12} xl={12}>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        className="label-check"
                        name="exclude_PDF_versioning"
                        type="checkbox"
                        label="Exclude PDF Versioning"
                        onChange={handleChange}
                        value={values.exclude_PDF_versioning}
                        checked={values.exclude_PDF_versioning}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={12} sm={12} xl={12} className="mt-4 pr-4">
                    <ButtonGroup className="mr-4">
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        className="button"
                        onClick={() => {
                          console.log(values);
                        }}
                      >
                        Submit
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </FormikForm>
            );
          }}
        </Formik>
      </>
    );
  }
}
