/* eslint-disable no-use-before-define */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { push } from 'modules/history';
import {
  Container,
  Table,
  Button,
  Alert,
  Form,
  FormControl,
  Modal,
  Row,
  Col,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import { JobStatus, Accepted, ElementsPerPage } from 'constants/index';
import Pagination from 'components/Pagination';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons';

import FormCreateEvent from '../Events/components/FormCreateEvent';
import ModalComponent from '../../components/ModalComponent';
import { userRoleId } from '../../constants';

const matchJobStatusToClass = {
  'New Ticket': 'newticket',
  Research: 'research',
  Submitted: 'submitted',
  Quote: 'quote',
  'On Hold': 'onhold',
  'N/A': 'na',
  'Void Cancel': 'voidcancel',
  'Void Duplicate': 'voidduplicate',
  'Void Lost': 'voidlost',
};

export default class JobListView extends Component {
  constructor(props) {
    super(props);
    this.refSubmit = createRef();
    const { list, profile } = props;
    const { id, role_code } = profile;
    this.state = {
      showCreateEventAlert: false,
      showVoiceAlert: false,
      showEraseAlert: false,
      showApprovedAlert: false,
      currentId: null,
      search: '',
      salesman_id: role_code === 'sales' ? id : '',
      second_salesman_id: '',
      csr_id: '',
      job_status: role_code === 'sales' ? 'New Ticket' : '',
      notes: '',
      name: '',
      participants: '',
      start_time: null,
      type: 0,
      showQuickEdit: false,
      editInfoJobList: this.props.editList,
      elementsPerPage: 25,
      voice_id: '',
      openCreate: {
        state: false,
        id: null,
        company_id: null,
        address_line_1_job: null,
        address_line_2_job: null,
        city_job: null,
        quote_number: null,
      },
    };
  }

  static propTypes = {
    changeStatus: PropTypes.func.isRequired,
    createEvent: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    current: PropTypes.object.isRequired,
    csrs: PropTypes.array.isRequired,
    editList: PropTypes.array.isRequired,
    fetchJobs: PropTypes.func.isRequired,
    getJob: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    onCreateJobPdf: PropTypes.func.isRequired,
    onEditJob: PropTypes.func.isRequired,
    owners: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    profile: PropTypes.object.isRequired,
    removeJob: PropTypes.func.isRequired,
    salesmans: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
    company: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(newProps) {
    this.setState({ editInfoJobList: newProps.editList });
  }

  toggleSelects = id => {
    const listSelects = document.getElementsByClassName(`selectJob${id}`);
    const iconSelect = document.getElementsByClassName(`iconSelect${id}`);
    for (const item of listSelects) {
      item.disabled = !item.disabled;
    }
    for (const item of iconSelect) {
      if (item.className === `fas fa-pen iconSelect${id}`) {
        item.className = `fas fa-check iconSelect${id}`;
      } else {
        item.className = `fas fa-pen iconSelect${id}`;
      }
    }
  };

  changeQuickEditState = () => {
    if (this.state.showQuickEdit) {
      this.setState({
        showQuickEdit: false,
      });
    } else {
      this.setState({
        showQuickEdit: true,
      });
    }
  };

  findById = (obj, id) => obj.id === id;

  onQuickEdit = id => {
    const { onEditJob } = this.props;
    this.changeQuickEditState();
    if (this.state.showQuickEdit) {
      const { editInfoJobList } = this.state;
      const modifiedJob = editInfoJobList.find(obj => this.findById(obj, id));
      onEditJob(id, modifiedJob);
      this.toggleSelects(id);
    } else {
      this.toggleSelects(id);
    }
  };

  erase = () => {
    const { removeJob } = this.props;
    const { currentId } = this.state;
    removeJob(currentId);
    this.setState({ showEraseAlert: false });
  };

  voice = values => {
    const { voice_id } = this.state;
    this.state.editInfoJobList.find(obj => this.findById(obj, voice_id)).void_note =
      values.void_note;
    this.setState({
      editInfoJobList: this.state.editInfoJobList,
      showVoiceAlert: false,
    });
  };

  approved = () => {
    const { changeStatus } = this.props;
    const { currentId } = this.state;
    changeStatus(currentId, true);
    this.setState({ showApprovedAlert: false, currentId: null });
  };

  create = () => {
    const { createEvent, userId } = this.props;
    const { currentId, notes, participants, start_time, type, name } = this.state;
    createEvent({
      user_id: userId,
      job_id: currentId,
      notes,
      participants,
      name,
      start_time,
      type,
    });
    this.setState({ showCreateEventAlert: false });
  };

  onPrint = id => {
    const { onCreateJobPdf } = this.props;
    onCreateJobPdf(id);
  };

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderVoiceAlert = (title, content, show, handleClose, successText, success) => {
    const INITIAL_VAlUE = { void_note: '' };
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            <Formik enableReinitialize onSubmit={success} initialValues={INITIAL_VAlUE}>
              {({ values, handleChange }) => (
                <FormikForm className="ml-4">
                  <Form.Group controlId="void_note">
                    <Field
                      as="textarea"
                      name="void_note"
                      className="textarea"
                      value={values.void_note}
                      onChange={handleChange}
                      rows={3}
                    />
                  </Form.Group>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      {successText}
                    </Button>
                  </Modal.Footer>
                </FormikForm>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    );
  };

  listUserForSelect = ({ label, value }, key) => (
    <option key={key} value={value}>
      {label}
    </option>
  );

  renderCreateEvent = () => (
    <ModalComponent
      show={this.state.openCreate.state}
      onHide={() =>
        this.setState({
          openCreate: {
            state: false,
            id: null,
            company_id: null,
            address_line_1_job: null,
            address_line_2_job: null,
            city_job: null,
            quote_number: null,
          },
        })
      }
      textCancel="Cancel"
      textSubmit="Save"
      onClickSubmit={() => this.refSubmit.current.click()}
      variantSubmit="primary"
      title="Add event"
      body={
        <FormCreateEvent
          refSubmit={this.refSubmit}
          currentJob={this.props.current}
          id={this.state.openCreate.id}
          company_id={this.state.openCreate.company_id}
          openCreate={this.state.openCreate}
          company={this.props.company}
          participants={this.props.owners.map(owner => owner.email) || []}
          handleClose={() =>
            this.setState({
              openCreate: {
                state: false,
                id: null,
                company_id: null,
                address_line_1_job: null,
                address_line_2_job: null,
                city_job: null,
                quote_number: null,
              },
            })
          }
        />
      }
    />
  );

  renderJob = (
    {
      id,
      quote_number,
      city_job,
      address_line_1_job,
      address_line_2_job,
      state_job,
      job_description,
      additional_note,
      created_at,
      updated_at,
      company_id,
    },
    key,
    editInfoJobList,
  ) => (
    <tr key={key}>
      <td style={{ width: '12%' }}>
        <Row className="mt-3 mb-2">
          <Col>
            <Button
              className="ml-5"
              variant="primary"
              size="sm"
              type="button"
              onClick={() => push(`/dashboard/jobs/${id}/edit`)}
            >
              <h3>{quote_number}</h3>
            </Button>
          </Col>
        </Row>

        <Row>
          <Col className="ml-5">
            <Button
              className="button-inline btn-lg mt-3"
              variant="outline-primary"
              onClick={() => this.onQuickEdit(id)}
            >
              <i className={`fas fa-pen iconSelect${id}`} />
            </Button>
            <Button
              className="button-inline btn-lg mt-3"
              variant="outline-secondary"
              onClick={async () => {
                await this.props.getJob(id);
                this.setState({
                  openCreate: {
                    state: true,
                    id,
                    company_id,
                    address_line_1_job,
                    address_line_2_job,
                    city_job,
                    quote_number,
                  },
                });
              }}
              style={{ marginLeft: '8px' }}
            >
              <FontAwesomeIcon icon={faCalendarDays} size="1x" className="secondary-color" />
            </Button>
          </Col>
        </Row>
      </td>
      <td style={{ width: '12%' }}>
        <Row className="mt-3 mb-2">
          <Col>
            <select
              className={`select-jobs select-jobs-${
                matchJobStatusToClass[
                  editInfoJobList.find(obj => this.findById(obj, id)).job_status
                ]
              } form-control selectJob${id} firstStatus${id}`}
              value={editInfoJobList.find(obj => this.findById(obj, id)).job_status}
              onChange={e => {
                const nanStatus = ['N/A', 'Void Cancel', 'Void Duplicate', 'Void Lost'];
                if (nanStatus.includes(e.target.value)) {
                  this.setState({ showVoiceAlert: true, voice_id: id });
                }
                this.handleChangeJobStatus(id, e);
              }}
              disabled
            >
              {JobStatus.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mt-3 mb-2">
          <Col className="mt-3">
            <Button
              variant="outline-danger"
              size="sm"
              type="button"
              onClick={() => this.onPrint(id)}
            >
              <i className="fas fa-file-pdf" style={{ fontSize: 15 }} />
              <h4> Download PDF</h4>
            </Button>
          </Col>
        </Row>
      </td>
      <td style={{ width: '12%' }}>
        <select
          className={`forms-control selectJob${id} firstStatus${id} mt-3`}
          value={editInfoJobList.find(obj => this.findById(obj, id)).salesman_id}
          onChange={e => this.handleChangeSalesman(id, e)}
          disabled
        >
          <option value="">Choose a Salesman</option>
          {this.props.salesmans.map((item, key) => this.listUserForSelect(item, key))}
        </select>
        <br />
        <select
          className={`forms-control selectJob${id} firstStatus${id} `}
          value={editInfoJobList.find(obj => this.findById(obj, id)).second_salesman_id}
          onChange={e => this.handleChangeSecondSalesman(id, e)}
          disabled
        >
          <option value="">Choose a Salesman</option>
          {this.props.salesmans.map((item, key) => this.listUserForSelect(item, key))}
        </select>
      </td>
      <td style={{ width: '12%' }}>
        <select
          className={`forms-control selectJob${id} firstStatus${id} mt-3`}
          value={editInfoJobList.find(obj => this.findById(obj, id)).csr_id}
          onChange={e => this.handleChangeCustomer(id, e)}
          disabled
        >
          <option value="">Choose a CSR </option>
          {this.props.csrs.map((item, key) => this.listUserForSelect(item, key))}
        </select>
      </td>
      <td style={{ width: '10%' }}>
        <h4 className="mt-3">{`${address_line_1_job} ${address_line_2_job ||
          ''}\n${city_job}\n ${state_job}`}</h4>
      </td>
      <td style={{ width: '15%' }}>
        <h4 className="mt-3">
          {!!job_description && `${job_description.substr(0, 139)} `}
          {!!job_description && (
            <OverlayTrigger
              trigger="click"
              key="bottom"
              placement="bottom"
              overlay={
                <Popover id="popover-positioned-'bottom'">
                  <Popover.Content
                    style={{ fontSize: '10px', lineHeight: '18px' }}
                  >{`${job_description} \n ${!!additional_note &&
                    additional_note}`}</Popover.Content>
                </Popover>
              }
            >
              <span className="d-inline-block pointer">
                <i className="fas fa-info-circle" />
              </span>
            </OverlayTrigger>
          )}
        </h4>
      </td>
      <td>
        <h4>{Moment(created_at).format('h:mm a MM/DD/YY')}</h4>
      </td>
      <td>
        <h4>{Moment(updated_at).format('h:mm a MM/DD/YY')}</h4>
      </td>
    </tr>
  );

  handlePageChange = page => {
    const { fetchJobs } = this.props;
    const {
      search,
      salesman_id,
      second_salesman_id,
      csr_id,
      job_status,
      accepted,
      elementsPerPage,
    } = this.state;
    fetchJobs({
      page,
      elementsPerPage,
      search,
      salesman_id,
      second_salesman_id,
      csr_id,
      job_status,
      accepted,
    });
  };

  handleFilterChange = evt => {
    const { name, value } = evt.target;
    this.setState({ [name]: value }, () => {
      const { fetchJobs } = this.props;
      const {
        search,
        salesman_id,
        second_salesman_id,
        csr_id,
        job_status,
        accepted,
        elementsPerPage,
      } = this.state;

      fetchJobs({
        page: 1,
        elementsPerPage,
        search,
        salesman_id,
        second_salesman_id,
        csr_id,
        job_status,
        accepted,
      });
    });
  };

  handleChangeType = event => {
    this.setState({ type: event.target.value });
  };

  handleChangeNotes = event => {
    this.setState({ notes: event.target.value });
  };

  handleChangeParticipants = event => {
    this.setState({ participants: event.target.value });
  };

  handleChangeName = event => {
    this.setState({ name: event.target.value });
  };

  handleChangeStartTime = event => {
    this.setState({ start_time: event.target.value });
  };

  handleChangeSalesman = (id, event) => {
    this.state.editInfoJobList.find(obj => this.findById(obj, id)).salesman_id = event.target.value;
    this.setState({ editInfoJobList: this.state.editInfoJobList });
  };

  handleChangeSecondSalesman = (id, event) => {
    this.state.editInfoJobList.find(obj => this.findById(obj, id)).second_salesman_id =
      event.target.value;
    this.setState({ editInfoJobList: this.state.editInfoJobList });
  };

  handleChangeCustomer = (id, event) => {
    this.state.editInfoJobList.find(obj => this.findById(obj, id)).csr_id = event.target.value;
    this.setState({ editInfoJobList: this.state.editInfoJobList });
  };

  handleChangeJobStatus = (id, event) => {
    this.state.editInfoJobList.find(obj => this.findById(obj, id)).job_status = event.target.value;
    this.setState({ editInfoJobList: this.state.editInfoJobList });
  };

  render() {
    const { list, salesmans, csrs, pages, currentPage } = this.props;
    const {
      showVoiceAlert,
      showEraseAlert,
      showApprovedAlert,
      showCreateEventAlert,
      search,
      salesman_id,
      second_salesman_id,
      job_status,
      csr_id,
      accepted,
      editInfoJobList,
      elementsPerPage,
    } = this.state;

    return (
      <div>
        <ButtonToolbar className="mt-4 mb-5 justify-content-between">
          <InputGroup className="mb-3 pb-3">
            <FormControl
              type="text"
              className="form-control-placeholdericon"
              placeholder=" &#xF002;  Search"
              onChange={this.handleFilterChange}
              value={search}
              name="search"
            />
          </InputGroup>
          <ButtonGroup aria-label="First group">
            <Button
              variant="outline-primary"
              className="button"
              size="lg"
              onClick={() => push('/dashboard/jobs/create')}
              style={{ height: '41px', width: '215px' }}
            >
              + Add a quote
            </Button>
          </ButtonGroup>
        </ButtonToolbar>

        <Container fluid className="pl-0 pr-0 mb-5">
          <Row>
            <Col xs="12" sm="12" md="2" lg="2">
              <Form.Group controlId="jobStatus">
                <Form.Control
                  as="select"
                  name="job_status"
                  onChange={this.handleFilterChange}
                  value={job_status}
                  className="forms-control"
                >
                  <option value="">Status</option>
                  {JobStatus.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="3" lg="3">
              <Form.Group controlId="salesA">
                <Form.Control
                  as="select"
                  name="salesman_id"
                  onChange={this.handleFilterChange}
                  value={salesman_id}
                  className="forms-control"
                >
                  <option value="">Sales Rep A</option>
                  {salesmans.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="2" lg="2">
              <Form.Group controlId="salesB">
                <Form.Control
                  as="select"
                  name="second_salesman_id"
                  onChange={this.handleFilterChange}
                  value={second_salesman_id}
                  className="forms-control"
                >
                  <option value="">Sales Rep B</option>
                  {salesmans.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="3" lg="3">
              <Form.Group controlId="csr">
                <Form.Control
                  as="select"
                  name="csr_id"
                  onChange={this.handleFilterChange}
                  value={csr_id}
                  className="forms-control"
                >
                  <option value="">CSR</option>
                  {csrs.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="2" lg="2">
              <Form.Group controlId="accepted">
                <Form.Control
                  as="select"
                  name="accepted"
                  onChange={this.handleFilterChange}
                  value={accepted}
                  className="forms-control"
                >
                  {Accepted.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <Row className="ml-1">
          <Form.Group controlId="elementsPerPage">
            <Form.Control
              as="select"
              className="input-select"
              name="elementsPerPage"
              size="sm"
              onChange={this.handleFilterChange}
              value={elementsPerPage}
            >
              {ElementsPerPage.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
        <div className="table-responsive">
          <Table>
            <thead className="table-borderless">
              <tr style={{ borderColor: '#ffffff' }}>
                <th>
                  <h4 className="ml-5" style={{ color: '#425268' }}>
                    Quote
                  </h4>
                </th>
                <th>
                  <h4 style={{ color: '#425268' }}>Status</h4>
                </th>
                <th>
                  <h4 style={{ color: '#425268' }}>Salesman</h4>
                </th>
                <th>
                  <h4 style={{ color: '#425268' }}>CSR</h4>
                </th>
                <th>
                  <h4 style={{ color: '#425268' }}>Address</h4>
                </th>
                <th>
                  <h4 style={{ color: '#425268' }}>Description</h4>
                </th>
                <th>
                  <h4 style={{ color: '#425268' }}>Date Created</h4>
                </th>
                <th>
                  <h4 style={{ color: '#425268' }}>Date Updated</h4>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>{list.map((item, key) => this.renderJob(item, key, editInfoJobList))}</tbody>
          </Table>
        </div>
        {list.length === 0 && (
          <Alert variant="warning" className="input-text">
            There are currently no jobs
          </Alert>
        )}
        {/* Alerts */}
        {this.renderVoiceAlert(
          ' Why is this not applicable?',
          ' Why is this not applicable?',
          showVoiceAlert,
          () => this.setState({ showVoiceAlert: false }),
          'Save',
          this.voice,
        )}
        {this.renderAlert(
          'Remove Job',
          'Are you sure that want remove job?',
          showEraseAlert,
          () => this.setState({ showEraseAlert: false }),
          'Remove',
          this.erase,
        )}
        {this.renderAlert(
          'Approve Job',
          'Are you sure that want Approve job?',
          showApprovedAlert,
          () => this.setState({ showApprovedAlert: false }),
          'Approve',
          this.approved,
        )}
        {this.renderCreateEvent()}
        <Pagination
          currentPage={currentPage}
          changePage={this.handlePageChange}
          totalPages={pages}
        />
      </div>
    );
  }
}
