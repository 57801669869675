import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import RoutePrivate from 'components/RoutePrivate';
import UserList from 'routes/UserList';
import CreateUser from 'routes/User/CreateUser';
import EditUser from 'routes/User/EditUser';
import JobList from 'routes/JobList';
import CreateJob from 'routes/Job/CreateJob';
import EditJob from 'routes/Job/EditJob';
import EventList from 'routes/Events/EventList';
import Settings from 'routes/Settings';
import Owner from 'routes/Settings/Owners/Owner';

import Events from 'routes/Events';

import { replace } from 'modules/history';

export class DashboardRoutes extends React.Component {
  componentDidMount() {
    const {
      location: { pathname },
    } = this.props;
    if (pathname == '/dashboard' || pathname == '/dashboard/') {
      return this.getHomeComponent();
    }
  }

  getHomeComponent = () => replace('/dashboard/jobs');

  render() {
    const { isAuthenticated, match } = this.props;

    return (
      <>
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/jobs/:id/edit"
          component={EditJob}
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/jobs/create"
          component={CreateJob}
          exact
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/jobs"
          component={JobList}
          exact
        />
        {/* <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/events"
          component={EventList}
       />*/}
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/users"
          component={UserList}
          exact
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/users/create"
          component={CreateUser}
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/users/:id/edit"
          component={EditUser}
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/settings"
          component={Settings}
          exact
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/settings/owner/:id?"
          component={Owner}
          exact
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/events/:job_id"
          component={EventList}
          exact
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/events"
          component={Events}
          exact
        />
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.auth.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(DashboardRoutes));
