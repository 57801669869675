import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Icon = styled.i`
  background-color: ${Colors.gray};
  color: ${Colors.blue};
  font-size: 35px;
  padding: 7px 13px;
  border-radius: 40px;
`;

export class Contact extends React.PureComponent {
  submit = values => {
    const { sendContact } = this.props;
    sendContact(values);
  };
  render() {
    return (
      <>
        <Row>
          <Col sm={12} md={6}>
            <h2 style={{ marginBottom: 25 }}>Contact Us</h2>
            <h5 style={{ width: '70%' }}>
              Irure occaecat tempor deserunt consectetur dolore laboris proident ullamco mollit
              fugiat quis pariatur est. Mollit eiusmod eiusmod irure nulla veniam pariatur eiusmod
              eiusmod velit consequat sint voluptate commodo.
            </h5>

            <Row className="align-items-center my-5 flex-nowrap">
              <Icon className="fas fa-phone-volume mr-5" />
              <h5>(301) 931-7800</h5>
            </Row>

            <Row className="align-items-center my-5 flex-nowrap">
              <Icon className="fas fa-phone-volume mr-5" />
              <h5>(301) 931-7800</h5>
            </Row>
            <Row className="align-items-center my-5 flex-nowrap">
              <Icon className="fas fa-map-marker-alt mr-5" />
              <h5>6600 Ammendale Road Beltsville, MD 20705</h5>
            </Row>
          </Col>
          <Col sm={12} md={6} className="d-flex justify-content-center">
            <img
              src="https://ep01.epimg.net/tecnologia/imagenes/2019/11/13/actualidad/1573658897_566202_1573659544_noticia_normal.jpg"
              width="80%"
              height="auto"
            />
          </Col>
        </Row>
        <Row className="my-5">
          <Formik
            enableReinitialize
            initialValues={{
              name: '',
              email: '',
              phone: '',
              topic: '',
              organization: '',
              address: '',
              message: '',
            }}
            onSubmit={(values, { resetForm }) => {
              this.submit(values);
              resetForm();
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required'),
              email: Yup.string()
                .required('Required')
                .email('Invalid Format'),
              phone: Yup.string().required('Required'),
              topic: Yup.string().required('Required'),
              organization: Yup.string().required('Required'),
              address: Yup.string().required('Required'),
              message: Yup.string().required('Required'),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              resetForm,
            }) => (
              <Container>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="name"
                          name="name"
                          placeholder="Enter name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                      <Form.Group controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="phone"
                          name="phone"
                          placeholder="Enter phone number"
                          value={values.phone}
                          onChange={handleChange}
                          isValid={touched.phone && !errors.phone}
                          onBlur={handleBlur}
                        />
                      </Form.Group>

                      <Form.Group controlId="organization">
                        <Form.Label>Organization Name</Form.Label>
                        <Form.Control
                          type="organization"
                          name="organization"
                          placeholder="Enter organization name"
                          value={values.organization}
                          onChange={handleChange}
                          isValid={touched.organization && !errors.organization}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group controlId="email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email address"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          onBlur={handleBlur}
                        />
                      </Form.Group>

                      <Form.Group controlId="topic">
                        <Form.Label>Topic</Form.Label>
                        <Form.Control
                          type="topic"
                          name="topic"
                          placeholder="Enter topic"
                          value={values.topic}
                          onChange={handleChange}
                          isValid={touched.topic && !errors.topic}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                      <Form.Group controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="address"
                          name="address"
                          placeholder="Enter address"
                          value={values.address}
                          onChange={handleChange}
                          isValid={touched.address && !errors.address}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="message">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          type="message"
                          name="message"
                          as="textarea"
                          rows="5"
                          placeholder="Enter message"
                          value={values.message}
                          onChange={handleChange}
                          isValid={touched.message && !errors.message}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button type="submit" size="lg" disabled={!isValid}>
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            )}
          </Formik>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Contact);
