import React from 'react';
import { connect } from 'react-redux';
import { cronofyRegisterUser } from 'actions/index';
import { Spinner } from 'react-bootstrap';

export class TypeYourPhoneNumber extends React.PureComponent {
  componentDidMount() {
    const {
      cronofyRegisterUser,
      location: {
        query: { code },
      },
    } = this.props;
    cronofyRegisterUser({ code });
  }

  render() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cronofyRegisterUser: code => dispatch(cronofyRegisterUser(code)),
  };
}

export default connect(null, mapDispatchToProps)(TypeYourPhoneNumber);
