import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { replace } from 'modules/history';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Breadcrumb,
  Alert,
  Navbar,
  Nav,
  Form,
  FormControl,
  Modal,
  Row,
  Col,
  ButtonGroup,
} from 'react-bootstrap';

import { MapEventType, eventTypes } from 'constants/index';
import Moment from 'moment';

export default class EventListView extends Component {
  static propTypes = {
    removeEvent: PropTypes.func.isRequired,
    editEvent: PropTypes.func.isRequired,
  };

  state = {
    name: '',
    currentId: null,
    showEditAlert: false,
    showEraseAlert: false,
    notes: '',
    participants: '',
    type: -1,
    start_time: null,
    created_at: null,
    showCurrentList: true,
    showPastList: false,
    currentButton: 'primary',
    pastButton: 'outline-secondary',
  };

  erase = () => {
    const { removeEvent } = this.props;
    const { currentId } = this.state;
    removeEvent(currentId);
    this.setState({ showEraseAlert: false });
  };

  edit = () => {
    const { editEvent } = this.props;
    const { currentId, notes, participants, start_time, type, name } = this.state;
    editEvent(currentId, {
      notes: notes,
      participants: participants,
      name: name,
      start_time: start_time,
      type: type,
    });
    this.setState({ showEditAlert: false });
  };

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{content}</Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderEvent = (
    { id, Job, notes, type, created_at, updated_at, start_time, participants, User, job_id, name },
    key,
  ) => (
    <tr key={key}>
      {this.props.onContainer == true && (
        <td>
          <h2
            className="mt-4 mb-4"
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => replace(`/dashboard/jobs/${job_id}/edit`)}
          >
            {Job.quote_number}
          </h2>
        </td>
      )}
      <td>
        {type == 0 && <i className="fa fa-calendar mt-4 mb-4" style={{ fontSize: '24px' }} />}
        {type == 1 && <i className="fa fa-phone mt-4 mb-4" style={{ fontSize: '24px' }} />}
        {type == 2 && <i className="fa fa-file-o mt-4 mb-4" style={{ fontSize: '24px' }} />}
        {type == 3 && <i className="fa fa-comment-o mt-4 mb-4" style={{ fontSize: '24px' }} />}
        {type == 4 && <i className="fa fa-envelope-o mt-4 mb-4" style={{ fontSize: '24px' }} />}
      </td>
      <td>
        <h4 className="mt-4 mb-4">{User.username}</h4>
      </td>
      <td>
        <h4 className="mt-4 mb-4">{MapEventType[type]}</h4>
      </td>
      <td>
        <h4 className="mt-4 mb-4">{notes}</h4>
      </td>
      {type == 0 && (
        <td>
          <h4 className="mt-4 mb-4">{Moment(start_time).format('h:mm a MM/DD/YY')}</h4>
        </td>
      )}
      {type != 0 && (
        <td>
          <h4 className="mt-4 mb-4">{Moment(updated_at).format('h:mm a MM/DD/YY')}</h4>
        </td>
      )}
      <td>
        <h4 className="mt-4 mb-4">{Moment(created_at).format('MM/DD/YY')}</h4>
      </td>
      <td style={{ textAlign: 'center' }}>
        <ButtonGroup className="mt-3">
          <Button
            className="button-inline btn-lg"
            variant="light"
            onClick={() =>
              this.setState({
                showEditAlert: true,
                currentId: id,
                notes: notes,
                created_at: created_at,
                type: type,
                start_time: Moment(start_time).format('YYYY-MM-DDTHH:mm'),
                participants: participants,
                name: name,
              })
            }
          >
            <i className="fas fa-pen"></i>
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mt-3">
          <Button
            className="mt-3"
            className="button-inline btn-lg"
            variant="light"
            onClick={() => this.setState({ showEraseAlert: true, currentId: id })}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );

  handleChangeNotes = event => {
    this.setState({ notes: event.target.value });
  };

  handleChangeParticipants = event => {
    this.setState({ participants: event.target.value });
  };

  handleChangeName = event => {
    this.setState({ name: event.target.value });
  };

  handleChangeStartTime = event => {
    this.setState({ start_time: event.target.value });
  };

  handleChangeType = event => {
    this.setState({ type: event.target.value });
  };

  render() {
    const { listCurrentEvent, listPastEvent } = this.props;
    const {
      showEraseAlert,
      showEditAlert,
      notes,
      participants,
      type,
      start_time,
      created_at,
      showCurrentList,
      showPastList,
      currentButton,
      pastButton,
    } = this.state;

    return (
      <div>
        <Row className="ml-1 mb-5 mt-4">
          <ButtonGroup>
            <Button
              variant={currentButton}
              size="lg"
              onClick={() =>
                this.setState({
                  showCurrentList: true,
                  showPastList: false,
                  currentButton: 'primary',
                  pastButton: 'outline-secondary',
                })
              }
            >
              <p className="text-button">Current</p>
            </Button>
          </ButtonGroup>
          <ButtonGroup className="ml-4">
            <Button
              variant={pastButton}
              size="lg"
              onClick={() =>
                this.setState({
                  showCurrentList: false,
                  showPastList: true,
                  currentButton: 'outline-secondary',
                  pastButton: 'primary',
                })
              }
            >
              <p className="text-button">Past</p>
            </Button>
          </ButtonGroup>
        </Row>

        {listCurrentEvent.length === 0 && showCurrentList == true && (
          <Alert variant="warning" className="input-text">
            There are no events in current
          </Alert>
        )}
        {listPastEvent.length === 0 && showPastList == true && (
          <Alert variant="warning" className="input-text">
            There are no events in current
          </Alert>
        )}
        {showCurrentList == true && (
          <Table responsive>
            <tbody style={{ textAlign: 'center' }}>
              {listCurrentEvent.map((item, key) => this.renderEvent(item, key))}
            </tbody>
          </Table>
        )}
        {showPastList == true && (
          <Table responsive>
            <tbody style={{ textAlign: 'center' }}>
              {listPastEvent.map((item, key) => this.renderEvent(item, key))}
            </tbody>
          </Table>
        )}
        {/* Alerts */}
        {this.renderAlert(
          'Remove Event',
          'Are you sure that want remove event?',
          showEraseAlert,
          () => this.setState({ showEraseAlert: false }),
          'Remove',
          this.erase,
        )}
        {this.renderAlert(
          <h3>Edit Event</h3>,
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="typeEvent">Select type of Event:</label>
                  <select
                    id="typeEvent"
                    className="form-control"
                    value={this.state.type}
                    onChange={this.handleChangeType}
                  >
                    <option value={eventTypes[0].value}>{eventTypes[0].label}</option>
                    <option value={eventTypes[1].value}>{eventTypes[1].label}</option>
                    <option value={eventTypes[2].value}>{eventTypes[2].label}</option>
                    <option value={eventTypes[3].value}>{eventTypes[3].label}</option>
                    <option value={eventTypes[4].value}>{eventTypes[4].label}</option>
                  </select>
                </div>
              </div>
              {this.state.type == 0 && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="eventName">Event name:</label>
                    <input
                      id="eventName"
                      className="form-control"
                      type="text"
                      value={this.state.name}
                      onChange={this.handleChangeName}
                    />
                  </div>
                </div>
              )}
            </div>
            {this.state.type == 0 && (
              <div>
                <div className="col-xs-12">
                  <div className="form-group">
                    <label>Select Date:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={this.state.start_time}
                      onChange={this.handleChangeStartTime}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-group">
                    <label>Participants:</label>
                    <textarea
                      name="textarea"
                      className="form-control"
                      rows="3"
                      onChange={this.handleChangeParticipants}
                    >
                      {this.state.participants}
                    </textarea>
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="form-group">
                <label>Notes:</label>
                <textarea
                  name="textarea"
                  className="form-control"
                  rows="3"
                  onChange={this.handleChangeNotes}
                >
                  {this.state.notes}
                </textarea>
              </div>
            </div>
          </form>,
          showEditAlert,
          () => this.setState({ showEditAlert: false }),
          'Save',
          this.edit,
        )}
      </div>
    );
  }
}
