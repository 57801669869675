import React, { Component } from 'react';
import { Row, Col, Navbar, Form, Button, Alert, Modal, InputGroup } from 'react-bootstrap';
import { Field, FieldArray } from 'formik';
import { States, Parkings, JobStatus, Labors } from 'constants/index';
import { isRequired, isZipCode } from 'utils/rules';

const INITIAL_LABOR = {
  labor_job: '',
  installers_job: '',
  hours_job: '',
  notes_job: '',
};

export default class JobAddressView extends Component {
  state = {
    isVisibleModal: false,
  };

  copyValues = () => {
    const { values, setFieldValue } = this.props;

    setFieldValue('full_name_job', values.full_name_billing);
    setFieldValue('address_line_1_job', values.address_line_1_billing);
    setFieldValue('address_line_2_job', values.address_line_2_billing);
    setFieldValue('city_job', values.city_billing);
    setFieldValue('state_job', values.state_billing);
    setFieldValue('zip_code_job', values.zip_code_billing);
  };

  switchModal = () => {
    const { isVisibleModal } = this.state;
    this.setState({ isVisibleModal: !isVisibleModal });
  };

  renderAlert = (handleChange, value) => (
    <Modal show={this.state.isVisibleModal} onHide={this.switchModal} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title> Why is this not applicable?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group controlId="void_note">
          <Field
            as="textarea"
            name="void_note"
            className="form-control"
            value={value}
            onChange={handleChange}
            rows={3}
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={this.switchModal}>
          Close
        </Button>
        <Button variant="primary" onClick={this.switchModal} disabled={!value}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderLabor = (labor, key, form, push, remove, size, errors = {}, touched = {}) => {
    const { setFieldValue, handleBlur } = this.props;
    const handleChange = evt => {
      const { name, value } = evt.target;
      setFieldValue(name, value);
    };
    return (
      <Row key={key}>
        <Col xs={12} sm={12} md={12} xl={12} className="mt-2 mb-4">
          <hr />
        </Col>

        <Col xs={9} sm={9} md={9} xl={9}>
          <Form.Label className="ml-1 text-button">Labor {key + 1}</Form.Label>
        </Col>
        <Col xs={3} sm={3} md={3} xl={3}>
          {size - 1 === key ? (
            <i
              className="fas fa-plus icon-button button-rounded  float-right pointer"
              style={{ background: '#56D053' }}
              onClick={() => push(INITIAL_LABOR)}
            />
          ) : (
            <i
              className="fas fa-trash icon-div button-rounded float-right pointer"
              style={{ background: '#FF5B5B' }}
              onClick={() => remove(key)}
            />
          )}
        </Col>
        <Col xs={12} sm={12} md={3} xl={3}>
          <Form.Group controlId={`labors.${key}.labor_job`}>
            <Form.Label className="mt-3 input-text">Labor</Form.Label>
            <Field
              as="select"
              className={
                errors.labor_job && touched.labor_job
                  ? 'form-control is-invalid is-invalid-select '
                  : 'form-control '
              }
              name={`labors.${key}.labor_job`}
              value={labor.labor_job}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" />
              {Labors.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
          </Form.Group>
        </Col>

        <Col xs={6} sm={6} md={3} xl={3}>
          <Form.Group controlId={`labors.${key}.installers_job`}>
            <Form.Label className="mt-3 input-text">Installers</Form.Label>
            <Form.Control
              as={Field}
              name={`labors.${key}.installers_job`}
              type="number"
              value={labor.installers_job}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={3} xl={3}>
          <Form.Group controlId={`labors.${key}.hours_job`}>
            <Form.Label className="mt-3 input-text">Hours</Form.Label>
            <Form.Control
              as={Field}
              name={`labors.${key}.hours_job`}
              type="number"
              value={labor.hours_job}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={3} xl={3}>
          <Form.Group controlId={`labors.${key}.notes_job`}>
            <Form.Label className="mt-3 input-text">Notes</Form.Label>
            <Field
              as="textarea"
              className={
                errors.notes_job && touched.notes_job ? 'form-control is-invalid ' : 'form-control '
              }
              name={`labors.${key}.notes_job`}
              value={labor.notes_job}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={3}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  render() {
    const { values, errors, handleChange, touched, handleBlur, setFieldValue, isEdit } = this.props;
    return (
      <>
        <Row
          className="px-4 py-4 ml-1 mr-1"
          style={{ marginBottom: '10px', background: '#F8FBFF' }}
        >
          <Col xs={12} md={12} sm={12} xl={12} className="pl-2">
            <Navbar style={{ marginBottom: '10px' }}>
              <Navbar.Brand className="text-button">Job Address</Navbar.Brand>
              <Navbar.Collapse className="justify-content-end">
                <Button variant="link" className="button" onClick={this.copyValues}>
                  Same As Billing?
                </Button>
              </Navbar.Collapse>
            </Navbar>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="full_name_job">
              <Form.Label className="mt-3 input-text">Location name</Form.Label>
              <Form.Control
                as={Field}
                name="full_name_job"
                type="text"
                value={values.full_name_job}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="address_line_1_job">
              <Form.Label className="mt-3 input-text">Address Line 1</Form.Label>
              <Form.Control
                as={Field}
                name="address_line_1_job"
                type="text"
                value={values.address_line_1_job}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="address_line_2_job">
              <Form.Label className="mt-3 input-text">Address Line 2</Form.Label>
              <Form.Control
                as={Field}
                name="address_line_2_job"
                type="text"
                values={values.address_line_2_job}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="city_job">
              <Form.Label className="mt-3 input-text">City</Form.Label>
              <Form.Control
                as={Field}
                name="city_job"
                type="text"
                values={values.city_job}
                isInvalid={!!errors.city_job && touched.city_job}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city_job && touched.city_job && errors.city_job}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={8} sm={8} md={8} xl={8}>
            <Form.Group controlId="state_job">
              <Form.Label className="mt-3 input-text">State</Form.Label>
              <Field
                as="select"
                className={
                  errors.state_job && touched.state_job
                    ? 'form-control is-invalid is-invalid-select '
                    : 'form-control '
                }
                name="state_job"
                value={values.state_job}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="" />
                {States.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
            </Form.Group>
          </Col>
          <Col xs={4} sm={4} md={4} xl={4}>
            <Form.Group controlId="zip_code_job">
              <Form.Label className="mt-3 input-text">Zip</Form.Label>
              <Form.Control
                as={Field}
                name="zip_code_job"
                type="number"
                values={values.zip_code_job}
                onChange={handleChange}
                // validate={isZipCode}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row
          className="px-4 py-4 ml-1 mr-1"
          style={{ marginBottom: '10px', background: '#F8FBFF' }}
        >
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="job_description">
              <Form.Label className="mt-3 input-text">Job description</Form.Label>
              <Field
                as="textarea"
                className="textarea"
                name="job_description"
                value={values.job_description}
                onChange={evt => {
                  setFieldValue('job_description_updated', true);
                  handleChange(evt);
                }}
                onBlur={handleBlur}
                rows={3}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="job_description">
              <Form.Label className="mt-3 input-text">Additional Note</Form.Label>
              <Field
                as="textarea"
                className="textarea"
                name="additional_note"
                value={values.additional_note}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={3}
              />
              <InputGroup.Checkbox
                name="additional_note_doubt"
                onChange={handleChange}
                onBlur={handleBlur}
                rows={3}
                checked={values.additional_note_doubt}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="csr_notes">
              <Form.Label className="mt-3 input-text">CSR notes</Form.Label>

              <Field
                as="textarea"
                className="textarea"
                name="csr_notes"
                value={values.csr_notes}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={3}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="parking_job">
              <Form.Label className="mt-3 input-text">Parking</Form.Label>
              <Field
                as="select"
                className={
                  errors.parking_job && touched.parking_job
                    ? 'form-control is-invalid is-invalid-select '
                    : 'form-control '
                }
                name="parking_job"
                value={values.parking_job}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="" />
                {Parkings.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} className="mt-3">
            <FieldArray
              name="labors"
              render={({ form, push, remove }) => (
                <div>
                  {values.labors.map((labor, key) =>
                    this.renderLabor(
                      labor,
                      key,
                      form,
                      push,
                      remove,
                      values.labors.length,
                      errors && errors.labors ? errors.labors[key] : {},
                      touched && touched.labors ? touched.labors[key] : {},
                    ),
                  )}
                </div>
              )}
            />
          </Col>
          {/* JOBSTATUS */}
          <Col xs={12} sm={12} md={12} xl={12}>
            <Form.Group controlId="job_status">
              <Form.Label className="mt-3 input-text">Job status</Form.Label>
              <Field
                as="select"
                className={
                  errors.job_status && touched.job_status
                    ? 'form-control is-invalid is-invalid-select '
                    : 'form-control '
                }
                name="job_status"
                value={values.job_status}
                /*  validate={isRequired} */
                onChange={evt => {
                  handleChange(evt);
                  if (
                    ['N/A', 'Void Cancel', 'Void Duplicate', 'Void Lost'].includes(evt.target.value)
                  ) {
                    this.switchModal();
                  }
                }}
                onBlur={handleBlur}
              >
                <option value="" />
                {JobStatus.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
              <Form.Control.Feedback type="invalid">
                {errors.job_status && touched.job_status && errors.job_status}
              </Form.Control.Feedback>
            </Form.Group>
            {this.renderAlert(handleChange, values.void_note)}
          </Col>
        </Row>
      </>
    );
  }
}
