/* eslint-disable no-use-before-define */
import React from 'react';
import { Button, Modal, Col, Row, Container, Spinner } from 'react-bootstrap';

import PropTypes from 'prop-types';

function ModalComponent({
  show,
  onHide,
  title,
  textCancel,
  textSubmit,
  variantSubmit,
  variantCancel,
  body,
  onClickSubmit,
  onClickCancel,
  loading,
  size = 'lg',
  closeButton = true,
  heightButtons = 32,
  disabledCancel,
  disabledSubmit,
  ...props
}) {
  return (
    <Modal
      {...props}
      show={show}
      onHide={onHide}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={border0}
    >
      <Modal.Header closeButton={closeButton} className="border-0 p-4">
        <Modal.Title id="contained-modal-title-vcenter" style={border0}>
          <div style={modalTitleStyle}>{title}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">{body}</Modal.Body>
      <Modal.Footer className="border-0 p-4 w-full">
        <Container fluid>
          <Row>
            <Col xs={6} style={{ marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 5 }}>
              <Button
                onClick={onHide || onClickCancel}
                variant={variantCancel || 'secondary'}
                className="w-100"
                disabled={loading || disabledCancel}
                style={{
                  height: heightButtons,
                  fontSize: 15,
                  fontFamily: 'Lato',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                {textCancel}
              </Button>
            </Col>
            <Col xs={6} style={{ marginLeft: 0, marginRight: 0, paddingLeft: 5, paddingRight: 0 }}>
              <Button
                onClick={onClickSubmit}
                variant={variantSubmit || 'primary'}
                type="submit"
                className="w-100"
                disabled={loading || disabledSubmit}
                style={{
                  height: heightButtons,
                  fontSize: 15,
                  fontFamily: 'Lato',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  width: '100%',
                }}
              >
                {loading ? <Spinner animation="border" /> : textSubmit}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

ModalComponent.propTypes = {
  body: PropTypes.node.isRequired,
  disabledCancel: PropTypes.bool,
  disabledSubmit: PropTypes.bool,
  closeButton: PropTypes.bool,
  heightButtons: PropTypes.number,
  loading: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickSubmit: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  textCancel: PropTypes.string,
  textSubmit: PropTypes.string,
  title: PropTypes.string.isRequired,
  variantCancel: PropTypes.string,
  variantSubmit: PropTypes.string,
};

ModalComponent.defaultProps = {
  textCancel: 'Cancel',
  textSubmit: 'Submit',
  variantSubmit: 'primary',
  variantCancel: 'secondary',
  onClickSubmit: () => {},
};

export default ModalComponent;

export const border0 = {
  border: 0,
};

export const buttonCancelStyle = {
  height: 32,
  fontSize: 15,
  fontFamily: 'Lato',
  fontWeight: '400',
  wordWrap: 'break-word',
};

export const buttonSubmitStyle = {
  height: 32,
  fontSize: 15,
  fontFamily: 'Lato',
  fontWeight: '400',
  wordWrap: 'break-word',
  width: '100%',
};

export const modalTitleStyle = {
  color: '#173264',
  fontSize: 18,
  fontFamily: 'Lato',
  fontWeight: 600,
  letterSpacing: 0.38,
  wordWrap: 'break-word',
};
