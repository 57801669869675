import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import PageWithBackgroundImage from 'components/PageWithBackgroundImage';
import Icon from 'components/Icon';
import Logo from 'components/Logo';
import { Colors } from 'modules/theme';
import config from 'config';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class LoginUsersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: true,
    };
  }

  propTypes = {
    loginCredentials: PropTypes.shape({
      email: PropTypes.string,
      password: PropTypes.string,
      remember: PropTypes.bool,
    }),
    submit: PropTypes.func,
  };

  setBaseUrl = () => {
    window.location.hostname = config.HOST;
  };

  render() {
    const { loginCredentials, submit } = this.props;
    const { password } = this.state;
    return (
      <PageWithBackgroundImage>
        <Formik
          enableReinitialize
          initialValues={loginCredentials}
          onSubmit={submit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required('Required')
              .email('Invalid Format'),
            password: Yup.string()
              .required('Required')
              .min(8, 'The password must have more than eight characters'),
          })}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Container className="mt-5 mb-5 p-5">
              <Row className="mb-5 pl-5">
                <Col className="col col-lg-4 mt-5 mb-5 pb-5">
                  <Logo />
                </Col>
              </Row>

              <Row className="mt-5 mb-5 px-5">
                <Col className="mt-5">
                  <h2 className="align-items-center">
                    <Icon
                      fontSize={30}
                      name="chevron-left"
                      color={Colors.blue}
                      customStyle={{ paddingRight: '20px' }}
                      onClick={() => this.setBaseUrl()}
                    />
                    Log in
                  </h2>
                </Col>
              </Row>

              <Form onSubmit={handleSubmit}>
                <Row className="pt-4 pb-4 px-5">
                  <Col>
                    <Form.Group controlId="login">
                      <Form.Control
                        type="email"
                        name="email"
                        className="form-control-placeholdericon"
                        placeholder=" &#xf0e0;  Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className="text-error">{errors.email && touched.email && errors.email}</p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pt-4  pb-4 px-5">
                  <Col>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type={password ? 'password' : 'text'}
                        name="password"
                        className="form-control-placeholdericon"
                        placeholder=" &#xf084;  Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Icon
                        fontSize={25}
                        name={password ? 'eye-slash' : 'eye'}
                        color={Colors.blue}
                        customStyle={{
                          paddingRight: '20px',
                          paddingTop: '10px',
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '12px',
                          top: '0px',
                        }}
                        onClick={() => this.setState({ password: !password })}
                      />
                      <p className="text-error">
                        {errors.password && touched.password && errors.password}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pt-4  pb-4 px-5">
                  <Col className="d-flex justify-content-md-end justify-content-start">
                    <Link
                      to="/recover-password"
                      className="fs-2"
                      style={{
                        fontSize: 16,
                      }}
                    >
                      Forgot your Password?
                    </Link>
                  </Col>
                </Row>

                <Row className="pt-4  px-5">
                  <Col className="d-flex">
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      type="submit"
                      style={{ height: '50px' }}
                    >
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 18,
                          textAlign: 'center',
                          marginBottom: 'auto',
                        }}
                      >
                        Log in
                      </p>
                    </Button>
                  </Col>
                </Row>
                <Row className="px-5">
                  <Col xs={12} md={5} lg={4}>
                    <Form.Group>
                      <div className="d-flex align-content-center form-check">
                        <input
                          style={{ width: 18, height: 18 }}
                          className="form-check-input"
                          type="checkbox"
                          id="defaultCheck1"
                          checked={values.remember}
                          onClick={() => setFieldValue('remember', !values.remember)}
                        />
                        <h2 style={{ marginLeft: 20 }} htmlFor="defaultCheck1" className="pt-3">
                          Keep me Signed In
                        </h2>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={7} lg={8} className="d-md-flex justify-content-end pt-4 ">
                    <h2>Don’t have an account? </h2>
                    <Link to="/register-company">
                      <h2 className="ml-md-2"> Register Company</h2>
                    </Link>
                  </Col>
                </Row>
              </Form>
            </Container>
          )}
        </Formik>
      </PageWithBackgroundImage>
    );
  }
}
