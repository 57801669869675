import React from 'react';
import styled from 'styled-components';
import logo from 'assets/media/images/logo2.png';

export const Image = styled.img`
  width: ${props => (props.small ? '140px' : '200px')};
  heigth: ${props => (props.small ? '140px' : '200px')};
`;

const Logo = props => <Image {...props} src={logo} />;

export default Logo;
