import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createInquiry } from 'actions/index';
import InquiryView from './InquiryView';

class InquiryContainer extends Component {
  static propTypes = {
    createInquiry: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    inquiry: PropTypes.object.isRequired,
  };

  submitInquiry = values => {
    const { createInquiry } = this.props;

    createInquiry(values);
  };

  render() {
    return (
      <InquiryView createInquiry={this.submitInquiry} loading={this.loading} errors={this.errors} />
    );
  }
}

function mapStateToProps({ inquiry }) {
  return {
    loading: inquiry.loading,
    errors: inquiry.errors,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createInquiry: data => dispatch(createInquiry(data)),
    // listInquiry: () => dispatch(listInquiry()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(InquiryContainer);
