import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { getOwners } from 'actions/index';
import OwnersView from './OwnersView';

const OwnersContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOwners({ status: '' }));
  }, [dispatch]);
  return <OwnersView />;
};

export default OwnersContainer;
