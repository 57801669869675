import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import AddButton from '../../components/Buttons/Add';
import RemoveButton from '../../components/Buttons/Remove';
import ExtraContactField from '../../components/Inputs/ExtraContactField';

export default class ExtraContact extends Component {
  componentDidMount() {
    const { values, setFieldValue } = this.props;
    if (!values?.extraContact || values?.extraContact?.length === 0) {
      setFieldValue('extraContact', values.extraContact || []);
    }
  }

  render() {
    const { handleBlur, name, values, title, setFieldValue, errors } = this.props;
    return (
      <>
        <FieldArray name={name}>
          {({ push, remove }) => (
            <>
              <div xs={12} sm={12} md={12} xl={12} className="d-flex justify-content-between my-3">
                <h5 className="text-button">{title}</h5>
                {values?.extraContact?.length < 3 && (
                  <AddButton add={() => push({ name: '', email: '', phone: '' })} />
                )}
              </div>
              {values?.extraContact?.map((_, index) => (
                <Row key={index}>
                  <Col xs={12} sm={6} md={4}>
                    <ExtraContactField
                      label="Name"
                      name="name"
                      value={values?.extraContact?.[index]?.name || ''}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      errors={errors}
                      index={index}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={4}>
                    <ExtraContactField
                      label="Email"
                      name="email"
                      value={values?.extraContact?.[index]?.email || ''}
                      setFieldValue={setFieldValue}
                      index={index}
                      values={values}
                      onBlur={handleBlur}
                      type="email"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <ExtraContactField
                      label="Phone"
                      name="phoneNumber"
                      value={values?.extraContact?.[index]?.phoneNumber || ''}
                      setFieldValue={setFieldValue}
                      values={values}
                      index={index}
                      errors={errors}
                      onBlur={handleBlur}
                      type="phone"
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={1}
                    className="d-flex justify-content-end order-md-last mt-5 my-md-0"
                  >
                    <RemoveButton remove={() => remove(index)} />
                  </Col>
                </Row>
              ))}
            </>
          )}
        </FieldArray>
      </>
    );
  }
}

ExtraContact.propTypes = {
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};
