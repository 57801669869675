import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Navbar, Form } from 'react-bootstrap';
import { Field } from 'formik';
import { isRequired } from 'utils/rules';
import ExtraContact from './ExtraContact';

export default class QuoteDetailsView extends Component {
  render() {
    const {
      values,
      errors,
      handleChange,
      touched,
      handleBlur,
      salesmans,
      csrs,
      setFieldValue,
    } = this.props;
    return (
      <Row className="px-4 py-4 ml-1 mr-1" style={{ marginBottom: '10px', background: '#F8FBFF' }}>
        <Col xs={12} md={12} sm={12} xl={12} className="pl-2">
          <Navbar style={{ marginBottom: '10px' }}>
            <Navbar.Brand className="text-button">Quote Details</Navbar.Brand>
          </Navbar>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="quote_number">
            <Form.Label className="mt-3 input-text ">Quote number</Form.Label>
            <Form.Control
              as={Field}
              name="quote_number"
              type="text"
              value={values.quote_number}
              isInvalid={!!errors.quote_number && touched.quote_number}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly
            />
            <Form.Control.Feedback type="invalid">
              {errors.quote_number && touched.quote_number && errors.quote_number}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="salesman_id">
            <Form.Label className="mt-3 input-text ">Salesman name</Form.Label>
            <Field
              as="select"
              className={
                errors.salesman_id && touched.salesman_id
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name="salesman_id"
              value={values.salesman_id}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value=""> </option>
              {salesmans.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
            <Form.Control.Feedback type="invalid">
              {errors.salesman_id && touched.salesman_id && errors.salesman_id}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={12} xl={12}>
          {values.salesman_id !== '' && (
            <Form.Group controlId="second_salesman_id">
              <Form.Label className="mt-3 input-text">Second salesman name</Form.Label>
              <Field
                as="select"
                className={
                  errors.second_salesman_id && touched.second_salesman_id
                    ? 'form-control is-invalid is-invalid-select'
                    : 'form-control'
                }
                name="second_salesman_id"
                value={values.second_salesman_id}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""> </option>
                {salesmans.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
              <Form.Control.Feedback type="invalid">
                {errors.second_salesman_id &&
                  touched.second_salesman_id &&
                  errors.second_salesman_id}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Col>

        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="csr_id">
            <Form.Label className="mt-3 input-text">CSR</Form.Label>
            <Field
              as="select"
              className={
                errors.csr_id && touched.csr_id
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name="csr_id"
              value={values.csr_id}
              onChange={handleChange}
              onBlur={handleBlur}
              validate={isRequired}
              isInvalid={!!errors.csr_id && touched.csr_id}
            >
              <option value=""> </option>
              {csrs.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
            <Form.Control.Feedback type="invalid">
              {errors.csr_id && touched.csr_id && errors.csr_id}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="onsite_contact_full_name">
            <Form.Label className="mt-3 input-text ">Onsite Contact</Form.Label>
            <Form.Control
              as={Field}
              name="onsite_contact_full_name"
              type="text"
              values={values.onsite_contact_full_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="onsite_contact_email">
            <Form.Label className="mt-3 input-text ">Onsite contact email</Form.Label>
            <Form.Control
              as={Field}
              name="onsite_contact_email"
              type="email"
              values={values.onsite_contact_email}
              /*  isInvalid={!!errors.onsite_contact_email && touched.onsite_contact_email}  */
              onChange={handleChange}
              /* validate={isEmail} */
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="contact_phone_number">
            <Form.Label className="mt-3 input-text ">Contact number</Form.Label>
            <Form.Control
              as={Field}
              name="contact_phone_number"
              type="text"
              values={values.contact_phone_number}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <ExtraContact
            values={values}
            errors={errors}
            name="extraContact"
            title="Extra Contacts"
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
    );
  }
}

QuoteDetailsView.propTypes = {
  csrs: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  salesmans: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};
