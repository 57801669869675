import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { injectStripe } from 'react-stripe-elements';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { Colors } from 'modules/theme';
import Icon from 'components/Icon';
import PageWithBackgroundImage from 'components/PageWithBackgroundImage';

class PaymentMethodView extends Component {
  checkCard = async step => {
    const { registerCompanySaveData, registerCompanyChangeStep, registerCompany } = this.props;

    if (step == 4) {
      const tokenObjet = await this.getCardToken();

      if (tokenObjet.hasOwnProperty('token')) {
        registerCompanySaveData({ values: { card_token: tokenObjet.token.id } });
        registerCompanyChangeStep({ step });
        registerCompany();
      } else {
        this.props.showAlert(tokenObjet.error.message);
      }
    } else {
      registerCompanyChangeStep({ step });
    }
  };

  getCardToken = () => {
    return this.props.stripe
      .createToken({ name: 'Card', type: 'card' })
      .then(cardToken => cardToken)
      .catch(err => err);
  };

  render() {
    const createOptions = () => {
      return {
        style: {
          base: {
            fontSize: '18px',
            color: 'black',
            letterSpacing: '0.025em',
            '::placeholder': {
              color: '#6c757d',
            },
          },
          invalid: {
            color: '#c23d4b',
          },
        },
      };
    };

    return (
      <PageWithBackgroundImage>
        <Container className="my-5">
          <Row className="mt-5 px-5">
            <Col>
              <h2 className="align-items-center">
                <Icon
                  fontSize={30}
                  name="chevron-left"
                  color={Colors.blue}
                  customStyle={{ paddingRight: '20px' }}
                  onClick={() => this.checkCard(2)}
                />
                Back
              </h2>
            </Col>
          </Row>
          <Row className="mt-5 pt-5 px-5">
            <Col>
              <h2>Payment Method</h2>
            </Col>
          </Row>
          <Row className="px-5 mt-5 mb-5 ml-1 mr-1">
            <Col style={{ border: `1px solid #ced4da`, borderRadius: '3px', padding: '10px' }}>
              <CardNumberElement {...createOptions()} onChange={this.handleChange} />
            </Col>
          </Row>
          <Row className="px-5 mt-5 mb-5 ml-1  mr-1">
            <Col style={{ border: `1px solid #ced4da`, borderRadius: '3px', padding: '10px' }}>
              <CardExpiryElement {...createOptions()} onChange={this.handleChange} />
            </Col>
            <Col
              style={{
                border: `1px solid #ced4da`,
                borderRadius: '3px',
                padding: '10px',
                marginLeft: '10px',
              }}
            >
              <CardCVCElement {...createOptions()} onChange={this.handleChange} />
            </Col>
          </Row>
          <Row className="mt-5 px-5 mb-4">
            <Col>
              <Form.Group controlId="login">
                <Form.Control
                  style={{ height: '45px', fontSize: 18 }}
                  type="text"
                  name="cardHolder"
                  placeholder="Card Holder"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="px-5">
            <Col className="d-flex">
              <Button
                variant="primary"
                size="lg"
                block
                onClick={() => this.checkCard(4)}
                style={{ height: '50px' }}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                    color: Colors.white,
                    fontSize: 20,
                    textAlign: 'center',
                    marginBottom: 'auto',
                  }}
                >
                  Register
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </PageWithBackgroundImage>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(PaymentMethodView));
