import React from 'react';
import Footer from '../Footer';
import MainNavbar from '../Navbar';
import AboutView from './AboutView';

const About = () => {
  return (
    <>
      <MainNavbar />
      <AboutView />
      <Footer />
    </>
  );
};

export default About;
