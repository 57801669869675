import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getEventsByJob, getEvent, getJob, getOwners } from 'actions/index';
import { useParams } from 'react-router-dom';

import EventListView from './EventListView';
import { statusUser } from '../../../constants';

export default function EventsContainer() {
  const dispatch = useDispatch();
  const editingEventId = useSelector(({ event }) => event.editingEventId);
  const [reloadEvents, setReloadEvents] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { job_id } = useParams();

  useEffect(() => {
    dispatch(getEventsByJob({ job_id, query: { elementsPerPage: 10, page: currentPage } }));
  }, [dispatch, currentPage, reloadEvents]);

  useEffect(() => {
    if (editingEventId) {
      dispatch(getEvent(editingEventId));
    }
  }, [dispatch, editingEventId]);

  useEffect(() => {
    dispatch(getJob(job_id));
  }, [dispatch, job_id]);

  useEffect(() => {
    dispatch(getOwners({ status: statusUser.ACTIVE }));
  }, [dispatch]);

  return (
    <EventListView
      reloadEvents={reloadEvents}
      setReloadEvents={setReloadEvents}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      jobIdParams={job_id}
    />
  );
}
