export const footerData = [
  {
    title: '© 2023 Glaziers Tool',
    isText: true,
  },
  {
    title: 'Privacy',
    path: '/privacy',
  },
  {
    title: 'Policy',
    path: '/policy',
  },
];
