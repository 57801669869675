/* HANDLES */
import nd_Handle_1 from 'assets/media/handles/Handle_1.png';
import nd_Handle_1_opp from 'assets/media/handles/Handle_1_opp.png';
import nd_Handle_2 from 'assets/media/handles/Handle_2.png';
import nd_Handle_2_opp from 'assets/media/handles/Handle_2_opp.png';
import nd_Handle_3 from 'assets/media/handles/Handle_3.png';
import nd_Handle_3_opp from 'assets/media/handles/Handle_3_opp.png';
import nd_Handle_4 from 'assets/media/handles/Handle_4.png';
import nd_Handle_4_opp from 'assets/media/handles/Handle_4_opp.png';
import nd_Handle_5 from 'assets/media/handles/Handle_5.png';
import nd_Handle_5_opp from 'assets/media/handles/Handle_5_opp.png';
import nd_Handle_6 from 'assets/media/handles/Handle_6.png';
import nd_Handle_6_opp from 'assets/media/handles/Handle_6_opp.png';
import nd_Handle_7 from 'assets/media/handles/Handle_7.png';
import nd_Handle_7_opp from 'assets/media/handles/Handle_7_opp.png';

import {
  gdFS,
  gpNoteFrameStyle,
  gdDA,
  gpNoteDoorAction,
  gdheader,
  gdheaderShower,
  gpNoteheader,
  gpNoteGlassType,
  gdGlassType,
  gdGlassCoating,
  gpNoteGlassCoating,
  gdHardwareFinish,
  gdShowerHardwareFinish,
  gpNoteHardwareFinish,
  gpNoteAdH,
  gpNoteBottomRail,
  gpNoteFrameSystem,
  gpNoteHangingHardware,
  gpNoteDoorCloser,
  gpLookNote,
  gpPanicDeviceNote,
  gpMidPanelNote,
  gpWeatherStrippingNote,
  gdHandle,
  gpNoteHandle,
  gdFixedPanelSecuring,
  gpNoteFixedPanelSecuring,
  nulle,
  gpMeasurements,
  gpA,
  gpAOutage,
  gpB,
  gpBOutage,
  gpC,
  gpCOutage,
  gpD,
  gpDOutage,
  gpE,
  gpEOutage,
  gpF,
  gpFOutage,
  gpG,
  gpGOutage,
  gpH,
  gpHOutage,
  gpI,
  gpIOutage,
  gpJ,
  gpJOutage,
  gpK,
  gpKOutage,
  gpL,
  gpLOutage,
  gpM,
  gpMOutage,
  gpN,
  gpNOutage,
  gpO,
  gpOOutage,
  gpP,
  gpPOutage,
  gpAngle1,
  gpAngle1Joints,
  gpAngle2,
  gpAngle2Joints,
  gpAngle3,
  gpAngle3Joints,
  gpAngle4,
  gpAngle4Joints,
  gpAngle5,
  gpAngle5Joints,
  doorWidthAluminium,
  doorHeightAluminium,
  transomType,
  transomHeightAluminium,
  panelsWide1,
  panelsWide2,
  panelsWide3,
  panelsWide4,
  gdA,
  gdG,
  gdGTwo,
  gdH,
  gdHTwo,
  gdJ,
  gpNoteClosers,
  gdK,
  gpNoteLocks,
  gdL,
  gpNoteHandles,
  gdN,
  gdQT,
  gdQB,
  gpNoteThreshold,
  gpNoteTopRail,
  gpNoteSidelitesTop,
  gpNoteSidelitesB,
  adE,
  adH,
  adJ,
  adK,
  adL,
  adO,
  adOP,
  adOPNoteHandles,
  adP,
  adQ,
  adU,
  adW,
  ndhmdDoorType,
  ndhmdDoorTypeNote,
  ndhmdDoorAction,
  ndhmdDoorActionNote,
  ndhmdDoorPanel,
  ndhmdDoorPanelNote,
  ndhmdHingeSize,
  ndhmdHingeSizeNote,
  ndhmdDoorFrameFinish,
  ndhmdDoorFrameFinishNote,
  ndhmdLouver,
  ndhmdLouverNote,
  ndhmdDoorHardwareFinish,
  ndhmdDoorHardwareFinishNote,
  ndhmdDoorClosers,
  ndhmdDoorCloserNote,
  ndhmdRoomType,
  ndhmdRoomTypeNote,
  ndhmdLocksetFunction,
  ndhmdLocksetFunctionNote,
  ndhmdDeadBolt,
  ndhmdDeadBoltNote,
  ndhmdDoorSweepWeatherStripping,
  ndhmdDoorSweepWeatherStrippingNote,
  ndhmdFrameProfile,
  ndhmdFrameProfileNote,
  ndhmdFrameThickness,
  ndhmdFrameThicknessNote,
  ndhmdFrameFabrication,
  ndhmdFrameFabricationNote,
  ndhmdFrameInstallType,
  ndhmdFrameInstallTypeNote,
  /*  ndhmdJ, */
  /* ndhmdJNote, */
  ndhmdK,
  ndhmdKNote,
  /*  ndhmdL,
  ndhmdLNote,
  ndhmdM,
  ndhmdMNote, */
  ndhmdN,
  ndhmdNNote,
  ndhmdO,
  ndhmdONote,
  /*   ndhmdP, */
  /* ndhmdPNote, */
  ndhmdR,
  ndhmdRNote,
  /*  ndhmdS,
  ndhmdSNote,
  ndhmdT,
  ndhmdTNote, */
  ndhmdV,
  ndhmdVNote,
  /* ndhmdQ, */
  ndhmdU,
  /*  ndhmdW, */
  ndhmdX,
  ndhmdY,
  pdFrameT,
  pdFrameTNote,
  pdThermallyB,
  pdThermallyBNote,
  pdFram,
  pdFramNote,
  pdFrameR,
  pdFrameRNote,
  pdAssem,
  pdAssemNote,
  pdGlassThick,
  pdGlassThickNote,
  pdGlassC,
  pdGlassCNote,
  pdGrid,
  pdGridNote,
  pdHardwareC,
  adENote,
  pdHardwareCNote,
  ndHandleA,
  ndHandleB,
  ndHandleC,
  ndHandleD,
  ndHandleE,
  ndHandleF,
  ndHandleG,
  ndHandleH,
  ndHandleI,
  gdGT,
  gdGB,
  ndhmdWS,
  filmsLocation,
  filmsQuantity,
  filmsSizeWidth,
  filmsSizeHeigth,
  filmsType,
  filmsTypeNotes,
  filmsBrand,
  filmsBrandNotes,
  filmsWetsealFilmToFrame,
  filmsWetsealFilmToFrameNotes,
  filmsFrameType,
  filmsFrameTypeNotes,
  filmsGlassType,
  filmsGlassTypeNotes,
  filmsGlassThickness,
  filmsGlassThicknessNotes,
  filmsTempered,
  filmsTemperedNotes,
  filmsRemovalRequired,
  filmsRemovalRequiredNotes,
  filmsLiftEquipmentNeeded,
  filmsLiftEquipmentNeededNotes,
  filmsInstallLocation,
  filmsInstallLocationNotes,
  filmsExtraOne,
  filmsExtraTwo,
  filmsExtraThree,
  filmsExtraFour,
  filmsExtraFive,
  filmsExtraFiveNotes,
  filmsExtraFourNotes,
  filmsExtraThreeNotes,
  filmsExtraTwoNotes,
  filmsExtraOneNotes,
  pdExtraOne,
  pdExtraOneNote,
  pdExtraTwo,
  pdExtraTwoNote,
  pdExtraThree,
  pdExtraFour,
  pdExtraFive,
  pdExtraFiveNote,
  pdExtraFourNote,
  pdExtraThreeNote,
  pdLocation,
  pdLocationNotes,
  pdScreens,
  pdScreensNotes,
  pdDoorHardwareNotes,
  pdDoorHardware,
  pdExteriorTrim,
  pdExteriorTrimNotes,
  pdInteriorTrimNotes,
  pdInteriorTrim,
  pdGlassInstallation,
  pdGlassInstallationNotes,
  pdGlassCoating,
  pdGlassCoatingNotes,
  pdFrameFinishInterior,
  pdFrameFinishInteriorNotes,
  pdFrameFinishExterior,
  pdFrameFinishExteriorNotes,
  pdType,
  pdTypeNotes,
  pdHardwareFinish,
  pdHardwareFinishNotes,
  pdGlassType,
  pdGlassTypesNotes,
  pdGridsVertical,
  pdGridsHorizontal,
  pdGridsColor,
  pdThickness,
  filmsAppliedSide,
  filmsAppliedSideNotes,
  filmsSpliceAcceptable,
  filmsSpliceAcceptableNotes,
  pdGridsColorNotes,
  pdThicknessNotes,
  pdWGlassThickness,
  pdWGlassThicknessNote,
  pdWGlassColorWindows,
  pdWGlassColorWindowsNote,
  gpGlassCoating,
  gpGlassCoatingNotes,
  emptyLabelCol,
  ndHandle1,
  ndHandle2,
  ndHandle3,
  ndHandle4,
  ndHandle5,
  ndHandle6,
  ndHandle7,
  ndHandle8,
  ndHandle9,
} from './inputs';

const TYPE_A_Handle_AJ_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+AJ+1.jpg';
const TYPE_A_Handle_AJ_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+AJ+2.jpg';
const TYPE_A_Handle_AK_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+AK+1.jpg';
const TYPE_A_Handle_AK_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+AK+2.jpg';
const TYPE_A_Handle_C_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+C+1.jpg';
const TYPE_A_Handle_C_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+C+2.jpg';
const TYPE_A_Handle_D_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+D+1.jpg';
const TYPE_A_Handle_D_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+D+2.jpg';
const TYPE_A_Handle_F_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+F+1.jpg';
const TYPE_A_Handle_F_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+A+STILE/PanicDeadbolt++TYPE+A+Handle+F+2.jpg';
const TYPE_BP_Handle_AJ_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+AJ++2.jpg';
const TYPE_BP_Handle_AJ_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+AJ+1.jpg';
const TYPE_BP_Handle_AK_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+AK++1.jpg';
const TYPE_BP_Handle_AK_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+AK+2.jpg';
const TYPE_BP_Handle_C_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+C+1.jpg';
const TYPE_BP_Handle_C_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+C+2.jpg';
const TYPE_BP_Handle_D_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+D+1.jpg';
const TYPE_BP_Handle_D_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+D+2.jpg';
const TYPE_BP_Handle_F_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+F++2.jpg';
const TYPE_BP_Handle_F_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+BP+STILE/PanicDeadbolt+TYPE+BP+HANDLE+F+1.jpg';
const TYPE_P_Handle_AJ_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+AJ+1.jpg';
const TYPE_P_Handle_AJ_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+AJ+2.jpg';
const TYPE_P_Handle_AK_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+AK+1.jpg';
const TYPE_P_Handle_AK_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+AK+2.jpg';
const TYPE_P_Handle_C_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+C+1.jpg';
const TYPE_P_Handle_C_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+C+2.jpg';
const TYPE_P_Handle_D_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+D+1.jpg';
const TYPE_P_Handle_D_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+D+2.jpg';
const TYPE_P_Handle_F_1 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+F+1.jpg';
const TYPE_P_Handle_F_2 =
  'https://sag-assets-media.s3.amazonaws.com/handles/PANIC2+P+STILE/PanicDeadbolt++TYPE+P++HANDLE+F+2.jpg';

/* -----------------------------NEW DOORS - FIELDS */

export const NewdoorCategory = [
  { value: 'Gdoor', label: 'Glass' },
  { value: 'Aludoor', label: 'Aluminum' },
  { value: 'hmdoor', label: 'Various Door Types: Non-Storefronts' },
  { value: 'patio', label: 'Windows & Patio Doors' },
  { value: 'shower', label: 'Showers' },
  { value: 'films', label: 'Film' },
];

/* NEW GLASS DOOR TYPES */

export const newDoorType = [
  { value: 'Gdoor' },
  { value: 'Aludoor' },
  { value: 'hmdoor' },
  { value: 'patio' },
  { value: 'shower' },
  { value: 'films' },
];

export const newDoorFields = {
  Gdoor: {
    fields: [
      gpMeasurements,
      nulle,
      gpA,
      gpAOutage,
      gpB,
      gpBOutage,
      gpC,
      gpCOutage,
      gpD,
      gpDOutage,
      gpE,
      gpEOutage,
      gpF,
      gpFOutage,
      gpG,
      gpGOutage,
      gpH,
      gpHOutage,
      gpI,
      gpIOutage,
      gpJ,
      gpJOutage,
      gpK,
      gpKOutage,
      gpL,
      gpLOutage,
      doorWidthAluminium,
      nulle,
      doorHeightAluminium,
      nulle,
      transomHeightAluminium,
      nulle,
      transomType,
      nulle,
      panelsWide1,
      nulle,
      panelsWide2,
      nulle,
      panelsWide3,
      nulle,
      panelsWide4,
      nulle,
      pdLocation,
      pdLocationNotes,
      gdA,
      gpNoteAdH,
      gdDA,
      gpNoteDoorAction,
      gdGlassType,
      gpNoteGlassType,
      gdHardwareFinish,
      gpNoteHardwareFinish,
      gdheader,
      gpNoteheader,
      gdJ,
      gpNoteClosers,
      gdL,
      gpNoteHandles,
      adQ,
      gpPanicDeviceNote,
      gdK,
      gpNoteLocks,
      gdN,
      gpNoteThreshold,
      gdGTwo,
      gpNoteTopRail,
      gdHTwo,
      gpNoteBottomRail,
      gdQT,
      gpNoteSidelitesTop,
      gdQB,
      gpNoteSidelitesB,
      pdExtraOne,
      pdExtraOneNote,
      pdExtraTwo,
      pdExtraTwoNote,
      pdExtraThree,
      pdExtraThreeNote,
      pdExtraFour,
      pdExtraFourNote,
      pdExtraFive,
      pdExtraFiveNote,
    ],

    optionalFields: type => {
      if (
        [
          'Type A (TOP & BOTTOM PATCH) - SINGLE',
          'Type A (TOP & BOTTOM PATCH) - PAIR',
          'Type F (TOP/BOTTOM PATCH& Bottom PATCH LOCK) - SINGLE',
          'Type F  (TOP/BOTTOM PATCH& Bottom PATCH LOCK) - PAIR',
        ].includes(type)
      ) {
        return [gdGT, gdGB];
      }
      return [gdG, gdGTwo, gdH, gdHTwo, gdGT, gdGB];
    },
  },
  Aludoor: {
    fields: [
      gpMeasurements,
      nulle,
      gpA,
      gpAOutage,
      gpB,
      gpBOutage,
      gpC,
      gpCOutage,
      gpD,
      gpDOutage,
      gpE,
      gpEOutage,
      gpF,
      gpFOutage,
      gpG,
      gpGOutage,
      gpH,
      gpHOutage,
      gpI,
      gpIOutage,
      gpJ,
      gpJOutage,
      gpK,
      gpKOutage,
      gpL,
      gpLOutage,
      doorWidthAluminium,
      nulle,
      doorHeightAluminium,
      nulle,
      transomHeightAluminium,
      nulle,
      transomType,
      nulle,
      panelsWide1,
      nulle,
      panelsWide2,
      nulle,
      panelsWide3,
      nulle,
      panelsWide4,
      nulle,
      pdLocation,
      pdLocationNotes,
      adH,
      gpNoteAdH,
      adK,
      gpNoteHardwareFinish,
      adJ,
      gpNoteFrameSystem,
      adE,
      adENote,
      adL,
      gpNoteHangingHardware,
      adO,
      gpNoteDoorCloser,
      adOP,
      adOPNoteHandles,
      adP,
      gpLookNote,
      adQ,
      gpPanicDeviceNote,
      adU,
      gpMidPanelNote,
      adW,
      gpNoteBottomRail,
      ndhmdWS,
      gpWeatherStrippingNote,
      gdN,
      gpNoteThreshold,
      pdGlassThick,
      pdGlassThickNote,
      pdGlassC,
      pdGlassCNote,
      gpGlassCoating,
      gpGlassCoatingNotes,
      pdExtraOne,
      pdExtraOneNote,
      pdExtraTwo,
      pdExtraTwoNote,
      pdExtraThree,
      pdExtraThreeNote,
      pdExtraFour,
      pdExtraFourNote,
      pdExtraFive,
      pdExtraFiveNote,
    ],
  },
  hmdoor: {
    fields: [
      gpMeasurements,
      nulle,
      gpA,
      gpAOutage,
      gpB,
      gpBOutage,
      gpC,
      gpCOutage,
      gpD,
      gpDOutage,
      gpE,
      gpEOutage,
      gpF,
      gpFOutage,
      gpG,
      gpGOutage,
      gpH,
      gpHOutage,
      gpI,
      gpIOutage,
      gpJ,
      gpJOutage,
      gpK,
      gpKOutage,
      gpL,
      gpLOutage,
      gpM,
      gpMOutage,
      gpN,
      gpNOutage,
      gpO,
      gpOOutage,
      gpP,
      gpPOutage,
      doorWidthAluminium,
      nulle,
      doorHeightAluminium,
      nulle,
      transomHeightAluminium,
      nulle,
      transomType,
      nulle,
      panelsWide1,
      nulle,
      panelsWide2,
      nulle,
      panelsWide3,
      nulle,
      panelsWide4,
      nulle,
      pdLocation,
      pdLocationNotes,
      ndhmdDoorType,
      ndhmdDoorTypeNote,
      ndhmdDoorAction,
      ndhmdDoorActionNote,
      ndhmdK,
      ndhmdKNote,
      ndhmdDoorPanel,
      ndhmdDoorPanelNote,
      ndhmdHingeSize,
      ndhmdHingeSizeNote,
      ndhmdN,
      ndhmdNNote,
      ndhmdO,
      ndhmdONote,
      ndhmdLouver,
      ndhmdLouverNote,
      ndhmdDoorFrameFinish,
      ndhmdDoorFrameFinishNote,
      ndhmdDoorHardwareFinish,
      ndhmdDoorHardwareFinishNote,
      ndhmdDoorClosers,
      ndhmdDoorCloserNote,
      ndhmdRoomType,
      ndhmdRoomTypeNote,
      ndhmdLocksetFunction,
      ndhmdLocksetFunctionNote,
      ndhmdDeadBolt,
      ndhmdDeadBoltNote,
      ndhmdR,
      ndhmdRNote,
      ndhmdV,
      ndhmdVNote,
      ndhmdDoorSweepWeatherStripping,
      ndhmdDoorSweepWeatherStrippingNote,
      ndhmdFrameProfile,
      ndhmdFrameProfileNote,
      ndhmdFrameThickness,
      ndhmdFrameThicknessNote,
      ndhmdFrameFabrication,
      ndhmdFrameFabricationNote,
      ndhmdFrameInstallType,
      ndhmdFrameInstallTypeNote,
      ndhmdU,
      nulle,
      ndhmdY,
      nulle,
      ndhmdX,
      emptyLabelCol,
      pdExtraOne,
      pdExtraOneNote,
      pdExtraTwo,
      pdExtraTwoNote,
      pdExtraThree,
      pdExtraThreeNote,
      pdExtraFour,
      pdExtraFourNote,
      pdExtraFive,
      pdExtraFiveNote,
    ],
  },
  patio: {
    fields: [
      gpMeasurements,
      nulle,
      gpA,
      gpAOutage,
      gpB,
      gpBOutage,
      gpC,
      gpCOutage,
      gpD,
      gpDOutage,
      gpE,
      gpEOutage,
      gpF,
      gpFOutage,
      gpG,
      gpGOutage,
      gpH,
      gpHOutage,
      gpI,
      gpIOutage,
      gpJ,
      gpJOutage,
      gpK,
      gpKOutage,
      gpL,
      gpLOutage,
      gpM,
      gpMOutage,
      gpN,
      gpNOutage,
      gpO,
      gpOOutage,
      gpP,
      gpPOutage,
      doorWidthAluminium,
      nulle,
      doorHeightAluminium,
      nulle,
      transomHeightAluminium,
      nulle,
      transomType,
      nulle,
      panelsWide1,
      nulle,
      panelsWide2,
      nulle,
      panelsWide3,
      nulle,
      panelsWide4,
      nulle,

      pdLocation,
      pdLocationNotes,
      pdType,
      pdTypeNotes,
      pdFrameT,
      pdFrameTNote,
      pdHardwareC,
      pdHardwareCNote,
      pdThermallyB,
      pdThermallyBNote,
      pdFram,
      pdFramNote,
      pdFrameR,
      pdFrameRNote,
      pdAssem,
      pdAssemNote,
      pdFrameFinishInterior,
      pdFrameFinishInteriorNotes,
      pdFrameFinishExterior,
      pdFrameFinishExteriorNotes,
      pdHardwareFinish,
      pdHardwareFinishNotes,
      pdGlassType,
      pdGlassTypesNotes,
      pdWGlassThickness,
      pdWGlassThicknessNote,
      pdWGlassColorWindows,
      pdWGlassColorWindowsNote,
      pdGlassCoating,
      pdGlassCoatingNotes,

      pdScreens,
      pdScreensNotes,
      pdGlassInstallation,
      pdGlassInstallationNotes,
      pdExteriorTrim,
      pdExteriorTrimNotes,
      pdInteriorTrim,
      pdInteriorTrimNotes,
      pdDoorHardware,
      pdDoorHardwareNotes,
      pdGrid,
      pdGridNote,
      pdGridsColor,
      pdGridsColorNotes,
      pdThickness,
      pdThicknessNotes,
      pdGridsHorizontal,
      nulle,
      pdGridsVertical,
      nulle,
      pdExtraOne,
      pdExtraOneNote,
      pdExtraTwo,
      pdExtraTwoNote,
      pdExtraThree,
      pdExtraThreeNote,
      pdExtraFour,
      pdExtraFourNote,
      pdExtraFive,
      pdExtraFiveNote,
    ],
  },
  shower: {
    fields: [
      gpMeasurements,
      nulle,
      gpA,
      gpAOutage,
      gpB,
      gpBOutage,
      gpC,
      gpCOutage,
      gpD,
      gpDOutage,
      gpE,
      gpEOutage,
      gpF,
      gpFOutage,
      gpG,
      gpGOutage,
      gpH,
      gpHOutage,
      gpI,
      gpIOutage,
      gpJ,
      gpJOutage,
      gpK,
      gpKOutage,
      gpL,
      gpLOutage,
      gpM,
      gpMOutage,
      gpN,
      gpNOutage,
      gpO,
      gpOOutage,
      gpP,
      gpPOutage,
      doorWidthAluminium,
      nulle,
      doorHeightAluminium,
      nulle,
      transomHeightAluminium,
      nulle,
      transomType,
      nulle,
      panelsWide1,
      nulle,
      panelsWide2,
      nulle,
      panelsWide3,
      nulle,
      panelsWide4,
      nulle,
      gpAngle1,
      gpAngle1Joints,
      gpAngle2,
      gpAngle2Joints,
      gpAngle3,
      gpAngle3Joints,
      gpAngle4,
      gpAngle4Joints,
      gpAngle5,
      gpAngle5Joints,
      pdLocation,
      pdLocationNotes,
      gdFS,
      gpNoteFrameStyle,
      gdDA,
      gpNoteDoorAction,
      gdGlassType,
      gpNoteGlassType,
      gdGlassCoating,
      gpNoteGlassCoating,
      gdheaderShower,
      gpNoteheader,
      gdShowerHardwareFinish,
      gpNoteHardwareFinish,
      gdHandle,
      gpNoteHandle,
      gdFixedPanelSecuring,
      gpNoteFixedPanelSecuring,
      pdExtraOne,
      pdExtraOneNote,
      pdExtraTwo,
      pdExtraTwoNote,
      pdExtraThree,
      pdExtraThreeNote,
      pdExtraFour,
      pdExtraFourNote,
      pdExtraFive,
      pdExtraFiveNote,
    ],
    optionalFields: type => {
      if (type === 'toppatchG' || type === 'bottompatchG') {
        return [gdGT, gdGB];
      }
      return [gdG, gdGTwo, gdH, gdHTwo, gdGT, gdGB];
    },
  },
  films: {
    fields: [
      filmsLocation,
      filmsQuantity,
      filmsSizeWidth,
      filmsSizeHeigth,
      filmsType,
      filmsTypeNotes,
      filmsBrand,
      filmsBrandNotes,
      filmsAppliedSide,
      filmsAppliedSideNotes,
      filmsGlassType,
      filmsGlassTypeNotes,
      filmsGlassThickness,
      filmsGlassThicknessNotes,
      filmsTempered,
      filmsTemperedNotes,
      filmsRemovalRequired,
      filmsRemovalRequiredNotes,
      filmsFrameType,
      filmsFrameTypeNotes,
      filmsWetsealFilmToFrame,
      filmsWetsealFilmToFrameNotes,
      filmsSpliceAcceptable,
      filmsSpliceAcceptableNotes,
      filmsInstallLocation,
      filmsInstallLocationNotes,
      filmsLiftEquipmentNeeded,
      filmsLiftEquipmentNeededNotes,
      filmsExtraOne,
      filmsExtraOneNotes,
      filmsExtraTwo,
      filmsExtraTwoNotes,
      filmsExtraThree,
      filmsExtraThreeNotes,
      filmsExtraFour,
      filmsExtraFourNotes,
      filmsExtraFive,
      filmsExtraFiveNotes,
    ],
  },
};

/* HANDLE */
export const NewDoorHandle = [
  { value: 'pull-handle-up-push-bar-3-holes', label: 'A Pull Handle, UP  & Push Bar (3 Holes) ' },
  {
    value: 'pull-handle-up-push-bar-3-holes-opp',
    label: 'B Pull Handle, UP &  Push Bar (3 Holes)',
  },
  {
    value: 'pull-handle-down-push-bar-3-holes',
    label: 'A Pull Handle, DOWN  & Push Bar (3 Holes)',
  },
  {
    value: 'pull-handle-down-push-bar-3-holes-opp',
    label: 'B Pull Handle, DOWN &  Push Bar (3 Holes)',
  },
  { value: 'pull-handle-2-holes', label: 'A Pull Handle (2 Holes)' },
  { value: 'pull-handle-2-holes-opp', label: 'B Pull Handle (2 Holes)' },
  { value: 'pull-bar-2-holes', label: 'A Push Bar (2 Holes)' },
  { value: 'pull-bar-2-holes-opp', label: 'B Push Bar (2 Holes)' },
  { value: 'ladder-pull-3-holes', label: 'A Ladder Pull (3 Holes)' },
  { value: 'ladder-pull-3-holes-opp', label: 'B Ladder Pull (3 Holes)' },
  { value: 'ladder-pull-2-holes', label: 'A Ladder Pull (2 Holes)' },
  { value: 'ladder-pull-2-holes-opp', label: 'B Ladder Pull (2 Holes)' },
  { value: 'single-pull-1-hole', label: 'A Single Pull (1 Hole)' },
  { value: 'single-pull-1-hole-opp', label: 'B Single Pull (1 Hole)' },
  { value: 'panicDeadbolt-TYPE-A-Handle-AJ-1', label: 'PanicDeadbolt TYPE A Handle AJ 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-AJ-2', label: 'PanicDeadbolt TYPE A Handle AJ 2' },
  { value: 'panicDeadbolt-TYPE-A-Handle-AK-1', label: 'PanicDeadbolt TYPE A Handle AK 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-AK-2', label: 'PanicDeadbolt TYPE A Handle AK 2' },
  { value: 'panicDeadbolt-TYPE-A-Handle-C-1', label: 'PanicDeadbolt TYPE A Handle C 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-C-2', label: 'PanicDeadbolt TYPE A Handle C 2' },
  { value: 'panicDeadbolt-TYPE-A-Handle-D-1', label: 'PanicDeadbolt TYPE A Handle D 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-D-2', label: 'PanicDeadbolt TYPE A Handle D 2' },
  { value: 'panicDeadbolt-TYPE-A-Handle-F-1', label: 'PanicDeadbolt TYPE A Handle F 1' },
  { value: 'panicDeadbolt-TYPE-A-Handle-F-2', label: 'PanicDeadbolt TYPE A Handle F 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-AJ-1', label: 'PanicDeadbolt TYPE BP Handle AJ 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-AJ-2', label: 'PanicDeadbolt TYPE BP Handle AJ 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-AK-1', label: 'PanicDeadbolt TYPE BP Handle AK 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-AK-2', label: 'PanicDeadbolt TYPE BP Handle AK 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-C-1', label: 'PanicDeadbolt TYPE BP Handle C 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-C-2', label: 'PanicDeadbolt TYPE BP Handle C 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-D-1', label: 'PanicDeadbolt TYPE BP Handle D 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-D-2', label: 'PanicDeadbolt TYPE BP Handle D 2' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-F-1', label: 'PanicDeadbolt TYPE BP Handle F 1' },
  { value: 'panicDeadbolt-TYPE-BP-Handle-F-2', label: 'PanicDeadbolt TYPE BP Handle F 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-AJ-1', label: 'PanicDeadbolt TYPE P Handle AJ 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-AJ-2', label: 'PanicDeadbolt TYPE P Handle AJ 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-AK-1', label: 'PanicDeadbolt TYPE P Handle AK 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-AK-2', label: 'PanicDeadbolt TYPE P Handle AK 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-C-1', label: 'PanicDeadbolt TYPE P Handle C 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-C-2', label: 'PanicDeadbolt TYPE P Handle C 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-D-1', label: 'PanicDeadbolt TYPE P Handle D 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-D-2', label: 'PanicDeadbolt TYPE P Handle D 2' },
  { value: 'panicDeadbolt-TYPE-P-Handle-F-1', label: 'PanicDeadbolt TYPE P Handle F 1' },
  { value: 'panicDeadbolt-TYPE-P-Handle-F-2', label: 'PanicDeadbolt TYPE P Handle F 2' },
];

export const NewDoorHandleFields = {
  'pull-handle-up-push-bar-3-holes': {
    picture: nd_Handle_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'pull-handle-up-push-bar-3-holes-opp': {
    picture: nd_Handle_1_opp,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'pull-handle-down-push-bar-3-holes': {
    picture: nd_Handle_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'pull-handle-down-push-bar-3-holes-opp': {
    picture: nd_Handle_2_opp,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'pull-handle-2-holes': {
    picture: nd_Handle_3,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'pull-handle-2-holes-opp': {
    picture: nd_Handle_3_opp,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'pull-bar-2-holes': {
    picture: nd_Handle_4,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'pull-bar-2-holes-opp': {
    picture: nd_Handle_4_opp,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'ladder-pull-3-holes': {
    picture: nd_Handle_5,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'ladder-pull-3-holes-opp': {
    picture: nd_Handle_5_opp,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'ladder-pull-2-holes': {
    picture: nd_Handle_6,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'ladder-pull-2-holes-opp': {
    picture: nd_Handle_6_opp,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'single-pull-1-hole': {
    picture: nd_Handle_7,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'single-pull-1-hole-opp': {
    picture: nd_Handle_7_opp,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-AJ-1': {
    picture: TYPE_A_Handle_AJ_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-AJ-2': {
    picture: TYPE_A_Handle_AJ_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-AK-1': {
    picture: TYPE_A_Handle_AK_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-AK-2': {
    picture: TYPE_A_Handle_AK_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-C-1': {
    picture: TYPE_A_Handle_C_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-C-2': {
    picture: TYPE_A_Handle_C_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-D-1': {
    picture: TYPE_A_Handle_D_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-D-2': {
    picture: TYPE_A_Handle_D_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-F-1': {
    picture: TYPE_A_Handle_F_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-A-Handle-F-2': {
    picture: TYPE_A_Handle_F_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-AJ-1': {
    picture: TYPE_BP_Handle_AJ_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-AJ-2': {
    picture: TYPE_BP_Handle_AJ_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-AK-1': {
    picture: TYPE_BP_Handle_AK_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-AK-2': {
    picture: TYPE_BP_Handle_AK_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-C-1': {
    picture: TYPE_BP_Handle_C_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-C-2': {
    picture: TYPE_BP_Handle_C_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-D-1': {
    picture: TYPE_BP_Handle_D_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-D-2': {
    picture: TYPE_BP_Handle_D_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-F-1': {
    picture: TYPE_BP_Handle_F_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-BP-Handle-F-2': {
    picture: TYPE_BP_Handle_F_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-AJ-1': {
    picture: TYPE_P_Handle_AJ_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-AJ-2': {
    picture: TYPE_P_Handle_AJ_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-AK-1': {
    picture: TYPE_P_Handle_AK_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-AK-2': {
    picture: TYPE_P_Handle_AK_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-C-1': {
    picture: TYPE_P_Handle_C_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-C-2': {
    picture: TYPE_P_Handle_C_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-D-1': {
    picture: TYPE_P_Handle_D_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-D-2': {
    picture: TYPE_P_Handle_D_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-F-1': {
    picture: TYPE_P_Handle_F_1,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
  'panicDeadbolt-TYPE-P-Handle-F-2': {
    picture: TYPE_P_Handle_F_2,
    fields: [
      ndHandleA,
      ndHandleB,
      ndHandleC,
      ndHandleD,
      ndHandleE,
      ndHandleF,
      ndHandleG,
      ndHandleH,
      ndHandleI,
      ndHandle1,
      ndHandle2,
      ndHandle3,
      ndHandle4,
      ndHandle5,
      ndHandle6,
      ndHandle7,
      ndHandle8,
      ndHandle9,
    ],
  },
};

export const NewdoorCategoryTypes = {
  Gdoor: { types: newDoorType, fields: newDoorFields },
  Aludoor: { types: newDoorType, fields: newDoorFields },
  hmdoor: { types: newDoorType, fields: newDoorFields },
  patio: { types: newDoorType, fields: newDoorFields },
  shower: { types: newDoorType, fields: newDoorFields },
  films: { types: newDoorType, fields: newDoorFields },
};
