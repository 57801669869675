import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CustomButton } from '../Elements/CustomButton';
import PricingImage from './pricing.png';

const Pricing = () => (
  <Row
    className="d-flex justify-content-center"
    id="pricing"
    style={{
      padding: '100px 0',
    }}
  >
    <Col xs={12} md={12} className="d-flex justify-content-center my-4">
      {/* smaller devices */}
      <div className="d-block d-md-none">
        <h4 className="landing-h5 secondary-color">Pricing</h4>
      </div>
      {/* larger devices */}
      <div className="d-none d-md-block">
        <h4 className="landing-h4 secondary-color">Pricing</h4>
      </div>
    </Col>
    <Col
      xs={10}
      md={6}
      className="py-5"
      style={{
        backgroundImage: `url(${PricingImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '30px',
      }}
    >
      <h4 className="secondary-color landing-h8 text-center">Starting plan</h4>
      <p className="landing-p text-color text-center">Account setup payment $100 (one-time fee)</p>
      <h4 className="landing-h5 dark-gray-color mt-md-5  text-center">$149</h4>
      <p className="landing-p dark-gray-color  text-center">For 1 to 3 users</p>
      <p className="landing-caption dark-gray-color my-md-4  text-center">
        You can add up additional users for $29 each
      </p>
      <Col xs={12} md={12} className="d-flex justify-content-center">
        <CustomButton
          size="lg"
          text="Start Now"
          variant="primary"
          path="login"
          font="landing-p"
          className="py-3 px-5"
        />
      </Col>
    </Col>
  </Row>
);
export default Pricing;
