/**
 * @module Sagas/Event
 * @desc Event
 */

import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ActionTypes } from 'constants/index';
import { showAlert } from 'actions';
import { replace } from 'modules/history';
import moment from 'moment';

export function* getEvents(api, action) {
  const { payload } = action;
  const { status, data } = yield call(api.getEvents, payload);

  if (status === 200) {
    const { rows, pages, currentPage } = data;

    yield put({
      type: ActionTypes.GET_EVENTS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_EVENTS_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getEvent(api, action) {
  const {
    payload: { id },
  } = action;
  const { status, data } = yield call(api.getEvent, id);
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_EVENT_SUCCESS,
      payload: { event: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_EVENT_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getEventsByJob(api, action) {
  const {
    payload: { job_id, query },
  } = action;
  const { status, data } = yield call(api.getEventsByJob, { job_id, query });
  if (status === 200) {
    const { rows, pages, currentPage, quote_number } = data;

    yield put({
      type: ActionTypes.GET_EVENTS_BY_JOB_SUCCESS,
      payload: { rows, pages, currentPage, quote_number },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_EVENTS_BY_JOB_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* createEvent(api, action) {
  const event = action.payload.data;
  event.start_time = moment(event.start_time);
  const response = yield call(api.createEvent, event);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.CREATE_EVENT_SUCCESS,
    });
    yield put(showAlert('Event Created', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.CREATE_EVENT_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* editEvent(api, action) {
  const {
    payload: { id, data: event },
  } = action;
  event.start_time = moment(event.start_time);
  const { status, data } = yield call(api.editEvent, id, event);

  if (status === 200) {
    yield put({
      type: ActionTypes.EDIT_EVENT_SUCCESS,
      payload: { id, event },
    });
    yield put(showAlert('Event Updated', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.EDIT_EVENT_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* deleteEvent(api, action) {
  const {
    payload: { id },
  } = action;
  const { status, data } = yield call(api.deleteEvent, id);

  if (status === 200) {
    yield put({
      type: ActionTypes.DELETE_EVENT_SUCCESS,
      payload: { id },
    });
    yield put({
      type: ActionTypes.GET_EVENTS,
    });
    yield put(showAlert('Event Deleted', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.DELETE_EVENT_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function openEditEvent({ openEdit, editingEventId }) {
  return {
    type: ActionTypes.OPEN_EDIT_EVENT,
    payload: { openEdit, editingEventId },
  };
}

export function* setCurrentPage(action) {
  const { payload: currentPage } = action;

  yield put({
    type: ActionTypes.SET_CURRENT_PAGE_SUCCESS,
    payload: { currentPage },
  });
}

export function openDeleteEvent({ state, id }) {
  return {
    type: ActionTypes.OPEN_DELETE_EVENTS,
    payload: { state, id },
  };
}
