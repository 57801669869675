import React, { useState } from 'react';

import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { Row, Col, Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import {
  ContainerTitle,
  Title,
  InfoText,
  Label,
  AdditionalInfoText,
  ButtonSave,
  ButtonTurnOff,
  ButtonCancel,
} from './Components';
import ModalComponent from '../../../components/ModalComponent';

const validationSchemaManager = Yup.object().shape({
  app_manager: Yup.string().email('Invalid email address'),
});

const AppManagerForm = ({ updateCompany, company }) => {
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [switchAppManagerEmail, setSwitchAppManager] = useState(company.app_manager_enabled);

  const handleSwitchEdit = () => {
    setEdit(!edit);
  };

  const enableNotifications = () => {
    updateCompany({
      ...company,
      app_manager_enabled: true,
    });
    setSwitchAppManager(true);
  };

  const disabledNotifications = () => {
    updateCompany({
      ...company,
      app_manager_enabled: false,
    });

    setSwitchAppManager(false);
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          ...company,
          app_manager: company.app_manager,
        }}
        validationSchema={validationSchemaManager}
        onSubmit={values => {
          updateCompany({
            ...company,
            app_manager: values.app_manager,
          });
          setEdit(false);
        }}
      >
        {({ values, handleChange, handleBlur, errors, touched, resetForm }) => (
          <FormikForm>
            <Row>
              <Col xs={12} sm={12} md={12} xl={12} style={{ paddingBottom: 16 }}>
                <div
                  style={{
                    width: 612,
                    height: 0,
                    border: '1px #E8EBF0 solid',
                  }}
                ></div>
              </Col>
              <Col xs={12} sm={12} md={12} xl={12}>
                <ContainerTitle style={{ marginBottom: 16 }}>
                  <Title>APP Manager</Title>
                  {switchAppManagerEmail ? (
                    <ButtonCancel onClick={openModal}>Turn off</ButtonCancel>
                  ) : (
                    <ButtonSave onClick={enableNotifications}>Turn on</ButtonSave>
                  )}
                </ContainerTitle>
              </Col>
              <Col xs={12} sm={12} md={12} xl={12} style={{ marginBottom: 16 }}>
                <InfoText>
                  APP MANAGER is a role that you can assign to a person. This feature allows the
                  person with the role to receive all notifications instead of the default owner.
                </InfoText>
              </Col>
              <Col xs={12} sm={6} md={5} lg={3} xl={3}>
                <Form.Group controlId="app_manager">
                  <Label>App manager email</Label>
                  <Form.Control
                    disabled={!edit}
                    as={Field}
                    name="app_manager"
                    type="email"
                    placeholder="address@gmail.com"
                    value={values.app_manager}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.app_manager && touched.app_manager && errors.app_manager}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                {edit ? (
                  <>
                    <ButtonSave type="submit" style={{ marginRight: 16 }}>
                      Save
                    </ButtonSave>
                    <ButtonCancel
                      onClick={() => {
                        handleSwitchEdit();
                        resetForm({ ...company, app_manager: company.app_manager });
                      }}
                    >
                      Cancel
                    </ButtonCancel>
                  </>
                ) : (
                  <ButtonTurnOff
                    onClick={() => {
                      handleSwitchEdit();
                    }}
                  >
                    Edit
                  </ButtonTurnOff>
                )}
              </Col>
              <Col xs={12}>
                <AdditionalInfoText>
                  The app manager email can be updated any moment.
                </AdditionalInfoText>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>

      <ModalComponent
        size="sm"
        show={showModal}
        onClickCancel={disabledNotifications}
        onClickSubmit={closeModal}
        closeButton={false}
        variantCancel="danger"
        variantSubmit="secondary"
        textCancel="Turn off"
        textSubmit="Cancel"
        heightButtons={42}
        title={
          <div
            style={{
              color: '#007BFF',
              fontSize: 20,
              fontFamily: 'Lato',
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            Are you sure you want to turn off “App manager” role?
          </div>
        }
        body={
          <div
            style={{
              color: '#2F3033',
              fontSize: 12,
              fontFamily: 'Lato',
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            If you proceed, the specified email address will no longer receive any notifications.
          </div>
        }
      />
    </>
  );
};

export default AppManagerForm;

AppManagerForm.propTypes = {
  company: PropTypes.shape({
    app_manager: PropTypes.string.isRequired,
    app_manager_enabled: PropTypes.bool.isRequired,
    email_notification: PropTypes.string.isRequired,
  }).isRequired,
  updateCompany: PropTypes.func.isRequired,
};
