import styled from 'styled-components';

import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Title = styled.p`
  color: #425268;
  font-size: 24px;
  font-family: Lato;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  justify-content: center;
  display: flex;
`;

export const ButtonStyle = styled(Button)`
  width: 188px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const TableHeaderStyle = styled.h4`
  color: #425268;
`;

export const ModalBodyStyle = styled.div`
  color: #425268;
  font-size: 15px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
  padding-bottom: 5px;
`;

export const ModalWarningStyle = styled.div`
  height: 52px;
  color: #dc3545;
  font-size: 15px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
`;

export const QuoteNumberContainerStyle = styled.div`
  height: 24px;
  margin: 0px 0px 0px 10px;
  padding: 16px 8px;
  background: #e9f1fc;
  border-radius: 4px;
  align-items: center;
  gap: 4px;
  display: inline-flex;
`;

export const QuoteNumberTextStyle = styled.div`
  text-align: center;
  color: #007bff;
  font-size: 24px;
  font-family: Lato;
  font-weight: 700;
  line-height: 16px;
  word-wrap: break-word;
`;

export const LinkContainer = styled.a`
  width: 60px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 5px 0px 10px 0px;
`;

export const IconStyleArrow = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: end;
  justify-content: end;
  color: #425268;
`;

export const HoverColorStyle = styled.a`
  color: #425268;
  &:hover {
    color: #303f4e;
  }
`;

export const OuterDiv = styled.div`
  padding-right: 16px;
  align-items: center;
  gap: 16px;
  display: inline-flex;
`;

export const InnerDiv = styled.div`
  color: #173264;
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
  display: flex;
`;

export const ProviderName = styled.div`
  text-transform: capitalize;
  margin-right: 5px;
`;

export const ProviderNameSub = styled.div`
  color: #425268;
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
`;

export const DropdownItemStyle = styled.div`
  width: 141px;
  height: 32px;
  padding: 4px;
  border-bottom: 1px #e8ebf0 solid;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const IconStyle = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  color: #dc3545;
`;

export const TextStyle = styled.div`
  color: #dc3545;
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
`;
