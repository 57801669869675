import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from 'actions/index';
import ContactView from './ContactView';
import { sendContact } from 'actions';

class ContactContainer extends Component {
  render() {
    return <ContactView sendContact={this.props.sendContact} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendContact: data => dispatch(sendContact(data)),
  };
}
export default connect(null, mapDispatchToProps)(ContactContainer);
