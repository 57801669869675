import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { CustomButton } from '../Elements/CustomButton';
import './style.css';

const heroTitle = 'The Ultimate Web-Based Solution for Glass Industry Professionals';
const heroDescription =
  'Say goodbye to cumbersome paperwork and outdated tools, and say hello to streamlined efficiency and accuracy';

const Hero = () => (
  <Row className="hero">
    <Col xs={12} md={12} className="d-flex justify-content-center pt-5">
      <div className="d-block d-md-none">
        <h2 className="text-center break-word landing-h5 secondary-color mt-md-5">{heroTitle}</h2>
      </div>
      <div className="d-none d-md-block">
        <h2 className="text-center break-word landing-h2 secondary-color mt-md-5">{heroTitle}</h2>
      </div>
    </Col>
    <Col md={1} />
    <Col xs={12} md={10} className="d-flex justify-content-center align-items-start">
      <div className="d-block d-md-none">
        <h6 className="text-center landing-h8 dark-gray-color">{heroDescription}</h6>
      </div>
      <div className="d-none d-md-block">
        <h6 className="text-center landing-h6 dark-gray-color">{heroDescription}</h6>
      </div>
    </Col>
    <Col md={1} />
    <Col xs={12} md={12} className="d-flex justify-content-center py-4 my-4">
      <Link to="/register-company">
        <CustomButton
          size="lg"
          text="Sign Up"
          variant="primary"
          className="px-5 py-4 py-md-3"
          path="register-company"
          font="landing-h8"
        />
      </Link>
    </Col>
  </Row>
);

export default Hero;
