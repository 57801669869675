import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavDropdown, Row, Col, Button, Dropdown, Collapse } from 'react-bootstrap';
import defaultAvatar from 'assets/media/images/avatar.png';
import { push } from 'modules/history';
import Logo from 'components/Logo';
import MenuItemMobile from './MenuItemMobile';
import DropdownNav from './DropdownNav';

export default class Header extends React.PureComponent {
  static propTypes = {
    logOut: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
  };
  render() {
    const {
      profile: { first_name, last_name, picture },
      logOut,
    } = this.props;
    return (
      <div className="headerWrapper ">
        <Navbar expand="lg">
          <Row>
            <Col className="mr-5">
              <Logo small />
            </Col>
          </Row>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="menu-navbar-nav">
            <DropdownNav logOut={logOut} />
            <MenuItemMobile logOut={logOut} />
          </Navbar.Collapse>

          <Col className="d-none d-lg-flex justify-content-end align-items-center mt-2">
            <h4>{`${first_name} ${last_name}`}</h4>
            <img className="imageUser" src={picture || defaultAvatar} />
          </Col>
        </Navbar>
      </div>
    );
  }
}
