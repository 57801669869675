/* eslint-disable no-use-before-define */
import React from 'react';

import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faPhone,
  faNotes,
  faEnvelope,
  faCommentSms,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';

import { push } from 'modules/history';

import { Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { userRoleId } from '../../../constants';

const ICONS = {
  appointment: faCalendarDays,
  call: faPhone,
  notes: faNotes,
  text_message: faCommentSms,
  email: faEnvelope,
};

function EventItem({ key, item, handleOpenEdit, handleOpenDelete, eventList }) {
  const { currentUser } = useSelector(({ auth }) => ({
    currentUser: auth.profile,
  }));
  return (
    <tbody key={key} className="table-borderless">
      <tr>
        <td style={{ width: '12%' }}>
          <h4 className="mt-4 mb-4">
            <div style={iconContainerStyle}>
              <FontAwesomeIcon icon={ICONS[item.type]} size="1x" className="secondary-color" />
              <div style={iconTextStyle}>{item.type}</div>
            </div>
            <div style={participantStyle}>{item.participants}</div>
          </h4>
        </td>
        {!eventList && (
          <td style={{ width: '12%' }}>
            <Button
              style={quoteNumberContainerStyle}
              variant="light"
              className="mt-5 mb-5"
              onClick={() => push(`/dashboard/events/${item.job_id}`)}
            >
              <div style={quoteNumberTextStyle}>{item.Job.quote_number}</div>
            </Button>
          </td>
        )}

        <td style={{ width: '12%' }}>
          <h4 className="mt-5 mb-5">{format(new Date(item.start_time), 'MM/dd/yyyy HH:mm')}</h4>
        </td>
        <td style={{ width: '10%' }}>
          <h4 className="mt-5 mb-5" style={notesStyle}>
            {item.notes}
          </h4>
        </td>
        <td className="mt-5 mb-5" style={{ width: '15%' }}>
          <Button
            className="button-inline btn-lg mt-5 mb-5"
            variant="outline-primary"
            style={buttonStyle}
            onClick={() => handleOpenEdit({ id: item.id, job_id: item.job_id })}
          >
            {currentUser.role_id === userRoleId.OWNER ? (
              <i className="fas fa-pen iconSelect" />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </Button>
          {currentUser.role_id === userRoleId.OWNER && (
            <Button
              className="button-inline btn-lg  mt-5 mb-5"
              variant="danger"
              onClick={() => handleOpenDelete(item.id)}
            >
              <i className="fas fa-trash iconSelect" />
            </Button>
          )}
        </td>
      </tr>
    </tbody>
  );
}

export default EventItem;

EventItem.propTypes = {
  handleOpenDelete: PropTypes.func.isRequired,
  handleOpenEdit: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
};

export const iconContainerStyle = {
  height: 30,
  paddingLeft: 7.8,
  paddingRight: 7.8,
  paddingTop: 4.2,
  paddingBottom: 4.2,
  background: '#E9F1FC',
  borderRadius: 4,
  alignItems: 'center',
  gap: 4,
  display: 'inline-flex',
};

export const iconTextStyle = {
  textAlign: 'center',
  color: '#173264',
  fontSize: 16,
  fontFamily: 'Lato',
  fontWeight: '700',
  wordWrap: 'break-word',
  textTransform: 'capitalize',
};

export const participantStyle = {
  color: '#425268',
  fontSize: 15,
  fontFamily: 'Lato',
  fontWeight: '400',
  wordWrap: 'break-word',
  marginTop: 15,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '250px',
};

export const quoteNumberContainerStyle = {
  height: 24,
  paddingLeft: 7.8,
  paddingRight: 7.8,
  paddingTop: 4.2,
  paddingBottom: 4.2,
  background: '#007BFF',
  borderRadius: 4,
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 4,
  display: 'inline-flex',
};

export const quoteNumberTextStyle = {
  textAlign: 'center',
  color: 'white',
  fontSize: 16,
  fontFamily: 'Lato',
  fontWeight: '700',
  lineHeight: 16,
  wordWrap: 'break-word',
};

export const notesStyle = {
  color: '#425268',
  fontSize: 15,
  fontFamily: 'Lato',
  fontWeight: '400',
  wordWrap: 'break-word',
};

export const buttonStyle = {
  flex: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
