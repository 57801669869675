import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  getEvents,
  getEvent,
  //  cronofyGetCalendars
} from 'actions/index';

import EventsView from './EventsView';

export default function EventsContainer() {
  const dispatch = useDispatch();
  const editingEventId = useSelector(({ event }) => event.editingEventId);
  const currentPage = useSelector(({ event }) => event.currentPage);
  const [reloadEvents, setReloadEvents] = useState(false);

  useEffect(() => {
    dispatch(getEvents({ elementsPerPage: 10, page: currentPage }));
  }, [dispatch, currentPage, reloadEvents]);

  useEffect(() => {
    if (editingEventId) {
      dispatch(getEvent(editingEventId));
    }
  }, [dispatch, editingEventId]);

  // useEffect(() => {
  //   dispatch(cronofyGetCalendars());
  // }, [dispatch]);

  return <EventsView reloadEvents={reloadEvents} setReloadEvents={setReloadEvents} />;
}
