import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import PageWithBackgroundImage from 'components/PageWithBackgroundImage';
import Logo from 'components/Logo';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Modal from 'components/Modal';

export default class LoginCompanyView extends Component {
  state = {
    isOpen: false,
  };

  submitEmail = values => {
    const { email } = values;
    const { findCompany } = this.props;

    findCompany(email);
  };

  onClose = () => {
    const { cleanCompany } = this.props;
    cleanCompany();
    this.setState({ isOpen: false });
  };

  renderModal = () => {
    const { isOpen } = this.state;
    const initialValues = { email: '' };
    const { company } = this.props;

    return (
      <Modal title="Find your team" onClose={this.onClose} isVisible={isOpen}>
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={this.submitEmail}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required('Required')
                .email('Invalid Format'),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              setFieldValue,
            }) => (
              <Container className="mb-5 p-5">
                <Form onSubmit={handleSubmit}>
                  <Row className=" px-5">
                    <Col>
                      <Form.Group controlId="login">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <p className="text-error">
                          {errors.email && touched.email && errors.email}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col className="d-flex" className="col col-lg-2">
                      <Button
                        className="form-control"
                        variant="primary"
                        size="lg"
                        type="submit"
                        disabled={
                          !isValid || (touched.subdomain && subdomainValid && !subdomainLoading)
                        }
                      >
                        <h2>Find</h2>
                      </Button>
                    </Col>
                  </Row>
                  {company && company !== 'not found' && (
                    <>
                      <Row className="mt-5 px-5 ">
                        <Col className="d-flex justify-content-start align-items-center ">
                          <h2>Subdomain:</h2>
                          <h2 className="pl-2" style={{ color: Colors.gray }}>
                            {company.subdomain}
                          </h2>
                        </Col>
                      </Row>

                      <Row className="pt-5  px-5">
                        <Col className="d-flex">
                          <Button
                            variant="primary"
                            size="lg"
                            block
                            type="button"
                            style={{ height: '50px' }}
                            onClick={() => {
                              window.location.hostname =
                                company.subdomain + '.' + window.location.hostname;
                            }}
                          >
                            <h2
                              style={{
                                fontWeight: 'bold',
                                color: Colors.white,
                                textAlign: 'center',
                                marginBottom: 'auto',
                              }}
                            >
                              Next
                            </h2>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  {company == 'not found' && (
                    <Row className="mt-5 px-5">
                      <Col>
                        <h2 className="justify-content-center" style={{ color: Colors.gray }}>
                          "There are currently no companies associate"
                        </h2>
                      </Col>
                    </Row>
                  )}
                </Form>
              </Container>
            )}
          </Formik>
        </div>
      </Modal>
    );
  };

  render() {
    const { checkSubdomain, subdomainLoading, subdomainValid, submit } = this.props;
    const initialValues = { subdomain: '' };

    return (
      <>
        <PageWithBackgroundImage>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              subdomain: Yup.string().required('Required'),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              setFieldValue,
            }) => (
              <Container className="mb-5 p-5">
                <Row className="mb-5 pl-5 pb-5">
                  <Col className="col col-lg-4 mt-5 mb-5 pb-5">
                    <Logo />
                  </Col>
                </Row>

                <Row className="mt-5 mb-5 px-5">
                  <Col className="mt-5">
                    <h2 className="align-items-center" style={{ fontWeight: 'bold' }}>
                      Enter your company Glaziers Tool URL
                    </h2>
                  </Col>
                </Row>

                <Form onSubmit={handleSubmit}>
                  <Row className="pt-4 pb-4 px-5">
                    <Col>
                      <Form.Group controlId="login">
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="subdomain"
                          value={values.subdomain}
                          onChange={e => {
                            const value = event.target.value.replace(/ /g, '');
                            setFieldValue(event.target.name, value);
                            checkSubdomain(e.target.value);
                          }}
                          onBlur={handleBlur}
                        />

                        <h2
                          className="d-none  d-sm-block"
                          style={{
                            color: '#A3A3A3',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            position: 'absolute',
                            right: '12px',
                            top: '0px',
                          }}
                        >
                          glazierstool.com
                        </h2>
                        <p className="text-error">
                          {errors.subdomain && touched.subdomain && errors.subdomain}
                        </p>
                        <p className="text-error">
                          {touched.subdomain &&
                            subdomainValid &&
                            !subdomainLoading &&
                            'Subdomain is invalid'}
                        </p>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="pt-4  px-5">
                    <Col className="d-flex">
                      <Button
                        className="form-control"
                        variant="primary"
                        size="lg"
                        block
                        type="submit"
                        disabled={
                          !isValid || (touched.subdomain && subdomainValid && !subdomainLoading)
                        }
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 18,
                            textAlign: 'center',
                          }}
                        >
                          Next
                        </p>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-5 px-5">
                    <Col className="text-center">
                      <Button variant="link" onClick={() => this.setState({ isOpen: true })}>
                        <Row>
                          <h2 style={{ color: 'black' }}>Already Using GT? </h2>
                          <h2 className="pl-2" style={{ color: '#26B4FF' }}>
                            Find your team
                          </h2>
                        </Row>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-5 px-5">
                    <Col className="text-center">
                      <hr />
                    </Col>
                  </Row>
                  <Row className="mt-5 px-5">
                    <Col className="text-center">
                      <Button variant="link" onClick={() => push('/register-company')}>
                        <h2 style={{ color: '#26B4FF' }}>+ Create an team </h2>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            )}
          </Formik>
        </PageWithBackgroundImage>
        {this.renderModal()}
      </>
    );
  }
}
