import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CustomButton } from '../Elements/CustomButton';
import { Logo } from '../Elements/Logo';

const MainNavbar = () => (
  <Navbar expand="lg" collapseOnSelect>
    <Navbar.Brand href="/">
      <Logo />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav" className="mx-auto">
      <Nav className="d-flex align-items-start ml-5 ml-lg-auto">
        <Nav.Link href="/about" className="my-1 my-lg-0">
          <Link to="/about">
            <CustomButton size="sm" text="About" variant="link" href="/about" path="/about" />
          </Link>
        </Nav.Link>
        <Nav.Link href="/benefits" className="my-1 my-lg-0">
          <CustomButton
            size="sm"
            text="Benefits"
            variant="link"
            href="/#benefits"
            path="/#benefits"
          />
        </Nav.Link>
        <Nav.Link href="/pricing" className="my-1 my-lg-0">
          <CustomButton size="sm" text="Pricing" variant="link" href="/#pricing" path="/#pricing" />
        </Nav.Link>
        <Nav.Link href="/contacts" className="my-1 my-lg-0">
          <CustomButton
            size="sm"
            text="Contacts"
            variant="link"
            href="/#inquiry"
            path="/#inquiry"
          />
        </Nav.Link>

        <Nav.Link href="/login" className="my-1 my-lg-0">
          <Link to="/login">
            <CustomButton
              size="lg"
              text="Sign In"
              variant="secondary"
              href="/login"
              path="/login"
              className="py-4 px-2"
            />
          </Link>
        </Nav.Link>
        <Nav.Link href="/register-company" className="my-1 my-lg-0">
          <Link to="/register-company">
            <CustomButton
              size="lg"
              text="Sign Up"
              variant="primary"
              href="/register-company"
              path="/register-company"
              className="py-4 px-2 "
            />
          </Link>
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);
export default MainNavbar;
