import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsers, deleteUser, editUser } from 'actions/index';
import UserListView from './UserListView';
import { userRoleId } from '../../constants';

const elementsPerPage = 10;

class UserListContainer extends Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { fetchUsers } = this.props;

    fetchUsers({
      page: 1,
      elementsPerPage,
      role_id: `${userRoleId.SALES},${userRoleId.CSR}`,
    });
  }

  render() {
    const {
      list,
      currentPage,
      pages,
      loading,
      removeUser,
      changeStatus,
      fetchUsers,
      profile,
    } = this.props;

    return (
      <UserListView
        profile={profile}
        list={list}
        currentPage={currentPage}
        pages={pages}
        loading={loading}
        removeUser={removeUser}
        changeStatus={changeStatus}
        fetchUsers={fetchUsers}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.auth.profile,
    list: state.user.list,
    count: state.user.count,
    currentPage: state.user.currentPage,
    pages: state.user.pages,
    loading: state.user.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: params => dispatch(getUsers(params)),
    changeStatus: (id, status) => dispatch(editUser(id, { status })),
    removeUser: id => dispatch(deleteUser(id, { status })),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);
