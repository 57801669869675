/* eslint-disable no-use-before-define */
import React from 'react';

function NoEvents() {
  return (
    <tbody style={styles.tbody}>
      <tr>
        <td colSpan="5">
          <div style={styles.message}>No events added</div>
          <div style={styles.instruction}>
            Please select a quote in the Current Jobs tab to add an event
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default NoEvents;

const styles = {
  tbody: {
    height: 'calc(100vh - 200px)',
  },
  message: {
    textAlign: 'center',
    color: '#425268',
    fontSize: 15,
    fontFamily: 'Lato',
    fontWeight: '600',
    wordWrap: 'break-word',
    marginBottom: 10,
    marginTop: 50,
  },
  instruction: {
    textAlign: 'center',
    color: '#425268',
    fontSize: 15,
    fontFamily: 'Lato',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
};
