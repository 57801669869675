import React from 'react';
import { Jumbotron, Button, Row, Image, Col, Card } from 'react-bootstrap';
import Tablet1 from './tablet-1.png';
const title = 'Web-Based Power for Flat Glass Pros: Unlock the potential with Glaziers Tool';
const description =
  'Glaziers Tool, is conceived as a companion application designed to assist glaziers working in the field. This innovative tool serves to streamline the process of collecting and sharing vital job field data by automatically consolidating all relevant job information and accompanying photos into a cohesive PDF format, seamlessly transmitted to your office. It enables your team to effortlessly request vendor quotes and conduct comprehensive reviews of project details prior to issuing a quote to the customer.';
const desc =
  'The result is an optimized workflow that enhances operational efficiency, reduces the likelihood of errors, and ultimately empowers glass professionals by simplifying and elevating their daily tasks, thus increasing productivity.';

const cards = [
  {
    title: 'Effortless Onboarding',
    description: 'Zero data entry requirements-start using today.',
  },
  {
    title: 'Templates',
    description: 'Versatile templates for diverse scenarios.',
  },
  {
    title: 'Streamlined Quoting & Sales',
    description:
      "Easily send field visit PDF's to enhance RFQ efficiency and drive increased sales.",
  },
  {
    title: 'Training',
    description:
      "GT facilitates estimator growth, expedites industry expertise, and enhances your team's capabilities.",
  },
];

const AboutView = () => (
  <>
    <Jumbotron className="medium-primary-bg my-4 py-5">
      <div className="d-block d-md-none">
        <h5 className="landing-h5 primary-color ml-md-5">About</h5>
      </div>
      <div className="d-none d-md-block">
        <h4 className="landing-h4 primary-color ml-md-5">About</h4>
      </div>
    </Jumbotron>
    <Row className="d-flex justify-content-center align-items-center py-5 mx-md-5">
      <Col xs={12} md={7}>
        <h5 className="landing-h6 primary-color mx-4 mx-md-0">{title}</h5>
        <p className="landing-p py-4 mx-4 mx-md-0">{description}</p>
      </Col>
      <Col
        xs={12}
        md={5}
        className="d-flex justify-content-center align-items-center light-primary-bg py-4"
      >
        <Image src={Tablet1} fluid />
      </Col>
    </Row>
    <Row className="mx-md-5 my-md-5">
      <h5 className="landing-h8 secondary-color  my-md-5 ml-5 ml-md-4 ">
        GT serves as an invaluable partner to glazing professionals
      </h5>
      <Row xs={12} md={10} className="d-flex my-md-5 ml-md-1 mx-3 mx-md-0">
        {cards.map(card => (
          <Col xs={12} md={3} key={card.title} className="my-3 my-md-0">
            <Card
              style={{
                minHeight: '120px',
              }}
            >
              <Card.Body>
                <Card.Title>
                  <h6 className=" landing-p secondary-color">{card.title}</h6>
                </Card.Title>
                <Card.Text className="landing-caption dark-gray">{card.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Row>
    <Row
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${require('./bg.png')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '150px 0',
      }}
    >
      <Col md={2}></Col>
      <Col md={8}>
        <h6 className="landing-h7 text-white text-center">
          The ultimate companion app for glaziers
        </h6>
        <p className="landing-h8 text-white text-center mt-4">
          With GT by their side, glaziers can confidently collect and share the data needed to
          complete quotes and RFQs with precision and ease.
        </p>
      </Col>
      <Col md={2}></Col>
    </Row>
  </>
);

export default AboutView;
