import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

const INITIAL_EVENT = {
  name: '',
  notes: '',
  username: '',
  participants: '',
  start_time: null,
  type: -1,
};
export const appState = {
  listEvent: [],
  listEventByJob: [],
  quote_number: '',
  count: 0,
  loading: false,
  current: INITIAL_EVENT,
  currentPage: 1,
  openEdit: false,
  editingEventId: null,
  openDelete: { state: false, id: null },
};

export default {
  event: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.listEvent = [];
      },
      [ActionTypes.GET_EVENTS]: draft => {
        draft.loading = true;
        draft.current = INITIAL_EVENT;
      },
      [ActionTypes.GET_EVENTS_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.listEvent = rows;
        draft.loading = false;
        draft.pages = pages;
        draft.currentPage = currentPage;
      },
      [ActionTypes.GET_EVENTS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_EVENT]: () => {},
      [ActionTypes.GET_EVENT_SUCCESS]: (draft, { payload: { event } }) => {
        draft.current = event;
      },
      [ActionTypes.GET_EVENT_FAILURE]: () => {},
      [ActionTypes.CREATE_EVENT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_EVENTS_BY_JOB]: draft => {
        draft.loading = true;
        draft.current = INITIAL_EVENT;
      },
      [ActionTypes.GET_EVENTS_BY_JOB_SUCCESS]: (
        draft,
        { payload: { rows, pages, currentPage, quote_number } },
      ) => {
        draft.listEventByJob = rows;
        draft.loading = false;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.quote_number = quote_number;
      },
      [ActionTypes.GET_EVENTS_BY_JOB_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_EVENT_SUCCESS]: draft => {
        draft.loading = false;
        draft.current = INITIAL_EVENT;
      },
      [ActionTypes.CREATE_EVENT_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_EVENT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_EVENT_SUCCESS]: draft => {
        draft.loading = false;
        draft.current = INITIAL_EVENT;
      },
      [ActionTypes.EDIT_EVENT_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_EVENT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_EVENT_SUCCESS]: (draft, { payload: { id } }) => {
        draft.listEvent = draft.listEvent.filter(item => item.id !== id);
        draft.listEventByJob = draft.listEventByJob.filter(item => item.id !== id);
        draft.count -= 1;
        draft.loading = false;
      },
      [ActionTypes.DELETE_EVENT_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.OPEN_EDIT_EVENT]: (state, action) => {
        state.openEdit = action.payload.openEdit;
        state.editingEventId = action.payload.editingEventId;
      },
      [ActionTypes.SET_CURRENT_PAGE]: (draft, { payload }) => {
        draft.currentPage = payload;
      },
      [ActionTypes.OPEN_DELETE_EVENT]: (draft, { payload: { state, id } }) => {
        draft.openDelete = { state, id };
      },
    },
    appState,
  ),
};
