import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Icon from 'components/Icon';
import { Container, Button, Row, Col, Form, Navbar } from 'react-bootstrap';

class Tabs extends Component {
  render() {
    const { tabs, active, onChangeTabs } = this.props;
    return (
      <>
        <Col xs={12} md={12} sm={4} xl={4} className="pl-0">
          <Navbar className="pl-3">
            <Navbar.Brand className="text-title">Settings</Navbar.Brand>
          </Navbar>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} style={{ marginBottom: '30px' }}>
          <Row>
            {tabs.map((tab, key) => {
              return (
                <>
                  {active == tab.title ? (
                    <Form.Group className="m-2 mr-3">
                      <Container
                        style={{
                          borderBottom: 'solid 3px',
                          borderColor: '#0084EA',
                          cursor: 'pointer',
                        }}
                        onClick={() => onChangeTabs(tab.title)}
                      >
                        <Icon name={tab.icon} fontSize={18} color={'#0084EA'} />
                        <Form.Label
                          style={{
                            marginLeft: '10px',
                            marginTop: '15px',
                            color: '#0084EA',
                            cursor: 'pointer',
                          }}
                          className="text-tabs"
                        >
                          {tab.title}
                        </Form.Label>
                      </Container>
                    </Form.Group>
                  ) : (
                    <Form.Group className="m-2 mr-3">
                      <Container
                        onClick={() => onChangeTabs(tab.title)}
                        style={{ cursor: 'pointer' }}
                      >
                        <Icon name={tab.icon} fontSize={18} color={'#173264'} />
                        <Form.Label
                          style={{
                            marginLeft: '10px',
                            marginTop: '15px',
                            color: '#173264',
                            cursor: 'pointer',
                          }}
                          className="text-tabs"
                        >
                          {tab.title}
                        </Form.Label>
                      </Container>
                    </Form.Group>
                  )}
                </>
              );
            })}
          </Row>
        </Col>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(null, mapDispatchToProps)(Tabs);
