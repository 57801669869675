import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { benefitsData } from './benefits-data';

// eslint-disable-next-line react/prop-types
const Benefit = ({ image, title, description }) => (
  <Row className="py-3 py-md-5 my-5">
    <Col xs={12} md={5}>
      <img src={image} alt={title} className="w-100" />
    </Col>
    <Col xs={12} md={5} className="mx-md-5">
      {/* For smaller devices */}
      <div className="d-block d-md-none">
        <h3 className="landing-h7 secondary-color my-5">{title}</h3>
        <p className="landing-h9 text-black my-5">{description}</p>
      </div>
      {/* For larger devices */}
      <div className="d-none d-md-block">
        <h3 className="landing-h3 secondary-color my-5">{title}</h3>
        <p className="landing-h9 text-black my-5">{description}</p>
      </div>
    </Col>
  </Row>
);

const Benefits = () => (
  <div id="benefits" className="mx-5">
    {benefitsData.map((item, index) => (
      <Benefit key={index} image={item.image} title={item.title} description={item.description} />
    ))}
  </div>
);
export default Benefits;
