/**
 * @module Sagas/User
 * @desc User
 */

import { delay, put, call, take } from 'redux-saga/effects';
import config from 'config';

import {
  showAlert,
  cronofyGetCalendars as getCalendarsAction,
  cronofyGetCalendarsSuccess,
} from 'actions/index';
import { ActionTypes } from 'constants/index';
import { push } from 'modules/history';

/**
 * Login
 */
export function* login(api, action) {
  const {
    payload: { email, password },
  } = action;

  const domain = window.location.hostname;
  const subdomain = domain.split('.');

  const response = yield call(api.login, { email, password, subdomain: subdomain[0] });
  const { status, data } = response;

  if (status === 200) {
    const { token, profile, company } = data;
    yield call(api.setAuthorization, token);
    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: { profile, company, token },
    });
  } else {
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
    });
    const { errors } = data;
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

/* FINDCOMPANY */
export function* findCompany(api, action) {
  const {
    payload: { email },
  } = action;

  const response = yield call(api.findCompany, email);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.FIND_COMPANY_SUCCESS,
      payload: { company: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.FIND_COMPANY_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
/* RECOVERPASSWORD */
export function* recoverPassword(api, action) {
  const {
    payload: { email },
  } = action;

  const response = yield call(api.recoverPassword, email);
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('E-mail sent!', { variant: 'success' }));
  } else {
    const { errors } = data;

    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
/* RESETPASSWORD */
export function* resetPassword(api, action) {
  const {
    payload: { token_pass_recovery, password },
  } = action;
  const response = yield call(api.resetPassword, { token_pass_recovery, password });
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Your password has been reset!', { variant: 'success' }));
    yield push('login');
  } else {
    const { errors } = data;
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
/**
 * Logout
 */
export function* logout(api) {
  yield call(api.removeAuthToken);
  yield put(showAlert('Logout successful', { variant: 'success' }));
  yield put({
    type: ActionTypes.USER_LOGOUT_SUCCESS,
  });
}

export function* getProfile(api, action) {
  const {
    payload: { id },
  } = action;
  const { status, data } = yield call(api.getProfile, id);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_PROFILE_SUCCESS,
      payload: { profile: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_PROFILE_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getCompany(api, action) {
  const {
    payload: { id },
  } = action;
  const { status, data } = yield call(api.getCompany, id);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_COMPANY_SUCCESS,
      payload: { company: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_COMPANY_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

/* UPDATE PROFILE */
export function* updateProfile(api, action) {
  const { status, data } = yield call(api.updateProfile, action.payload.data);

  if (status === 200) {
    const { profile, requireLogout } = data;
    yield put({
      type: ActionTypes.UPDATE_PROFILE_SUCCESS,
      payload: { profile },
    });
    yield put(showAlert('Profile Updated.', { variant: 'success' }));

    if (requireLogout) {
      yield put({
        type: ActionTypes.REQUIRE_LOGOUT,
      });
      yield delay(5000);
      yield call(api.removeAuthToken);
      yield put(showAlert('Logout successful', { variant: 'success' }));
      yield put({
        type: ActionTypes.USER_LOGOUT_SUCCESS,
      });
    }
  } else {
    const { errors } = data;
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* updateCompany(api, action) {
  const { status, data } = yield call(api.updateCompany, action.payload.data);

  if (status === 200) {
    const { requireLogout } = data;
    yield put({
      type: ActionTypes.UPDATE_COMPANY_SUCCESS,
      payload: data,
    });
    yield put(showAlert('Company Updated.', { variant: 'success' }));

    if (requireLogout) {
      yield put({
        type: ActionTypes.REQUIRE_LOGOUT,
      });
      yield delay(2500);
      yield call(api.removeAuthToken);
      yield put(showAlert('Logout successful', { variant: 'success' }));
      yield put({
        type: ActionTypes.USER_LOGOUT_SUCCESS,
      });
      // The host is reset in case the subdomain is changed in the configuration.
      window.location.hostname = config.HOST;
    }
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.UPDATE_COMPANY_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
/* CHANGE PASSWORD */

export function* changePassword(api, action) {
  const { status, data } = yield call(api.changePassword, action.payload.data);
  if (status === 200) {
    yield put({
      type: ActionTypes.REQUIRE_LOGOUT,
    });
    yield delay(5000);
    yield call(api.removeAuthToken);
    yield put(showAlert('Password updated.', { variant: 'success' }));
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    });
  } else {
    const { errors } = data;
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

/* CLOSE ACCOUNT */
export function* closeAccount(api, action) {
  const { success, data } = yield call(api.closeAccount, action.payload.data);
  if (success) {
    yield put({
      type: ActionTypes.REQUIRE_LOGOUT,
    });
    yield delay(5000);
    yield call(api.removeAuthToken);
    yield put(showAlert('Account Deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    });
  } else {
    const { errors } = data;
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* cronofyRegisterUser(api, action) {
  const response = yield call(api.cronofyRegisterUser, action.payload.data);
  const { status, data, errors } = response;

  if (status === 200) {
    yield put(getCalendarsAction());
    yield put(showAlert('Synchronized Account', { variant: 'success' }));
    yield push('/dashboard/events');
  } else {
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* cronofyGetCalendars(api) {
  const response = yield call(api.cronofyGetCalendars);
  const { status, data, errors } = response;
  if (status === 200) {
    yield put(cronofyGetCalendarsSuccess(data));
  } else {
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* cronofyRevokeAuthorization(api) {
  const response = yield call(api.cronofyRevokeAuthorization);
  const { status, data, errors } = response;
  if (status === 200) {
    yield put(showAlert('Revoked calendar', { variant: 'success' }));
    yield push('/dashboard/events');
    yield put({
      type: ActionTypes.CRONOFY_REVOKE_AUTHORIZATION_SUCCESS,
    });
  } else {
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
