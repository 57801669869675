// @flow
/**
 * @module Actions/Event
 * @desc Event Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getEvents,
  getEvent,
  getEventsByJob,
  createEvent,
  editEvent,
  deleteEvent,
  openEditEvent,
  setCurrentPage,
  openDeleteEvent,
} = createActions({
  [ActionTypes.GET_EVENTS]: params => params,
  [ActionTypes.GET_EVENT]: id => ({ id }),
  [ActionTypes.GET_EVENTS_BY_JOB]: ({ job_id, query }) => ({ job_id, query }),
  [ActionTypes.CREATE_EVENT]: data => ({ data }),
  [ActionTypes.EDIT_EVENT]: (id, data) => ({ id, data }),
  [ActionTypes.DELETE_EVENT]: id => ({ id }),
  [ActionTypes.OPEN_EDIT_EVENT]: body => body,
  [ActionTypes.SET_CURRENT_PAGE]: page => page,
  [ActionTypes.OPEN_DELETE_EVENT]: body => body,
});
