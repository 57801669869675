import React, { Component } from 'react';
import '../components/styles.css';
import { Row, Col, Button, NavDropdown, Dropdown } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';

export default class DropdownNav extends Component {
  render() {
    const { logOut } = this.props;

    return (
      <NavDropdown
        className="d-none d-lg-flex mt-2 "
        title={<span className="my-auto text-header">Glass repair team</span>}
        id="basic-nav-dropdown"
      >
        {/* <NavDropdown.Item className="mt-3 mb-3">Members:</NavDropdown.Item>
        <Button
          className="ml-4 mr-5"
          variant="outline-warning"
          onClick={() => push('/dashboard/users/create')}
        >
          <h4
            style={{
              margin: 'auto',
            }}
          >
            + Add member
          </h4>
        </Button>
        <div className="m-4 p-4 navDropdownBox">
          <h4 className="h4-dropdown">Current plan:</h4>
          <Row>
            <Col>
              <h1 className="text-current-plan">BASIC</h1>
            </Col>
            <Col>
              <Button
                variant="primary"
                size="lg"
                block
                onClick={() => push('/dashboard/users/create')}
              >
                Update
              </Button>
            </Col>
          </Row>
          <p>Payment per month</p>
        </div>
        <NavDropdown.Item className="mt-3">
          <h4 className="h4-dropdown">Setting team</h4>
          </NavDropdown.Item>*/}
        <NavDropdown.Item className="mt-3 mb-4" onClick={logOut}>
          <h4 className="h4-dropdown">Log out</h4>
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
}
