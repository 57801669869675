import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNote,
  faRocket,
  faMemoCircleInfo,
  faBadgeCheck,
  faUsers,
  faUserCheck,
  faArrowProgress,
  faWrench,
  faClipboardListCheck,
} from '@fortawesome/pro-regular-svg-icons';
const cards = [
  {
    icon: faRocket,
    title: 'Boosting Confidence',
    description:
      'When the office or vendors receive data from the field via GT, they can have confidence that the information is complete and in the required format.',
  },
  {
    icon: faMemoCircleInfo,
    title: 'Streamlined Data Transmission',
    description:
      'GT provides a simple and repeatable method for sending accurate data from the field to the office or vendors.',
  },
  {
    icon: faBadgeCheck,
    title: 'Proven Performance',
    description:
      'Benefit from a industry-tailored and time-tested solution trusted by thousands of glaziers who have experienced tangible results.',
  },
  {
    icon: faUsers,
    title: 'Better Collaboration',
    description:
      'The app facilitates seamless communication between field teams, the office, and vendors. It ensures that everyone is on the same page, reducing the potential for miscommunication.',
  },
  {
    icon: faArrowProgress,
    title: 'Enhancing Efficiency',
    description:
      'GT enhances efficiency by simplifying the data transmission process. This efficiency translates into a more productive workflow and quicker response times when dealing with quotes and vendor requests.',
  },
  {
    icon: faWrench,
    title: 'Evolving Excellence',
    description:
      'Glaziers Tool continually improves and adapts to stay ahead of industry demands and user expectations. We value user feedback, incorporating it into regular updates.',
  },
  {
    icon: faClipboardListCheck,
    title: 'Consistency and Standardization',
    description:
      'Every time data is sent from the field using GT, it follows the same format and structure, ensuring uniformity across all projects. This approach streamlines processes further and reduces the chance of misunderstandings.',
  },
  {
    icon: faUserCheck,
    title: 'User-Friendly Interface',
    description:
      "GT's user-friendly interface ensures that even those with limited technical expertise can use it with ease. This makes it accessible to a wide range of glazing professionals, from veterans to those new to the industry.",
  },
  {
    icon: faNote,
    title: 'Effortless Field Data Collection',
    description:
      'GT offers a straightforward platform for collecting essential field data. Its simplicity ensures that field teams can easily input data, eliminating the need for complex hand-drawn diagrams or extensive documentation.',
  },
];

const WhyGlaziersFirst = () => (
  <Row
    className="py-2 py-md-5"
    style={{
      backgroundColor: '#F9FCFF',
      /*  */
    }}
  >
    <Col md={1} />
    <Col xs={12}>
      <div className="d-block d-md-none">
        <h5 className="landing-h5 secondary-color text-center">Why Choose Glaziers Tool?</h5>
      </div>
      <div className="d-none d-md-block">
        <h3 className="landing-h3 secondary-color text-center">Why Choose Glaziers Tool?</h3>
      </div>
    </Col>
    <Col xs={1} />
    <Col xs={12} md={10}>
      <Row>
        {cards.map(card => (
          <Col xs={12} md={4} key={card.title} className="my-2 my-md-0 px-4 py-4">
            <Card
              style={{
                minHeight: '310px',
              }}
            >
              <Card.Body className="px-4 py-md-0">
                <Card.Title className="">
                  <FontAwesomeIcon
                    icon={card.icon}
                    size="2x"
                    className="secondary-color pb-2 my-3"
                  />
                  <h6 className="py-1 landing-h7 secondary-color">{card.title}</h6>
                </Card.Title>
                <Card.Text className="landing-p dark-gray">{card.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Col>
    <Col xs={1} />
  </Row>
);
export default WhyGlaziersFirst;
