import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const ExtraContactField = ({ label, name, onBlur, setFieldValue, index, type, value }) => {
  const fieldName = `extraContact[${index}].${name}`;

  return (
    <Form.Group controlId={fieldName}>
      <Form.Label className="mt-3 input-text">{label}</Form.Label>
      <Form.Control
        as={Field}
        name={fieldName}
        type={type}
        value={value}
        onChange={e => {
          setFieldValue(fieldName, e.target.value);
        }}
        onBlur={onBlur}
      />
    </Form.Group>
  );
};

ExtraContactField.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

ExtraContactField.defaultProps = {
  type: 'text',
};
export default ExtraContactField;
