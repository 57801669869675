// @flow
/**
 * @module Actions/Contact
 * @desc Contact Actions
 */

import { createActions } from 'redux-actions';
import { ActionTypes } from 'constants/index';

export const { sendContact } = createActions({
  [ActionTypes.SEND_CONTACT]: data => ({ data }),
});
