import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from 'actions/index';
import DashboardRoutes from '../routes/DashboardRutes';
import PageWithMenu from '../components/PageWithMenu';

class DashboardLayout extends React.Component {
  render() {
    const { onLogout, profile } = this.props;
    return (
      <PageWithMenu onLogout={onLogout} profile={profile}>
        <DashboardRoutes {...this.props} />
      </PageWithMenu>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.auth.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardLayout));
