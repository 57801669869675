import React from 'react';
import '../components/styles.css';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { Colors } from 'modules/theme';

const MenuItem = props => {
  const { name, onClick, text, url } = props;
  const path = window.location.pathname;
  const active = path.indexOf(url);

  let color;
  let classStyle;

  if (active == 0) {
    color = Colors.blue;
    classStyle = 'menu-items-active';
  } else {
    color = Colors.blue1;
    classStyle = 'menu-items';
  }

  return (
    <div>
      <div className={`d-none d-sm-none d-md-block ${classStyle}`} onClick={onClick}>
        <Row className="d-lg-flex justify-content-center align-items-center">
          <Icon name={name} fontSize={24} color={color} />
        </Row>
        <Row className="d-lg-flex justify-content-center align-items-center mt-4">
          <p style={{ color: color, fontWeight: 'bold', fontSize: '12px' }}>{text}</p>
        </Row>
      </div>
    </div>
  );
};

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default MenuItem;
