import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { push } from 'modules/history';
import {
  Table,
  Button,
  Alert,
  Form,
  FormControl,
  Modal,
  Row,
  Col,
  Dropdown,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import Pagination from 'components/Pagination';
import Icon from 'components/Icon';
import defaultAvatar from 'assets/media/images/avatar.png';
import Moment from 'moment';

const elementsPerPage = 10;

export default class UserListView extends Component {
  static propTypes = {
    changeStatus: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    removeUser: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
  };

  state = {
    showEraseAlert: false,
    currentId: null,
    showBlockAlert: false,
    showApprovedAlert: false,
    search: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  };

  erase = () => {
    const { removeUser } = this.props;
    const { currentId } = this.state;
    removeUser(currentId);
    this.setState({ showEraseAlert: false });
  };

  block = () => {
    const { changeStatus } = this.props;
    const { currentId } = this.state;
    changeStatus(currentId, 'blocked');
    this.setState({ showBlockAlert: false });
  };

  approved = () => {
    const { changeStatus } = this.props;
    const { currentId } = this.state;
    changeStatus(currentId, 'active');
    this.setState({ showApprovedAlert: false });
  };

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderUser = (
    { id, first_name, last_name, email, phone_number, role_name, status, created_at, picture },
    profile,
    key,
  ) => (
    <tr key={key}>
      <td>
        <Row className="mt-5 mb-5 ml-3">
          <img
            style={{
              width: '35px',
              height: '35px',
              borderRadius: '20px',
              marginLeft: '15px',
              objectFit: 'cover',
            }}
            src={picture || defaultAvatar}
          ></img>

          <h4 className="mt-2 ml-4">{`${first_name} ${last_name}`}</h4>
        </Row>
      </td>
      <td>
        <h4 className="mt-5 mb-5">{email}</h4>
      </td>
      <td>
        <h4 className="mt-5 mb-5">{phone_number}</h4>
      </td>
      <td>
        <h4 className="mt-5 mb-5">{role_name}</h4>
      </td>
      <td>
        <h4 className="mt-5 mb-5">{status}</h4>
      </td>
      <td>
        <h4 className="mt-5 mb-5">{Moment(created_at).format('h:mm a MM/DD/YY')}</h4>
      </td>
      <td>
        {profile.id !== id && (
          <Dropdown className="mt-5 mb-5">
            <Dropdown.Toggle
              style={{
                background: '#F8FBFF',
                border: '0px',
                paddingRight: 0,
              }}
              id="dropdown-basic"
            >
              <Icon fontSize={30} name="ellipsis-h" color={'#425268'} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {status !== 'active' && (
                <Dropdown.Item>
                  <Button
                    className="button-inline"
                    variant="success"
                    onClick={() => this.setState({ showApprovedAlert: true, currentId: id })}
                  >
                    Approve
                  </Button>
                </Dropdown.Item>
              )}
              {/* {status !== 'blocked' && (
                <Dropdown.Item>
                  <Button
                    className="button-inline"
                    variant="dark"
                    onClick={() => this.setState({ showBlockAlert: true, currentId: id })}
                  >
                    Block
                  </Button>
                </Dropdown.Item>
             )}*/}
              <Dropdown.Item>
                <Button
                  className="button-inline"
                  variant="warning"
                  onClick={() => push(`/dashboard/users/${id}/edit`)}
                >
                  Edit
                </Button>
              </Dropdown.Item>
              <Dropdown.Item>
                <Button
                  className="button-inline"
                  variant="danger"
                  onClick={() => this.setState({ showEraseAlert: true, currentId: id })}
                >
                  Delete
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
    </tr>
  );

  handlePageChange = page => {
    const { fetchUsers } = this.props;
    const { search, first_name, last_name, email, phone_number } = this.state;
    fetchUsers({
      page,
      elementsPerPage,
      search,
      first_name,
      last_name,
      email,
      phone_number,
    });
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchUsers } = this.props;
      const { search, first_name, last_name, email, phone_number } = this.state;

      fetchUsers({ page: 1, elementsPerPage, search, first_name, last_name, email, phone_number });
    });
  };

  render() {
    const { list, pages, currentPage, profile /* , count, loading*/ } = this.props;
    const { showEraseAlert, showBlockAlert, showApprovedAlert, search } = this.state;

    return (
      <div>
        <ButtonToolbar className="mt-4 mb-5 justify-content-between">
          <ButtonGroup className="mb-3" aria-label="First group">
            <Form inline>
              <FormControl
                type="text"
                className="form-control-placeholdericon"
                placeholder=" &#xF002;  Search"
                onChange={evt => this.searchOnChange(evt.target.value)}
                value={search}
                name="search"
              />
            </Form>
          </ButtonGroup>
          <ButtonGroup aria-label="First group">
            <Button
              variant="outline-primary"
              className="button"
              size="lg"
              onClick={() => push('/dashboard/users/create')}
              style={{ height: '41px', width: '215px' }}
            >
              + Add member
            </Button>
          </ButtonGroup>
        </ButtonToolbar>

        <Table responsive>
          <thead className="table-borderless">
            <tr style={{ borderColor: '#ffffff' }}>
              <th>
                <h4 className="ml-5" style={{ color: '#425268' }}>
                  Full Name
                </h4>
              </th>
              <th>
                <h4 style={{ color: '#425268' }}>Email</h4>
              </th>
              <th>
                <h4 style={{ color: '#425268' }}>Phone Number</h4>
              </th>
              <th>
                <h4 style={{ color: '#425268' }}>Role</h4>
              </th>
              <th>
                <h4 style={{ color: '#425268' }}>Status</h4>
              </th>
              <th>
                <h4 style={{ color: '#425268' }}>Date Created</h4>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="table-borderless">
            {list.map((user, key) => this.renderUser(user, profile, key))}
          </tbody>
        </Table>
        {list.length === 0 && (
          <Alert variant="warning" className="input-text">
            There are currently no users
          </Alert>
        )}
        {/* Alerts */}
        {this.renderAlert(
          'Remove User',
          'Are you sure that want remove user?',
          showEraseAlert,
          () => this.setState({ showEraseAlert: false }),
          'Remove',
          this.erase,
        )}
        {this.renderAlert(
          'Block User',
          'Are you sure that want Block user?',
          showBlockAlert,
          () => this.setState({ showBlockAlert: false }),
          'Block',
          this.block,
        )}
        {this.renderAlert(
          'Approve User',
          'Are you sure that want Approve user?',
          showApprovedAlert,
          () => this.setState({ showApprovedAlert: false }),
          'Approve',
          this.approved,
        )}
        <Pagination
          currentPage={currentPage}
          changePage={this.handlePageChange}
          totalPages={pages}
        />
      </div>
    );
  }
}
