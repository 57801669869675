import React from 'react';

import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { push } from 'modules/history';
import { Colors } from 'modules/theme';
import Icon from 'components/Icon';

import FormOwner from '../FormOwner';

const OwnerView = ({ isEditMode }) => {
  return (
    <>
      <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Col xs={12}>
          <h2 className="align-items-center">
            <Icon
              fontSize={25}
              name="chevron-left"
              color={Colors.blue}
              customStyle={{ paddingRight: '20px' }}
              onClick={() => push('/dashboard/settings')}
            />
            {isEditMode ? 'Edit' : 'Create'} Owner
          </h2>
        </Col>

        <Col xs={12}>
          <FormOwner isEditMode={isEditMode} />
        </Col>
      </Row>
    </>
  );
};

export default OwnerView;

OwnerView.propTypes = {
  isEditMode: PropTypes.bool,
};
