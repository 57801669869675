import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Row, Col, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

class TabActions extends Component {
  render() {
    const {
      name,
      count,
      current,
      add,
      ss,
      s,
      copy,
      erase,
      viewItem,
      hiddenDuplicate,
      hiddenCopy,
    } = this.props;
    const items = Array.from(Array(count).keys());

    return (
      <Alert style={{ marginTop: '20px', marginBottom: '0px', background: '#fff' }}>
        <Row>
          <Col xs={12} sm={8} md={8} lg={8}>
            <ButtonToolbar aria-label="Toolbar with button groups">
              <ButtonGroup aria-label="First group">
                {items.map((number, key) => (
                  <Button
                    active={number === current}
                    className="text-button"
                    variant="light"
                    size="lg"
                    key={key}
                    onClick={() => {
                      viewItem(number);
                    }}
                  >
                    {`${name} ${number + 1}`}
                  </Button>
                ))}
                {count === 0 && (
                  <Button className="mt-3" variant="light" size="lg" disabled>
                    {name} 1
                  </Button>
                )}
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            <ButtonToolbar aria-label="Toolbar with button groups" className="justify-content-end">
              <ButtonGroup>
                <Row className="m-3 pointer" variant="success" size="lg" onClick={add}>
                  <i
                    className="fas fa-plus icon-button button-rounded mr-1"
                    style={{ background: '#56D053' }}
                  />
                  <p className="input-text">Add</p>
                </Row>
                {count !== 0 && (
                  <Row
                    className="m-3 pointer"
                    variant="danger"
                    size="lg"
                    onClick={erase}
                    disabled={count === 0}
                  >
                    <i
                      className="fas fa-trash icon-div button-rounded mr-1"
                      style={{ background: '#FF5B5B' }}
                    />
                    <p className="input-text">Erase</p>
                  </Row>
                )}
                {count !== 0 && (
                  <>
                    {!hiddenDuplicate && (
                      <Row className="m-3 pointer" variant="light" size="lg" onClick={copy}>
                        <i
                          className="fas fa-copy icon-button button-rounded mr-1"
                          style={{ background: '#26B4FF' }}
                        />
                        <p className="input-text">Duplicate</p>
                      </Row>
                    )}

                    {/* <Row
                      className="m-3 pointer"
                      variant="secondary"
                      size="lg"
                      onClick={ss}
                      disabled={count === 0}
                    >
                        SS
                   </Row>*/}
                    {!hiddenCopy && (
                      <Row
                        className="m-3"
                        variant="secondary"
                        size="lg"
                        onClick={s}
                        disabled={count === 0}
                      >
                        <p
                          className="icon-button button-rounded mr-1 pointer"
                          style={{ background: '#26B4FF', fontWeight: 'bold' }}
                        >
                          S
                        </p>
                        <p className="input-text pointer">Copy Size</p>
                      </Row>
                    )}
                  </>
                )}
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
      </Alert>
    );
  }
}

TabActions.propTypes = {
  add: PropTypes.func.isRequired,
  copy: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  erase: PropTypes.func.isRequired,
  s: PropTypes.func.isRequired,
  ss: PropTypes.func.isRequired,
  viewItem: PropTypes.func.isRequired,
};
export default TabActions;
