import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  count: 0,
  loading: false,
  current: null,
  salesmans: [],
  csrs: [],
  owners: [],
};

export default {
  user: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_USERS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_USERS_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_USERS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_USER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_USER_SUCCESS]: (draft, { payload: { user } }) => {
        draft.current = user;
        draft.loading = false;
      },
      [ActionTypes.GET_USER_FAILURE]: draft => {
        draft.loading = false;
      },
      // /
      [ActionTypes.GET_SALESMAN]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_SALESMAN_SUCCESS]: (draft, { payload: { salesmans } }) => {
        draft.loading = false;
        draft.salesmans = salesmans;
      },
      [ActionTypes.GET_SALESMAN_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_CUSTOMER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_CUSTOMER_SUCCESS]: (draft, { payload: { csrs } }) => {
        draft.loading = false;
        draft.csrs = csrs;
      },
      [ActionTypes.GET_CUSTOMER_FAILURE]: draft => {
        draft.loading = false;
      },
      // Owners
      [ActionTypes.GET_OWNERS]: draft => {},
      [ActionTypes.GET_OWNERS_SUCCESS]: (draft, { payload: { owners } }) => {
        draft.owners = owners;
      },
      [ActionTypes.GET_OWNERS_FAILURE]: draft => {},
      [ActionTypes.CREATE_USER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_USER_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_USER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_USER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_USER_SUCCESS]: (draft, { payload: { id, user } }) => {
        draft.list = draft.list.map(item => {
          if (item.id === id) {
            return { ...item, ...user };
          }
          return item;
        });
        draft.owners = draft.owners.map(owner => {
          if (owner.id === id) {
            return { ...owner, ...user };
          }
          return owner;
        });
        draft.loading = false;
      },
      [ActionTypes.EDIT_USER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_USER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_USER_SUCCESS]: (draft, { payload: { id } }) => {
        draft.list = draft.list.filter(item => item.id !== id);
        draft.owners = draft.owners.filter(owner => owner.id !== id);
        draft.count -= 1;
        draft.loading = false;
      },
      [ActionTypes.DELETE_USER_FAILURE]: draft => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
