import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ActionTypes } from 'constants/index';
import { showAlert } from 'actions';
import { replace } from 'modules/history';
import moment from 'moment';

export function* createInquiry(api, { payload }) {
  const response = yield call(api.createInquiry, payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.CREATE_INQUIRY_SUCCESS,
    });
    yield put(showAlert('Inquiry Created', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.CREATE_INQUIRY_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
