import React, { useState } from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { Field, FieldArray } from 'formik';
import DropZone from 'components/DropZone';
import PropTypes from 'prop-types';

import TabActions from 'components/TabActions';
import GlassRemindersView from './GlassRemindersView';
import GlassMaterialsView from './GlassMaterialsView';

const INITIAL_VALUE = {
  general_notes: '',
  otherExtra1: '',
  otherNotes1: '',
  otherExtra2: '',
  otherNotes2: '',
  otherExtra3: '',
  otherNotes3: '',
  otherExtra4: '',
  otherNotes4: '',
  otherExtra5: '',
  otherNotes5: '',
  location: '',
  images: [],
  glassReminders: {
    glass_elevator: 'no',
    solar_film: 'no',
    solar_film_option_sag: null,
    wet_seal: 'no',
    wet_seal_option: null,
    furniture: 'no',
    walls_cielings: 'no',
    walls_cielings_option: null,
    blind_remove: 'no',
    color_waiver: 'no',
    damage_waiver: 'no',
    disclaimers: 'no',
    lift_inside: 'no',
    lift_outside: 'no',
  },
  glassMaterials: {
    caulks: [{ amount: 0, value: '', notes: '' }],
    types: [{ amount: 0, value: '', notes: '' }],
    equipaments: [{ amount: 0, value: '', notes: '' }],
    channels: [{ amount: 0, value: '', notes: '' }],
    miscellaneous: [{ amount: 0, value: '', notes: '' }],
  },
};

const NotesView = ({ values, errors, handleChange, touched, handleBlur, setFieldValue, token }) => {
  const [current, setCurrent] = useState(0);
  const counterOther = values?.other?.length;
  return (
    <div>
      <FieldArray
        name="other"
        render={({ push, remove }) => (
          <div style={{ marginBottom: '40px' }} key="other">
            <TabActions
              name="Other"
              count={counterOther}
              current={current}
              add={() => {
                setCurrent(counterOther);
                push({
                  ...INITIAL_VALUE,
                });
              }}
              erase={() => {
                remove(current);
                setCurrent(0);
              }}
              viewItem={index => {
                setCurrent(index);
              }}
              hiddenDuplicate={true}
              hiddenCopy={true}
            />
            <Row className="mb-4 ml-0 mr-0 pt-5">
              {values.other && values?.other?.length > 0 ? (
                // values.other.map((glassPiece, key) => (
                <Col key={current} xs={12} sm={12} md={12} xl={12}>
                  <Form.Group controlId={`other[${current}].general_notes`}>
                    <Form.Label>
                      <h3>General Notes</h3>
                    </Form.Label>
                    <Field
                      as="textarea"
                      className="textarea"
                      name={`other[${current}].general_notes`}
                      value={values.other[current].general_notes || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      rows={3}
                      style={{ border: 'none' }}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                        {errors.other && errors.other[key] && errors.other[key].general_notes}
                      </Form.Control.Feedback> */}
                  </Form.Group>
                  <Row style={{ marginTop: 10, paddingTop: 15 }}>
                    <Col xs={12} sm={4} md={4} xl={4} style={{ marginBottom: 15 }}>
                      <Form.Group controlId={`other[${current}].location`}>
                        <Form.Label>
                          <h3>Location</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].location`}
                          value={values.other[current].location || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={8} sm={8} md={8} xl={8}></Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId={`other[${current}].otherExtra1`}>
                        <Form.Label>
                          <h3>Extra#1</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherExtra1`}
                          value={values.other[current].otherExtra1 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId={`other[${current}].otherNotes1`}>
                        <Form.Label>
                          <h3>Notes</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherNotes1`}
                          value={values.other[current].otherNotes1 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}></Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId="otherExtra2">
                        <Form.Label>
                          <h3>Extra#2</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherExtra2`}
                          value={values.other[current].otherExtra2 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId="otherNotes2">
                        <Form.Label>
                          <h3>Notes</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherNotes2`}
                          value={values.other[current].otherNotes2 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}></Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId="otherExtra3">
                        <Form.Label>
                          <h3>Extra#3</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherExtra3`}
                          value={values.other[current].otherExtra3 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId="otherNotes3">
                        <Form.Label>
                          <h3>Notes</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherNotes3`}
                          value={values.other[current].otherNotes3 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}></Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId="otherExtra4">
                        <Form.Label>
                          <h3>Extra#4</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherExtra4`}
                          value={values.other[current].otherExtra4 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId="otherNotes4">
                        <Form.Label>
                          <h3>Notes</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherNotes4`}
                          value={values.other[current].otherNotes4 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}></Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId="otherExtra5">
                        <Form.Label>
                          <h3>Extra#5</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherExtra5`}
                          value={values.other[current].otherExtra5 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}>
                      <Form.Group controlId="otherNotes5">
                        <Form.Label>
                          <h3>Notes</h3>
                        </Form.Label>
                        <Field
                          className="form-control"
                          name={`other[${current}].otherNotes5`}
                          value={values.other[current].otherNotes5 || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} xl={4}></Col>
                    <Col xs={12} sm={12} md={6} xl={6}>
                      <GlassRemindersView
                        prefix={`other[${current}].glassReminders`}
                        values={values.other[current].glassReminders || {}}
                        errors={
                          errors && errors.glassReminders ? errors.glassReminders[current] : {}
                        }
                        handleChange={handleChange}
                        touched={
                          touched && touched.glassReminders ? touched.glassReminders[current] : {}
                        }
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </Col>

                    <Col xs={12} sm={12} md={6} xl={6}>
                      <GlassMaterialsView
                        prefix={`other.${current}.glassMaterials`}
                        values={values.other[current] ? values.other[current].glassMaterials : {}}
                        errors={
                          errors && errors.glassMaterials ? errors.glassMaterials[current] : {}
                        }
                        handleChange={handleChange}
                        touched={
                          touched && touched.glassMaterials ? touched.glassMaterials[current] : {}
                        }
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12}>
                      <h3>Images</h3>
                      {/* //TODO: al borrar sobre el boton de la imagen, no se borra del dropzopne */}
                      <DropZone
                        height={300}
                        token={token}
                        folder="glaziers-notes-uploads"
                        initialFiles={values.other[current].images}
                        handleSuccess={({ url }, position) => {
                          const images = [...values.other[current].images];
                          const pos = Math.min(position, values.other[current].images);
                          images.splice(pos, 0, url);
                          setFieldValue(`other.${current}.images`, images);
                        }}
                        handleRemove={({ url }) =>
                          setFieldValue(
                            `other.${current}.images`,
                            values.other[current].images.filter(u => u !== url),
                          )
                        }
                        handlePosition={(fromIndex, toIndex) => {
                          const images = [...values.other[current].images];
                          var element = images[fromIndex];
                          images.splice(fromIndex, 1);
                          images.splice(toIndex, 0, element);

                          setFieldValue(`other.${current}.images`, images);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              ) : (
                // ))
                <Alert variant="light" style={{ paddingTop: 20, background: '#F8FBFF' }}>
                  <Alert.Heading style={{ textAlign: 'center' }}>
                    {' There are currently no Glass pieces, '}
                    <Alert.Link
                      // onClick={() => {
                      //   this.setState({ current: glassPiecesCount }, () => {
                      //     push(INITIAL_VALUE);
                      //   });
                      // }}
                      onClick={() => {
                        setCurrent(0);
                        push({
                          ...INITIAL_VALUE,
                        });
                      }}
                    >
                      Click here
                    </Alert.Link>
                    {' to create a new'}
                  </Alert.Heading>
                </Alert>
              )}
            </Row>
          </div>
        )}
      />
    </div>
  );
};

export default NotesView;

NotesView.propTypes = {
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};
