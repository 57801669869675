import React from 'react';
import PropTypes from 'prop-types';

const RemoveButton = ({ remove }) => (
  <div className="cursor-pointer mt-md-5" variant="danger" size="lg" onClick={remove}>
    <i className="fas fa-trash icon-div button-rounded" style={{ background: '#FF5B5B' }} />
  </div>
);
export default RemoveButton;

RemoveButton.propTypes = {
  remove: PropTypes.func.isRequired,
};
