/**
 * @module Sagas/Job
 * @desc Job
 */

import { call, put, select } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { showAlert } from 'actions';

export function* getProducts(api, action) {
  const { payload } = action;
  const { status, data } = yield call(api.getProducts, payload);
  if (status === 200) {
    const { products } = data;
    yield put({
      type: ActionTypes.GET_PRODUCTS_SUCCESS,
      payload: { products },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_PRODUCTS_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* registerCompany(api) {
  const state = yield select();
  let payload = { ...state.registerCompany.register_data };
  delete payload.confirm_email;
  delete payload.confirm_password;
  const { status, data } = yield call(api.registerCompany, payload);
  if (status === 200) {
    yield put({
      type: ActionTypes.REGISTER_COMPANY_SUCCESS,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.REGISTER_COMPANY_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
export function* checkSubdomain(api, action) {
  const state = yield select();
  let { payload } = action;

  if (state.auth.company) {
    payload.company_id = state.auth.company.id;
  }

  const { status, data } = yield call(api.checkSubdomain, payload);
  if (status === 200) {
    const { subdomainValid } = data;
    yield put({
      type: ActionTypes.CHECK_SUBDOMAIN_SUCCESS,
      payload: subdomainValid,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.CHECK_SUBDOMAIN_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
