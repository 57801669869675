import styled from 'styled-components';

import { Button, Form } from 'react-bootstrap';

export const ContainerTitle = styled.div`
  width: 317px;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
`;

export const Title = styled.div`
  color: #0f0504;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
`;

export const InfoText = styled.div`
  width: 612px;
  color: #71717a;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
  // padding-bottom: 16px;
`;

export const Label = styled(Form.Label)`
  color: #71717a;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
  word-wrap: break-word;
`;

export const AdditionalInfoText = styled(Form.Text)`
  color: #71717a;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
`;

export const ButtonSave = styled(Button)`
  width: 100px !important;
  height: 32px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  // background: #e8ebf0 !important;
  // color: #425268 !important;
  border: 0 !important;
  gap: 8px !important;
  font-size: 14px !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400 !important;
  word-wrap: break-word !important;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
`;

export const ButtonCancel = styled(Button)`
  width: 100px !important;
  height: 32px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  background: #e8ebf0 !important;
  color: #dc3545 !important;
  border: 0 !important;
  gap: 8px !important;
  font-size: 14px !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400 !important;
  word-wrap: break-word !important;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  &:hover {
    background: #d1d6dd !important;
  }
`;

export const ButtonTurnOff = styled(Button)`
  width: 100px !important;
  height: 32px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  background: #e8ebf0 !important;
  color: #425268 !important;
  border: 0 !important;
  gap: 8px !important;
  font-size: 14px !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400 !important;
  word-wrap: break-word !important;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  &:hover {
    background: #d1d6dd !important;
  }
`;
