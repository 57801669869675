// @flow
/**
 * Client
 * @module Client
 */
import apisauce from 'apisauce';
import config from 'config';

const create = (baseURL = config.BASE_URL) => {
  const api = apisauce.create({
    baseURL,
  });
  // api
  const setAuthorization = token => api.setHeader('jwt', token);
  const removeAuthToken = () => api.deleteHeader('Authorization');

  // login
  const login = data => api.post('/sign-in', data);
  const findCompany = email => api.post('/find-company', { email });
  const recoverPassword = email => api.post('/recover-password', { email });

  const resetPassword = ({ token_pass_recovery, password }) =>
    api.post('/reset-password', { token_pass_recovery, password });

  // user
  const getUsers = params => api.get('/users', params);
  const getUser = id => api.get(`/users/${id}`);
  const editUser = (id, data) => api.put(`/users/${id}`, data);
  const createUser = data => api.post('/users', data);
  const deleteUser = id => api.delete(`/users/${id}`);
  // salesman
  const getSalesman = () => api.get('/users/salesman');
  // customer
  const getCustomer = () => api.get('/users/customer');
  // Owners
  const getOwners = ({ status }) => api.get(`/users/owners?status=${status}`);
  // job
  const getJobs = params => api.get('/jobs', params);
  const getJob = id => api.get(`/jobs/${id}`);
  const createJob = data => api.post('/jobs', data);
  const editJob = (id, data) => api.put(`/jobs/${id}`, data);
  const deleteJob = id => api.delete(`/jobs/${id}`);
  // pdf
  const createJobPdf = id => api.get(`/jobs/${id}/pdf`);
  // settings
  const getProfile = () => api.get('/my-profile');
  const getCompany = () => api.get('/my-company');

  const updateProfile = data => api.put('/my-profile', data);
  const updateCompany = data => api.put('/my-company', data);

  const changePassword = data => api.put('/users/change-password', data);
  const cronofyRegisterUser = data => api.post('/setting/cronofy-register-user', data);
  const cronofyGetCalendars = data => api.post('/setting/get-calendars', data);
  const cronofyRevokeAuthorization = data =>
    api.post('/setting/cronofy-revoke-authorization', data);
  // Events
  const getEvents = params => api.get('/events', params);
  const getEvent = id => api.get(`/events/${id}`);
  const getEventsByJob = ({ job_id, query }) => api.get(`/events/job/${job_id}`, query);
  const createEvent = data => api.post('/events', data);
  const editEvent = (id, data) => api.put(`/events/${id}`, data);
  const deleteEvent = id => api.delete(`/events/${id}`);
  // registerCompany
  const getProducts = () => api.get('/products');
  const registerCompany = data => api.post('/companies/register', data);
  const checkSubdomain = data => api.post('/companies/check-subdomain', data);
  // contact
  const sendContact = data => api.post('/contact', data);

  // inquiry
  const createInquiry = data => api.post('/inquiry', data);
  return {
    // api
    setAuthorization,
    removeAuthToken,
    // login
    login,
    findCompany,
    recoverPassword,
    resetPassword,
    // user
    getUsers,
    getUser,
    createUser,
    editUser,
    deleteUser,
    // salesman
    getSalesman,
    // customer
    getCustomer,
    // Owners
    getOwners,
    // job
    getJobs,
    getJob,
    createJob,
    editJob,
    deleteJob,
    createJobPdf,
    // events
    getEvents,
    getEvent,
    getEventsByJob,
    createEvent,
    editEvent,
    deleteEvent,
    // settings
    getProfile,
    getCompany,
    updateProfile,
    updateCompany,
    changePassword,
    cronofyRegisterUser,
    cronofyGetCalendars,
    cronofyRevokeAuthorization,
    // get products
    getProducts,
    registerCompany,
    checkSubdomain,
    // conatct
    sendContact,

    createInquiry,
  };
};

export default {
  create,
};
