import React, { Component } from 'react';
import Header from './Header';
import { push } from 'modules/history';
import '../components/styles.css';
import MenuItem from './MenuItem';

export default class MenuPage extends Component {
  render() {
    const { profile, onLogout } = this.props;

    return (
      <div className="pageWithMenu">
        <Header profile={profile} logOut={onLogout} />
        <div className="menu">
          <nav className="main-menu">
            <ul className="menu-list" className="h-100 pl-1">
              <MenuItem
                name="toolbox"
                onClick={() => push('/dashboard/jobs')}
                text="Current Jobs"
                url={'/dashboard/jobs'}
              />
              <MenuItem
                name="users"
                onClick={() => push('/dashboard/users')}
                text="Team"
                url={'/dashboard/users'}
              />
              <MenuItem
                name="calendar"
                onClick={() => push('/dashboard/events')}
                text="Events"
                url={'/dashboard/events'}
              />
              <MenuItem
                name="cog"
                onClick={() => push('/dashboard/settings')}
                text="Setting"
                url={'/dashboard/settings'}
              />
            </ul>
          </nav>
        </div>
        <div className="pageContent">{this.props.children}</div>
      </div>
    );
  }
}
