import React from 'react';
import PropTypes from 'prop-types';

const AddButton = ({ add, text }) => (
  <div className="cursor-pointer d-flex " variant="success" onClick={add}>
    <i className="fas fa-plus icon-button button-rounded mr-1" style={{ background: '#56D053' }} />
    {text && <p className="input-text pointer">{text}</p>}
  </div>
);

AddButton.propTypes = {
  add: PropTypes.func.isRequired,
  text: PropTypes.string,
};
AddButton.defaultProps = {
  text: '',
};

export default AddButton;
