import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export default {
  inquiry: handleActions(
    {
      [ActionTypes.CREATE_INQUIRY]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_INQUIRY_SUCCESS]: (draft, { payload: { inquiry } }) => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_INQUIRY_FAILURE]: (
        draft,
        {
          payload: {
            error: { message },
          },
        },
      ) => {
        draft.loading = false;
        draft.errors = message;
      },
    },
    {
      loading: false,
      current: {},
      errors: null,
    },
  ),
};
