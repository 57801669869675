import React, { useEffect } from 'react';
import Owner from './OwnerView';
import { useParams } from 'react-router-dom';
import { getUser } from 'actions/index';
import { useDispatch } from 'react-redux';

const CreateOwnerContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const isEditMode = Boolean(id);

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [id, dispatch]);

  return <Owner isEditMode={isEditMode} />;
};

export default CreateOwnerContainer;
