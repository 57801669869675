import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { Colors } from 'modules/theme';

const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-position-x: center;
  background-size: cover;
  background-blend-mode: multiply;
  text-align: left;
  height: -webkit-fill-available;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;
  overflow-x: scroll;
  z-index: 1050;
`;

const ModalContainer = styled.div`
  background-color: white;
  position: relative;
  width: ${props => (props.size ? getSize(props.size) : '70%')};
  margin: 30px auto;
  border-radius: 10px;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const Header = styled.p`
  text-align: center;
  color: #9e9e9e;
  font-size: 20px;
  margin-bottom: 20px;
`;

const Box = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  padding: 20px;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 0px;
  right: -50px;
  float: right;
  background-color: ${Colors.blue};
  border-radius: 7px;
  padding: 10px 15px;
  cursor: pointer;
`;

const getSize = (size, isLaptop) => {
  const sizes = {
    small: '35%',
    medium: '50%',
    large: '70%',
    extraLarg: '90%',
  };

  return sizes[size];
};

const Modal = props => {
  const { children, title, onClose, isVisible, size } = props;
  if (!isVisible) {
    document.body.style.overflow = 'unset';
    return null;
  } else {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
  }

  return (
    <Container>
      <ModalContainer size={size}>
        <CloseIcon onClick={onClose}>
          <Icon color={Colors.white} name="times" />
        </CloseIcon>
        <Box>
          <Header>{title}</Header>
          <Body>{children}</Body>
        </Box>
      </ModalContainer>
    </Container>
  );
};

export default Modal;
