import React, { Component } from 'react';
import '../components/styles.css';
import { Row, Col, Button, Navbar, Nav, Collapse } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';

export default class MenuItemMobile extends Component {
  render() {
    const { logOut } = this.props;

    return (
      <Nav
        className="d-block d-sm-block d-md-block d-lg-none mr-auto"
        style={{ background: '#f8fbff', borderRadius: '5px' }}
      >
        {/*<Row className="justify-content-center mt-5 pt-5 ">
          <h4 className="h4-dropdown">Members:</h4>
        </Row>
        <Nav.Link href="/dashboard/users/create">
          <Row className="justify-content-center mt-4">
            <Button variant="outline-warning">
              <h4>+ Add member</h4>
            </Button>
          </Row>
        </Nav.Link>

        <Row className="justify-content-center  mt-2">
          <div className="m-4 p-4 navDropdownBox">
            <h4 className="h4-dropdown">Current plan:</h4>
            <Row>
              <Col>
                <h1 className="text-current-plan">BASIC</h1>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  size="lg"
                  block
                  onClick={() => push('/dashboard/users/create')}
                >
                  Update
                </Button>
              </Col>
            </Row>
            <p>Payment per month</p>
          </div>
    </Row>*/}

        <Nav.Link href="/dashboard/jobs">
          <Row className="justify-content-center  mt-2">
            <h4 className="h4-dropdown">Current Jobs</h4>
          </Row>
        </Nav.Link>
        <Nav.Link href="/dashboard/users">
          <Row className="justify-content-center  mt-5">
            <h4 className="h4-dropdown">Team</h4>
          </Row>
        </Nav.Link>
        <Nav.Link href="/dashboard/events">
          <Row className="justify-content-center  mt-5">
            <h4 className="h4-dropdown">Events</h4>
          </Row>
        </Nav.Link>
        <Nav.Link href="/dashboard/settings">
          <Row className="justify-content-center  mt-5">
            <h4 className="h4-dropdown">Setting</h4>
          </Row>
        </Nav.Link>
        {/* <Nav.Link href="/dashboard/settings">
          <Row className="justify-content-center  mt-5">
            <h4 className="h4-dropdown">Setting team</h4>
          </Row>
    </Nav.Link>*/}
        <Nav.Link onClick={logOut}>
          <Row className="justify-content-center  mt-5 mb-5 pb-5">
            <h4 className="h4-dropdown ">Log out</h4>
          </Row>
        </Nav.Link>
      </Nav>
    );
  }
}
