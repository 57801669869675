import React, { Component } from 'react';
import { push, replace } from 'modules/history';
import { connect } from 'react-redux';
import config from 'config';
import { debounce } from 'lodash';

import RegisterCompanyView from './RegisterCompanyView';
import ChoosePlanView from './ChoosePlanView';
import PaymentMethodView from './PaymentMethodView';
import {
  registerCompanySaveData,
  registerCompanyChangeStep,
  getProducts,
  registerCompany,
  showAlert,
  checkSubdomain,
  resetRegisterForm,
} from 'actions/index';
import { Spinner, Button, Col, Row } from 'react-bootstrap';
import { StripeProvider, Elements } from 'react-stripe-elements';
import PageWithBackgroundImage from 'components/PageWithBackgroundImage';

class RegisterCompanyContainer extends Component {
  state = { product_id: null };
  componentDidMount() {
    const { getProducts, resetRegisterForm } = this.props;
    getProducts();
    resetRegisterForm();
  }

  selectPlan = product_id => {
    this.setState({ product_id });
  };

  checkSubdomainDebounce = debounce(async value => {
    this.props.checkSubdomain(value);
  }, 600);

  render() {
    const {
      registerCompanySaveData,
      registerCompanyChangeStep,
      register_data,
      step,
      products,
      registerCompany,
      showAlert,
      registerSuccess,
      subdomainLoading,
      subdomainValid,
    } = this.props;

    const { product_id } = this.state;

    if (step == 1) {
      return (
        <RegisterCompanyView
          registerCompanySaveData={registerCompanySaveData}
          registerCompanyChangeStep={registerCompanyChangeStep}
          register_data={register_data}
          checkSubdomain={this.checkSubdomainDebounce}
          subdomainLoading={subdomainLoading}
          subdomainValid={subdomainValid}
        />
      );
    }

    if (step == 2) {
      return (
        <ChoosePlanView
          register_data={register_data}
          registerCompanySaveData={registerCompanySaveData}
          registerCompanyChangeStep={registerCompanyChangeStep}
          products={products}
          selectPlan={this.selectPlan}
          product_id={product_id}
        />
      );
    }

    if (step == 3) {
      return (
        <StripeProvider apiKey={config.STRIPE_PUBLIC_KEY}>
          <Elements>
            <PaymentMethodView
              showAlert={showAlert}
              registerCompanySaveData={registerCompanySaveData}
              registerCompanyChangeStep={registerCompanyChangeStep}
              registerCompany={registerCompany}
            />
          </Elements>
        </StripeProvider>
      );
    }
    if (step == 4) {
      return (
        <PageWithBackgroundImage>
          <Col className="d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" role="status" />
          </Col>
        </PageWithBackgroundImage>
      );
    }

    if (step == 5) {
      return (
        <PageWithBackgroundImage>
          {registerSuccess ? (
            <div className="d-flex justify-content-center align-items-center  flex-column h-100">
              <h2 className="my-5">Register successfull!</h2>
              <Button onClick={() => push('/login')} size="lg">
                Go to login
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center  flex-column h-100">
              <h2 className="my-5">Register failed.</h2>
              <Button variant="secondary" onClick={() => location.reload()} size="lg">
                Retry
              </Button>
            </div>
          )}
        </PageWithBackgroundImage>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    step: state.registerCompany.step,
    register_data: state.registerCompany.register_data,
    products: state.registerCompany.products,
    registerSuccess: state.registerCompany.registerSuccess,
    subdomainLoading: state.registerCompany.subdomainLoading,
    subdomainValid: state.registerCompany.subdomainValid,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    registerCompanySaveData: values => dispatch(registerCompanySaveData(values)),
    registerCompanyChangeStep: values => dispatch(registerCompanyChangeStep(values)),
    getProducts: () => dispatch(getProducts()),
    registerCompany: () => dispatch(registerCompany()),
    showAlert: message => dispatch(showAlert(message, { variant: 'danger' })),
    checkSubdomain: subdomain => dispatch(checkSubdomain(subdomain)),
    resetRegisterForm: () => dispatch(resetRegisterForm()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterCompanyContainer);
