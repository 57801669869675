import React from 'react';

import PropTypes from 'prop-types';

import CompanyEmailForm from './CompanyEmailForm';
import AppManagerForm from './AppManagerForm';

const NotificationsView = ({ updateCompany, company }) => (
  <>
    <CompanyEmailForm updateCompany={updateCompany} company={company} />
    <AppManagerForm updateCompany={updateCompany} company={company} />
  </>
);

export default NotificationsView;

NotificationsView.propTypes = {
  company: PropTypes.object.isRequired,
  updateCompany: PropTypes.func.isRequired,
};
