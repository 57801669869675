import React, { createRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Table,
  //  Dropdown
} from 'react-bootstrap';
import PropTypes from 'prop-types';
// import config from 'config';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faRotate, faEllipsisVertical, faTrash } from '@fortawesome/pro-regular-svg-icons';

import Pagination from '../../components/Pagination';
import { deleteEvent, openEditEvent, openDeleteEvent, setCurrentPage } from '../../actions/event';

// import { cronofyRevokeAuthorization } from '../../actions/auth';
import NoEvents from './components/NoEvents';
import EventItem from './components/EventItem';
import FormEditEvent from './components/FormEditEvent';

import {
  Title,
  TableHeaderStyle,
  ModalBodyStyle,
  ModalWarningStyle,
  // HoverColorStyle,
  // OuterDiv,
  // InnerDiv,
  // ProviderName,
  // ProviderNameSub,
  // DropdownItemStyle,
  // IconStyle,
  // TextStyle,
  // ButtonStyle,
} from './StyledComponents';
import ModalComponent from '../../components/ModalComponent';
import { userRoleId } from '../../constants';

const headers = ['Event type', 'Quote number', 'Date & Time', 'Notes'];

// const cronofy_url = `https://app.cronofy.com/oauth/v2/authorize?client_id=${config.CRONOFY_CLIENT_ID}&redirect_uri=${config.BASE_URL_WEB}/cronofy-token&response_type=code&scope=read_only%20write_only&avoid_linking=true`;

// const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
//   <HoverColorStyle
//     href=""
//     ref={ref}
//     onClick={e => {
//       e.preventDefault();
//       onClick(e);
//     }}
//   >
//     {children}
//   </HoverColorStyle>
// ));
export default function EventsView({ setReloadEvents }) {
  const [jobId, setJobId] = useState(null);
  const refSubmit = createRef();
  const dispatch = useDispatch();

  const {
    loading,
    listEvent,
    pages,
    openEdit,
    editingEventId,
    currentPage,
    openDelete,
  } = useSelector(({ event }) => event);
  const { profile } = useSelector(({ auth }) => auth);

  const handleChangePage = useCallback(
    newPage => {
      dispatch(setCurrentPage(newPage));
    },
    [dispatch],
  );

  const handleOpenEdit = useCallback(
    ({ id, job_id }) => {
      dispatch(openEditEvent({ openEdit: true, editingEventId: id }));
      setJobId(job_id);
    },
    [dispatch],
  );

  const handleCloseEdit = useCallback(() => {
    dispatch(openEditEvent({ openEdit: false, editingEventId: null }));
    setJobId(null);
  }, [dispatch]);

  const handleOpenDelete = useCallback(
    id => {
      dispatch(openDeleteEvent({ state: true, id }));
    },
    [dispatch],
  );

  const handleCloseDelete = useCallback(() => {
    dispatch(openDeleteEvent({ state: false, id: null }));
  }, [dispatch]);

  // const revokeCalendar = () => {
  //   dispatch(cronofyRevokeAuthorization());
  // };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <Title>All Events</Title>

        {/* {cronofyProfile && cronofyProfile.profile_connected ? (
          <OuterDiv>
            <div>
              <InnerDiv
                style={{
                  color: '#173264',
                  fontSize: 14,
                  fontFamily: 'Lato',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  display: 'flex',
                }}
              >
                <ProviderName style={{ textTransform: 'capitalize', marginRight: '5px' }}>
                  {cronofyProfile.provider_name}
                </ProviderName>
                <ProviderNameSub>calendar synced</ProviderNameSub>
              </InnerDiv>
              <ProviderNameSub
                style={{
                  color: '#425268',
                  fontSize: 14,
                  fontFamily: 'Lato',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                {cronofyProfile.profile_name}
              </ProviderNameSub>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  <FontAwesomeIcon icon={faEllipsisVertical} style={{ width: 24, height: 24 }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={revokeCalendar}>
                    <DropdownItemStyle>
                      <IconStyle
                        icon={faTrash}
                        style={{ width: 14, height: 14, color: '#DC3545' }}
                      />
                      <TextStyle>Remove calendar</TextStyle>
                    </DropdownItemStyle>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </OuterDiv>
        ) : (
          <ButtonStyle
            variant="outline-primary"
            // onClick={() => window.open(cronofy_url, '_blank')}
            className="d-flex"
          >
            <FontAwesomeIcon icon={faRotate} style={{ width: 24, height: 24 }} />
            <div className="landing-p">Sync calendar</div>
          </ButtonStyle>
        )} */}
      </div>
      <Table>
        <thead className="table-borderless">
          <tr style={{ borderColor: '#ffffff' }}>
            {headers.map(header => (
              <th key={header}>
                <TableHeaderStyle>{header}</TableHeaderStyle>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        {listEvent.length === 0 ? (
          <NoEvents />
        ) : (
          listEvent.map(item => (
            <EventItem
              key={item.id}
              item={item}
              handleOpenEdit={handleOpenEdit}
              handleOpenDelete={handleOpenDelete}
            />
          ))
        )}
      </Table>
      <Pagination currentPage={currentPage} changePage={handleChangePage} totalPages={pages} />
      <ModalComponent
        show={openEdit}
        onHide={handleCloseEdit}
        title={profile.role_id !== userRoleId.OWNER ? 'View event' : 'Edit event'}
        textCancel="Cancel"
        textSubmit="Save"
        onClickSubmit={() => refSubmit.current.click()}
        disabledCancel={profile.role_id !== userRoleId.OWNER}
        disabledSubmit={profile.role_id !== userRoleId.OWNER}
        loading={loading}
        body={
          <FormEditEvent
            refSubmit={refSubmit}
            jobId={jobId}
            id={editingEventId}
            handleCloseEdit={handleCloseEdit}
            setReloadEvents={setReloadEvents}
          />
        }
      />

      <ModalComponent
        show={openDelete.state}
        onHide={handleCloseDelete}
        loading={loading}
        size="md"
        onClickSubmit={() => {
          dispatch(deleteEvent(openDelete.id));
          handleChangePage(1);
          handleCloseDelete();
        }}
        title="Delete event"
        textCancel="Cancel"
        variantSubmit="danger"
        textSubmit="Delete"
        body={
          <>
            <ModalBodyStyle>Are you sure you want to delete this event?</ModalBodyStyle>
            <ModalWarningStyle>This action cannot be undone</ModalWarningStyle>
          </>
        }
      />
    </div>
  );
}

EventsView.propTypes = {
  setReloadEvents: PropTypes.func.isRequired,
};
