import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const buttonBaseStyles = {
  borderRadius: '40px',
  border: 'none',
  textAlign: 'center',
};
const buttonColorStyles = {
  primary: { backgroundColor: '#007BFF', color: '#FDFEFF' },
  secondary: { backgroundColor: '#E9F1FC', color: '#007BFF' },
};

const CustomButton = ({
  size,
  type,
  text,
  variant,
  customStyles,
  className,
  href,
  path,
  handleClick = null,
  font = 'landing-h8',
}) => {
  const buttonStyles = {
    ...buttonBaseStyles,
    ...(buttonColorStyles[variant] || {}),
    ...customStyles,
  };

  return (
    <>
      {href ? (
        <a href={href} className={`text-decoration-none ${className}`} style={buttonStyles}>
          <span className="landing-h8 px-2 py-1">{text}</span>
        </a>
      ) : (
        <Button
          variant={variant}
          size={size}
          type={type || 'submit'}
          className={`${className} py-2`}
          style={buttonStyles}
          path={path}
          onClick={handleClick}
        >
          <span className={`${font} px-2 py-4 `}>{text}</span>
        </Button>
      )}
    </>
  );
};

CustomButton.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
  customStyles: PropTypes.object,
  className: PropTypes.string,
  href: PropTypes.string,
  path: PropTypes.string,
  handleClick: PropTypes.func,
  font: PropTypes.string,
};

export { CustomButton };
