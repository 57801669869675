export const glassOverallThickness = [
  { value: '1', label: '1' },
  { value: '7/8', label: '7/8' },
  { value: '3/4', label: '3/4' },
  { value: '5/8', label: '5/8' },
  { value: '1/2', label: '1/2' },
  { value: '7/16', label: '7/16' },
  { value: '9/16', label: '9/16' },
  { value: '3/8', label: '3/8' },
  { value: '5/16', label: '5/16' },
  { value: '1/4', label: '1/4' },
  { value: '3/16', label: '3/16' },
  { value: '1/8', label: '1/8' },
  { value: '1/16', label: '1/16' },
  { value: '6', label: '6' },
  { value: '5 15/16', label: '5 15/16' },
  { value: '5 7/8', label: '5 7/8' },
  { value: '5 13/16', label: '5 13/16' },
  { value: '5 3/4', label: '5 3/4' },
  { value: '5 11/16', label: '5 11/16' },
  { value: '5 5/8', label: '5 5/8' },
  { value: '5 9/16', label: '5 9/16' },
  { value: '5 1/2', label: '5 1/2' },
  { value: '5 7/16', label: '5 7/16' },
  { value: '5 3/8', label: '5 3/8' },
  { value: '5 5/16', label: '5 5/16' },
  { value: '5 1/4', label: '5 1/4' },
  { value: '5 3/16', label: '5 3/16' },
  { value: '5 1/8', label: '5 1/8' },
  { value: '5 1/16', label: '5 1/16' },
  { value: '5', label: '5' },
  { value: '4 15/16', label: '4 15/16' },
  { value: '4 7/8', label: '4 7/8' },
  { value: '4 13/16', label: '4 13/16' },
  { value: '4 3/4', label: '4 3/4' },
  { value: '4 11/16', label: '4 11/16' },
  { value: '4 5/8', label: '4 5/8' },
  { value: '4 9/16', label: '4 9/16' },
  { value: '4 1/2', label: '4 1/2' },
  { value: '4 7/16', label: '4 7/16' },
  { value: '4 3/8', label: '4 3/8' },
  { value: '4 5/16', label: '4 5/16' },
  { value: '4 1/4', label: '4 1/4' },
  { value: '4 3/16', label: '4 3/16' },
  { value: '4 1/8', label: '4 1/8' },
  { value: '4 1/16', label: '4 1/16' },
  { value: '4', label: '4' },
  { value: '3 15/16', label: '3 15/16' },
  { value: '3 7/8', label: '3 7/8' },
  { value: '3 13/16', label: '3 13/16' },
  { value: '3 3/4', label: '3 3/4' },
  { value: '3 11/16', label: '3 11/16' },
  { value: '3 5/8', label: '3 5/8' },
  { value: '3 9/16', label: '3 9/16' },
  { value: '3 1/2', label: '3 1/2' },
  { value: '3 7/16', label: '3 7/16' },
  { value: '3 3/8', label: '3 3/8' },
  { value: '3 5/16', label: '3 5/16' },
  { value: '3 1/4', label: '3 1/4' },
  { value: '3 3/16', label: '3 3/16' },
  { value: '3 1/8', label: '3 1/8' },
  { value: '3 1/16', label: '3 1/16' },
  { value: '3', label: '3' },
  { value: '2 15/16', label: '2 15/16' },
  { value: '2 7/8', label: '2 7/8' },
  { value: '2 13/16', label: '2 13/16' },
  { value: '2 3/4', label: '2 3/4' },
  { value: '2 11/16', label: '2 11/16' },
  { value: '2 5/8', label: '2 5/8' },
  { value: '2 9/16', label: '2 9/16' },
  { value: '2 1/2', label: '2 1/2' },
  { value: '2 7/16', label: '2 7/16' },
  { value: '2 3/8', label: '2 3/8' },
  { value: '2 5/16', label: '2 5/16' },
  { value: '2 1/4', label: '2 1/4' },
  { value: '2 3/16', label: '2 3/16' },
  { value: '2 1/8', label: '2 1/8' },
  { value: '2 1/16', label: '2 1/16' },
  { value: '2', label: '2' },
  { value: '1 15/16', label: '1 15/16' },
  { value: '1 7/8', label: '1 7/8' },
  { value: '1 13/16', label: '1 13/16' },
  { value: '1 3/4', label: '1 3/4' },
  { value: '1 11/16', label: '1 11/16' },
  { value: '1 5/8', label: '1 5/8' },
  { value: '1 9/16', label: '1 9/16' },
  { value: '1 1/2', label: '1 1/2' },
  { value: '1 7/16', label: '1 7/16' },
  { value: '1 3/8', label: '1 3/8' },
  { value: '1 5/16', label: '1 5/16' },
  { value: '1 1/4', label: '1 1/4' },
  { value: '1 3/16', label: '1 3/16' },
  { value: '1 1/8', label: '1 1/8' },
  { value: '1 1/16', label: '1 1/16' },
  { value: '15/16', label: '15/16' },
  { value: '13/16', label: '13/16' },
  { value: '11/16', label: '11/16' },
];

export const elThickness = [
  { value: '1/16"', label: '1/16"' },
  { value: '1/8"', label: '1/8"' },
  { value: '3/16"', label: '3/16"' },
  { value: '1/4"', label: '1/4"' },
  { value: '3/8"', label: '3/8"' },
  { value: '1/2"', label: '1/2"' },
  { value: '5/8"', label: '5/8"' },
  { value: '3/4"', label: '3/4"' },
  { value: '1/4" LAMI (1/8, 015, 1/8)', label: '1/4" LAMI (1/8, 015, 1/8)' },
  { value: '1/4" LAMI  (1/8, 030,1/8)', label: '1/4" LAMI  (1/8, 030,1/8)' },
  { value: '5/16" LAMI  (1/8, 060, 1/8)', label: '5/16" LAMI  (1/8, 060, 1/8)' },
  { value: '5/16" LAMI (1/8, 090 ,1/8)', label: '5/16" LAMI (1/8, 090 ,1/8)' },
  { value: '3/8" LAMI (3/16, 030, 3/16)', label: '3/8" LAMI (3/16, 030, 3/16)' },
  { value: '3/8" LAMI (1/4, 030, 1/8)', label: '3/8" LAMI (1/4, 030, 1/8)' },
  { value: '3/8" LAMI (1/4, 060, 1/8)', label: '3/8" LAMI (1/4, 060, 1/8)' },
  { value: '7/16" LAMI (3/16, 060, 3/16)', label: '7/16" LAMI (3/16, 060, 3/16)' },
  { value: '7/16" LAMI (3/16, 090, 3/16)', label: '7/16" LAMI (3/16, 090, 3/16)' },
  { value: '1/2" LAMI (1/4, 030, 1/4)', label: '1/2" LAMI (1/4, 030, 1/4)' },
  { value: '1/2" LAMI (1/4, 060, 1/4)', label: '1/2" LAMI (1/4, 060, 1/4)' },
  { value: '9/16" LAMI (1/4, 060,1/4)', label: '9/16" LAMI (1/4, 060,1/4)' },
  { value: '9/16" LAMI (1/4, 090, 1/4)', label: '9/16" LAMI (1/4, 090, 1/4)' },
  { value: '5/8" LAMI (3/8, 030, 1/4)', label: '5/8" LAMI (3/8, 030, 1/4)' },
  { value: '3/4" LAMI (1/2, 060, 1/4)', label: '3/4" LAMI (1/2, 060, 1/4)' },
  { value: '13/16" LAMI (3/8, 060, 3/8)', label: '13/16" LAMI (3/8, 060, 3/8)' },
  { value: '13/16" LAMI (3/8, 090, 3/8)', label: '13/16" LAMI (3/8, 090, 3/8)' },
];
export const elTreatment = [
  { value: 'Annealed', label: 'Annealed' },
  { value: 'Lami', label: 'Lami' },
  { value: 'Lami (PVB)', label: 'Lami (PVB)' },
  { value: 'Lami (SGP)', label: 'Lami (SGP)' },
  { value: 'Tempered', label: 'Tempered' },
  { value: 'Tempered/Lami', label: 'Tempered/Lami' },
  { value: 'Tempered/Lami (PVB)', label: 'Tempered/Lami (PVB)' },
  { value: 'Tempered/Lami (SGP)', label: 'Tempered/Lami (SGP)' },
  { value: 'HeatStrengthened', label: 'HeatStrengthened' },
  { value: 'HeatStrengthened/Lami', label: 'HeatStrengthened/Lami' },
  { value: 'HeatStrengthened/Lami (PVB)', label: 'HeatStrengthened/Lami (PVB)' },
  { value: 'HeatStrengthened/Lami (SGP)', label: 'HeatStrengthened/Lami (SGP)' },
  { value: 'HeatSoaked', label: 'HeatSoaked' },
  { value: 'HeatSoaked/Lami (PVB)', label: 'HeatSoaked/Lami (PVB)' },
  { value: 'HeatSoaked/Lami (SGP)', label: 'HeatSoaked/Lami (SGP)' },
  { value: 'Polymer (plastic)', label: 'Polymer (plastic)' },
  { value: 'Annealed (cut on-site)', label: 'Annealed (cut on-site)' },
  { value: 'Lami (cut on-site)', label: 'Lami (cut on-site)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const elColor = [
  { value: 'Clear', label: 'Clear' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Gray', label: 'Gray ' },
  { value: 'Clear MIRROR', label: 'Clear MIRROR' },
  { value: 'Clear MIRROR w/SafetyBacking', label: 'Clear MIRROR w/SafetyBacking' },
  { value: 'Gray MIRROR', label: 'Gray MIRROR' },
  { value: 'Gray MIRROR w/SafetyBacking', label: 'Gray MIRROR w/SafetyBacking' },
  { value: 'Bronze MIRROR', label: 'Bronze MIRROR' },
  { value: 'Bronze MIRROR w/SafetyBacking', label: 'Bronze MIRROR w/SafetyBacking' },
  { value: 'MirrorPane (2WAY)', label: 'MirrorPane (2WAY)' },
  { value: 'MirrorView  (low ambient)', label: 'MirrorView  (low ambient)' },
  { value: 'MirrorView50/50 (high ambient)', label: 'MirrorView50/50 (high ambient)' },
  { value: 'Low Iron ', label: 'Low Iron' },
  { value: 'Starfire ', label: 'Starfire ' },
  { value: 'Optiwhite', label: 'Optiwhite' },
  { value: 'UltraClear', label: 'UltraClear' },
  { value: 'ExtraClear', label: 'ExtraClear' },
  { value: 'Acuity ', label: 'Acuity ' },
  { value: 'OptiGray (light)', label: 'OptiGray (light)' },
  { value: 'CrystalGray (light)', label: 'CrystalGray (light)' },
  { value: 'Majestic Grey (light)', label: 'Majestic Grey (light)' },
  { value: 'Graylite2 (black)', label: 'Graylite2 (black)' },
  { value: 'SuperGray (black)', label: 'SuperGray (black)' },
  { value: 'Midnight Gray (black) ', label: 'CleaMidnight Gray (black)' },
  { value: 'White', label: 'White' },
  { value: 'Acid/Satin Etch', label: 'Acid/Satin Etch' },
  { value: 'Acid/Satin Low Iron', label: 'Acid/Satin Low Iron' },
  { value: 'Acid/Satin Low Iron (Double Sided)', label: 'Acid/Satin Low Iron (Double Sided)' },
  { value: 'Green ', label: 'Green ' },
  { value: 'Solexia ', label: 'Solexia ' },
  { value: 'Atlantica', label: 'Atlantica' },
  { value: 'EverGreen', label: 'EverGreen' },
  { value: 'Pure Green ', label: 'Pure Green' },
  { value: 'Forrest Green ', label: 'Forrest Green' },
  { value: 'Blue ', label: 'Blue ' },
  { value: 'BlueGreen', label: 'BlueGreen' },
  { value: 'Azuria ', label: 'Azuria ' },
  { value: 'Pacifica ', label: 'Pacifica ' },
  { value: 'SolarBlue', label: 'SolarBlue' },
  { value: 'OptiBlue ', label: 'OptiBlue ' },
  { value: 'ArticBlue', label: 'ArticBlue' },
  { value: 'GraphiteBlue ', label: 'GraphiteBlue ' },
  { value: 'CrystalBlue', label: 'CrystalBlue' },
  { value: 'Sky Blue ', label: 'Sky Blue' },
  { value: 'Midnight Blue ', label: 'Midnight Blue' },
  { value: 'Pure Blue ', label: 'Pure Blue' },
  { value: 'Gold', label: 'Gold' },
  { value: 'SunsetGold', label: 'SunsetGold' },
  { value: '1-CLEAR', label: '1-CLEAR' },
  { value: '2-GREEN', label: '2-GREEN' },
  { value: '3-GRAY', label: '3-GRAY' },
  { value: '4-BRONZE', label: '4-BRONZE' },
  { value: '6-BLUE.GREEN', label: '6-BLUE.GREEN' },
  { value: '7-AZURIA', label: '7-AZURIA' },
  { value: '13-STARFIRE (low iron)', label: '13-STARFIRE (low iron)' },
  { value: '18- OPTIBLUE', label: '18- OPTIBLUE' },
  { value: '19-CRYSTALGRAY', label: '19-CRYSTALGRAY' },
  { value: '24-OPTIWHITE (low iron)', label: '24-OPTIWHITE (low iron)' },
  { value: '26-SOLARBLUE', label: '26-SOLARBLUE' },
  { value: '27-PACIFICA', label: '27-PACIFICA' },
  { value: '30-OPTIGRAY', label: '30-OPTIGRAY' },
  { value: '31-ULTRACLEAR (LOW IRON)', label: '31-ULTRACLEAR (LOW IRON)' },
  { value: '32-CRYSTALBLUE', label: '32-CRYSTALBLUE' },
  { value: '33-MAJESTICGREY', label: '33-MAJESTICGREY' },
  { value: '35- Pure Mid Iron', label: '35- Pure Mid Iron' },
  { value: '5-BLUE (NOT AVAILABLE) ', label: '5-BLUE (NOT AVAILABLE)' },
  { value: '8-EVERGREEN (NOT AVAILABLE) ', label: '8-EVERGREEN (NOT AVAILABLE)' },
  { value: '29-GRAPHITE (NOT AVAILABLE) ', label: '29-GRAPHITE (NOT AVAILABLE)' },
  { value: 'FireLite (20-90MIN)', label: 'FireLite (20-90MIN)' },
  { value: 'FireLitePlus (20-180MIN)', label: 'FireLitePlus (20-180MIN)' },
  { value: 'FireliteNT (20-180MIN)', label: 'FireliteNT (20-180MIN)' },
  { value: 'FireliteIGU (20-180MIN)', label: 'FireliteIGU (20-180MIN)' },
  { value: 'Wire - SQUARE Polished ', label: 'Wire - SQUARE Polished' },
  { value: 'Wire - SQUARE Patterned ', label: 'Wire - SQUARE Patterned' },
  { value: 'Wire - DIAMOND Polished ', label: 'Wire - DIAMOND Polished' },
  { value: 'Wire - DIAMOND Patterned ', label: 'Wire - DIAMOND Patterned' },
  { value: 'Wirelite (FIRE 45Min) ', label: 'Wirelite (FIRE 45Min)' },
  { value: 'Wirelite (FIRE 20-90Min) ', label: 'Wirelite (FIRE 20-90Min)' },
  { value: 'Altdeutsch K', label: 'Altdeutsch K' },
  { value: 'Antique', label: 'Antique' },
  { value: 'Aqualite', label: 'Aqualite' },
  { value: 'Aquatex', label: 'Aquatex' },
  { value: 'Arctic', label: 'Arctic' },
  { value: 'Artico', label: 'Artico' },
  { value: 'Austral ', label: 'Austral' },
  { value: 'Autumn', label: 'Autumn' },
  { value: 'Bamboo', label: 'Bamboo' },
  { value: 'Bubbles', label: 'Bubbles' },
  { value: 'Cast', label: 'Cast' },
  { value: 'Chantilly ', label: 'Chantilly' },
  { value: 'Charcoal Sticks ', label: 'Charcoal Sticks' },
  { value: 'Chinchilla', label: 'Chinchilla' },
  { value: 'Clear Antique', label: 'Clear Antique' },
  { value: 'Contora ', label: 'Contora' },
  { value: 'Corduroy', label: 'Corduroy' },
  { value: 'Cotswold ', label: 'Cotswold' },
  { value: 'Cross Reeded 1/2"', label: 'Cross Reeded 1/2"' },
  { value: 'Cross Reeded Small', label: 'Cross Reeded Small' },
  { value: 'Delta Frost', label: 'Delta Frost' },
  { value: 'Digital ', label: 'Digital' },
  { value: 'Everglade ', label: 'Everglade' },
  { value: 'Flemish', label: 'Flemish' },
  { value: 'Florielle ', label: 'Florielle' },
  { value: 'Frost Etch 100', label: 'Frost Etch 100' },
  { value: 'Frost Etch 20', label: 'Frost Etch 20' },
  { value: 'Glacier', label: 'Glacier' },
  { value: 'Glue Chip', label: 'Glue Chip' },
  { value: 'Master Carre', label: 'Master Carre' },
  { value: 'Master Ligne', label: 'Master Ligne' },
  { value: 'Master Point', label: 'Master Point' },
  { value: 'Master Ray', label: 'Master Ray' },
  { value: 'Mayflower', label: 'Mayflower' },
  { value: 'Minister', label: 'Minister' },
  { value: 'Monumental', label: 'Monumental' },
  { value: 'Morisco', label: 'Morisco' },
  { value: 'Oak ', label: 'Oak' },
  { value: 'Pattern 516', label: 'Pattern 516' },
  { value: 'Pattern 62', label: 'Pattern 62' },
  { value: 'Pelerine', label: 'Pelerine' },
  { value: 'Rain', label: 'Rain' },
  { value: 'Rayado', label: 'Rayado' },
  { value: 'Redded 1/2"', label: 'Redded 1/2"' },
  { value: 'Reeded', label: 'Reeded' },
  { value: 'Seedy', label: 'Seedy' },
  { value: 'Seedy Import', label: 'Seedy Import' },
  { value: 'Seedy Marine', label: 'Seedy Marine' },
  { value: 'Skytech ', label: 'Skytech' },
  { value: 'Soft Hammered', label: 'Soft Hammered' },
  { value: 'Sparkle', label: 'Sparkle' },
  { value: 'Spraylite', label: 'Spraylite' },
  { value: 'Stipolite', label: 'Stipolite' },
  { value: 'Sycamore ', label: 'Sycamore' },
  { value: 'Taffeta', label: 'Taffeta' },
  { value: 'Textured Flutes', label: 'Textured Flutes' },
  { value: 'Thin Cross Reeded 1/8"', label: 'Thin Cross Reeded 1/8"' },
  { value: 'Thin Reeded 1/8"', label: 'Thin Reeded 1/8"' },
  { value: 'Thin Ribbed', label: 'Thin Ribbed' },
  { value: 'Warwick', label: 'Warwick' },
  { value: 'Waterwave', label: 'Waterwave' },
  { value: 'Winterlake', label: 'Winterlake' },
  { value: 'Yacare', label: 'Yacare' },
  { value: 'Azzurro (Antique Mirror)', label: 'Azzurro (Antique Mirror)' },
  { value: 'Biranco.E.Nero (Antique Mirror)', label: 'Clear' },
  { value: 'Byzantine (Antique Mirror)', label: 'Byzantine (Antique Mirror)' },
  { value: 'Cometa (Antique Mirror)', label: 'Cometa (Antique Mirror)' },
  { value: 'Golden (Antique Mirror)', label: 'Golden (Antique Mirror)' },
  { value: 'Grigio Argento (Antique Mirror)', label: 'Grigio Argento (Antique Mirror)' },
  { value: 'Luna (Antique Mirror)', label: 'Luna (Antique Mirror)' },
  { value: 'Marbled (Antique Mirror)', label: 'Marbled (Antique Mirror)' },
  { value: 'Marbled Bronze (Antique Mirror)', label: 'Marbled Bronze (Antique Mirror)' },
  { value: 'Mercury (Antique Mirror)', label: 'Mercury (Antique Mirror)' },
  { value: 'Meterora (Antique Mirror)', label: 'Meterora (Antique Mirror)' },
  { value: 'Policromo (Antique Mirror)', label: 'Policromo (Antique Mirror)' },
  { value: 'Saturno (Antique Mirror)', label: 'Saturno (Antique Mirror)' },
  { value: 'Speckled (Antique Mirror)', label: 'Speckled (Antique Mirror)' },
  { value: 'Tuscana (Antique Mirror)', label: 'Tuscana (Antique Mirror)' },
  { value: 'Vintage (Antique Mirror)', label: 'Vintage (Antique Mirror)' },
  { value: 'Vintage Bronze (Antique Mirror)', label: 'Vintage Bronze (Antique Mirror)' },
];

export const elCoatingFamily = [
  { value: 'VS-20', label: 'VS-20' },
  { value: 'VS-14', label: 'VS-14' },
  { value: 'VS-08', label: 'VS-08' },
  { value: 'VP-22', label: 'VP-22' },
  { value: 'VP-18', label: 'VP-18' },
  { value: 'VP-13', label: 'VP-13' },
  { value: 'VE-85', label: 'VE-85' },
  { value: 'VE-2M', label: 'VE-2M' },
  { value: 'VE-48', label: 'VE-48' },
  { value: 'VE-45', label: 'VE-45' },
  { value: 'VE-42', label: 'VE-42' },
  { value: 'VRE-65', label: 'VRE-65' },
  { value: 'VRE-59', label: 'VRE-59' },
  { value: 'VRE-54', label: 'VRE-54' },
  { value: 'VRE-46', label: 'VRE-46' },
  { value: 'VRE-38', label: 'VRE-38' },
  { value: 'VUE-50', label: 'VUE-50' },
  { value: 'VUE-40', label: 'VUE-40' },
  { value: 'VUE-30', label: 'VUE-30 ' },
  { value: 'VNE-63', label: 'VNE-63' },
  { value: 'VNE-53', label: 'VNE-53' },
  { value: 'VLE-70', label: 'VLE-70' },
  { value: 'VLE-57', label: 'VLE-57' },
  { value: 'VLE-51', label: 'VLE-51' },
  { value: 'VLE-47', label: 'VLE-47' },
  { value: 'VLE-39', label: 'VLE-39' },
];

export const elSide = [
  { value: 'NA', label: 'NA' },
  { value: '#1', label: '#1' },
  { value: '#2', label: '#2' },
  { value: '#3', label: '#3' },
  { value: '#4', label: '#4' },
  { value: '#5', label: '#5' },
  { value: '#6', label: '#6' },
];

export const elCoating = [
  { value: 'Low-E', label: 'Low-E' },
  { value: 'Solarban60', label: 'Solarban60' },
  { value: 'SolarbanZ50', label: 'SolarbanZ50' },
  { value: 'Solarban67', label: 'Solarban67' },
  { value: 'Solarban70', label: 'Solarban70' },
  { value: 'Solarban72', label: 'Solarban72' },
  { value: 'SolarbanZ75', label: 'SolarbanZ75' },
  { value: 'Solarban90', label: 'Solarban90' },
  { value: 'SolarbanR100', label: 'SolarbanR100' },
  { value: 'Sungate400', label: 'Sungate400' },
  { value: 'SolarCool', label: 'SolarCool' },
  { value: 'VistaCool', label: 'VistaCool' },
  { value: 'VS-20', label: 'VS-20' },
  { value: 'VS-14', label: 'VS-14' },
  { value: 'VS-08', label: 'VS-08' },
  { value: 'VP-22', label: 'VP-22' },
  { value: 'VP-18', label: 'VP-18' },
  { value: 'VP-13', label: 'VP-13' },
  { value: 'VE-85', label: 'VE-85' },
  { value: 'VE-2M', label: 'VE-2M' },
  { value: 'VE-48', label: 'VE-48' },
  { value: 'VE-45', label: 'VE-45' },
  { value: 'VE-42', label: 'VE-42' },
  { value: 'VRE-65', label: 'VRE-65' },
  { value: 'VRE-59', label: 'VRE-59' },
  { value: 'VRE-54', label: 'VRE-54' },
  { value: 'VRE-46', label: 'VRE-46' },
  { value: 'VRE-38', label: 'VRE-38' },
  { value: 'VRE-4725', label: 'VRE-4725' },
  { value: 'VUE-50', label: 'VUE-50' },
  { value: 'VUE-40', label: 'VUE-40' },
  { value: 'VUE-30', label: 'VUE-30' },
  { value: 'VNE-63', label: 'VNE-63' },
  { value: 'VNE-53', label: 'VNE-53' },
  { value: 'VLE-70', label: 'VLE-70' },
  { value: 'VLE-57', label: 'VLE-57' },
  { value: 'VLE-51', label: 'VLE-51' },
  { value: 'VLE-47', label: 'VLE-47' },
  { value: 'VLE-39', label: 'VLE-39' },
  { value: 'VT-08 (RETIRED)', label: 'VT-08 (RETIRED)' },
  { value: 'VT-14 (RETIRED)', label: 'VT-14 (RETIRED)' },
  { value: 'VT-20  (RETIRED)', label: 'VT-20  (RETIRED)' },
  { value: 'VT-24 (RETIRED)', label: 'VT-24 (RETIRED)' },
  { value: 'VT-30 (RETIRED)', label: 'VT-30 (RETIRED)' },
  { value: 'VT-35 (RETIRED)', label: 'VT-35 (RETIRED)' },
  { value: 'VT-35 (RETIRED)', label: 'VT-35 (RETIRED)' },
  { value: 'VA-08 (RETIRED)', label: 'VA-08 (RETIRED)' },
  { value: 'VA-14 (RETIRED)', label: 'VA-14 (RETIRED)' },
  { value: 'VA-20 (RETIRED)', label: 'VA-20 (RETIRED)' },
  { value: 'VA-22 (RETIRED)', label: 'VA-22 (RETIRED)' },
  { value: 'VA-24 (RETIRED)', label: 'VA-24 (RETIRED)' },
  { value: 'VA-30 (RETIRED)', label: 'VA-30 (RETIRED)' },
  { value: 'VA-35 (RETIRED)', label: 'VA-35 (RETIRED)' },
  { value: 'VA-40 (RETIRED)', label: 'VA-40 (RETIRED)' },
  { value: 'VB-08 (RETIRED)', label: 'VB-08 (RETIRED)' },
  { value: 'VB-14 (RETIRED)', label: 'VB-14 (RETIRED)' },
  { value: 'VB-20 (RETIRED)', label: 'VB-20 (RETIRED)' },
  { value: 'VB-24 (RETIRED)', label: 'VB-24 (RETIRED)' },
  { value: 'VB-30 (RETIRED)', label: 'VB-30 (RETIRED)' },
  { value: 'VB-35 (RETIRED)', label: 'VB-35 (RETIRED)' },
  { value: 'VB-40 (RETIRED)', label: 'VB-40 (RETIRED)' },
  { value: 'VB-40 (RETIRED)', label: 'VB-40 (RETIRED)' },
  { value: 'VC-08 (RETIRED)', label: 'VC-08 (RETIRED)' },
  { value: 'VC-14 (RETIRED)', label: 'VC-14 (RETIRED)' },
  { value: 'VC-20 (RETIRED)', label: 'VC-20 (RETIRED)' },
  { value: 'VC-24 (RETIRED)', label: 'VC-24 (RETIRED)' },
  { value: 'VC-30 (RETIRED)', label: 'VC-30 (RETIRED)' },
  { value: 'VC-35 (RETIRED)', label: 'VC-35 (RETIRED)' },
  { value: 'VC-40 (RETIRED)', label: 'VC-40 (RETIRED)' },
  { value: 'VG-08 (RETIRED)', label: 'VG-08 (RETIRED)' },
  { value: 'VG-14 (RETIRED)', label: 'VG-14 (RETIRED)' },
  { value: 'VG-20 (RETIRED)', label: 'VG-20 (RETIRED)' },
  { value: 'VG-24 (RETIRED)', label: 'VG-24 (RETIRED)' },
  { value: 'VG-30 (RETIRED)', label: 'VG-30 (RETIRED)' },
  { value: 'VG-35 (RETIRED)', label: 'VG-35 (RETIRED)' },
  { value: 'VG-40 (RETIRED)', label: 'VG-40 (RETIRED)' },
  { value: 'VW-08 (RETIRED)', label: 'VW-08 (RETIRED)' },
  { value: 'VW-14 (RETIRED)', label: 'VW-14 (RETIRED)' },
  { value: 'VW-20 (RETIRED)', label: 'VW-20 (RETIRED)' },
  { value: 'VW-24 (RETIRED)', label: 'VW-24 (RETIRED)' },
  { value: 'VW-30 (RETIRED)', label: 'VW-30 (RETIRED)' },
  { value: 'VW-35 (RETIRED)', label: 'VW-35 (RETIRED)' },
  { value: 'VW-40 (RETIRED)', label: 'VW-40 (RETIRED)' },
  { value: 'VSE-30 (RETIRED)', label: 'VSE-30 (RETIRED)' },
  { value: 'Activ', label: 'Activ' },
  { value: 'Eclipse', label: 'Eclipse' },
  { value: 'EclipseAdvantage ', label: 'EclipseAdvantage ' },
  { value: 'EnergyAdvantage ', label: 'EnergyAdvantage ' },
  { value: 'SolarE', label: 'SolarE' },
  { value: 'AG43', label: 'AG43' },
  { value: 'AG50', label: 'AG50' },
  { value: 'SN75', label: 'SN75' },
  { value: 'SN75HT', label: 'SN75HT' },
  { value: 'SN70', label: 'SN70' },
  { value: 'SN70/35', label: 'SN70/35' },
  { value: 'SN70/37', label: 'SN70/37' },
  { value: 'SN68', label: 'SN68' },
  { value: 'SN63', label: 'SN63' },
  { value: 'SN63HT', label: 'SN63HT' },
  { value: 'SN51/28', label: 'SN51/28' },
  { value: 'SN40/23', label: 'SN40/23' },
  { value: 'SN29/18', label: 'SN29/18' },
  { value: 'SNL68', label: 'SNL68' },
  { value: 'SNR43', label: 'SNR43' },
  { value: 'SNX50', label: 'SNX50' },
  { value: 'SNX51/23', label: 'SNX51/23' },
  { value: 'SNX60', label: 'SNX60' },
  { value: 'SNX62/27', label: 'SNX62/27' },
  { value: 'SNX-L62/34', label: 'SNX-L62/34' },
  { value: 'HP LightBlue62/52', label: 'HP LightBlue62/52' },
  { value: 'HP Neutral60/40 ', label: 'HP Neutral60/40 ' },
  { value: 'HP Neutral50/32', label: 'HP Neutral50/32' },
  { value: 'HP Neutral41/33', label: 'HP Neutral41/33' },
  { value: 'Neutral78/65', label: 'Neutral78/65' },
  { value: 'Neutral50', label: 'Neutral50' },
  { value: 'Neutral40', label: 'Neutral40' },
  { value: 'HP Silver43/31', label: 'HP Silver43/31' },
  { value: 'HP RoyalBlue41/29', label: 'HP RoyalBlue41/29' },
  { value: 'HP Amber41/29', label: 'HP Amber41/29' },
  { value: 'HP BrightGreen40/29', label: 'HP BrightGreen40/29' },
  { value: 'HP Bronze40/27', label: 'HP Bronze40/27' },
  { value: 'HP Silver35/26', label: 'HP Silver35/26' },
  { value: 'SolarBronze20', label: 'SolarBronze20' },
  { value: 'SolarGray20', label: 'SolarGray20' },
  { value: 'SolarGold20', label: 'SolarGold20' },
  { value: 'SolarBrightGreen20', label: 'SolarBrightGreen20' },
  { value: 'HD Silver70', label: 'HD Silver70' },
  { value: 'HD Neutral67', label: 'HD Neutral67' },
  { value: 'HD Diamond66', label: 'HD Diamond66' },
  { value: 'HD LightBlue52', label: 'HD LightBlue52' },
  { value: 'HD RoyalBlue20', label: 'HD RoyalBlue20' },
  { value: 'HD SilverGray32', label: 'HD SilverGray32' },
  { value: 'HD Silver20', label: 'HD Silver20' },
  { value: 'HD Silver10', label: 'HD Silver10' },
  { value: 'ClimaGuard53/23', label: 'ClimaGuard53/23' },
  { value: 'ClimaGuard55/27', label: 'ClimaGuard55/27' },
  { value: 'ClimaGuard62/27', label: 'ClimaGuard62/27' },
  { value: 'ClimaGuard70/36', label: 'ClimaGuard70/36' },
  { value: 'ClimaGuard72/57', label: 'ClimaGuard72/57' },
  { value: 'ClimaGuard80/70', label: 'ClimaGuard80/70' },
  { value: 'ClimaGuardiS-20', label: 'ClimaGuardiS-20' },
  { value: 'N70/38 (SB60)', label: 'N70/38 (SB60)' },
  { value: 'R53/33', label: 'R53/33' },
  { value: 'R43/28', label: 'R43/28' },
  { value: 'R47/31', label: 'R47/31' },
  { value: 'R53/33', label: 'R53/33' },
  { value: 'LoĒ-i81', label: 'LoĒ-i81' },
  { value: 'LoĒ-i88', label: 'LoĒ-i88' },
  { value: 'LoĒ-x89', label: 'LoĒ-x89' },
  { value: 'LoĒ-180', label: 'LoĒ-180' },
  { value: 'LoĒ2-270', label: 'LoĒ2-270' },
  { value: 'LoĒ2-272', label: 'LoĒ2-272' },
  { value: 'LoĒ3-340', label: 'LoĒ3-340' },
  { value: 'LoĒ3-366', label: 'LoĒ3-366' },
  { value: 'ShowerGuard', label: 'ShowerGuard' },
  { value: 'EnduroShield', label: 'EnduroShield' },
  { value: 'Black SPANDREL (PLK, VIR) ', label: 'Black SPANDREL (PLK, VIR) ' },
  { value: 'Blue Frost SPANDREL (VIR) ', label: 'Blue Frost SPANDREL (VIR) ' },
  { value: 'Blue SPANDREL (VIR) ', label: 'Blue SPANDREL (VIR) ' },
  { value: 'Bronze SPANDREL (VIR) ', label: 'Bronze SPANDREL (VIR) ' },
  { value: 'Bronze Tone SPANDREL (PLK) ', label: 'Bronze Tone SPANDREL (PLK) ' },
  { value: 'Cactus Green SPANDREL (PLK) ', label: 'Cactus Green SPANDREL (PLK) ' },
  { value: 'Charcoal SPANDREL  (OBE) ', label: 'Charcoal SPANDREL  (OBE) ' },
  { value: 'Dark Bronze SPANDREL (VIR) ', label: 'Dark Bronze SPANDREL (VIR) ' },
  { value: 'Dark Gray  SPANDREL (VIR)', label: 'Dark Gray  SPANDREL (VIR)' },
  { value: 'Etch White SPANDREL (PLK) ', label: 'Etch White SPANDREL (PLK) ' },
  { value: 'EverGreen SPANDREL (OBE, VIR) ', label: 'EverGreen SPANDREL (OBE, VIR) ' },
  { value: 'Fog Gray  SPANDREL (VIR)', label: 'Fog Gray  SPANDREL (VIR)' },
  { value: 'Ford Blue SPANDREL (OBE) ', label: 'Ford Blue SPANDREL (OBE) ' },
  { value: 'Graphite SPANDREL (PLK) ', label: 'Graphite SPANDREL (PLK) ' },
  { value: 'Gray Black SPANDREL (OBE) ', label: 'Gray Black SPANDREL (OBE) ' },
  { value: 'Gray SPANDREL (VIR, PLK) ', label: 'Gray SPANDREL (VIR, PLK) ' },
  { value: 'Harmonic Blue SPANDREL (PLK) ', label: 'Harmonic Blue SPANDREL (PLK) ' },
  { value: 'Harmony Bronze SPANDREL', label: 'Harmony Bronze SPANDREL' },
  { value: 'High-Opacity White SPANDREL (VIR) ', label: 'High-Opacity White SPANDREL (VIR) ' },
  { value: 'Lava Bronze SPANDREL (OBE) ', label: 'Lava Bronze SPANDREL (OBE) ' },
  { value: 'Medium Gray SPANDREL (VIR) ', label: 'Medium Gray SPANDREL (VIR) ' },
  { value: 'Opaque White  SPANDREL (PLK)', label: 'Opaque White  SPANDREL (PLK)' },
  { value: 'Sage Green SPANDREL  (VIR)', label: 'Sage Green SPANDREL  (VIR)' },
  {
    value: 'Simulated Etch White SPANDREL  (PLK) ',
    label: 'Simulated Etch White SPANDREL  (PLK) ',
  },
  { value: 'SolarBronze SPANDREL  (OBE)', label: 'SolarBronze SPANDREL  (OBE)' },
  { value: 'SolarGray SPANDREL (OBE) ', label: 'SolarGray SPANDREL (OBE) ' },
  { value: 'Solex SPANDREL (OBE) ', label: 'Solex SPANDREL (OBE) ' },
  { value: 'Subdued Bronze SPANDREL (VIR) ', label: 'Subdued Bronze SPANDREL (VIR) ' },
  { value: 'Subdued Gray SPANDREL (VIR) ', label: 'Subdued Gray SPANDREL (VIR) ' },
  { value: 'Warm Gray SPANDREL (OBE, VIR) ', label: 'Warm Gray SPANDREL (OBE, VIR) ' },
  { value: 'White SPANDREL (OBE) ', label: 'White SPANDREL (OBE) ' },
  { value: 'Simulated Acid-Etch SPANDREL (VIR) ', label: 'Simulated Acid-Etch SPANDREL (VIR) ' },
  { value: 'Simulated Sandblast SPANDREL (VIR)', label: 'Simulated Sandblast SPANDREL (VIR)' },
  { value: 'Spice  SPANDREL (VIR)', label: 'Spice  SPANDREL (VIR)' },
  { value: 'Yellow Moon SPANDREL (VIR) ', label: 'Yellow Moon SPANDREL (VIR) ' },
  { value: '20%= 1/8 LINES (OBE)', label: '20%= 1/8 LINES (OBE)' },
  { value: '20%= 1/8 DOTS (OBE)', label: '20%= 1/8 DOTS (OBE)' },
  { value: '20%= 1/8 HOLES (OBE)', label: '20%= 1/8 HOLES (OBE)' },
  { value: '40%= 1/8 DOTS (OBE)', label: '40%= 1/8 DOTS (OBE)' },
  { value: '40%= 1/8 HOLES (OBE)', label: '40%= 1/8 HOLES (OBE)' },
  { value: '50%= 1/8 LINES (OBE)', label: '50%= 1/8 LINES (OBE)' },
  { value: '60%= 1/8 DOTS (OBE)', label: '60%= 1/8 DOTS (OBE)' },
  { value: '60%= 1/8 HOLES (OBE)', label: '60%= 1/8 HOLES (OBE)' },
  { value: '1/2 LINES ON 1” CENTERS  (OBE)', label: '1/2 LINES ON 1” CENTERS  (OBE)' },
  { value: '20%= 1/8” LINES: 2256  (VIR)', label: '20%= 1/8” LINES: 2256  (VIR)' },
  { value: '20%= 1/8” DOTS: 5065  (VIR)', label: '20%= 1/8” DOTS: 5065  (VIR)' },
  { value: '30%= 1/8” LINES:2973 (VIR)', label: '30%= 1/8” LINES:2973 (VIR)' },
  { value: '30%= 1/8” DOTS: 5959 (VIR)', label: '30%= 1/8” DOTS: 5959 (VIR)' },
  { value: '40%= 1/8” LINES : 2030 (VIR)', label: '40%= 1/8” LINES : 2030 (VIR)' },
  { value: '40%= 1/8” DOTS : 5006 (VIR)', label: '40%= 1/8” DOTS : 5006 (VIR)' },
  { value: '40%= PATTERN: 6017 (VIR)', label: '40%= PATTERN: 6017 (VIR)' },
  { value: '50%= 1/8” LINES: 2002 (VIR)', label: '50%= 1/8” LINES: 2002 (VIR)' },
  { value: '50%= 1" LINES: 2013 (VIR)', label: '50%= 1" LINES: 2013 (VIR)' },
  { value: '50%= 1/2” LINES: 2032 (VIR)', label: '50%= 1/2” LINES: 2032 (VIR)' },
  { value: '50%= 1/4” LINES: 2050 (VIR)', label: '50%= 1/4” LINES: 2050 (VIR)' },
  { value: '50%= 1/4” DOTS: 5960 (VIR)', label: '50%= 1/4” DOTS: 5960 (VIR)' },
  { value: '50%= Pattern: 6002 (VIR)', label: '50%= Pattern: 6002 (VIR)' },
  { value: '50%= Pattern: 6002 (VIR)', label: '50%= Pattern: 6002 (VIR)' },
  { value: '60%= 1/8” HOLES: 5023 (VIR)', label: '60%= 1/8” HOLES: 5023 (VIR)' },
  { value: '60%= 1/8” HOLES: 5023 (VIR)', label: '60%= 1/8” HOLES: 5023 (VIR)' },
  { value: '60%= Pattern: 6019 (VIR)', label: '60%= Pattern: 6019 (VIR)' },
  { value: '70%= Pattern: 6015 (VIR)', label: '70%= Pattern: 6015 (VIR)' },
];

export const glassSpacerColor = [
  { value: 'Clear', label: 'Clear' },
  { value: 'Black', label: 'Black' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Clear (Low Profile)', label: 'Clear (Low Profile)' },
  { value: 'Black (Low Profile)', label: 'Black (Low Profile)' },
  { value: 'Bronze (Low Profile)', label: 'Bronze (Low Profile)' },
  { value: 'Mill - Aluminum', label: 'Mill - Aluminum' },
  { value: 'Mill - Stainless Steel', label: 'Mill - Stainless Steel' },
  { value: 'Black - Aluminum', label: 'Black - Aluminum' },
  { value: 'Black - Stainless Steel', label: 'Black - Stainless Steel' },
  { value: 'Black - Warm Edge', label: 'Black - Warm Edge' },
  { value: 'VTS - Warm Edge (BLACK)', label: 'VTS - Warm Edge (BLACK)' },
  { value: 'K4SBK - Warm Edge (BLACK)', label: 'K4SBK - Warm Edge (BLACK)' },
  { value: 'Super Spacer', label: 'Super Spacer' },
  { value: 'Super Spacer - Premium Enhanced', label: 'Super Spacer - Premium Enhanced' },
  {
    value: 'Super Spacer -Super Spacer - Premium Enhanced+',
    label: 'Super Spacer -Super Spacer - Premium Enhanced+',
  },
  { value: 'Super Spacer -T-Spacer', label: 'Super Spacer -T-Spacer' },
  { value: 'Super Spacer -TriSeal', label: 'Super Spacer -TriSeal' },
];

export const extraValues = {
  storefront_door_beads_1_over_4: { width: '5/8', height: '5/8' },
  storefront_door_beads_1: { width: '7/8', height: '7/8' },
  screw_beads_metal: { width: '3/4', height: '3/4' },
  'snap-beads-aluminium-interior': { width: '3/4', height: '3/4' },
  'snap-beads-vinyl-interior': { width: '3/4', height: '3/4' },
  'snap-beads-aluminium_exterior': { width: '3/4', height: '3/4' },
  snap_beads_vinyl_exterior: { width: '3/4', height: '3/4' },
  snap_beads_wood_interior: { width: '3/4', height: '3/4' },
  snap_beads_wood_exterior: { width: '3/4', height: '3/4' },
  flush_1_over_4_top_exterior: { width: '5/8', height: '5/8' },
  flush_1_over_4_top_interior: { width: '5/8', height: '5/8' },
  flush_1_over_4_bottom_exterior: { width: '5/8', height: '5/8' },
  flush_1_over_4_bottom_interior: { width: '5/8', height: '5/8' },
  flush_1_over_4_left_right_exterior: { width: '5/8', height: '5/8' },
  flush_1_over_4_left_right_interior: { width: '5/8', height: '5/8' },
  flush_igu_top_exterior: { width: '7/8', height: '7/8' },
  flush_igu_top_interior: { width: '7/8', height: '7/8' },
  flush_igu_bottom_exterior: { width: '7/8', height: '7/8' },
  flush_igu_bottom_interior: { width: '7/8', height: '7/8' },
  flush_igu_left_right_exterior: { width: '7/8', height: '7/8' },
  flush_igu_left_right_interior: { width: '7/8', height: '7/8' },
  wrap_around_window_vinyl_single_pane: { width: '3/4', height: '3/4' },
  wrap_around_window_aluminum_single_pane: { width: '3/4', height: '3/4' },
  wrap_around_window_wood_single_pane: { width: '3/4', height: '3/4' },
  wrap_around_window_vinyl_igu: { width: '7/8', height: '7/8' },
  wrap_around_window_aluminum_igu: { width: '7/8', height: '7/8' },
  wrap_around_window_wood_igu: { width: '7/8', height: '7/8' },
  wrap_around_patio_door_single_pane: { width: '3/4', height: '3/4' },
  wrap_around_patio_door_igu: { width: '1', height: '1' },
  curtain_wall_all_sides_captured_exterior: { width: '1', height: '1' },
  curtain_wall_all_sides_captured_interior: { width: '1', height: '1' },
  curtain_wall_butt_glazed_left_only: { width: '1 1/2', height: '1' },
  curtain_wall_butt_glazed_right_only: { width: '1 1/2', height: '1' },
  curtain_wall_butt_glazed_left_right: { width: '2', height: '1' },
  curtain_wall_butt_glazed_top_only: { width: '1', height: '1 1/2' },
  curtain_wall_butt_glazed_bottom_only: { width: '1', height: '1 1/2' },
  curtain_wall_butt_glazed_top_bottom: { width: '1', height: '2' },
  structurally_glazed_all_sides_butt_glazed: { width: '2', height: '2' },
  zipper_wall: { width: '3/4', height: '3/4' },
  u_channel_b_t: { width: '0', height: '1 1/4' },
  seamless_mullion: { width: '7/8', height: '7/8' },
  screw_beads: { width: '3/4', height: '3/4' },
  sash_and_division_bar: { width: '3/4', height: '3/4' },
  sash_single_pane: { width: '5/8', height: '5/8' },
  sagh_igu: { width: '7/8', height: '7/8' },
  lug_sash: { width: '3/4', height: '3/4' },
  hack_and_glaze_steel_interior: { width: '4', height: '4' },
  hack_and_glaze_steel_exterior: { width: '4', height: '4' },
  hack_and_glaze_wood_interior: { width: '4', height: '4' },
  hack_and_glaze_wood_exterior: { width: '4', height: '4' },
  table_top: { width: '0', height: '0' },
  handrail: { width: '0', height: '0' },
  stand_Off: { width: '0', height: '0' },
  lay_in_canopy: { width: '0', height: '0' },
  vision_kit_all_new: { width: '0', height: '0' },
  vision_kit_glass_only: { width: '3/4', height: '3/4' },
  j_channel_b: { width: '0', height: '0' },
  j_channel_t: { width: '0', height: '0' },
  j_channel_t_b: { width: '0', height: '0' },
  j_channel_t_b_l_r: { width: '0', height: '0' },
  l_channel_b: { width: '0', height: '0' },
  l_channel_t: { width: '0', height: '0' },
  l_channel_t_b: { width: '0', height: '0' },
  l_channel_t_b_l_r: { width: '0', height: '0' },
  caulk_mastic_only: { width: '0', height: '0' },
};

export const extraMeasurments = [
  { extra_height: '0', extra_width: '0', value: '0', label: '0' },
  { extra_height: '1/16', extra_width: '1/16', value: '1/16', label: '1/16' },
  { extra_height: '1/8', extra_width: '1/8', value: '1/8', label: '1/8' },
  { extra_height: '3/16', extra_width: '3/16', value: '3/16', label: '3/16' },
  { extra_height: '1/4', extra_width: '1/4', value: '1/4', label: '1/4' },
  { extra_height: '5/16', extra_width: '5/16', value: '5/16', label: '5/16' },
  { extra_height: '3/8', extra_width: '3/8', value: '3/8', label: '3/8' },
  { extra_height: '7/16', extra_width: '7/16', value: '7/16', label: '7/16' },
  { extra_height: '1/2', extra_width: '1/2', value: '1/2', label: '1/2' },
  { extra_height: '9/16', extra_width: '9/16', value: '9/16', label: '9/16' },
  { extra_height: '5/8', extra_width: '5/8', value: '5/8', label: '5/8' },
  { extra_height: '11/16', extra_width: '11/16', value: '11/16', label: '11/16' },
  { extra_height: '3/4', extra_width: '3/4', value: '3/4', label: '3/4' },
  { extra_height: '13/16', extra_width: '13/16', value: '13/16', label: '13/16' },
  { extra_height: '7/8', extra_width: '7/8', value: '7/8', label: '7/8' },
  { extra_height: '15/16', extra_width: '15/16', value: '15/16', label: '15/16' },
  { extra_height: '1', extra_width: '1', value: '1', label: '1' },
  { extra_height: '1 1/16', extra_width: '1 1/16', value: '1 1/16', label: '1 1/16' },
  { extra_height: '1 1/8', extra_width: '1 1/8', value: '1 1/8', label: '1 1/8' },
  { extra_height: '1 3/16', extra_width: '1 3/16', value: '1 3/16', label: '1 3/16' },
  { extra_height: '1 1/4', extra_width: '1 1/4', value: '1 1/4', label: '1 1/4' },
  { extra_height: '1 5/16', extra_width: '1 5/16', value: '1 5/16', label: '1 5/16' },
  { extra_height: '1 3/8', extra_width: '1 3/8', value: '1 3/8', label: '1 3/8' },
  { extra_height: '1 7/16', extra_width: '1 7/16', value: '1 7/16', label: '1 7/16' },
  { extra_height: '1 1/2', extra_width: '1 1/2', value: '1 1/2', label: '1 1/2' },
  { extra_height: '1 9/16', extra_width: '1 9/16', value: '1 9/16', label: '1 9/16' },
  { extra_height: '1 5/8', extra_width: '1 5/8', value: '1 5/8', label: '1 5/8' },
  { extra_height: '1 11/16', extra_width: '1 11/16', value: '1 11/16', label: '1 11/16' },
  { extra_height: '1 3/4', extra_width: '1 3/4', value: '1 3/4', label: '1 3/4' },
  { extra_height: '1 13/16', extra_width: '1 13/16', value: '1 13/16', label: '1 13/16' },
  { extra_height: '1 7/8', extra_width: '1 7/8', value: '1 7/8', label: '1 7/8' },
  { extra_height: '1 15/16', extra_width: '1 15/16', value: '1 15/16', label: '1 15/16' },
  { extra_height: '2', extra_width: '2', value: '2', label: '2' },
  { extra_height: '2 1/16', extra_width: '2 1/16', value: '2 1/16', label: '2 1/16' },
  { extra_height: '2 1/8', extra_width: '2 1/8', value: '2 1/8', label: '2 1/8' },
  { extra_height: '2 3/16', extra_width: '2 3/16', value: '2 3/16', label: '2 3/16' },
  { extra_height: '2 1/4', extra_width: '2 1/4', value: '2 1/4', label: '2 1/4' },
  { extra_height: '2 5/16', extra_width: '2 5/16', value: '2 5/16', label: '2 5/16' },
  { extra_height: '2 3/8', extra_width: '2 3/8', value: '2 3/8', label: '2 3/8' },
  { extra_height: '2 7/16', extra_width: '2 7/16', value: '2 7/16', label: '2 7/16' },
  { extra_height: '2 1/2', extra_width: '2 1/2', value: '2 1/2', label: '2 1/2' },
  { extra_height: '2 9/16', extra_width: '2 9/16', value: '2 9/16', label: '2 9/16' },
  { extra_height: '2 5/8', extra_width: '2 5/8', value: '2 5/8', label: '2 5/8' },
  { extra_height: '2 11/16', extra_width: '2 11/16', value: '2 11/16', label: '2 11/16' },
  { extra_height: '2 3/4', extra_width: '2 3/4', value: '2 3/4', label: '2 3/4' },
  { extra_height: '2 13/16', extra_width: '2 13/16', value: '2 13/16', label: '2 13/16' },
  { extra_height: '2 7/8', extra_width: '2 7/8', value: '2 7/8', label: '2 7/8' },
  { extra_height: '2 15/16', extra_width: '2 15/16', value: '2 15/16', label: '2 15/16' },
  { extra_height: '3', extra_width: '3', value: '3', label: '3' },
  { extra_height: '3 1/16', extra_width: '3 1/16', value: '3 1/16', label: '3 1/16' },
  { extra_height: '3 1/8', extra_width: '3 1/8', value: '3 1/8', label: '3 1/8' },
  { extra_height: '3 3/16', extra_width: '3 3/16', value: '3 3/16', label: '3 3/16' },
  { extra_height: '3 1/4', extra_width: '3 1/4', value: '3 1/4', label: '3 1/4' },
  { extra_height: '3 5/16', extra_width: '3 5/16', value: '3 5/16', label: '3 5/16' },
  { extra_height: '3 3/8', extra_width: '3 3/8', value: '3 3/8', label: '3 3/8' },
  { extra_height: '3 7/16', extra_width: '3 7/16', value: '3 7/16', label: '3 7/16' },
  { extra_height: '3 1/2', extra_width: '3 1/2', value: '3 1/2', label: '3 1/2' },
  { extra_height: '3 9/16', extra_width: '3 9/16', value: '3 9/16', label: '3 9/16' },
  { extra_height: '3 5/8', extra_width: '3 5/8', value: '3 5/8', label: '3 5/8' },
  { extra_height: '3 11/16', extra_width: '3 11/16', value: '3 11/16', label: '3 11/16' },
  { extra_height: '3 3/4', extra_width: '3 3/4', value: '3 3/4', label: '3 3/4' },
  { extra_height: '3 13/16', extra_width: '3 13/16', value: '3 13/16', label: '3 13/16' },
  { extra_height: '3 7/8', extra_width: '3 7/8', value: '3 7/8', label: '3 7/8' },
  { extra_height: '3 15/16', extra_width: '3 15/16', value: '3 15/16', label: '3 15/16' },
  { extra_height: '4', extra_width: '4', value: '4', label: '4' },
  { extra_height: '4 1/16', extra_width: '4 1/16', value: '4 1/16', label: '4 1/16' },
  { extra_height: '4 1/8', extra_width: '4 1/8', value: '4 1/8', label: '4 1/8' },
  { extra_height: '4 3/16', extra_width: '4 3/16', value: '4 3/16', label: '4 3/16' },
  { extra_height: '4 1/4', extra_width: '4 1/4', value: '4 1/4', label: '4 1/4' },
  { extra_height: '4 5/16', extra_width: '4 5/16', value: '4 5/16', label: '4 5/16' },
  { extra_height: '4 3/8', extra_width: '4 3/8', value: '4 3/8', label: '4 3/8' },
  { extra_height: '4 7/16', extra_width: '4 7/16', value: '4 7/16', label: '4 7/16' },
  { extra_height: '4 1/2', extra_width: '4 1/2', value: '4 1/2', label: '4 1/2' },
  { extra_height: '4 9/16', extra_width: '4 9/16', value: '4 9/16', label: '4 9/16' },
  { extra_height: '4 5/8', extra_width: '4 5/8', value: '4 5/8', label: '4 5/8' },
  { extra_height: '4 11/16', extra_width: '4 11/16', value: '4 11/16', label: '4 11/16' },
  { extra_height: '4 3/4', extra_width: '4 3/4', value: '4 3/4', label: '4 3/4' },
  { extra_height: '4 13/16', extra_width: '4 13/16', value: '4 13/16', label: '4 13/16' },
  { extra_height: '4 7/8', extra_width: '4 7/8', value: '4 7/8', label: '4 7/8' },
  { extra_height: '4 15/16', extra_width: '4 15/16', value: '4 15/16', label: '4 15/16' },
  { extra_height: '5', extra_width: '5', value: '5', label: '5' },
  { extra_height: '5 1/16', extra_width: '5 1/16', value: '5 1/16', label: '5 1/16' },
  { extra_height: '5 1/8', extra_width: '5 1/8', value: '5 1/8', label: '5 1/8' },
  { extra_height: '5 3/16', extra_width: '5 3/16', value: '5 3/16', label: '5 3/16' },
  { extra_height: '5 1/4', extra_width: '5 1/4', value: '5 1/4', label: '5 1/4' },
  { extra_height: '5 5/16', extra_width: '5 5/16', value: '5 5/16', label: '5 5/16' },
  { extra_height: '5 3/8', extra_width: '5 3/8', value: '5 3/8', label: '5 3/8' },
  { extra_height: '5 7/16', extra_width: '5 7/16', value: '5 7/16', label: '5 7/16' },
  { extra_height: '5 1/2', extra_width: '5 1/2', value: '5 1/2', label: '5 1/2' },
  { extra_height: '5 9/16', extra_width: '5 9/16', value: '5 9/16', label: '5 9/16' },
  { extra_height: '5 5/8', extra_width: '5 5/8', value: '5 5/8', label: '5 5/8' },
  { extra_height: '5 11/16', extra_width: '5 11/16', value: '5 11/16', label: '5 11/16' },
  { extra_height: '5 3/4', extra_width: '5 3/4', value: '5 3/4', label: '5 3/4' },
  { extra_height: '5 13/16', extra_width: '5 13/16', value: '5 13/16', label: '5 13/16' },
  { extra_height: '5 7/8', extra_width: '5 7/8', value: '5 7/8', label: '5 7/8' },
  { extra_height: '5 15/16', extra_width: '5 15/16', value: '5 15/16', label: '5 15/16' },
  { extra_height: '6', extra_width: '6', value: '6', label: '6' },
];
/* FABRICATION */

export const HoleType = [
  { value: 'Rectangle Square', label: 'Rectangle/Square' },
  { value: 'Rectangle Square (Rounded Corners)', label: 'Rectangle/Square (Rounded Corners)' },
  { value: 'Circle', label: 'Circle' },
  { value: 'csc', label: 'Counter Sunk (Circle)' },
];

export const XLocationStart = [
  { value: 'Top', label: 'Top' },
  { value: 'Bottom', label: 'Bottom' },
  { value: 'Left', label: 'Left' },
  { value: 'Right', label: 'Right' },
];

export const YLocationStart = [
  { value: 'Top', label: 'Top' },
  { value: 'Bottom', label: 'Bottom' },
  { value: 'Left', label: 'Left' },
  { value: 'Right', label: 'Right' },
];

export const GridThickness = [
  { value: '3/16" FLAT', label: '3/16" FLAT' },
  { value: '3/8" FLAT', label: '3/8" FLAT' },
  { value: '7/16" FLAT', label: '7/16" FLAT' },
  { value: '1/2" FLAT', label: '1/2" FLAT' },
  { value: '9/16" FLAT"', label: '9/16" FLAT"' },
  { value: '5/8" FLAT', label: '5/8" FLAT' },
  { value: '3/4" FLAT"', label: '3/4" FLAT"' },
  { value: '1" FLAT', label: '1" FLAT' },
  { value: '11/16" CONTOUR', label: '11/16" CONTOUR' },
  { value: '1" CONTOUR', label: '1" CONTOUR' },
  { value: '5.5MM FLAT', label: '5.5MM FLAT' },
  { value: '8MM FLAT', label: '8MM FLAT' },
  { value: 'SLIMLINE  FLAT', label: 'SLIMLINE  FLAT' },
  { value: 'OTHER', label: 'OTHER' },
  { value: 'RESEARCH', label: 'RESEARCH' },
];

export const GridColor = [
  { value: 'White', label: 'White' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Black', label: 'Black' },
  { value: 'Green', label: 'Green' },
  { value: 'Tan', label: 'Tan' },
  { value: 'Mill (Clear Anodized)', label: 'Mill (Clear Anodized)' },
  { value: 'Champagne', label: 'Champagne' },
  { value: 'Cherry', label: 'Cherry' },
  { value: 'Earthtone', label: 'Earthtone' },
  { value: 'White (bright)', label: 'White (bright)' },
  { value: '2 TONE: Bronze/White', label: '2 TONE: Bronze/White' },
  { value: '2 TONE: Champagne/White', label: '2 TONE: Champagne/White' },
  { value: '2 TONE: Cherry/Tan', label: '2 TONE: Cherry/Tan' },
  { value: '2 TONE: Cherry/White', label: '2 TONE: Cherry/White' },
  { value: '2 TONE: Dark Oak/Tan', label: '2 TONE: Dark Oak/Tan' },
  { value: '2 TONE: Dark Oak/White', label: '2 TONE: Dark Oak/White' },
  { value: '2 TONE: Light Oak/Tan', label: '2 TONE: Light Oak/Tan' },
  { value: '2 TONE: Light Oak/White', label: '2 TONE: Light Oak/White' },
  { value: '2 TONE: Tan/White', label: '2 TONE: Tan/White' },
  { value: '2 TONE: White/Champagne', label: '2 TONE: White/Champagne' },
  { value: 'SDL - Clear', label: 'SDL - Clear' },
  { value: 'SDL - Black', label: 'SDL - Black' },
  { value: 'SDL - Bronze', label: 'SDL - Bronze' },
  { value: 'SDL - Clear (low profile)', label: 'SDL - Clear (low profile)' },
  { value: 'SDL - Black (low profile)', label: 'SDL - Black (low profile)' },
  { value: 'SDL - Bronze (low profile)', label: 'SDL - Bronze (low profile)' },
  { value: 'SDL - Mill - Aluminum', label: 'SDL - Mill - Aluminum' },
  { value: 'SDL - Mill - Stainless Steel', label: 'SDL - Mill - Stainless Steel' },
  { value: 'SDL - Black - Aluminum', label: 'SDL - Black - Aluminum' },
  { value: 'SDL - Black - Stainless Steel', label: 'SDL - Black - Stainless Steel' },
  {
    value: 'Custom',
    label: 'Custom',
  },
  { value: 'RESEARCH', label: 'RESEARCH' },
];

export const OffsetLite = [
  { value: 'Exterior (interior lite smaller)', label: 'Exterior (interior lite smaller)' },
  { value: 'Interior (exterior lite smaller)', label: 'Interior (exterior lite smaller)' },
];

export const OffsetFill = [
  { value: '90', label: '90' },
  { value: 'Normal ', label: 'Normal ' },
  { value: 'Buttered ', label: 'Buttered ' },
];

export const OffsetSide = [
  { value: 'Top', label: 'Top' },
  { value: 'Bottom', label: 'Bottom' },
  { value: 'Left ', label: 'Left' },
  { value: 'Right ', label: 'Right' },
];

export const OffsetOffset = [
  { value: '1/6', label: '1/6' },
  { value: '1/8', label: '1/8' },
  { value: '3/16', label: '3/16' },
  { value: '1/4', label: '1/4' },
  { value: '5/16', label: '5/16' },
  { value: '3/8', label: '3/8' },
  { value: '7/16', label: '7/16' },
  { value: '1/2', label: '1/2' },
  { value: '9/16', label: '9/16' },
  { value: '5/8', label: '5/8' },
  { value: '11/16', label: '11/16' },
  { value: '3/4', label: '3/4' },
  { value: '13/16', label: '13/16' },
  { value: '7/8', label: '7/8' },
  { value: '15/16', label: '15/16' },
  { value: '1', label: '1' },
  { value: '1 1/6', label: '1 1/6' },
  { value: '1 1/8', label: '1 1/8' },
  { value: '1 3/16', label: '1 3/16' },
  { value: '1 1/4', label: '1 1/4' },
  { value: '1 5/16', label: '1 5/16' },
  { value: '1 3/8', label: '1 3/8' },
  { value: '1 7/16', label: '1 7/16' },
  { value: '1 1/2', label: '1 1/2' },
  { value: '1 9/16', label: '1 9/16' },
  { value: '1 5/8', label: '1 5/8' },
  { value: '1 11/16', label: '1 11/16' },
  { value: '1 3/4', label: '1 3/4' },
  { value: '1 13/16', label: '1 13/16' },
  { value: '1 7/8', label: '1 7/8' },
  { value: '1 15/16', label: '1 15/16' },
  { value: '2', label: '2' },
  { value: '2 1/6', label: '2 1/6' },
  { value: '2 1/8', label: '2 1/8' },
  { value: '2 3/16', label: '2 3/16' },
  { value: '2 1/4', label: '2 1/4' },
  { value: '2 5/16', label: '2 5/16' },
  { value: '2 3/8', label: '2 3/8' },
  { value: '2 7/16', label: '2 7/16' },
  { value: '2 1/2', label: '2 1/2' },
  { value: '2 9/16', label: '2 9/16' },
  { value: '2 5/8', label: '2 5/8' },
  { value: '2 11/16', label: '2 11/16' },
  { value: '2 3/4', label: '2 3/4' },
  { value: '2 13/16', label: '2 13/16' },
  { value: '2 7/8', label: '2 7/8' },
  { value: '2 15/16', label: '2 15/16' },
  { value: '3', label: '3' },
];

export const newColors = [
  { value: 'Clear', label: 'Clear' },
  { value: 'Clear Low-E', label: 'Clear Low-E' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Mirror-Clear', label: 'Mirror-Clear' },
  { value: 'Mirror-Clear w/SafetyBacking', label: 'Mirror-Clear w/SafetyBacking' },
  { value: 'Mirror-Bronze', label: 'Mirror-Bronze' },
  { value: 'Mirror-Bronze w/SafetyBacking', label: 'Mirror-Bronze w/SafetyBacking' },
  { value: 'Mirror-Gray', label: 'Mirror-Gray' },
  { value: 'Mirror-Gray w/SafetyBacking', label: 'Mirror-Gray w/SafetyBacking' },
  { value: 'Mirror-Other (see notes)', label: 'Mirror-Other (see notes)' },
  {
    value: 'Mirror-Other w/SafetyBacking (see notes)',
    label: 'Mirror-Other w/SafetyBacking (see notes)',
  },
  { value: 'MirrorPane (1WAY)', label: 'MirrorPane (1WAY)' },
  { value: 'MirrorView (low ambient)', label: 'MirrorView (low ambient)' },
  { value: 'MirrorView 50/50 (high ambient)', label: 'MirrorView 50/50 (high ambient)' },
  { value: 'Low Iron', label: 'Low Iron' },
  { value: 'Starfire', label: 'Starfire' },
  { value: 'Optiwhite', label: 'Optiwhite' },
  { value: 'UltraClear', label: 'UltraClear' },
  { value: 'ExtraClear', label: 'ExtraClear' },
  { value: 'Acuity', label: 'Acuity' },
  { value: 'OptiGray (light)', label: 'OptiGray (light)' },
  { value: 'CrystalGray (light)', label: 'CrystalGray (light)' },
  { value: 'Majestic Grey (light)', label: 'Majestic Grey (light)' },
  { value: 'Graylite2 (black)', label: 'Graylite2 (black)' },
  { value: 'SuperGray (black)', label: 'SuperGray (black)' },
  { value: 'Midnight Gray (black)', label: 'Midnight Gray (black)' },
  { value: 'White', label: 'White' },
  { value: 'Green', label: 'Green' },
  { value: 'Green-Solexia', label: 'Green-Solexia' },
  { value: 'Green-Atlantica', label: 'Green-Atlantica' },
  { value: 'Green-EverGreen', label: 'Green-EverGreen' },
  { value: 'Green-Forrest', label: 'Green-Forrest' },
  { value: 'Green-Pure', label: 'Green-Pure' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Blue-Artic', label: 'Blue-Artic' },
  { value: 'Blue-Azuria', label: 'Blue-Azuria' },
  { value: 'Blue-Crystal', label: 'Blue-Crystal' },
  { value: 'Blue-Graphite', label: 'Blue-Graphite' },
  { value: 'Blue-Green', label: 'Blue-Green' },
  { value: 'Blue-Midnight', label: 'Blue-Midnight' },
  { value: 'Blue-Opti', label: 'Blue-Opti' },
  { value: 'Blue-Pacifica', label: 'Blue-Pacifica' },
  { value: 'Blue-Pure', label: 'Blue-Pure' },
  { value: 'Blue-Sky', label: 'Blue-Sky' },
  { value: 'Blue-Solar', label: 'Blue-Solar' },
  { value: 'Gold', label: 'Gold' },
  { value: 'Gold-Sunset', label: 'Gold-Sunset' },
  { value: '1-CLEAR', label: '1-CLEAR' },
  { value: '2-GREEN', label: '2-GREEN' },
  { value: '3-GRAY', label: '3-GRAY' },
  { value: '4-BRONZE', label: '4-BRONZE' },
  { value: '6-BLUE.GREEN', label: '6-BLUE.GREEN' },
  { value: '7-AZURIA', label: '7-AZURIA' },
  { value: '8-EVERGREEN', label: '8-EVERGREEN' },
  { value: '13-STARFIRE (low iron)', label: '13-STARFIRE (low iron)' },
  { value: '19-CRYSTALGRAY', label: '19-CRYSTALGRAY' },
  { value: '24-OPTIWHITE (low iron)', label: '24-OPTIWHITE (low iron)' },
  { value: '26-SOLARBLUE', label: '26-SOLARBLUE' },
  { value: '27-PACIFICA', label: '27-PACIFICA' },
  { value: '29-GRAPHITE', label: '29-GRAPHITE' },
  { value: '30-OPTIGRAY', label: '30-OPTIGRAY' },
  {
    value: 'Wired Glass - SQUARE Polished - WG',
    label: 'Wired Glass - SQUARE Polished - WG',
  },
  {
    value: 'Wired Glass - SQUARE Patterned - WG',
    label: 'Wired Glass - SQUARE Patterned - WG',
  },
  {
    value: 'Wired Glass - DIAMOND Polished - WG',
    label: 'Wired Glass - DIAMOND Polished - WG',
  },
  {
    value: 'Wired Glass - DIAMOND Patterned - WG',
    label: 'Wired Glass - DIAMOND Patterned - WG',
  },
  {
    value: 'Wired Glass - Georgian Polished - WG',
    label: 'Wired Glass - Georgian Polished - WG',
  },
  {
    value: 'Wired Glass - Georgian Stipolite - WG',
    label: 'Wired Glass - Georgian Stipolite - WG',
  },
  {
    value: 'Wired Glass - Custom (see notes) - WG',
    label: 'Wired Glass - Custom (see notes) - WG',
  },
  {
    value: 'Wired Glass - Research (see notes) - WG',
    label: 'Wired Glass - Research (see notes) - WG',
  },
  {
    value: 'Wired Glass - Other (see notes) - WG',
    label: 'Wired Glass - Other (see notes) - WG',
  },
  { value: 'Fire Rated - Contraflam - FR', label: 'Fire Rated - Contraflam - FR' },
  {
    value: 'Fire Rated - FireLite (20-90Min) - FR',
    label: 'Fire Rated - FireLite (20-90Min) - FR',
  },
  {
    value: 'Fire Rated - FireLite IGU (20-180Min) - FR',
    label: 'Fire Rated - FireLite IGU (20-180Min) - FR',
  },
  {
    value: 'Fire Rated - FireLite NT (20-180Min) - FR',
    label: 'Fire Rated - FireLite NT (20-180Min) - FR',
  },
  {
    value: 'Fire Rated - FireLite Plus (20-180Min) - FR',
    label: 'Fire Rated - FireLite Plus (20-180Min) - FR',
  },
  {
    value: 'Fire Rated - Wirelite NT (20-90Min) - FR',
    label: 'Fire Rated - Wirelite NT (20-90Min) - FR',
  },
  {
    value: 'Fire Rated - Wirelite (45Min) - FR',
    label: 'Fire Rated - Wirelite (45Min) - FR',
  },
  { value: 'Fire Rated - Keralite - FR', label: 'Fire Rated - Keralite - FR' },
  {
    value: 'Fire Rated - Pilkington Pyrodur - FR (discontinued)',
    label: 'Fire Rated - Pilkington Pyrodur - FR (discontinued)',
  },
  {
    value: 'Fire Rated - Pilkington Pyrostop - FR',
    label: 'Fire Rated - Pilkington Pyrostop - FR',
  },
  {
    value: 'Fire Rated - Pyran Platinum F (20Min) - FR',
    label: 'Fire Rated - Pyran Platinum F (20Min) - FR',
  },
  {
    value: 'Fire Rated - Pyran Platinum F (90Min) - FR',
    label: 'Fire Rated - Pyran Platinum F (90Min) - FR',
  },
  {
    value: 'Fire Rated - Pyran Platinum F (180Min) - FR',
    label: 'Fire Rated - Pyran Platinum F (180Min) - FR',
  },
  {
    value: 'Fire Rated - Pyran Platinum Film - FR',
    label: 'Fire Rated - Pyran Platinum Film - FR',
  },
  {
    value: 'Fire Rated - Pyran Platinum L (20Min) - FR',
    label: 'Fire Rated - Pyran Platinum L (20Min) - FR',
  },
  {
    value: 'Fire Rated - Pyran Platinum L (90Min) - FR',
    label: 'Fire Rated - Pyran Platinum L (90Min) - FR',
  },
  {
    value: 'Fire Rated - Pyran Platinum L (180Min) - FR',
    label: 'Fire Rated - Pyran Platinum L (180Min) - FR',
  },
  {
    value: 'Fire Rated - Pyrobel - AGC.Carndinal (45Min) - FR',
    label: 'Fire Rated - Pyrobel - AGC.Carndinal (45Min) - FR',
  },
  {
    value: 'Fire Rated - Pyrobel - AGC.Carndinal (60Min) - FR',
    label: 'Fire Rated - Pyrobel - AGC.Carndinal (60Min) - FR',
  },
  {
    value: 'Fire Rated - Pyrobel - AGC.Carndinal (90Min) - FR',
    label: 'Fire Rated - Pyrobel - AGC.Carndinal (90Min) - FR',
  },
  {
    value: 'Fire Rated - Pyrobel - AGC.Carndinal (120Min) - FR',
    label: 'Fire Rated - Pyrobel - AGC.Carndinal (120Min) - FR',
  },
  {
    value: 'Fire Rated - Custom (see notes) - FR',
    label: 'Fire Rated - Custom (see notes) - FR',
  },
  {
    value: 'Fire Rated - Research (see notes) - FR',
    label: 'Fire Rated - Research (see notes) - FR',
  },
  {
    value: 'Fire Rated - Other (see notes) - FR',
    label: 'Fire Rated - Other (see notes) - FR',
  },
  {
    value: 'Altdeutsch K - Textured Pattern - TP',
    label: 'Altdeutsch K - Textured Pattern - TP',
  },
  { value: 'Antique - Textured Pattern - TP', label: 'Antique - Textured Pattern - TP' },
  { value: 'Aqualite - Textured Pattern - TP', label: 'Aqualite - Textured Pattern - TP' },
  { value: 'Artico - Textured Pattern - TP', label: 'Artico - Textured Pattern - TP' },
  { value: 'Autumn - Textured Pattern - TP', label: 'Autumn - Textured Pattern - TP' },
  { value: 'Bamboo - Textured Pattern - TP', label: 'Bamboo - Textured Pattern - TP' },
  { value: 'Bubbles - Textured Pattern - TP', label: 'Bubbles - Textured Pattern - TP' },
  { value: 'Cast - Textured Pattern - TP', label: 'Cast - Textured Pattern - TP' },
  {
    value: 'Chinchilla - Textured Pattern - TP',
    label: 'Chinchilla - Textured Pattern - TP',
  },
  { value: 'Cotswold - Textured Pattern - TP', label: 'Cotswold - Textured Pattern - TP' },
  {
    value: 'Delta Frost - Textured Pattern - TP',
    label: 'Delta Frost - Textured Pattern - TP',
  },
  { value: 'Flemish - Textured Pattern - TP', label: 'Flemish - Textured Pattern - TP' },
  {
    value: 'Flemish (Low Iron) - Textured Pattern - TP',
    label: 'Flemish (Low Iron) - Textured Pattern - TP',
  },
  {
    value: 'Glue Chip 1-Sided - Textured Pattern - TP',
    label: 'Glue Chip 1-Sided - Textured Pattern - TP',
  },
  {
    value: 'Master Carre - Textured Pattern - TP',
    label: 'Master Carre - Textured Pattern - TP',
  },
  {
    value: 'Master Point - Textured Pattern - TP',
    label: 'Master Point - Textured Pattern - TP',
  },
  {
    value: 'Master Ray - Textured Pattern - TP',
    label: 'Master Ray - Textured Pattern - TP',
  },
  {
    value: 'Micro Ribbed (Low Iron) - Textured Pattern - TP',
    label: 'Micro Ribbed (Low Iron) - Textured Pattern - TP',
  },
  {
    value: 'Monumental - Textured Pattern - TP',
    label: 'Monumental - Textured Pattern - TP',
  },
  { value: 'Morisco - Textured Pattern - TP', label: 'Morisco - Textured Pattern - TP' },
  { value: 'Rain - Textured Pattern - TP', label: 'Rain - Textured Pattern - TP' },
  {
    value: 'Reeded Clear - Textured Pattern - TP',
    label: 'Reeded Clear - Textured Pattern - TP',
  },
  {
    value: 'Reeded (Low Iron) - Textured Pattern - TP',
    label: 'Reeded (Low Iron) - Textured Pattern - TP',
  },
  {
    value: 'Seedy Import - Textured Pattern - TP',
    label: 'Seedy Import - Textured Pattern - TP',
  },
  {
    value: 'Seedy Temperable - Textured Pattern - TP',
    label: 'Seedy Temperable - Textured Pattern - TP',
  },
  {
    value: 'Stream (Low Iron) - Textured Pattern - TP',
    label: 'Stream (Low Iron) - Textured Pattern - TP',
  },
  { value: 'Taffeta - Textured Pattern - TP', label: 'Taffeta - Textured Pattern - TP' },
  {
    value: 'Textured Flutes - Textured Pattern - TP',
    label: 'Textured Flutes - Textured Pattern - TP',
  },
  {
    value: 'Thin Ribbed - Textured Pattern - TP',
    label: 'Thin Ribbed - Textured Pattern - TP',
  },
  {
    value: 'Custom (see notes) - Textured Pattern - TP',
    label: 'Custom (see notes) - Textured Pattern - TP',
  },
  {
    value: 'Research (see notes) - Textured Pattern - TP',
    label: 'Research (see notes) - Textured Pattern - TP',
  },
  {
    value: 'Other (see notes) - Textured Pattern - TP',
    label: 'Other (see notes) - Textured Pattern - TP',
  },
  {
    value: 'Crystal Light (Low Iron)  - Satin Etched - SE',
    label: 'Crystal Light (Low Iron)  - Satin Etched - SE',
  },
  { value: 'Dots - Satin Etched - SE', label: 'Dots - Satin Etched - SE' },
  { value: 'Ice - Satin Etched - SE', label: 'Ice - Satin Etched - SE' },
  { value: 'Linen - Satin Etched - SE', label: 'Linen - Satin Etched - SE' },
  { value: 'Lines - Satin Etched - SE', label: 'Lines - Satin Etched - SE' },
  {
    value: 'Satin Etch 1-Sided (Low Iron) - Satin Etched - SE',
    label: 'Satin Etch 1-Sided (Low Iron) - Satin Etched - SE',
  },
  {
    value: 'Satin Etch 2-Sided (Low Iron) - Satin Etched - SE',
    label: 'Satin Etch 2-Sided (Low Iron) - Satin Etched - SE',
  },
  { value: 'Satin Etched - Satin Etched - SE', label: 'Satin Etched - Satin Etched - SE' },
  { value: 'Satin Bamboo - Satin Etched - SE', label: 'Satin Bamboo - Satin Etched - SE' },
  {
    value: 'Stylos S (Low Iron) - Satin Etched - SE',
    label: 'Stylos S (Low Iron) - Satin Etched - SE',
  },
  {
    value: 'Custom (see notes) - Satin Etched - SE',
    label: 'Custom (see notes) - Satin Etched - SE',
  },
  {
    value: 'Research (see notes) - Satin Etched - SE',
    label: 'Research (see notes) - Satin Etched - SE',
  },
  {
    value: 'Other (see notes) - Satin Etched - SE',
    label: 'Other (see notes) - Satin Etched - SE',
  },
  { value: 'Arcobaleno - Antique Mirror - AM', label: 'Arcobaleno - Antique Mirror - AM' },
  {
    value: 'Bianco e Nero - Antique Mirror - AM',
    label: 'Bianco e Nero - Antique Mirror - AM',
  },
  { value: 'Blue Morena - Antique Mirror - AM', label: 'Blue Morena - Antique Mirror - AM' },
  { value: 'Cometa - Antique Mirror - AM', label: 'Cometa - Antique Mirror - AM' },
  { value: 'Gold Lichen - Antique Mirror - AM', label: 'Gold Lichen - Antique Mirror - AM' },
  { value: 'Golden - Antique Mirror - AM', label: 'Golden - Antique Mirror - AM' },
  {
    value: 'Grigio Argento - Antique Mirror - AM',
    label: 'Grigio Argento - Antique Mirror - AM',
  },
  { value: 'Luna - Antique Mirror - AM', label: 'Luna - Antique Mirror - AM' },
  { value: 'Meteora - Antique Mirror - AM', label: 'Meteora - Antique Mirror - AM' },
  { value: 'Policromo - Antique Mirror - AM', label: 'Policromo - Antique Mirror - AM' },
  {
    value: 'Policromo Cobalto - Antique Mirror - AM',
    label: 'Policromo Cobalto - Antique Mirror - AM',
  },
  { value: 'Saturno - Antique Mirror - AM', label: 'Saturno - Antique Mirror - AM' },
  { value: 'Soft Spots - Antique Mirror - AM', label: 'Soft Spots - Antique Mirror - AM' },
  { value: 'Tramonto - Antique Mirror - AM', label: 'Tramonto - Antique Mirror - AM' },
  { value: 'White Ice - Antique Mirror - AM', label: 'White Ice - Antique Mirror - AM' },
  {
    value: 'Custom (see notes) - Antique Mirror - AM',
    label: 'Custom (see notes) - Antique Mirror - AM',
  },
  {
    value: 'Research (see notes) - Antique Mirror - AM',
    label: 'Research (see notes) - Antique Mirror - AM',
  },
  {
    value: 'Other (see notes) - Antique Mirror - AM',
    label: 'Other (see notes) - Antique Mirror - AM',
  },
  { value: 'LEXAN', label: 'LEXAN' },
  { value: 'LEXAN - Clear', label: 'LEXAN - Clear' },
  { value: 'LEXAN - Black', label: 'LEXAN - Black' },
  { value: 'LEXAN - Bronze', label: 'LEXAN - Bronze' },
  { value: 'LEXAN - Grey', label: 'LEXAN - Grey' },
  { value: 'LEXAN - Dark Grey', label: 'LEXAN - Dark Grey' },
  { value: 'LEXAN - Custom (see notes)', label: 'LEXAN - Custom (see notes)' },
  { value: 'LEXAN - Research (see notes)', label: 'LEXAN - Research (see notes)' },
  { value: 'LEXAN - Other (see notes)', label: 'LEXAN - Other (see notes)' },
  { value: 'Plexiglas', label: 'Plexiglas' },
  { value: 'Plexiglas - Clear', label: 'Plexiglas - Clear' },
  { value: 'Plexiglas - White', label: 'Plexiglas - White' },
  { value: 'Plexiglas - Black', label: 'Plexiglas - Black' },
  { value: 'Plexiglas - Blue', label: 'Plexiglas - Blue' },
  { value: 'Plexiglas - Green', label: 'Plexiglas - Green' },
  { value: 'Plexiglas - Red', label: 'Plexiglas - Red' },
  { value: 'Plexiglas - Yellow', label: 'Plexiglas - Yellow' },
  { value: 'Plexiglas - Bronze', label: 'Plexiglas - Bronze' },
  { value: 'Plexiglas - Gray', label: 'Plexiglas - Gray' },
  { value: 'Plexiglas - Custom (see notes)', label: 'Plexiglas - Custom (see notes)' },
  { value: 'Plexiglas - Research (see notes)', label: 'Plexiglas - Research (see notes)' },
  { value: 'Plexiglas - Other (see notes)', label: 'Plexiglas - Other (see notes)' },
  { value: 'Acrylic', label: 'Acrylic' },
  { value: 'Acrylic - Clear - P95', label: 'Acrylic - Clear - P95' },
  { value: 'Acrylic - Black - 2026', label: 'Acrylic - Black - 2026' },
  { value: 'Acrylic - White - 2447', label: 'Acrylic - White - 2447' },
  { value: 'Acrylic - White - 7328', label: 'Acrylic - White - 7328' },
  { value: 'Acrylic - White - 7508', label: 'Acrylic - White - 7508' },
  { value: 'Acrylic - Custom (see notes)', label: 'Acrylic - Custom (see notes)' },
  { value: 'Acrylic - Research (see notes)', label: 'Acrylic - Research (see notes)' },
  { value: 'Acrylic - Other (see notes)', label: 'Acrylic - Other (see notes)' },
  { value: 'Acrylic - Mirror', label: 'Acrylic - Mirror' },
  { value: 'Acrylic - Mirror - Clear - 0000', label: 'Acrylic - Mirror - Clear - 0000' },
  { value: 'Acrylic - Mirror - Amber - 2422', label: 'Acrylic - Mirror - Amber - 2422' },
  { value: 'Acrylic - Mirror - Black - 2064', label: 'Acrylic - Mirror - Black - 2064' },
  {
    value: 'Acrylic - Mirror - Blue.Light - 1000',
    label: 'Acrylic - Mirror - Blue.Light - 1000',
  },
  {
    value: 'Acrylic - Mirror - Blue.Royal - 2424',
    label: 'Acrylic - Mirror - Blue.Royal - 2424',
  },
  {
    value: 'Acrylic - Mirror - Blue.Sky - 2069',
    label: 'Acrylic - Mirror - Blue.Sky - 2069',
  },
  { value: 'Acrylic - Mirror - Bronze - 1600', label: 'Acrylic - Mirror - Bronze - 1600' },
  {
    value: 'Acrylic - Mirror - Bronze.Dark - 2404',
    label: 'Acrylic - Mirror - Bronze.Dark - 2404',
  },
  {
    value: 'Acrylic - Mirror - Clear 2-Way - 0000',
    label: 'Acrylic - Mirror - Clear 2-Way - 0000',
  },
  { value: 'Acrylic - Mirror - Gold - 1300', label: 'Acrylic - Mirror - Gold - 1300' },
  {
    value: 'Acrylic - Mirror - Gold.Rose - 1350',
    label: 'Acrylic - Mirror - Gold.Rose - 1350',
  },
  { value: 'Acrylic - Mirror - Gray - 1050', label: 'Acrylic - Mirror - Gray - 1050' },
  {
    value: 'Acrylic - Mirror - Gray.Dark - 2064',
    label: 'Acrylic - Mirror - Gray.Dark - 2064',
  },
  { value: 'Acrylic - Mirror - Green - 3030', label: 'Acrylic - Mirror - Green - 3030' },
  {
    value: 'Acrylic - Mirror - Green.Emerald - 4674',
    label: 'Acrylic - Mirror - Green.Emerald - 4674',
  },
  {
    value: 'Acrylic - Mirror - Green.Kelly - 2414',
    label: 'Acrylic - Mirror - Green.Kelly - 2414',
  },
  { value: 'Acrylic - Mirror - Orange - 1119', label: 'Acrylic - Mirror - Orange - 1119' },
  { value: 'Acrylic - Mirror - Pink - 1450', label: 'Acrylic - Mirror - Pink - 1450' },
  { value: 'Acrylic - Mirror - Purple - 1020', label: 'Acrylic - Mirror - Purple - 1020' },
  { value: 'Acrylic - Mirror - Red - 1400', label: 'Acrylic - Mirror - Red - 1400' },
  {
    value: 'Acrylic - Mirror - Red.Garnet - 2423',
    label: 'Acrylic - Mirror - Red.Garnet - 2423',
  },
  {
    value: 'Acrylic - Mirror - Red.Orange - 1310',
    label: 'Acrylic - Mirror - Red.Orange - 1310',
  },
  { value: 'Acrylic - Mirror - Teal - 2120', label: 'Acrylic - Mirror - Teal - 2120' },
  { value: 'Acrylic - Mirror - Yellow - 2208', label: 'Acrylic - Mirror - Yellow - 2208' },
  {
    value: 'Acrylic - Mirror - Custom (see notes)',
    label: 'Acrylic - Mirror - Custom (see notes)',
  },
  {
    value: 'Acrylic - Mirror - Research (see notes)',
    label: 'Acrylic - Mirror - Research (see notes)',
  },
  {
    value: 'Acrylic - Mirror - Other (see notes)',
    label: 'Acrylic - Mirror - Other (see notes)',
  },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const newCoating = [
  { value: 'Low-E', label: 'Low-E' },
  { value: 'EnergyAdvantage', label: 'EnergyAdvantage' },
  { value: 'EclipseAdvantage', label: 'EclipseAdvantage' },
  { value: 'Solarban60', label: 'Solarban60' },
  { value: 'Solarban67', label: 'Solarban67' },
  { value: 'Solarban70', label: 'Solarban70' },
  { value: 'Solarban72', label: 'Solarban72' },
  { value: 'Solarban90', label: 'Solarban90' },
  { value: 'SolarbanR100', label: 'SolarbanR100' },
  { value: 'SolarbanZ50', label: 'SolarbanZ50' },
  { value: 'SolarbanZ75', label: 'SolarbanZ75' },
  { value: 'SolarCool', label: 'SolarCool' },
  { value: 'VistaCool', label: 'VistaCool' },
  { value: 'Sungate400', label: 'Sungate400' },
  { value: 'ClimaGuard 53/23', label: 'ClimaGuard 53/23' },
  { value: 'ClimaGuard 55/27', label: 'ClimaGuard 55/27' },
  { value: 'ClimaGuard 62/27', label: 'ClimaGuard 62/27' },
  { value: 'ClimaGuard 70/36', label: 'ClimaGuard 70/36' },
  { value: 'ClimaGuard 72/57', label: 'ClimaGuard 72/57' },
  { value: 'ClimaGuard 80/70', label: 'ClimaGuard 80/70' },
  { value: 'ClimaGuard IS-20', label: 'ClimaGuard IS-20' },
  { value: 'ES20', label: 'ES20' },
  { value: 'ES23', label: 'ES23' },
  { value: 'ES25', label: 'ES25' },
  { value: 'ES28', label: 'ES28' },
  { value: 'ES31', label: 'ES31' },
  { value: 'ES36', label: 'ES36' },
  { value: 'ES40', label: 'ES40' },
  { value: 'ESR42', label: 'ESR42' },
  { value: 'ES63', label: 'ES63' },
  { value: 'ES73', label: 'ES73' },
  { value: 'SuperSilver', label: 'SuperSilver' },
  { value: 'Stopray Vision 36T', label: 'Stopray Vision 36T' },
  { value: 'Stopray Vision 50T', label: 'Stopray Vision 50T' },
  { value: 'SunGuard AG 43', label: 'SunGuard AG 43' },
  { value: 'SunGuard AG 50', label: 'SunGuard AG 50' },
  { value: 'SunGuard HD Diamond 66', label: 'SunGuard HD Diamond 66' },
  { value: 'SunGuard HD Light Blue 52', label: 'SunGuard HD Light Blue 52' },
  { value: 'SunGuard HD Neutral 67', label: 'SunGuard HD Neutral 67' },
  { value: 'SunGuard HD Royal Blue 20', label: 'SunGuard HD Royal Blue 20' },
  { value: 'SunGuard HD Silver 10', label: 'SunGuard HD Silver 10' },
  { value: 'SunGuard HD Silver 20', label: 'SunGuard HD Silver 20' },
  { value: 'SunGuard HD Silver 32', label: 'SunGuard HD Silver 32' },
  { value: 'SunGuard HD Silver 70', label: 'SunGuard HD Silver 70' },
  { value: 'SunGuard HP Amber 41/29', label: 'SunGuard HP Amber 41/29' },
  { value: 'SunGuard HP Bronze 40/27', label: 'SunGuard HP Bronze 40/27' },
  { value: 'SunGuard HP Blue.Light 62/52', label: 'SunGuard HP Blue.Light 62/52' },
  { value: 'SunGuard HP Blue.Royal 41/29', label: 'SunGuard HP Blue.Royal 41/29' },
  { value: 'SunGuard HP Green.Bright 40/29', label: 'SunGuard HP Green.Bright 40/29' },
  { value: 'SunGuard HP Neutral 41/33', label: 'SunGuard HP Neutral 41/33' },
  { value: 'SunGuard HP Neutral 50/32', label: 'SunGuard HP Neutral 50/32' },
  { value: 'SunGuard HP Neutral 60/40', label: 'SunGuard HP Neutral 60/40' },
  { value: 'SunGuard HP Silver 35/26', label: 'SunGuard HP Silver 35/26' },
  { value: 'SunGuard HP Silver 43/31', label: 'SunGuard HP Silver 43/31' },
  { value: 'SunGuard Neutral 40', label: 'SunGuard Neutral 40' },
  { value: 'SunGuard Neutral 50', label: 'SunGuard Neutral 50' },
  { value: 'SunGuard Neutral 78/65', label: 'SunGuard Neutral 78/65' },
  { value: 'SunGuard SN 29/18', label: 'SunGuard SN 29/18' },
  { value: 'SunGuard SN 40/23', label: 'SunGuard SN 40/23' },
  { value: 'SunGuard SN 51/28', label: 'SunGuard SN 51/28' },
  { value: 'SunGuard SN 54', label: 'SunGuard SN 54' },
  { value: 'SunGuard SN 63', label: 'SunGuard SN 63' },
  { value: 'SunGuard SN 63 HT', label: 'SunGuard SN 63 HT' },
  { value: 'SunGuard SN 68', label: 'SunGuard SN 68' },
  { value: 'SunGuard SN 70', label: 'SunGuard SN 70' },
  { value: 'SunGuard SN 70/35', label: 'SunGuard SN 70/35' },
  { value: 'SunGuard SN 70/37', label: 'SunGuard SN 70/37' },
  { value: 'SunGuard SN 75', label: 'SunGuard SN 75' },
  { value: 'SunGuard SN 75 HT', label: 'SunGuard SN 75 HT' },
  { value: 'SunGuard SNL 68', label: 'SunGuard SNL 68' },
  { value: 'SunGuard SNR 43', label: 'SunGuard SNR 43' },
  { value: 'SunGuard SNX 50', label: 'SunGuard SNX 50' },
  { value: 'SunGuard SNX 51/23', label: 'SunGuard SNX 51/23' },
  { value: 'SunGuard SNX 60', label: 'SunGuard SNX 60' },
  { value: 'SunGuard SNX 62/27', label: 'SunGuard SNX 62/27' },
  { value: 'SunGuard SNX-L 62/34', label: 'SunGuard SNX-L 62/34' },
  { value: 'SunGuard Solar Bronze 20', label: 'SunGuard Solar Bronze 20' },
  { value: 'SunGuard Solar Green.Bright 20', label: 'SunGuard Solar Green.Bright 20' },
  { value: 'SunGuard Solar Gold 20', label: 'SunGuard Solar Gold 20' },
  { value: 'SunGuard Solar Gray 20', label: 'SunGuard Solar Gray 20' },
  { value: 'SunGuard SuperNeutral 68', label: 'SunGuard SuperNeutral 68' },
  { value: 'Energy Select 36', label: 'Energy Select 36' },
  { value: 'Energy Select N70/38 (SB60)', label: 'Energy Select N70/38 (SB60)' },
  { value: 'Energy Select R43/28', label: 'Energy Select R43/28' },
  { value: 'Energy Select R47/31', label: 'Energy Select R47/31' },
  { value: 'Energy Select R53/33', label: 'Energy Select R53/33' },
  { value: 'N31/18 (TECNOGLASS)', label: 'N31/18 (TECNOGLASS)' },
  { value: 'N40/22 (TECNOGLASS)', label: 'N40/22 (TECNOGLASS)' },
  { value: 'N48/25 (TECNOGLASS)', label: 'N48/25 (TECNOGLASS)' },
  { value: 'N70/38 (TECNOGLASS)', label: 'N70/38 (TECNOGLASS)' },
  { value: 'N76/60 (TECNOGLASS)', label: 'N76/60 (TECNOGLASS)' },
  { value: 'R32/22 (TECNOGLASS)', label: 'R32/22 (TECNOGLASS)' },
  { value: 'R36/23 (TECNOGLASS)', label: 'R36/23 (TECNOGLASS)' },
  { value: 'R43/28 (TECNOGLASS)', label: 'R43/28 (TECNOGLASS)' },
  { value: 'R44/27 (TECNOGLASS)', label: 'R44/27 (TECNOGLASS)' },
  { value: 'R47/31 (TECNOGLASS)', label: 'R47/31 (TECNOGLASS)' },
  { value: 'R53/33 (TECNOGLASS)', label: 'R53/33 (TECNOGLASS)' },
  { value: 'R59/37 (TECNOGLASS)', label: 'R59/37 (TECNOGLASS)' },
  { value: 'NT52/22 (TECNOGLASS)', label: 'NT52/22 (TECNOGLASS)' },
  { value: 'LoE-180', label: 'LoE-180' },
  { value: 'LoE-240', label: 'LoE-240' },
  { value: 'LoE-i81', label: 'LoE-i81' },
  { value: 'LoE-i88', label: 'LoE-i88' },
  { value: 'LoE-x89', label: 'LoE-x89' },
  { value: 'LoE2-270', label: 'LoE2-270' },
  { value: 'LoE2-272', label: 'LoE2-272' },
  { value: 'LoE3-340', label: 'LoE3-340' },
  { value: 'LoE3-366', label: 'LoE3-366' },
  { value: 'VE-2M', label: 'VE-2M' },
  { value: 'VE-42', label: 'VE-42' },
  { value: 'VE-45', label: 'VE-45' },
  { value: 'VE-48', label: 'VE-48' },
  { value: 'VE-55', label: 'VE-55' },
  { value: 'VE-58', label: 'VE-58' },
  { value: 'VE-61', label: 'VE-61' },
  { value: 'VE-65', label: 'VE-65' },
  { value: 'VE-85', label: 'VE-85' },
  { value: 'VP-13', label: 'VP-13' },
  { value: 'VP-18', label: 'VP-18' },
  { value: 'VP-22', label: 'VP-22' },
  { value: 'VS-08', label: 'VS-08' },
  { value: 'VS-14', label: 'VS-14' },
  { value: 'VS-20', label: 'VS-20' },
  { value: 'VHT-54', label: 'VHT-54' },
  { value: 'VHT-60', label: 'VHT-60' },
  { value: 'VHT-67', label: 'VHT-67' },
  { value: 'VHT-75', label: 'VHT-75' },
  { value: 'VLE-39', label: 'VLE-39' },
  { value: 'VLE-47', label: 'VLE-47' },
  { value: 'VLE-51', label: 'VLE-51' },
  { value: 'VLE-57', label: 'VLE-57' },
  { value: 'VLE-70', label: 'VLE-70' },
  { value: 'VLT-36', label: 'VLT-36' },
  { value: 'VLT-41', label: 'VLT-41' },
  { value: 'VLT-47', label: 'VLT-47' },
  { value: 'VLT-53', label: 'VLT-53' },
  { value: 'VLT-59', label: 'VLT-59' },
  { value: 'VLT-63', label: 'VLT-63' },
  { value: 'VNE-43', label: 'VNE-43' },
  { value: 'VNE-45', label: 'VNE-45' },
  { value: 'VNE-53', label: 'VNE-53' },
  { value: 'VNE-63', label: 'VNE-63' },
  { value: 'VRE-38', label: 'VRE-38' },
  { value: 'VRE-43', label: 'VRE-43' },
  { value: 'VRE-46', label: 'VRE-46' },
  { value: 'VRE-54', label: 'VRE-54' },
  { value: 'VRE-59', label: 'VRE-59' },
  { value: 'VRE-65', label: 'VRE-65' },
  { value: 'VRE-4725', label: 'VRE-4725' },
  { value: 'VUE-30', label: 'VUE-30' },
  { value: 'VUE-40', label: 'VUE-40' },
  { value: 'VUE-50', label: 'VUE-50' },
  { value: 'RoomSide Low-E', label: 'RoomSide Low-E' },
  { value: 'VA-08 (RETIRED)', label: 'VA-08 (RETIRED)' },
  { value: 'VA-14 (RETIRED)', label: 'VA-14 (RETIRED)' },
  { value: 'VA-20 (RETIRED)', label: 'VA-20 (RETIRED)' },
  { value: 'VA-22 (RETIRED)', label: 'VA-22 (RETIRED)' },
  { value: 'VA-24 (RETIRED)', label: 'VA-24 (RETIRED)' },
  { value: 'VA-30 (RETIRED)', label: 'VA-30 (RETIRED)' },
  { value: 'VA-35 (RETIRED)', label: 'VA-35 (RETIRED)' },
  { value: 'VA-40 (RETIRED)', label: 'VA-40 (RETIRED)' },
  { value: 'VB-08 (RETIRED)', label: 'VB-08 (RETIRED)' },
  { value: 'VB-14 (RETIRED)', label: 'VB-14 (RETIRED)' },
  { value: 'VB-20 (RETIRED)', label: 'VB-20 (RETIRED)' },
  { value: 'VB-24 (RETIRED)', label: 'VB-24 (RETIRED)' },
  { value: 'VB-30 (RETIRED)', label: 'VB-30 (RETIRED)' },
  { value: 'VB-35 (RETIRED)', label: 'VB-35 (RETIRED)' },
  { value: 'VB-40 (RETIRED)', label: 'VB-40 (RETIRED)' },
  { value: 'VC-08 (RETIRED)', label: 'VC-08 (RETIRED)' },
  { value: 'VC-14 (RETIRED)', label: 'VC-14 (RETIRED)' },
  { value: 'VC-20 (RETIRED)', label: 'VC-20 (RETIRED)' },
  { value: 'VC-24 (RETIRED)', label: 'VC-24 (RETIRED)' },
  { value: 'VC-30 (RETIRED)', label: 'VC-30 (RETIRED)' },
  { value: 'VC-35 (RETIRED)', label: 'VC-35 (RETIRED)' },
  { value: 'VC-40 (RETIRED)', label: 'VC-40 (RETIRED)' },
  { value: 'VG-08 (RETIRED)', label: 'VG-08 (RETIRED)' },
  { value: 'VG-14 (RETIRED)', label: 'VG-14 (RETIRED)' },
  { value: 'VG-20 (RETIRED)', label: 'VG-20 (RETIRED)' },
  { value: 'VG-24 (RETIRED)', label: 'VG-24 (RETIRED)' },
  { value: 'VG-30 (RETIRED)', label: 'VG-30 (RETIRED)' },
  { value: 'VG-35 (RETIRED)', label: 'VG-35 (RETIRED)' },
  { value: 'VG-40 (RETIRED)', label: 'VG-40 (RETIRED)' },
  { value: 'VT-08 (RETIRED)', label: 'VT-08 (RETIRED)' },
  { value: 'VT-14 (RETIRED)', label: 'VT-14 (RETIRED)' },
  { value: 'VT-20 (RETIRED)', label: 'VT-20 (RETIRED)' },
  { value: 'VT-24 (RETIRED)', label: 'VT-24 (RETIRED)' },
  { value: 'VT-30 (RETIRED)', label: 'VT-30 (RETIRED)' },
  { value: 'VT-35 (RETIRED)', label: 'VT-35 (RETIRED)' },
  { value: 'VT-40 (RETIRED)', label: 'VT-40 (RETIRED)' },
  { value: 'VW-08 (RETIRED)', label: 'VW-08 (RETIRED)' },
  { value: 'VW-14 (RETIRED)', label: 'VW-14 (RETIRED)' },
  { value: 'VW-20 (RETIRED)', label: 'VW-20 (RETIRED)' },
  { value: 'VW-24 (RETIRED)', label: 'VW-24 (RETIRED)' },
  { value: 'VW-30 (RETIRED)', label: 'VW-30 (RETIRED)' },
  { value: 'VW-35 (RETIRED)', label: 'VW-35 (RETIRED)' },
  { value: 'VW-40 (RETIRED)', label: 'VW-40 (RETIRED)' },
  { value: 'VSE-30 (RETIRED)', label: 'VSE-30 (RETIRED)' },
  { value: 'Viracon - Custom (see notes)', label: 'Viracon - Custom (see notes)' },
  { value: 'Viracon - Research (see notes)', label: 'Viracon - Research (see notes)' },
  { value: 'Viracon - Other (see notes)', label: 'Viracon - Other (see notes)' },
  {
    value: 'Back Painted - BP - Custom (see notes)',
    label: 'Back Painted - BP - Custom (see notes)',
  },
  {
    value: 'Back Painted - BP - Research (see notes)',
    label: 'Back Painted - BP - Research (see notes)',
  },
  {
    value: 'Back Painted - BP - Other (see notes)',
    label: 'Back Painted - BP - Other (see notes)',
  },
  { value: 'Black (PLK, VIR) - Spandrel - S', label: 'Black (PLK, VIR) - Spandrel - S' },
  { value: 'Blue (VIR) - Spandrel - S', label: 'Blue (VIR) - Spandrel - S' },
  { value: 'Blue.Ford (OBE) - Spandrel - S', label: 'Blue.Ford (OBE) - Spandrel - S' },
  { value: 'Blue.Frost (VIR) - Spandrel - S', label: 'Blue.Frost (VIR) - Spandrel - S' },
  {
    value: 'Blue.Harmonic (PLK) - Spandrel - S',
    label: 'Blue.Harmonic (PLK) - Spandrel - S',
  },
  { value: 'Bronze (VIR) - Spandrel - S', label: 'Bronze (VIR) - Spandrel - S' },
  { value: 'Bronze.Dark (VIR) - Spandrel - S', label: 'Bronze.Dark (VIR) - Spandrel - S' },
  { value: 'Bronze.Lava (OBE) - Spandrel - S', label: 'Bronze.Lava (OBE) - Spandrel - S' },
  {
    value: 'Bronze.Solar (OBE) - Spandrel - S',
    label: 'Bronze.Solar (OBE) - Spandrel - S',
  },
  {
    value: 'Bronze.Subdued (VIR) - Spandrel - S',
    label: 'Bronze.Subdued (VIR) - Spandrel - S',
  },
  { value: 'Bronze.Tone (PLK) - Spandrel - S', label: 'Bronze.Tone (PLK) - Spandrel - S' },
  { value: 'Bronze.Harmony - Spandrel - S', label: 'Bronze.Harmony - Spandrel - S' },
  { value: 'Charcoal (OBE) - Spandrel - S', label: 'Charcoal (OBE) - Spandrel - S' },
  {
    value: 'Green.Cactus (PLK) - Spandrel - S',
    label: 'Green.Cactus (PLK) - Spandrel - S',
  },
  {
    value: 'Green.Evergreen (OBE, VIR) - Spandrel - S',
    label: 'Green.Evergreen (OBE, VIR) - Spandrel - S',
  },
  { value: 'Green.Sage (VIR) - Spandrel - S', label: 'Green.Sage (VIR) - Spandrel - S' },
  { value: 'Gray (VIR, PLK) - Spandrel - S', label: 'Gray (VIR, PLK) - Spandrel - S' },
  { value: 'Gray.Black (OBE) - Spandrel - S', label: 'Gray.Black (OBE) - Spandrel - S' },
  { value: 'Gray.Dark (VIR) - Spandrel - S', label: 'Gray.Dark (VIR) - Spandrel - S' },
  { value: 'Gray.Fog (VIR) - Spandrel - S', label: 'Gray.Fog (VIR) - Spandrel - S' },
  { value: 'Gray.Medium (VIR) - Spandrel - S', label: 'Gray.Medium (VIR) - Spandrel - S' },
  { value: 'Gray.Solar (OBE) - Spandrel - S', label: 'Gray.Solar (OBE) - Spandrel - S' },
  {
    value: 'Gray.Subdued (VIR) - Spandrel - S',
    label: 'Gray.Subdued (VIR) - Spandrel - S',
  },
  {
    value: 'Gray.Warm (OBE, VIR) - Spandrel - S',
    label: 'Gray.Warm (OBE, VIR) - Spandrel - S',
  },
  { value: 'Graphite (PLK) - Spandrel - S', label: 'Graphite (PLK) - Spandrel - S' },
  {
    value: 'Simulated Acid-Etch (VIR) - Spandrel - S',
    label: 'Simulated Acid-Etch (VIR) - Spandrel - S',
  },
  {
    value: 'White.Simulated Etch (PLK) - Spandrel - S',
    label: 'White.Simulated Etch (PLK) - Spandrel - S',
  },
  {
    value: 'Simulated Sandblast (VIR) - Spandrel - S',
    label: 'Simulated Sandblast (VIR) - Spandrel - S',
  },
  { value: 'Solex (OBE) - Spandrel - S', label: 'Solex (OBE) - Spandrel - S' },
  { value: 'Spice (VIR) - Spandrel - S', label: 'Spice (VIR) - Spandrel - S' },
  { value: 'White (OBE) - Spandrel - S', label: 'White (OBE) - Spandrel - S' },
  { value: 'White.Etch (PLK) - Spandrel - S', label: 'White.Etch (PLK) - Spandrel - S' },
  {
    value: 'White.High-Opacity (VIR) - Spandrel - S',
    label: 'White.High-Opacity (VIR) - Spandrel - S',
  },
  {
    value: 'White.Opaque (PLK) - Spandrel - S',
    label: 'White.Opaque (PLK) - Spandrel - S',
  },
  { value: 'Yellow.Moon (VIR) - Spandrel - S', label: 'Yellow.Moon (VIR) - Spandrel - S' },
  {
    value: 'Black (Opaci Coat 300) - Spandrel - S',
    label: 'Black (Opaci Coat 300) - Spandrel - S',
  },
  {
    value: 'Blue-Harmony (Opaci Coat 300) - Spandrel - S',
    label: 'Blue-Harmony (Opaci Coat 300) - Spandrel - S',
  },
  {
    value: 'Bronze-Harmony (Opaci Coat 300) - Spandrel - S',
    label: 'Bronze-Harmony (Opaci Coat 300) - Spandrel - S',
  },
  {
    value: 'Bronze-Lava (Opaci Coat 300) - Spandrel - S',
    label: 'Bronze-Lava (Opaci Coat 300) - Spandrel - S',
  },
  {
    value: 'Charcoal (Opaci Coat 300) - Spandrel - S',
    label: 'Charcoal (Opaci Coat 300) - Spandrel - S',
  },
  {
    value: 'Grey-Warm (Opaci Coat 300) - Spandrel - S',
    label: 'Grey-Warm (Opaci Coat 300) - Spandrel - S',
  },
  {
    value: 'Solex-Harmony (Opaci Coat 300) - Spandrel - S',
    label: 'Solex-Harmony (Opaci Coat 300) - Spandrel - S',
  },
  {
    value: 'White-Primary on Clear (Opaci Coat 300) - Spandrel - S',
    label: 'White-Primary on Clear (Opaci Coat 300) - Spandrel - S',
  },
  { value: 'Other (see notes) - Spandrel - S', label: 'Other (see notes) - Spandrel - S' },
  {
    value: 'Custom (see notes) - Spandrel - S',
    label: 'Custom (see notes) - Spandrel - S',
  },
  {
    value: 'Research (see notes) - Spandrel - S',
    label: 'Research (see notes) - Spandrel - S',
  },
  {
    value: 'Lines - 1/2” on 1” Centers (OBE) - Fritt Patterns - FP',
    label: 'Lines - 1/2” on 1” Centers (OBE) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 20% - 1/8 (OBE) - Fritt Patterns - FP',
    label: 'Lines - 20% - 1/8 (OBE) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 20% - 1/8” (2256, VIR) - Fritt Patterns - FP',
    label: 'Lines - 20% - 1/8” (2256, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 30% - 1/8” (2973, VIR) - Fritt Patterns - FP',
    label: 'Lines - 30% - 1/8” (2973, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 40% - 1/8 (OBE) - Fritt Patterns - FP',
    label: 'Lines - 40% - 1/8 (OBE) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 40% - 1/8” (2030, VIR) - Fritt Patterns - FP',
    label: 'Lines - 40% - 1/8” (2030, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 50% - 1/2” (2032, VIR) - Fritt Patterns - FP',
    label: 'Lines - 50% - 1/2” (2032, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 50% - 1/4” (2050, VIR) - Fritt Patterns - FP',
    label: 'Lines - 50% - 1/4” (2050, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 50% - 1/8 (OBE) - Fritt Patterns - FP',
    label: 'Lines - 50% - 1/8 (OBE) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 50% - 1/8” (2002, VIR) - Fritt Patterns - FP',
    label: 'Lines - 50% - 1/8” (2002, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 50% - 1” (2013, VIR) - Fritt Patterns - FP',
    label: 'Lines - 50% - 1” (2013, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Lines - 60% - 1/8” (2604, VIR) - Fritt Patterns - FP',
    label: 'Lines - 60% - 1/8” (2604, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Holes - 20% - 1/8” (OBE) - Fritt Patterns - FP',
    label: 'Holes - 20% - 1/8” (OBE) - Fritt Patterns - FP',
  },
  {
    value: 'Holes - 40% - 1/8” (OBE) - Fritt Patterns - FP',
    label: 'Holes - 40% - 1/8” (OBE) - Fritt Patterns - FP',
  },
  {
    value: 'Holes - 60% - 1/8” (5023, VIR) - Fritt Patterns - FP',
    label: 'Holes - 60% - 1/8” (5023, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Dots - 20% - 1/8 (OBE) - Fritt Patterns - FP',
    label: 'Dots - 20% - 1/8 (OBE) - Fritt Patterns - FP',
  },
  {
    value: 'Dots - 20% - 1/8” (5065, VIR) - Fritt Patterns - FP',
    label: 'Dots - 20% - 1/8” (5065, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Dots - 30% - 1/8” (5959, VIR) - Fritt Patterns - FP',
    label: 'Dots - 30% - 1/8” (5959, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Dots - 40% - 1/8 (OBE) - Fritt Patterns - FP',
    label: 'Dots - 40% - 1/8 (OBE) - Fritt Patterns - FP',
  },
  {
    value: 'Dots - 40% - 1/8” (5006, VIR) - Fritt Patterns - FP',
    label: 'Dots - 40% - 1/8” (5006, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Dots - 50% - 1/4” (5960, VIR) - Fritt Patterns - FP',
    label: 'Dots - 50% - 1/4” (5960, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Dots - 60% - 7/16” (5961, VIR) - Fritt Patterns - FP',
    label: 'Dots - 60% - 7/16” (5961, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Pattern - 40% (6017, VIR) - Fritt Patterns - FP',
    label: 'Pattern - 40% (6017, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Pattern - 50% (6002, VIR) - Fritt Patterns - FP',
    label: 'Pattern - 50% (6002, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Pattern - 60% (6019, VIR) - Fritt Patterns - FP',
    label: 'Pattern - 60% (6019, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Pattern - 70% (6015, VIR) - Fritt Patterns - FP',
    label: 'Pattern - 70% (6015, VIR) - Fritt Patterns - FP',
  },
  {
    value: 'Custom (see notes) - Fritt Patterns - FP',
    label: 'Custom (see notes) - Fritt Patterns - FP',
  },
  {
    value: 'Research (see notes) - Fritt Patterns - FP',
    label: 'Research (see notes) - Fritt Patterns - FP',
  },
  {
    value: 'Other (see notes) - Fritt Patterns - FP',
    label: 'Other (see notes) - Fritt Patterns - FP',
  },
  { value: 'Bordeaux - Digital Printed - DP', label: 'Bordeaux - Digital Printed - DP' },
  { value: 'Bound - Digital Printed - DP', label: 'Bound - Digital Printed - DP' },
  { value: 'Coppice - Digital Printed - DP', label: 'Coppice - Digital Printed - DP' },
  { value: 'Crackle - Digital Printed - DP', label: 'Crackle - Digital Printed - DP' },
  { value: 'Crema - Digital Printed - DP', label: 'Crema - Digital Printed - DP' },
  { value: 'Flora - Digital Printed - DP', label: 'Flora - Digital Printed - DP' },
  { value: 'Fusion - Digital Printed - DP', label: 'Fusion - Digital Printed - DP' },
  {
    value: 'Golden Onyx - Digital Printed - DP',
    label: 'Golden Onyx - Digital Printed - DP',
  },
  { value: 'Inlay - Digital Printed - DP', label: 'Inlay - Digital Printed - DP' },
  { value: 'Jade - Digital Printed - DP', label: 'Jade - Digital Printed - DP' },
  { value: 'Orbit - Digital Printed - DP', label: 'Orbit - Digital Printed - DP' },
  { value: 'Passage - Digital Printed - DP', label: 'Passage - Digital Printed - DP' },
  {
    value: 'Serpentine - Digital Printed - DP',
    label: 'Serpentine - Digital Printed - DP',
  },
  { value: 'Slate - Digital Printed - DP', label: 'Slate - Digital Printed - DP' },
  { value: 'Structure - Digital Printed - DP', label: 'Structure - Digital Printed - DP' },
  { value: 'Terrazzo - Digital Printed - DP', label: 'Terrazzo - Digital Printed - DP' },
  { value: 'Whisper - Digital Printed - DP', label: 'Whisper - Digital Printed - DP' },
  {
    value: 'Concrete Oak (Wood Grain) - Digital Printed - DP',
    label: 'Concrete Oak (Wood Grain) - Digital Printed - DP',
  },
  {
    value: 'Natural Oak (Wood Grain) - Digital Printed - DP',
    label: 'Natural Oak (Wood Grain) - Digital Printed - DP',
  },
  {
    value: 'Swedish Oak (Wood Grain) - Digital Printed - DP',
    label: 'Swedish Oak (Wood Grain) - Digital Printed - DP',
  },
  {
    value: 'Zebrano Chocolate (Wood Grain) - Digital Printed - DP',
    label: 'Zebrano Chocolate (Wood Grain) - Digital Printed - DP',
  },
  {
    value: 'Zebrano Expresso (Wood Grain) - Digital Printed - DP',
    label: 'Zebrano Expresso (Wood Grain) - Digital Printed - DP',
  },
  {
    value: 'Zebrano Macchiato (Wood Grain) - Digital Printed - DP',
    label: 'Zebrano Macchiato (Wood Grain) - Digital Printed - DP',
  },
  {
    value: 'Zebrano Mocachino (Wood Grain) - Digital Printed - DP',
    label: 'Zebrano Mocachino (Wood Grain) - Digital Printed - DP',
  },
  {
    value: 'Custom (see notes) - Digital Printed - DP',
    label: 'Custom (see notes) - Digital Printed - DP',
  },
  {
    value: 'Research (see notes) - Digital Printed - DP',
    label: 'Research (see notes) - Digital Printed - DP',
  },
  {
    value: 'Other (see notes) - Digital Printed - DP',
    label: 'Other (see notes) - Digital Printed - DP',
  },
  { value: 'EnduroShield', label: 'EnduroShield' },
  { value: 'ShowerGuard', label: 'ShowerGuard' },
  { value: 'Protective Layer - (see notes)', label: 'Protective Layer - (see notes)' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
];

export const glassThicknessOptions = [
  { value: 'SSB', label: 'SSB' },
  { value: 'DSB', label: 'DSB' },
  { value: '1/16', label: '1/16' },
  { value: '1/8', label: '1/8' },
  { value: '3/16', label: '3/16' },
  { value: '1/4', label: '1/4' },
  { value: '5/16', label: '5/16' },
  { value: '3/8', label: '3/8' },
  { value: '7/16', label: '7/16' },
  { value: '15/32', label: '15/32' },
  { value: '1/2', label: '1/2' },
  { value: '9/16', label: '9/16' },
  { value: '5/8', label: '5/8' },
  { value: '11/16', label: '11/16' },
  { value: '3/4', label: '3/4' },
  { value: '13/16', label: '13/16' },
  { value: '7/8', label: '7/8' },
  { value: '15/16', label: '15/16' },
  { value: '1', label: '1' },
  { value: '1 1/16', label: '1 1/16' },
  { value: '1 1/8', label: '1 1/8' },
  { value: '1 3/16', label: '1 3/16' },
  { value: '1 1/4', label: '1 1/4' },
  { value: '1 5/16', label: '1 5/16' },
  { value: '1 3/8', label: '1 3/8' },
  { value: '1 7/16', label: '1 7/16' },
  { value: '1 1/2', label: '1 1/2' },
  { value: '1 9/16', label: '1 9/16' },
  { value: '1 5/8', label: '1 5/8' },
  { value: '1 11/16', label: '1 11/16' },
  { value: '1 3/4', label: '1 3/4' },
  { value: '1 13/16', label: '1 13/16' },
  { value: '1 7/8', label: '1 7/8' },
  { value: '1 15/16', label: '1 15/16' },
  { value: '1/4 (1/8,PVB-015,1/8)', label: '1/4 (1/8,PVB-015,1/8)' },
  { value: '1/4 (1/8,PVB-030,1/8)', label: '1/4 (1/8,PVB-030,1/8)' },
  { value: '5/16 (1/8,PVB-060,1/8)', label: '5/16 (1/8,PVB-060,1/8)' },
  { value: '5/16 (1/8,PVB-090,1/8)', label: '5/16 (1/8,PVB-090,1/8)' },
  { value: '3/8 (3/16,PVB-030,3/16)', label: '3/8 (3/16,PVB-030,3/16)' },
  { value: '3/8 (1/4,PVB-030,1/8)', label: '3/8 (1/4,PVB-030,1/8)' },
  { value: '3/8 (1/4,PVB-060,1/8)', label: '3/8 (1/4,PVB-060,1/8)' },
  { value: '7/16 (3/16,PVB-060,3/16)', label: '7/16 (3/16,PVB-060,3/16)' },
  { value: '7/16 (3/16,PVB-090,3/16)', label: '7/16 (3/16,PVB-090,3/16)' },
  { value: '15/32 (1/4,PVB-015,3/16)', label: '15/32 (1/4,PVB-015,3/16)' },
  { value: '15/32 (1/4,PVB-030,3/16)', label: '15/32 (1/4,PVB-030,3/16)' },
  { value: '15/32 (3/16,PVB-045,3/16)', label: '15/32 (3/16,PVB-045,3/16)' },
  { value: '15/32 (1/8,PVB-060,3/16)', label: '15/32 (1/8,PVB-060,3/16)' },
  { value: '15/32 (3/16,PVB-060,1/8)', label: '15/32 (3/16,PVB-060,1/8)' },
  { value: '15/32 (1/8,PVB-090,1/8)', label: '15/32 (1/8,PVB-090,1/8)' },
  { value: '1/2 (1/4,PVB-015,1/4)', label: '1/2 (1/4,PVB-015,1/4)' },
  { value: '1/2 (1/4,PVB-030,1/4)', label: '1/2 (1/4,PVB-030,1/4)' },
  { value: '1/2 (1/4,PVB-060,1/4)', label: '1/2 (1/4,PVB-060,1/4)' },
  { value: '9/16 (1/4,PVB-060,1/4)', label: '9/16 (1/4,PVB-060,1/4)' },
  { value: '9/16 (1/4,PVB-090,1/4)', label: '9/16 (1/4,PVB-090,1/4)' },
  { value: '5/8 (5/16,PVB-015,5/16)', label: '5/8 (5/16,PVB-015,5/16)' },
  { value: '5/8 (1/4,PVB-030,1/4)', label: '5/8 (1/4,PVB-030,1/4)' },
  { value: '5/8 (3/8,PVB-030,1/4)', label: '5/8 (3/8,PVB-030,1/4)' },
  { value: '5/8 (1/4,PVB-035,1/4)', label: '5/8 (1/4,PVB-035,1/4)' },
  { value: '3/4 (3/8,PVB-015,3/8)', label: '3/4 (3/8,PVB-015,3/8)' },
  { value: '3/4 (5/16,PVB-030,5/16)', label: '3/4 (5/16,PVB-030,5/16)' },
  { value: '3/4 (5/16,PVB-035,5/16)', label: '3/4 (5/16,PVB-035,5/16)' },
  { value: '3/4 (1/4,PVB-045,1/4)', label: '3/4 (1/4,PVB-045,1/4)' },
  { value: '3/4 (1/2,PVB-060,1/4)', label: '3/4 (1/2,PVB-060,1/4)' },
  { value: '13/16 (3/8,PVB-060,3/8)', label: '13/16 (3/8,PVB-060,3/8)' },
  { value: '13/16 (3/8,PVB-090,3/8)', label: '13/16 (3/8,PVB-090,3/8)' },
  { value: '7/8 (1/2,PVB-015,1/2)', label: '7/8 (1/2,PVB-015,1/2)' },
  { value: '7/8 (3/8,PVB-035,3/8)', label: '7/8 (3/8,PVB-035,3/8)' },
  { value: '7/8 (5/16,PVB-045,5/16)', label: '7/8 (5/16,PVB-045,5/16)' },
  { value: '7/8 (1/4,PVB-060,1/4)', label: '7/8 (1/4,PVB-060,1/4)' },
  { value: '1 (9/16,PVB-015,9/16)', label: '1 (9/16,PVB-015,9/16)' },
  { value: '1 (1/2,PVB-030,1/2)', label: '1 (1/2,PVB-030,1/2)' },
  { value: '1 (1/2,PVB-035,1/2)', label: '1 (1/2,PVB-035,1/2)' },
  { value: '1 (1/4,PVB-075,5/16)', label: '1 (1/4,PVB-075,5/16)' },
  { value: '1 (5/16,PVB-075,3/8)', label: '1 (5/16,PVB-075,3/8)' },
  { value: '1 (3/8,PVB-060,5/16)', label: '1 (3/8,PVB-060,5/16)' },
  { value: '1 1/8 (9/16,PVB-035,9/16)', label: '1 1/8 (9/16,PVB-035,9/16)' },
  { value: '1 1/8 (1/2,PVB-045,1/2)', label: '1 1/8 (1/2,PVB-045,1/2)' },
  { value: '1 1/8 (5/16,PVB-060,9/16)', label: '1 1/8 (5/16,PVB-060,9/16)' },
  { value: '1 1/8 (9/16,PVB-060,5/16)', label: '1 1/8 (9/16,PVB-060,5/16)' },
  { value: '1 1/8 (9/16,PVB-090,1/4)', label: '1 1/8 (9/16,PVB-090,1/4)' },
  { value: '1 1/4 (9/16,PVB-045,9/16)', label: '1 1/4 (9/16,PVB-045,9/16)' },
  { value: '1 1/4 (1/4,PVB-075,9/16)', label: '1 1/4 (1/4,PVB-075,9/16)' },
  { value: '1 1/4 (1/2,PVB-060,1/2)', label: '1 1/4 (1/2,PVB-060,1/2)' },
  { value: '1 1/4 (1/2,PVB-060,3/8)', label: '1 1/4 (1/2,PVB-060,3/8)' },
  { value: '1 1/4 (3/8,PVB-090,5/16)', label: '1 1/4 (3/8,PVB-090,5/16)' },
  { value: '1 1/4 (1/2,PVB-090,1/4)', label: '1 1/4 (1/2,PVB-090,1/4)' },
  { value: '1 1/4 (1/2,PVB-090,5/16)', label: '1 1/4 (1/2,PVB-090,5/16)' },
  { value: '1 3/8 (9/16,PVB-060,9/16)', label: '1 3/8 (9/16,PVB-060,9/16)' },
  { value: '1 3/8 (9/16,PVB-075,1/2)', label: '1 3/8 (9/16,PVB-075,1/2)' },
  { value: '1/4 (1/8,SGP-015,1/8)', label: '1/4 (1/8,SGP-015,1/8)' },
  { value: '1/4 (1/8,SGP-030,1/8)', label: '1/4 (1/8,SGP-030,1/8)' },
  { value: '5/16 (1/8,SGP-060,1/8)', label: '5/16 (1/8,SGP-060,1/8)' },
  { value: '5/16 (1/8,SGP-090,1/8)', label: '5/16 (1/8,SGP-090,1/8)' },
  { value: '3/8 (3/16,SGP-030,3/16)', label: '3/8 (3/16,SGP-030,3/16)' },
  { value: '3/8 (1/4,SGP-030,1/8)', label: '3/8 (1/4,SGP-030,1/8)' },
  { value: '3/8 (1/4,SGP-060,1/8)', label: '3/8 (1/4,SGP-060,1/8)' },
  { value: '7/16 (3/16,SGP-060,3/16)', label: '7/16 (3/16,SGP-060,3/16)' },
  { value: '7/16 (3/16,SGP-090,3/16)', label: '7/16 (3/16,SGP-090,3/16)' },
  { value: '15/32 (1/4,SGP-015,3/16)', label: '15/32 (1/4,SGP-015,3/16)' },
  { value: '15/32 (1/4,SGP-030,3/16)', label: '15/32 (1/4,SGP-030,3/16)' },
  { value: '15/32 (3/16,SGP-045,3/16)', label: '15/32 (3/16,SGP-045,3/16)' },
  { value: '1/2 (1/4,SGP-030,1/4)', label: '1/2 (1/4,SGP-030,1/4)' },
  { value: '1/2 (1/4,SGP-060,1/4)', label: '1/2 (1/4,SGP-060,1/4)' },
  { value: '9/16 (1/4,SGP-060,1/4)', label: '9/16 (1/4,SGP-060,1/4)' },
  { value: '9/16 (1/4,SGP-090,1/4)', label: '9/16 (1/4,SGP-090,1/4)' },
  { value: '5/8 (3/8,SGP-030,1/4)', label: '5/8 (3/8,SGP-030,1/4)' },
  { value: '3/4 (1/2,SGP-060,1/4)', label: '3/4 (1/2,SGP-060,1/4)' },
  { value: '13/16 (3/8,SGP-060,3/8)', label: '13/16 (3/8,SGP-060,3/8)' },
  { value: '13/16 (3/8,SGP-090,3/8)', label: '13/16 (3/8,SGP-090,3/8)' },
  { value: '1 (1/2,SGP-035,1/2)', label: '1 (1/2,SGP-035,1/2)' },
  { value: '1 (5/16,SGP-035,1/2)', label: '1 (5/16,SGP-035,1/2)' },
  { value: '1 (3/8,SGP-060,3/8)', label: '1 (3/8,SGP-060,3/8)' },
  { value: '1 1/8 (9/16,SGP-035,3/8)', label: '1 1/8 (9/16,SGP-035,3/8)' },
  { value: '1 1/8 (9/16,SGP-035,9/16)', label: '1 1/8 (9/16,SGP-035,9/16)' },
  { value: '1 1/8 (1/2,SGP-060,1/2)', label: '1 1/8 (1/2,SGP-060,1/2)' },
  { value: '1 1/4 (3/8,SGP-035,1/2)', label: '1 1/4 (3/8,SGP-035,1/2)' },
  { value: '1 1/4 (9/16,SGP-060,9/16)', label: '1 1/4 (9/16,SGP-060,9/16)' },
  { value: '0.76mm', label: '0.76mm' },
  { value: '1mm', label: '1mm' },
  { value: '1.5mm', label: '1.5mm' },
  { value: '2mm', label: '2mm' },
  { value: '2.4mm', label: '2.4mm' },
  { value: '2.5mm', label: '2.5mm' },
  { value: '3mm', label: '3mm' },
  { value: '3.2mm', label: '3.2mm' },
  { value: '4mm', label: '4mm' },
  { value: '4.5mm', label: '4.5mm' },
  { value: '5mm', label: '5mm' },
  { value: '6mm', label: '6mm' },
  { value: '8mm', label: '8mm' },
  { value: '9mm', label: '9mm' },
  { value: '9.5mm', label: '9.5mm' },
  { value: '10mm', label: '10mm' },
  { value: '12mm', label: '12mm' },
  { value: '12.7mm', label: '12.7mm' },
  { value: '15mm', label: '15mm' },
  { value: '16mm', label: '16mm' },
  { value: '18mm', label: '18mm' },
  { value: '19mm', label: '19mm' },
  { value: '24mm', label: '24mm' },

  { value: 'L1.BR.A: 1 1/4 (32mm)', label: 'L1.BR.A: 1 1/4 (32mm)' },
  { value: 'L2.BR.A: 1 3/8 (35mm)', label: 'L2.BR.A: 1 3/8 (35mm)' },
  { value: 'L3.BR.A: 1 3/4 (44mm)', label: 'L3.BR.A: 1 3/4 (44mm)' },
  { value: 'L4.BR.A: 2 (51mm)', label: 'L4.BR.A: 2 (51mm)' },
  { value: 'L5.BR.A: 2 1/2 (64mm)', label: 'L5.BR.A: 2 1/2 (64mm)' },
  { value: 'L6.BR.A: 3 (76mm)', label: 'L6.BR.A: 3 (76mm)' },
  { value: 'L1.BR.GCPC: 3/4 (1/8G,1/2PC,1/8G)', label: 'L1.BR.GCPC: 3/4 (1/8G,1/2PC,1/8G)' },
  { value: 'L1.BR.GCPC: 3/4 (1/4G,1/4PC,1/4G)', label: 'L1.BR.GCPC: 3/4 (1/4G,1/4PC,1/4G)' },
  {
    value: 'L1.BR.GCPC: 3/4 (1/8G,1/2PC,1/8G,NSC)',
    label: 'L1.BR.GCPC: 3/4 (1/8G,1/2PC,1/8G,NSC)',
  },
  {
    value: 'L1.BR.GCPC: 3/4 (1/4G,1/4PC,1/4G,NSC)',
    label: 'L1.BR.GCPC: 3/4 (1/4G,1/4PC,1/4G,NSC)',
  },
  { value: 'L1.BR.GCPC: 7/8 (3/8G,1/8PC,3/8G)', label: 'L1.BR.GCPC: 7/8 (3/8G,1/8PC,3/8G)' },
  { value: 'L1.BR.GCPC: 1 (1/2G,1/2PC)', label: 'L1.BR.GCPC: 1 (1/2G,1/2PC)' },
  { value: 'L1.BR.GCPC: 1 1/8 (3/8G,3/8PC,3/8G)', label: 'L1.BR.GCPC: 1 1/8 (3/8G,3/8PC,3/8G)' },
  {
    value: 'L1.BR.GCPC: 1 1/4 (1/4G,1/4PC,1/4G,1/4PC,1/4G)',
    label: 'L1.BR.GCPC: 1 1/4 (1/4G,1/4PC,1/4G,1/4PC,1/4G)',
  },
  { value: 'L1.BR.GCPC: OTHER SEE NOTES', label: 'L1.BR.GCPC: OTHER SEE NOTES' },

  { value: 'L2.BR.GCPC: 1 (1/8G,3/4PC,1/8G)', label: 'L2.BR.GCPC: 1 (1/8G,3/4PC,1/8G)' },
  { value: 'L2.BR.GCPC: 1 (1/4G,1/2PC,1/4G)', label: 'L2.BR.GCPC: 1 (1/4G,1/2PC,1/4G)' },
  { value: 'L2.BR.GCPC: 1 (1/8G,3/4PC,1/8G,NSC)', label: 'L2.BR.GCPC: 1 (1/8G,3/4PC,1/8G,NSC)' },
  { value: 'L2.BR.GCPC: 1 (1/4G,1/2PC,1/4G,NSC)', label: 'L2.BR.GCPC: 1 (1/4G,1/2PC,1/4G,NSC)' },
  { value: 'L2.BR.GCPC: 1 1/4 (1/2G,1/4PC,1/2G)', label: 'L2.BR.GCPC: 1 1/4 (1/2G,1/4PC,1/2G)' },
  {
    value: 'L2.BR.GCPC: 1 3/8 (1/4G,1/4PC,1/4G,1/4PC,3/8G)',
    label: 'L2.BR.GCPC: 1 3/8 (1/4G,1/4PC,1/4G,1/4PC,3/8G)',
  },
  { value: 'L2.BR.GCPC: 1 1/2 (1/2G,1/2PC,1/2G)', label: 'L2.BR.GCPC: 1 1/2 (1/2G,1/2PC,1/2G)' },
  {
    value: 'L2.BR.GCPC: 1 1/2 (1/4G,PVB-060,1/4PC,PVB-060,1/4G,PVB-060,1/4PC)',
    label: 'L2.BR.GCPC: 1 1/2 (1/4G,PVB-060,1/4PC,PVB-060,1/4G,PVB-060,1/4PC)',
  },
  {
    value: 'L2.BR.GCPC: 1 5/8 (3/8G,1/4PC,3/8G,1/4PC,3/8G)',
    label: 'L2.BR.GCPC: 1 5/8 (3/8G,1/4PC,3/8G,1/4PC,3/8G)',
  },
  {
    value: 'L2.BR.GCPC: 1 3/4 (3/8G,PVB-060,3/8PC,PVB-060,3/8G)',
    label: 'L2.BR.GCPC: 1 3/4 (3/8G,PVB-060,3/8PC,PVB-060,3/8G)',
  },
  {
    value: 'L2.BR.GCPC: 2 (1/2G,PVB-060,1/2PC,PVB-060,1/2G)',
    label: 'L2.BR.GCPC: 2 (1/2G,PVB-060,1/2PC,PVB-060,1/2G)',
  },
  { value: 'L2.BR.GCPC: OTHER SEE NOTES', label: 'L2.BR.GCPC: OTHER SEE NOTES' },
  { value: 'L3.BR.GCPC: 1 1/4 (1/8G,1PC,1/8G)', label: 'L3.BR.GCPC: 1 1/4 (1/8G,1PC,1/8G)' },
  { value: 'L3.BR.GCPC: 1 1/4 (1/4G,3/4PC,1/4G)', label: 'L3.BR.GCPC: 1 1/4 (1/4G,3/4PC,1/4G)' },
  {
    value: 'L3.BR.GCPC: 1 1/4 (1/8G,1/4PC,1/8G,1/4PC,1/8G)',
    label: 'L3.BR.GCPC: 1 1/4 (1/8G,1/4PC,1/8G,1/4PC,1/8G)',
  },
  {
    value: 'L3.BR.GCPC: 1 1/4 (1/8G,1PC,1/8G,NSC)',
    label: 'L3.BR.GCPC: 1 1/4 (1/8G,1PC,1/8G,NSC)',
  },
  {
    value: 'L3.BR.GCPC: 1 1/4 (1/4G,3/4PC,1/4G,NSC)',
    label: 'L3.BR.GCPC: 1 1/4 (1/4G,3/4PC,1/4G,NSC)',
  },
  {
    value: 'L3.BR.GCPC: 1 1/4 (1/8G,1/4PC,1/8G,1/4PC,1/8G,NSC)',
    label: 'L3.BR.GCPC: 1 1/4 (1/8G,1/4PC,1/8G,1/4PC,1/8G,NSC)',
  },
  {
    value: 'L3.BR.GCPC: 1 5/8 (1/2G,1/2PC,1/4G,1/4PC,1/4G)',
    label: 'L3.BR.GCPC: 1 5/8 (1/2G,1/2PC,1/4G,1/4PC,1/4G)',
  },
  {
    value: 'L3.BR.GCPC: 1 5/8 (1/4G,SGP-035,1/4PC,SGP-035,1/4G,SGP-035,1/4PC)',
    label: 'L3.BR.GCPC: 1 5/8 (1/4G,SGP-035,1/4PC,SGP-035,1/4G,SGP-035,1/4PC)',
  },
  {
    value: 'L3.BR.GCPC: 1 3/4 (3/8G,3/8PC,3/8G,3/8PC,3/8G)',
    label: 'L3.BR.GCPC: 1 3/4 (3/8G,3/8PC,3/8G,3/8PC,3/8G)',
  },
  {
    value: 'L3.BR.GCPC: 1 7/8 (3/8G,SGP-035,3/8PC,SGP-035,3/8G)',
    label: 'L3.BR.GCPC: 1 7/8 (3/8G,SGP-035,3/8PC,SGP-035,3/8G)',
  },
  {
    value: 'L3.BR.GCPC: 2 (1/2G,1/2PC,1/2G,1/2PC)',
    label: 'L3.BR.GCPC: 2 (1/2G,1/2PC,1/2G,1/2PC)',
  },
  {
    value: 'L3.BR.GCPC: 2 1/8 (1/2G,3/8PC,1/2G,3/8PC,1/2G)',
    label: 'L3.BR.GCPC: 2 1/8 (1/2G,3/8PC,1/2G,3/8PC,1/2G)',
  },
  {
    value: 'L3.BR.GCPC: 2 1/4 (1/2G,SGP-035,1/2PC,SGP-035,1/2G)',
    label: 'L3.BR.GCPC: 2 1/4 (1/2G,SGP-035,1/2PC,SGP-035,1/2G)',
  },
  { value: 'L3.BR.GCPC: OTHER SEE NOTES', label: 'L3.BR.GCPC: OTHER SEE NOTES' },

  {
    value: 'L4.BR.GCPC: 1 1/2 (1/8G,1 1/4PC,1/8G)',
    label: 'L4.BR.GCPC: 1 1/2 (1/8G,1 1/4PC,1/8G)',
  },
  { value: 'L4.BR.GCPC: 1 1/2 (1/4G,1PC,1/4G)', label: 'L4.BR.GCPC: 1 1/2 (1/4G,1PC,1/4G)' },
  {
    value: 'L4.BR.GCPC: 1 1/2 (1/8G,1/2PC,1/8G,1/2PC,1/8G)',
    label: 'L4.BR.GCPC: 1 1/2 (1/8G,1/2PC,1/8G,1/2PC,1/8G)',
  },
  {
    value: 'L4.BR.GCPC: 1 1/2 (1/8G,1 1/4PC,1/8G,NSC)',
    label: 'L4.BR.GCPC: 1 1/2 (1/8G,1 1/4PC,1/8G,NSC)',
  },
  {
    value: 'L4.BR.GCPC: 1 1/2 (1/4G,1PC,1/4G,NSC)',
    label: 'L4.BR.GCPC: 1 1/2 (1/4G,1PC,1/4G,NSC)',
  },
  {
    value: 'L4.BR.GCPC: 1 1/2 (1/8G,1/2PC,1/8G,1/2PC,1/8G,NSC)',
    label: 'L4.BR.GCPC: 1 1/2 (1/8G,1/2PC,1/8G,1/2PC,1/8G,NSC)',
  },
  {
    value: 'L4.BR.GCPC: 2 1/4 (3/8G,3/8PC,1/2G,1/2PC,3/8G)',
    label: 'L4.BR.GCPC: 2 1/4 (3/8G,3/8PC,1/2G,1/2PC,3/8G)',
  },
  {
    value: 'L4.BR.GCPC: 2 1/4 (1/4G,PVB-060,1/4PC,PVB-060,1/2G,PVB-060,1/4PC,PVB-060,1/4G)',
    label: 'L4.BR.GCPC: 2 1/4 (1/4G,PVB-060,1/4PC,PVB-060,1/2G,PVB-060,1/4PC,PVB-060,1/4G)',
  },
  {
    value: 'L4.BR.GCPC: 2 1/2 (1/2G,1/2PC,1/2G,1/2PC,1/2G)',
    label: 'L4.BR.GCPC: 2 1/2 (1/2G,1/2PC,1/2G,1/2PC,1/2G)',
  },
  {
    value: 'L4.BR.GCPC: 2 1/2 (1/4G,SGP-035,1/4PC,SGP-035,1/4G,SGP-035,1/4PC,SGP-035,1/2G)',
    label: 'L4.BR.GCPC: 2 1/2 (1/4G,SGP-035,1/4PC,SGP-035,1/4G,SGP-035,1/4PC,SGP-035,1/2G)',
  },
  {
    value: 'L4.BR.GCPC: 2 3/4 (3/8G,PVB-060,3/8PC,PVB-060,3/8G,PVB-060,3/8PC,PVB-060,3/8G)',
    label: 'L4.BR.GCPC: 2 3/4 (3/8G,PVB-060,3/8PC,PVB-060,3/8G,PVB-060,3/8PC,PVB-060,3/8G)',
  },
  {
    value: 'L4.BR.GCPC: 2 7/8 (1/2G,3/8PC,1/2G,1/2PC,1/2G,3/8PC,1/4G)',
    label: 'L4.BR.GCPC: 2 7/8 (1/2G,3/8PC,1/2G,1/2PC,1/2G,3/8PC,1/4G)',
  },
  { value: 'L4.BR.GCPC: 3 (3/4G,1/2PC,3/4G)', label: 'L4.BR.GCPC: 3 (3/4G,1/2PC,3/4G)' },
  {
    value: 'L4.BR.GCPC: 3 (3/8G,SGP-035,3/8PC,SGP-035,3/8G,SGP-035,3/8PC,SGP-035,3/8G)',
    label: 'L4.BR.GCPC: 3 (3/8G,SGP-035,3/8PC,SGP-035,3/8G,SGP-035,3/8PC,SGP-035,3/8G)',
  },
  {
    value: 'L4.BR.GCPC: 3 1/4 (1/2G,PVB-060,1/2PC,PVB-060,1/2G,PVB-060,1/2PC,PVB-060,1/2G)',
    label: 'L4.BR.GCPC: 3 1/4 (1/2G,PVB-060,1/2PC,PVB-060,1/2G,PVB-060,1/2PC,PVB-060,1/2G)',
  },
  { value: 'L4.BR.GCPC: OTHER SEE NOTES', label: 'L4.BR.GCPC: OTHER SEE NOTES' },

  {
    value: 'L5.BR.GCPC: 1 3/4 (1/8G,1 1/2PC,1/8G)',
    label: 'L5.BR.GCPC: 1 3/4 (1/8G,1 1/2PC,1/8G)',
  },
  {
    value: 'L5.BR.GCPC: 1 3/4 (1/4G,1 1/4PC,1/4G)',
    label: 'L5.BR.GCPC: 1 3/4 (1/4G,1 1/4PC,1/4G)',
  },
  {
    value: 'L5.BR.GCPC: 1 3/4 (1/8G,3/4PC,1/8G,3/4PC,1/8G)',
    label: 'L5.BR.GCPC: 1 3/4 (1/8G,3/4PC,1/8G,3/4PC,1/8G)',
  },
  {
    value: 'L5.BR.GCPC: 1 3/4 (1/8G,1 1/2PC,1/8G,NSC)',
    label: 'L5.BR.GCPC: 1 3/4 (1/8G,1 1/2PC,1/8G,NSC)',
  },
  {
    value: 'L5.BR.GCPC: 1 3/4 (1/4G,1 1/4PC,1/4G,NSC)',
    label: 'L5.BR.GCPC: 1 3/4 (1/4G,1 1/4PC,1/4G,NSC)',
  },
  {
    value: 'L5.BR.GCPC: 1 3/4 (1/8G,3/4PC,1/8G,3/4PC,1/8G,NSC)',
    label: 'L5.BR.GCPC: 1 3/4 (1/8G,3/4PC,1/8G,3/4PC,1/8G,NSC)',
  },
  {
    value: 'L5.BR.GCPC: 3 1/2 (1/2G,1/2PC,1/2G,1/2PC,1/2G,1/2PC)',
    label: 'L5.BR.GCPC: 3 1/2 (1/2G,1/2PC,1/2G,1/2PC,1/2G,1/2PC)',
  },
  {
    value: 'L5.BR.GCPC: 3 1/2 (1/2G,SGP-035,1/2PC,SGP-035,1/2G,SGP-035,1/2PC,SGP-035,1/2G)',
    label: 'L5.BR.GCPC: 3 1/2 (1/2G,SGP-035,1/2PC,SGP-035,1/2G,SGP-035,1/2PC,SGP-035,1/2G)',
  },
  {
    value: 'L5.BR.GCPC: 3 1/2 (3/4G,PVB-090,1/2PC,PVB-090,3/4G,PVB-090,1/2PC,PVB-090,3/4G)',
    label: 'L5.BR.GCPC: 3 1/2 (3/4G,PVB-090,1/2PC,PVB-090,3/4G,PVB-090,1/2PC,PVB-090,3/4G)',
  },
  {
    value: 'L5.BR.GCPC: 3 3/4 (3/4G,3/8PC,3/4G,3/8PC,3/4G,3/8PC,3/4G)',
    label: 'L5.BR.GCPC: 3 3/4 (3/4G,3/8PC,3/4G,3/8PC,3/4G,3/8PC,3/4G)',
  },
  { value: 'L5.BR.GCPC: 4 (1G,1/2PC,1G,1/2PC)', label: 'L5.BR.GCPC: 4 (1G,1/2PC,1G,1/2PC)' },
  {
    value: 'L5.BR.GCPC: 4 (1G,PVB-090,3/4PC,PVB-090,1G,PVB-090,3/4PC,PVB-090,1G)',
    label: 'L5.BR.GCPC: 4 (1G,PVB-090,3/4PC,PVB-090,1G,PVB-090,3/4PC,PVB-090,1G)',
  },
  {
    value: 'L5.BR.GCPC: 4 1/2 (1G,1/2PC,1G,1/2PC,1G)',
    label: 'L5.BR.GCPC: 4 1/2 (1G,1/2PC,1G,1/2PC,1G)',
  },
  { value: 'L5.BR.GCPC: OTHER SEE NOTES', label: 'L5.BR.GCPC: OTHER SEE NOTES' },

  { value: 'L6.BR.GCPC: 2 (1/8G,1 3/4PC,1/8G)', label: 'L6.BR.GCPC: 2 (1/8G,1 3/4PC,1/8G)' },
  { value: 'L6.BR.GCPC: 2 (1/4G,1 1/2PC,1/4G)', label: 'L6.BR.GCPC: 2 (1/4G,1 1/2PC,1/4G)' },
  {
    value: 'L6.BR.GCPC: 2 (1/8G,1/2PC,1/8G,1PC,1/8G)',
    label: 'L6.BR.GCPC: 2 (1/8G,1/2PC,1/8G,1PC,1/8G)',
  },
  {
    value: 'L6.BR.GCPC: 2 (1/8G,1 3/4PC,1/8G,NSC)',
    label: 'L6.BR.GCPC: 2 (1/8G,1 3/4PC,1/8G,NSC)',
  },
  {
    value: 'L6.BR.GCPC: 2 (1/4G,1 1/2PC,1/4G,NSC)',
    label: 'L6.BR.GCPC: 2 (1/4G,1 1/2PC,1/4G,NSC)',
  },
  {
    value: 'L6.BR.GCPC: 2 (1/8G,1/2PC,1/8G,1PC,1/8G,NSC)',
    label: 'L6.BR.GCPC: 2 (1/8G,1/2PC,1/8G,1PC,1/8G,NSC)',
  },
  { value: 'L6.BR.GCPC: OTHER SEE NOTES', label: 'L6.BR.GCPC: OTHER SEE NOTES' },

  {
    value: 'L7.BR.GCPC: 2 1/2 (1/8G,2 1/4PC,1/8G)',
    label: 'L7.BR.GCPC: 2 1/2 (1/8G,2 1/4PC,1/8G)',
  },
  { value: 'L7.BR.GCPC: 2 1/2 (1/4G,2PC,1/4G)', label: 'L7.BR.GCPC: 2 1/2 (1/4G,2PC,1/4G)' },
  {
    value: 'L7.BR.GCPC: 2 1/2 (1/8G,1/2PC,1/8G,1/2PC,1/8G)',
    label: 'L7.BR.GCPC: 2 1/2 (1/8G,1/2PC,1/8G,1/2PC,1/8G)',
  },
  {
    value: 'L7.BR.GCPC: 2 1/2 (1/8G,1/4PC,1/8G,1/4PC,1/8G,1/4PC,1/8G)',
    label: 'L7.BR.GCPC: 2 1/2 (1/8G,1/4PC,1/8G,1/4PC,1/8G,1/4PC,1/8G)',
  },
  {
    value: 'L7.BR.GCPC: 2 1/2 (1/8G,2 1/4PC,1/8G,NSC)',
    label: 'L7.BR.GCPC: 2 1/2 (1/8G,2 1/4PC,1/8G,NSC)',
  },
  {
    value: 'L7.BR.GCPC: 2 1/2 (1/4G,2PC,1/4G,NSC)',
    label: 'L7.BR.GCPC: 2 1/2 (1/4G,2PC,1/4G,NSC)',
  },
  {
    value: 'L7.BR.GCPC: 2 1/2 (1/8G,1/2PC,1/8G,1/2PC,1/8G,NSC)',
    label: 'L7.BR.GCPC: 2 1/2 (1/8G,1/2PC,1/8G,1/2PC,1/8G,NSC)',
  },
  { value: 'L7.BR.GCPC: OTHER SEE NOTES', label: 'L7.BR.GCPC: OTHER SEE NOTES' },

  { value: 'L8.BR.GCPC: 3 (1/8G,2 3/4PC,1/8G)', label: 'L8.BR.GCPC: 3 (1/8G,2 3/4PC,1/8G)' },
  { value: 'L8.BR.GCPC: 3 (1/4G,2 1/2PC,1/4G)', label: 'L8.BR.GCPC: 3 (1/4G,2 1/2PC,1/4G)' },
  {
    value: 'L8.BR.GCPC: 3 (1/8G,1/2PC,1/8G,1/2PC,1/8G,1/2PC,1/8G)',
    label: 'L8.BR.GCPC: 3 (1/8G,1/2PC,1/8G,1/2PC,1/8G,1/2PC,1/8G)',
  },
  {
    value: 'L8.BR.GCPC: 3 (1/8G,1/4PC,1/8G,1/2PC,1/8G,1/4PC,1/8G,1/4PC,1/8G)',
    label: 'L8.BR.GCPC: 3 (1/8G,1/4PC,1/8G,1/2PC,1/8G,1/4PC,1/8G,1/4PC,1/8G)',
  },
  {
    value: 'L8.BR.GCPC: 3 (1/8G,2 3/4PC,1/8G,NSC)',
    label: 'L8.BR.GCPC: 3 (1/8G,2 3/4PC,1/8G,NSC)',
  },
  {
    value: 'L8.BR.GCPC: 3 (1/4G,2 1/2PC,1/4G,NSC)',
    label: 'L8.BR.GCPC: 3 (1/4G,2 1/2PC,1/4G,NSC)',
  },
  {
    value: 'L8.BR.GCPC: 3 (1/8G,1/2PC,1/8G,1/2PC,1/8G,1/2PC,1/8G,NSC)',
    label: 'L8.BR.GCPC: 3 (1/8G,1/2PC,1/8G,1/2PC,1/8G,1/2PC,1/8G,NSC)',
  },
  {
    value: 'L8.BR.GCPC: 3 (1/8G,1/4PC,1/8G,1/2PC,1/8G,1/4PC,1/8G,1/4PC,1/8G,NSC)',
    label: 'L8.BR.GCPC: 3 (1/8G,1/4PC,1/8G,1/2PC,1/8G,1/4PC,1/8G,1/4PC,1/8G,NSC)',
  },
  { value: 'L8.BR.GCPC: OTHER SEE NOTES', label: 'L8.BR.GCPC: OTHER SEE NOTES' },

  {
    value: 'L1.BR.AG: 1 1/4 (5/16G,PVB-060,5/16G,PVB-060,5/16G)',
    label: 'L1.BR.AG: 1 1/4 (5/16G,PVB-060,5/16G,PVB-060,5/16G)',
  },
  {
    value: 'L1.BR.AG: 1 1/2 (3/8G,PVB-090,3/8G,PVB-090,3/8G)',
    label: 'L1.BR.AG: 1 1/2 (3/8G,PVB-090,3/8G,PVB-090,3/8G)',
  },
  {
    value: 'L1.BR.AG: 1 3/4 (1/2G,PVB-060,1/2G,PVB-060,1/2G)',
    label: 'L1.BR.AG: 1 3/4 (1/2G,PVB-060,1/2G,PVB-060,1/2G)',
  },
  {
    value: 'L1.BR.AG: 1 1/4 (5/16G,ASP-060,5/16G,ASP-060,5/16G)',
    label: 'L1.BR.AG: 1 1/4 (5/16G,ASP-060,5/16G,ASP-060,5/16G)',
  },
  {
    value: 'L1.BR.AG: 1 1/2 (3/8G,ASP-090,3/8G,ASP-090,3/8G)',
    label: 'L1.BR.AG: 1 1/2 (3/8G,ASP-090,3/8G,ASP-090,3/8G)',
  },
  {
    value: 'L1.BR.AG: 1 3/4 (1/2G,ASP-060,1/2G,ASP-060,1/2G)',
    label: 'L1.BR.AG: 1 3/4 (1/2G,ASP-060,1/2G,ASP-060,1/2G)',
  },
  { value: 'L1.BR.AG: OTHER SEE NOTES', label: 'L1.BR.AG: OTHER SEE NOTES' },

  {
    value: 'L2.BR.AG: 1 3/8 (3/8G,PVB-090,3/8G,PVB-090,3/8G)',
    label: 'L2.BR.AG: 1 3/8 (3/8G,PVB-090,3/8G,PVB-090,3/8G)',
  },
  {
    value: 'L2.BR.AG: 1 5/8 (5/16G,PVB-060,5/16G,PVB-060,5/16G,PVB-060,5/16G)',
    label: 'L2.BR.AG: 1 5/8 (5/16G,PVB-060,5/16G,PVB-060,5/16G,PVB-060,5/16G)',
  },
  {
    value: 'L2.BR.AG: 1 7/8 (1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G)',
    label: 'L2.BR.AG: 1 7/8 (1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G)',
  },
  {
    value: 'L2.BR.AG: 1 3/8 (3/8G,ASP-090,3/8G,ASP-090,3/8G)',
    label: 'L2.BR.AG: 1 3/8 (3/8G,ASP-090,3/8G,ASP-090,3/8G)',
  },
  {
    value: 'L2.BR.AG: 1 5/8 (5/16G,ASP-060,5/16G,ASP-060,5/16G,ASP-060,5/16G)',
    label: 'L2.BR.AG: 1 5/8 (5/16G,ASP-060,5/16G,ASP-060,5/16G,ASP-060,5/16G)',
  },
  {
    value: 'L2.BR.AG: 1 7/8 (1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G)',
    label: 'L2.BR.AG: 1 7/8 (1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G)',
  },
  { value: 'L2.BR.AG: OTHER SEE NOTES', label: 'L2.BR.AG: OTHER SEE NOTES' },

  {
    value: 'L3.BR.AG: 1 5/8 (5/16G,PVB-060,5/16G,PVB-060,5/16G,PVB-060,5/16G)',
    label: 'L3.BR.AG: 1 5/8 (5/16G,PVB-060,5/16G,PVB-060,5/16G,PVB-060,5/16G)',
  },
  {
    value: 'L3.BR.AG: 2 (3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G)',
    label: 'L3.BR.AG: 2 (3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G)',
  },
  {
    value: 'L3.BR.AG: 2 1/4 (1/2G,PVB-060,1/2G,PVB-060,1/2G,PVB-060,1/2G)',
    label: 'L3.BR.AG: 2 1/4 (1/2G,PVB-060,1/2G,PVB-060,1/2G,PVB-060,1/2G)',
  },
  {
    value: 'L3.BR.AG: 1 5/8 (5/16G,ASP-060,5/16G,ASP-060,5/16G,ASP-060,5/16G)',
    label: 'L3.BR.AG: 1 5/8 (5/16G,ASP-060,5/16G,ASP-060,5/16G,ASP-060,5/16G)',
  },
  {
    value: 'L3.BR.AG: 2 (3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G)',
    label: 'L3.BR.AG: 2 (3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G)',
  },
  {
    value: 'L3.BR.AG: 2 1/4 (1/2G,ASP-060,1/2G,ASP-060,1/2G,ASP-060,1/2G)',
    label: 'L3.BR.AG: 2 1/4 (1/2G,ASP-060,1/2G,ASP-060,1/2G,ASP-060,1/2G)',
  },
  { value: 'L3.BR.AG: OTHER SEE NOTES', label: 'L3.BR.AG: OTHER SEE NOTES' },

  {
    value: 'L4.BR.AG: 2 (3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G)',
    label: 'L4.BR.AG: 2 (3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G)',
  },
  {
    value: 'L4.BR.AG: 2 1/4 (1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G)',
    label: 'L4.BR.AG: 2 1/4 (1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G)',
  },
  {
    value: 'L4.BR.AG: 2 1/2 (5/8G,PVB-060,5/8G,PVB-060,5/8G,PVB-060,5/8G)',
    label: 'L4.BR.AG: 2 1/2 (5/8G,PVB-060,5/8G,PVB-060,5/8G,PVB-060,5/8G)',
  },
  {
    value: 'L4.BR.AG: 2 (3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G)',
    label: 'L4.BR.AG: 2 (3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G)',
  },
  {
    value: 'L4.BR.AG: 2 1/4 (1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G)',
    label: 'L4.BR.AG: 2 1/4 (1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G)',
  },
  {
    value: 'L4.BR.AG: 2 1/2 (5/8G,ASP-060,5/8G,ASP-060,5/8G,ASP-060,5/8G)',
    label: 'L4.BR.AG: 2 1/2 (5/8G,ASP-060,5/8G,ASP-060,5/8G,ASP-060,5/8G)',
  },
  { value: 'L4.BR.AG: OTHER SEE NOTES', label: 'L4.BR.AG: OTHER SEE NOTES' },

  {
    value: 'L5.BR.AG: 2 3/8 (5/16G,PVB-090,5/16G,PVB-090,5/16G,PVB-090,5/16G,PVB-090,5/16G)',
    label: 'L5.BR.AG: 2 3/8 (5/16G,PVB-090,5/16G,PVB-090,5/16G,PVB-090,5/16G,PVB-090,5/16G)',
  },
  {
    value: 'L5.BR.AG: 2 5/8 (3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G)',
    label: 'L5.BR.AG: 2 5/8 (3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G)',
  },
  {
    value: 'L5.BR.AG: 2 7/8 (1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G)',
    label: 'L5.BR.AG: 2 7/8 (1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G)',
  },
  {
    value: 'L5.BR.AG: 2 3/8 (5/16G,ASP-090,5/16G,ASP-090,5/16G,ASP-090,5/16G,ASP-090,5/16G)',
    label: 'L5.BR.AG: 2 3/8 (5/16G,ASP-090,5/16G,ASP-090,5/16G,ASP-090,5/16G,ASP-090,5/16G)',
  },
  {
    value: 'L5.BR.AG: 2 5/8 (3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G)',
    label: 'L5.BR.AG: 2 5/8 (3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G)',
  },
  {
    value: 'L5.BR.AG: 2 7/8 (1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G)',
    label: 'L5.BR.AG: 2 7/8 (1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G)',
  },
  { value: 'L5.BR.AG: OTHER SEE NOTES', label: 'L5.BR.AG: OTHER SEE NOTES' },

  {
    value: 'L6.BR.AG: 2 1/2 (1/2G,PVB-060,1/2G,PVB-060,1/2G,PVB-060,1/2G)',
    label: 'L6.BR.AG: 2 1/2 (1/2G,PVB-060,1/2G,PVB-060,1/2G,PVB-060,1/2G)',
  },
  {
    value: 'L6.BR.AG: 2 3/4 (5/8G,PVB-060,5/8G,PVB-060,5/8G,PVB-060,5/8G)',
    label: 'L6.BR.AG: 2 3/4 (5/8G,PVB-060,5/8G,PVB-060,5/8G,PVB-060,5/8G)',
  },
  {
    value: 'L6.BR.AG: 3 (3/4G,PVB-060,3/4G,PVB-060,3/4G,PVB-060,3/4G)',
    label: 'L6.BR.AG: 3 (3/4G,PVB-060,3/4G,PVB-060,3/4G,PVB-060,3/4G)',
  },
  {
    value: 'L6.BR.AG: 2 1/2 (1/2G,ASP-060,1/2G,ASP-060,1/2G,ASP-060,1/2G)',
    label: 'L6.BR.AG: 2 1/2 (1/2G,ASP-060,1/2G,ASP-060,1/2G,ASP-060,1/2G)',
  },
  {
    value: 'L6.BR.AG: 2 3/4 (5/8G,ASP-060,5/8G,ASP-060,5/8G,ASP-060,5/8G)',
    label: 'L6.BR.AG: 2 3/4 (5/8G,ASP-060,5/8G,ASP-060,5/8G,ASP-060,5/8G)',
  },
  {
    value: 'L6.BR.AG: 3 (3/4G,ASP-060,3/4G,ASP-060,3/4G,ASP-060,3/4G)',
    label: 'L6.BR.AG: 3 (3/4G,ASP-060,3/4G,ASP-060,3/4G,ASP-060,3/4G)',
  },
  { value: 'L6.BR.AG: OTHER SEE NOTES', label: 'L6.BR.AG: OTHER SEE NOTES' },

  {
    value:
      'L7.BR.AG: 2 3/4 (3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G)',
    label:
      'L7.BR.AG: 2 3/4 (3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G,PVB-090,3/8G)',
  },
  {
    value: 'L7.BR.AG: 3 (1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G)',
    label: 'L7.BR.AG: 3 (1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G,PVB-090,1/2G)',
  },
  {
    value: 'L7.BR.AG: 3 1/4 (5/8G,PVB-090,5/8G,PVB-090,5/8G,PVB-090,5/8G,PVB-090,5/8G)',
    label: 'L7.BR.AG: 3 1/4 (5/8G,PVB-090,5/8G,PVB-090,5/8G,PVB-090,5/8G,PVB-090,5/8G)',
  },
  {
    value:
      'L7.BR.AG: 2 3/4 (3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G)',
    label:
      'L7.BR.AG: 2 3/4 (3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G,ASP-090,3/8G)',
  },
  {
    value: 'L7.BR.AG: 3 (1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G)',
    label: 'L7.BR.AG: 3 (1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G,ASP-090,1/2G)',
  },
  {
    value: 'L7.BR.AG: 3 1/4 (5/8G,ASP-090,5/8G,ASP-090,5/8G,ASP-090,5/8G,ASP-090,5/8G)',
    label: 'L7.BR.AG: 3 1/4 (5/8G,ASP-090,5/8G,ASP-090,5/8G,ASP-090,5/8G,ASP-090,5/8G)',
  },
  { value: 'L7.BR.AG: OTHER SEE NOTES', label: 'L7.BR.AG: OTHER SEE NOTES' },
  { value: '2', label: '2' },
  { value: '2 1/16', label: '2 1/16' },
  { value: '2 1/8', label: '2 1/8' },
  { value: '2 3/16', label: '2 3/16' },
  { value: '2 1/4', label: '2 1/4' },
  { value: '2 5/16', label: '2 5/16' },
  { value: '2 3/8', label: '2 3/8' },
  { value: '2 7/16', label: '2 7/16' },
  { value: '2 1/2', label: '2 1/2' },
  { value: '2 9/16', label: '2 9/16' },
  { value: '2 5/8', label: '2 5/8' },
  { value: '2 11/16', label: '2 11/16' },
  { value: '2 3/4', label: '2 3/4' },
  { value: '2 13/16', label: '2 13/16' },
  { value: '2 7/8', label: '2 7/8' },
  { value: '2 15/16', label: '2 15/16' },
  { value: '3', label: '3' },
  { value: '3 1/16', label: '3 1/16' },
  { value: '3 1/8', label: '3 1/8' },
  { value: '3 3/16', label: '3 3/16' },
  { value: '3 1/4', label: '3 1/4' },
  { value: '3 5/16', label: '3 5/16' },
  { value: '3 3/8', label: '3 3/8' },
  { value: '3 7/16', label: '3 7/16' },
  { value: '3 1/2', label: '3 1/2' },
  { value: '3 9/16', label: '3 9/16' },
  { value: '3 5/8', label: '3 5/8' },
  { value: '3 11/16', label: '3 11/16' },
  { value: '3 3/4', label: '3 3/4' },
  { value: '3 13/16', label: '3 13/16' },
  { value: '3 7/8', label: '3 7/8' },
  { value: '3 15/16', label: '3 15/16' },
  { value: '4', label: '4' },
  { value: '4 1/16', label: '4 1/16' },
  { value: '4 1/8', label: '4 1/8' },
  { value: '4 3/16', label: '4 3/16' },
  { value: '4 1/4', label: '4 1/4' },
  { value: '4 5/16', label: '4 5/16' },
  { value: '4 3/8', label: '4 3/8' },
  { value: '4 7/16', label: '4 7/16' },
  { value: '4 1/2', label: '4 1/2' },
  { value: '4 9/16', label: '4 9/16' },
  { value: '4 5/8', label: '4 5/8' },
  { value: '4 11/16', label: '4 11/16' },
  { value: '4 3/4', label: '4 3/4' },
  { value: '4 13/16', label: '4 13/16' },
  { value: '4 7/8', label: '4 7/8' },
  { value: '4 15/16', label: '4 15/16' },
  { value: '5', label: '5' },
  { value: '5 1/16', label: '5 1/16' },
  { value: '5 1/8', label: '5 1/8' },
  { value: '5 3/16', label: '5 3/16' },
  { value: '5 1/4', label: '5 1/4' },
  { value: '5 5/16', label: '5 5/16' },
  { value: '5 3/8', label: '5 3/8' },
  { value: '5 7/16', label: '5 7/16' },
  { value: '5 1/2', label: '5 1/2' },
  { value: '5 9/16', label: '5 9/16' },
  { value: '5 5/8', label: '5 5/8' },
  { value: '5 11/16', label: '5 11/16' },
  { value: '5 3/4', label: '5 3/4' },
  { value: '5 13/16', label: '5 13/16' },
  { value: '5 7/8', label: '5 7/8' },
  { value: '5 15/16', label: '5 15/16' },
  { value: '6', label: '6' },
  { value: '6 1/16', label: '6 1/16' },
  { value: '6 1/8', label: '6 1/8' },
  { value: '6 3/16', label: '6 3/16' },
  { value: '6 1/4', label: '6 1/4' },
  { value: '6 5/16', label: '6 5/16' },
  { value: '6 3/8', label: '6 3/8' },
  { value: '6 7/16', label: '6 7/16' },
  { value: '6 1/2', label: '6 1/2' },
  { value: '6 9/16', label: '6 9/16' },
  { value: '6 5/8', label: '6 5/8' },
  { value: '6 11/16', label: '6 11/16' },
  { value: '6 3/4', label: '6 3/4' },
  { value: '6 13/16', label: '6 13/16' },
  { value: '6 7/8', label: '6 7/8' },
  { value: '6 15/16', label: '6 15/16' },
  { value: 'Other (see notes)', label: 'Other (see notes)' },
  { value: 'Research (see notes)', label: 'Research (see notes)' },
  { value: 'Custom (see notes)', label: 'Custom (see notes)' },
];
