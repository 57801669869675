export const isRequired = text => {
  if (text) {
    return null;
  }

  return 'Required';
};

export const isEmail = text => {
  if (!text) {
    return 'Required';
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(text)) {
    return 'Invalid email address';
  }

  return null;
};

export const isZipCode = text => {
  if (!text) {
    return 'Required';
  }
  if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/i.test(text)) {
    return 'Invalid zip code';
  }

  return null;
};
