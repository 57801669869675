import { all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import client from 'modules/client';
import { startupApp } from './startup';
import {
  login,
  logout,
  findCompany,
  recoverPassword,
  resetPassword,
  updateProfile,
  updateCompany,
  getProfile,
  getCompany,
  changePassword,
  cronofyRegisterUser,
  cronofyGetCalendars,
  cronofyRevokeAuthorization,
} from './auth';
import {
  getUsers,
  createUser,
  getUser,
  editUser,
  deleteUser,
  getSalesman,
  getCustomer,
  getOwners,
} from './user';
import { getJobs, getJob, createJob, editJob, deleteJob, acceptJob, createJobPdf } from './job';
import { getEvents, getEvent, createEvent, editEvent, deleteEvent, getEventsByJob } from './event';
import { getProducts, registerCompany, checkSubdomain } from './registerCompany';
import { sendContact } from './contact';
import { createInquiry } from './inquiry';
/**
 * rootSaga
 */
const api = client.create();

export default function* root() {
  yield all([
    // startup
    takeLatest(ActionTypes.STARTUP, startupApp, api),
    // auth
    takeLatest(ActionTypes.LOGIN, login, api),
    takeLatest(ActionTypes.FIND_COMPANY, findCompany, api),
    takeLatest(ActionTypes.RECOVER_PASSWORD, recoverPassword, api),
    takeLatest(ActionTypes.RESET_PASSWORD, resetPassword, api),
    takeLatest(ActionTypes.LOGOUT, logout, api),
    // user
    takeLatest(ActionTypes.GET_USERS, getUsers, api),
    takeLatest(ActionTypes.GET_USER, getUser, api),
    takeLatest(ActionTypes.CREATE_USER, createUser, api),
    takeLatest(ActionTypes.EDIT_USER, editUser, api),
    takeLatest(ActionTypes.DELETE_USER, deleteUser, api),

    // owners
    takeLatest(ActionTypes.GET_OWNERS, getOwners, api),

    // salesman
    takeLatest(ActionTypes.GET_SALESMAN, getSalesman, api),
    takeLatest(ActionTypes.GET_CUSTOMER, getCustomer, api),

    // job
    takeLatest(ActionTypes.GET_JOBS, getJobs, api),
    takeLatest(ActionTypes.CREATE_JOB, createJob, api),
    takeLatest(ActionTypes.GET_JOB, getJob, api),
    takeLatest(ActionTypes.EDIT_JOB, editJob, api),
    takeLatest(ActionTypes.ACCEPT_JOB, acceptJob, api),
    takeLatest(ActionTypes.DELETE_JOB, deleteJob, api),
    takeLatest(ActionTypes.CREATE_JOB_PDF, createJobPdf, api),

    // event
    takeLatest(ActionTypes.GET_EVENTS, getEvents, api),
    takeLatest(ActionTypes.GET_EVENTS_BY_JOB, getEventsByJob, api),
    takeLatest(ActionTypes.CREATE_EVENT, createEvent, api),
    takeLatest(ActionTypes.GET_EVENT, getEvent, api),
    takeLatest(ActionTypes.EDIT_EVENT, editEvent, api),
    takeLatest(ActionTypes.DELETE_EVENT, deleteEvent, api),

    // settings
    takeLatest(ActionTypes.GET_PROFILE, getProfile, api),
    takeLatest(ActionTypes.GET_COMPANY, getCompany, api),

    takeLatest(ActionTypes.UPDATE_PROFILE, updateProfile, api),
    takeLatest(ActionTypes.UPDATE_COMPANY, updateCompany, api),
    takeLatest(ActionTypes.CHANGE_PASSWORD, changePassword, api),
    takeLatest(ActionTypes.CRONOFY_REGISTER_USER, cronofyRegisterUser, api),
    takeLatest(ActionTypes.CRONOFY_GET_CALENDARS, cronofyGetCalendars, api),
    takeLatest(ActionTypes.CRONOFY_REVOKE_AUTHORIZATION, cronofyRevokeAuthorization, api),
    // register company
    takeLatest(ActionTypes.GET_PRODUCTS, getProducts, api),
    takeLatest(ActionTypes.REGISTER_COMPANY, registerCompany, api),
    takeLatest(ActionTypes.CHECK_SUBDOMAIN, checkSubdomain, api),
    // contact
    takeLatest(ActionTypes.SEND_CONTACT, sendContact, api),

    // inquiry
    takeLatest(ActionTypes.CREATE_INQUIRY, createInquiry, api),
  ]);
}
