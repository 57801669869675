import React, { Component } from 'react';
import { Field, FieldArray } from 'formik';
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import {
  HoleType,
  XLocationStart,
  YLocationStart,
  GridThickness,
  GridColor,
  OffsetLite,
  OffsetFill,
  OffsetSide,
  OffsetOffset,
} from 'constants/glassTypeOptions';
import { isRequired } from 'utils/rules';

const INITIAL_HOLE = {
  hole_type: '',
  exterior_size: '',
  interior_size: '',
  size: '',
  location_type_x: '',
  location_x: '',
  location_type_y: '',
  location_y: '',
  holes_notes: '',
};

const INITIAL_OFFSET = {
  lite: '',
  fill: '',
  side: '',
  offset: '',
  offset_notes: '',
};
export default class FabricationView extends Component {
  addValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) + 1);
  };

  substractValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, parseInt(value || 0, 10) - 1);
  };

  push = (form, field, key, value) => {
    const { setFieldValue, prefix } = this.props;
    const path = prefix.split('.');
    const { values } = form;

    let tmp = values;
    path.forEach(field => {
      tmp = tmp[field];
    });

    const array = tmp[field];
    setFieldValue(`${prefix}.${field}`, [...array, value]);
  };

  remove = (form, field, key) => {
    const { setFieldValue, prefix } = this.props;
    const path = prefix.split('.');
    const { values } = form;

    let tmp = values;
    path.forEach(field => {
      tmp = tmp[field];
    });

    const array = tmp[field];
    setFieldValue(
      `${prefix}.${field}`,
      array.filter((_, index) => index !== key),
    );
  };

  renderOffset = (offset, key, form, size, errors = {}, touched = {}) => {
    const { prefix, setFieldValue, handleBlur } = this.props;
    const handleChange = evt => {
      const { name, value } = evt.target;
      setFieldValue(name, value);
    };
    return (
      <Row className="mx-0 mt-3">
        <Col xs={9} sm={9} md={9} xl={9}>
          <Form.Label className="text-info font-weight-bold">Offset {key + 1}</Form.Label>
        </Col>
        <Col xs={3} sm={3} md={3} xl={3}>
          {size - 1 === key ? (
            <i
              className="fas fa-plus-square float-right"
              style={{ fontSize: '20px', color: '#28a745', cursor: 'pointer' }}
              onClick={() => this.push(form, 'offsets', key, INITIAL_OFFSET)}
            />
          ) : (
            <i
              className="fas fa-minus-square float-right"
              style={{ fontSize: '20px', color: '#dc3545', cursor: 'pointer' }}
              onClick={() => this.remove(form, 'offsets', key)}
            />
          )}
        </Col>
        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.offsets.${key}.lite`}>
            <Form.Label>Lite</Form.Label>
            <Field
              as="select"
              className={
                errors.lite && touched.lite
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`${prefix}.offsets.${key}.lite`}
              value={offset.lite}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Choose a lite</option>
              {OffsetLite.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.offsets.${key}.fill`}>
            <Form.Label>Fill</Form.Label>
            <Field
              as="select"
              className={
                errors.fill && touched.fill
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`${prefix}.offsets.${key}.fill`}
              value={offset.fill}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Choose a fill</option>
              {OffsetFill.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.offsets.${key}.side`}>
            <Form.Label>Side</Form.Label>
            <Field
              as="select"
              className={
                errors.side && touched.side
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`${prefix}.offsets.${key}.side`}
              value={offset.side}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Choose a side</option>
              {OffsetSide.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.offsets.${key}.offset`}>
            <Form.Label>Offset</Form.Label>
            <Field
              as="select"
              className={
                errors.offset && touched.offset
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`${prefix}.offsets.${key}.offset`}
              value={offset.offset}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Choose an offset</option>
              {OffsetOffset.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId={`${prefix}.offsets.${key}.offset_notes`}>
            <Form.Label>Notes</Form.Label>
            <Field
              as="textarea"
              className={
                errors.offset_notes && touched.offset_notes
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              name={`${prefix}.offsets.${key}.offset_notes`}
              value={offset.offset_notes}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={3}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  renderHole = (hole, key, form, size, errors = {}, touched = {}) => {
    const { prefix, setFieldValue, handleBlur } = this.props;
    const handleChange = evt => {
      const { name, value } = evt.target;
      setFieldValue(name, value);
    };
    return (
      <Row className="mx-0 mt-3">
        <Col xs={9} sm={9} md={9} xl={9}>
          <Form.Label className="text-info font-weight-bold">Hole {key + 1}</Form.Label>
        </Col>
        <Col xs={3} sm={3} md={3} xl={3}>
          {size - 1 === key ? (
            <i
              className="fas fa-plus-square float-right"
              style={{ fontSize: '20px', color: '#28a745', cursor: 'pointer' }}
              onClick={() => this.push(form, 'holes', key, INITIAL_HOLE)}
            />
          ) : (
            <i
              className="fas fa-minus-square float-right"
              style={{ fontSize: '20px', color: '#dc3545', cursor: 'pointer' }}
              onClick={() => this.remove(form, 'holes', key)}
            />
          )}
        </Col>
        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.holes.${key}.hole_type`}>
            <Form.Label>Holes Type</Form.Label>
            <Field
              as="select"
              className={
                errors.hole_type && touched.hole_type
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name={`${prefix}.holes.${key}.hole_type`}
              value={hole.hole_type}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Choose a hole type</option>
              {HoleType.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
          </Form.Group>
        </Col>
        {hole.hole_type === 'csc' && (
          <Col xs={6} sm={6} md={3} xl={3}>
            <Form.Group controlId={`${prefix}.holes.${key}.exterior_size`}>
              <Form.Label>Exterior Size</Form.Label>
              <Form.Control
                as={Field}
                name={`${prefix}.holes.${key}.exterior_size`}
                type="text"
                placeholder="Exterior Size"
                value={hole.exterior_size}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
        )}
        {hole.hole_type === 'csc' && (
          <Col xs={6} sm={6} md={3} xl={3}>
            <Form.Group controlId={`${prefix}.holes.${key}.interior_size`}>
              <Form.Label>Interior Size</Form.Label>
              <Form.Control
                as={Field}
                name={`${prefix}.holes.${key}.interior_size`}
                type="text"
                placeholder="Interior Size"
                value={hole.interior_size}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
        )}

        {hole.hole_type !== 'csc' && (
          <Col xs={12} sm={12} md={6} xl={6}>
            <Form.Group controlId={`${prefix}.holes.${key}.size`}>
              <Form.Label>Size</Form.Label>
              <Form.Control
                as={Field}
                name={`${prefix}.holes.${key}.size`}
                type="text"
                placeholder="Interior Size"
                value={hole.size}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
        )}

        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.holes.${key}.location_type_x`}>
            <Form.Label>Location C/L</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="X">X</InputGroup.Text>
              </InputGroup.Prepend>
              <Field
                as="select"
                className={
                  errors.location_type_x && touched.location_type_x
                    ? 'form-control is-invalid is-invalid-select'
                    : 'form-control'
                }
                name={`${prefix}.holes.${key}.location_type_x`}
                value={hole.location_type_x}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">X Location Starting Point</option>
                {XLocationStart.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.holes.${key}.location_x`}>
            <Form.Label>Location</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="X">X</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as={Field}
                name={`${prefix}.holes.${key}.location_x`}
                type="text"
                placeholder="Location"
                value={hole.location_x}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.holes.${key}.location_type_y`}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="Y">Y</InputGroup.Text>
              </InputGroup.Prepend>
              <Field
                as="select"
                className={
                  errors.location_type_y && touched.location_type_y
                    ? 'form-control is-invalid is-invalid-select'
                    : 'form-control'
                }
                name={`${prefix}.holes.${key}.location_type_y`}
                value={hole.location_type_y}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Y Location Starting Point</option>
                {YLocationStart.map(({ label, value }, index) => (
                  <option value={value} key={index}>
                    {label}
                  </option>
                ))}
              </Field>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6} xl={6}>
          <Form.Group controlId={`${prefix}.holes.${key}.location_y`}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="Y">Y</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as={Field}
                name={`${prefix}.holes.${key}.location_y`}
                type="text"
                placeholder="Location"
                value={hole.location_y}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId={`${prefix}.holes.${key}.holes_notes`}>
            <Form.Label>Notes</Form.Label>
            <Field
              as="textarea"
              className={
                errors.holes_notes && touched.holes_notes
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              name={`${prefix}.holes.${key}.holes_notes`}
              value={hole.holes_notes}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={3}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  render() {
    const { values, errors, handleChange, touched, handleBlur, setFieldValue, prefix } = this.props;
    return (
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId={`${prefix}.polished_edges`}>
            <Form.Label>
              <Form.Check
                as={Field}
                type="switch"
                id={`${prefix}.polished_edges_avaliable`}
                name={`${prefix}.polished_edges_avaliable`}
                defaultValue={values.polished_edges_avaliable}
                onChange={handleChange}
                label="Polished Edges"
              />
            </Form.Label>

            {!!values.polished_edges_avaliable && (
              <div>
                <Form.Control
                  as={Field}
                  name={`${prefix}.polished_edges`}
                  type="text"
                  placeholder="Polished Edges"
                  value={values.polished_edges}
                  isInvalid={!!errors.polished_edges && touched.polished_edges}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.polished_edges && touched.polished_edges && errors.polished_edges}
                </Form.Control.Feedback>
              </div>
            )}
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId={`${prefix}.pattern`}>
            <Form.Label>
              <Form.Check
                as={Field}
                type="switch"
                id={`${prefix}.pattern_avaliable`}
                name={`${prefix}.pattern_avaliable`}
                defaultValue={values.pattern_avaliable}
                onChange={handleChange}
                label="Pattern"
              />
            </Form.Label>
            {!!values.pattern_avaliable && (
              <div>
                <Form.Control
                  as={Field}
                  name={`${prefix}.pattern`}
                  type="text"
                  placeholder="Pattern"
                  value={values.pattern}
                  isInvalid={!!errors.pattern && touched.pattern}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.pattern && touched.pattern && errors.pattern}
                </Form.Control.Feedback>
              </div>
            )}
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Check
            as={Field}
            type="switch"
            id={`${prefix}.hole_avaliable`}
            name={`${prefix}.hole_avaliable`}
            defaultValue={values.hole_avaliable}
            onChange={evt => {
              const isAvaliable = evt.target.value;
              setFieldValue(`${prefix}.holes`, isAvaliable ? [INITIAL_HOLE] : []);
              handleChange(evt);
            }}
            label="Holes"
          />
        </Col>
        {!!values.hole_avaliable && (
          <FieldArray
            name="holes"
            render={({ form, push, remove }) => (
              <div>
                {values.holes.map((hole, key) =>
                  this.renderHole(
                    hole,
                    key,
                    form,
                    values.holes.length,
                    errors && errors.holes ? errors.holes[key] : {},
                    touched && touched.holes ? touched.holes[key] : {},
                  ),
                )}
              </div>
            )}
          />
        )}
        {/* GRID */}
        <Col xs={12} sm={12} md={12} xl={12} className="mt-3">
          <Form.Check
            as={Field}
            type="switch"
            id={`${prefix}.grid_avaliable`}
            name={`${prefix}.grid_avaliable`}
            defaultValue={values.grid_avaliable}
            onChange={handleChange}
            label="Grids"
          />
        </Col>
        {!!values.grid_avaliable && (
          <Row className="mx-0 mt-3">
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.grid_thick`}>
                <Form.Label>Thickness</Form.Label>
                <Field
                  as="select"
                  className={
                    errors.grid_thick && touched.grid_thick
                      ? 'form-control is-invalid is-invalid-select'
                      : 'form-control'
                  }
                  name={`${prefix}.grid_thick`}
                  value={values.grid_thick}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Choose a grid thickness</option>
                  {GridThickness.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Field>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.grid_color`}>
                <Form.Label>Grids Color</Form.Label>
                <Field
                  as="select"
                  className={
                    errors.grid_color && touched.grid_color
                      ? 'form-control is-invalid is-invalid-select'
                      : 'form-control'
                  }
                  name={`${prefix}.grid_color`}
                  value={values.grid_color}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Choose a color</option>
                  {GridColor.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Field>
                <Form.Control.Feedback type="invalid">
                  {errors.grid_color && touched.grid_color && errors.grid_color}
                </Form.Control.Feedback>
              </Form.Group>

              {values.grid_color === 'Custom' && (
                <Form.Group controlId={`${prefix}.custom_color`}>
                  <Form.Label>Custom Color</Form.Label>
                  <Form.Control
                    as={Field}
                    name={`${prefix}.custom_color`}
                    type="text"
                    placeholder="Custom Color"
                    value={values.custom_color}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.custom_color && touched.custom_color && errors.custom_color}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.horizontal_grids`}>
                <Form.Label>Horizontal Grids</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        this.substractValue(`${prefix}.horizontal_grids`, values.horizontal_grids)
                      }
                    >
                      -
                    </Button>
                  </InputGroup.Prepend>
                  <Form.Control
                    as={Field}
                    type="number"
                    name={`${prefix}.horizontal_grids`}
                    value={values.horizontal_grids}
                    onChange={handleChange}
                    placeholder="Horizontal Grids"
                    onBlur={handleBlur}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        this.addValue(`${prefix}.horizontal_grids`, values.horizontal_grids)
                      }
                    >
                      +
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.horizontal_grids && touched.horizontal_grids && errors.horizontal_grids}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId={`${prefix}.vertical_grid`}>
                <Form.Label>Vertical Grids</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        this.substractValue(`${prefix}.vertical_grid`, values.vertical_grid)
                      }
                    >
                      -
                    </Button>
                  </InputGroup.Prepend>
                  <Form.Control
                    as={Field}
                    type="number"
                    name={`${prefix}.vertical_grid`}
                    value={values.vertical_grid}
                    onChange={handleChange}
                    placeholder="Vertical Grids"
                    onBlur={handleBlur}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={() => this.addValue(`${prefix}.vertical_grid`, values.vertical_grid)}
                    >
                      +
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.vertical_grid && touched.vertical_grid && errors.vertical_grid}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        )}
        {/* OFFSET */}

        <Col xs={12} sm={12} md={12} xl={12} className="mt-3">
          <Form.Check
            as={Field}
            type="switch"
            id={`${prefix}.offset_avaliable`}
            name={`${prefix}.offset_avaliable`}
            defaultValue={values.offset_avaliable}
            onChange={evt => {
              const isAvaliable = evt.target.value;
              setFieldValue(`${prefix}.offsets`, isAvaliable ? [INITIAL_OFFSET] : []);
              handleChange(evt);
            }}
            label="Offsets"
          />
        </Col>
        {!!values.offset_avaliable && (
          <FieldArray
            name="offsets"
            render={({ form, push, remove }) => (
              <div>
                {values.offsets.map((offset, key) =>
                  this.renderOffset(
                    offset,
                    key,
                    form,
                    values.offsets.length,
                    errors && errors.offsets ? errors.offsets[key] : {},
                    touched && touched.offsets ? touched.offsets[key] : {},
                  ),
                )}
              </div>
            )}
          />
        )}
      </Row>
    );
  }
}
