import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { inquiryData } from './data';
import InquiryImage from './inquiry.png';
import InquiryForm from './InquiryForm';

const Inquiry = ({ createInquiry, loading, errors }) => (
  <Row
    className="py-5 d-flex align-items-start"
    id="inquiry"
    style={{
      backgroundImage: `url(${InquiryImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <Col md={1} />
    <Col xs={10} md={4} className="my-2 py-2 my-md-4 py-md-4 mx-3 mx-md-0">
      {inquiryData.map((item, index) => (
        <Row key={index}>
          <h5 className="primary-color landing-h5">{item.title}</h5>
          <p className="dark-gray-color landing-h8 py-4">{item.description}</p>
        </Row>
      ))}
    </Col>
    <Col md={1} />

    <Col xs={12} md={5} className="my-md-4 py-md-4">
      <InquiryForm createInquiry={createInquiry} loading={loading} errors={errors} />
    </Col>
  </Row>
);

Inquiry.propTypes = {
  createInquiry: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.object,
};

export default Inquiry;
