import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { push } from 'modules/history';
import { Colors } from 'modules/theme';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import PageWithBackgroundImage from 'components/PageWithBackgroundImage';
import Icon from 'components/Icon';

export default class RegisterCompanyView extends Component {
  submit = values => {
    const { registerCompanySaveData, registerCompanyChangeStep } = this.props;
    registerCompanySaveData({ values });
    registerCompanyChangeStep({ step: 2 });
  };

  render() {
    const { register_data, checkSubdomain, subdomainLoading, subdomainValid } = this.props;
    return (
      <PageWithBackgroundImage>
        <Formik
          enableReinitialize
          initialValues={register_data}
          onSubmit={this.submit}
          validationSchema={Yup.object().shape({
            //user fields
            username: Yup.string().required('Required'),
            email: Yup.string()
              .required('Required')
              .email('Invalid Format'),
            confirm_email: Yup.string()
              .required('Required')
              .test('passwords-match', 'Emails dont match', function(value) {
                return this.parent.email === value;
              }),
            first_name: Yup.string().required('Required'),
            phone_number: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            password: Yup.string()
              .required('Required')
              .min(8, 'The password must have more than eight characters'),
            confirm_password: Yup.string()
              .required('Required')
              .test('passwords-match', 'Passwords dont match', function(value) {
                return this.parent.password === value;
              }),

            //company fields
            company_name: Yup.string().required('Required'),
            company_address: Yup.string().required('Required'),
            company_city: Yup.string().required('Required'),
            company_state: Yup.string().required('Required'),
            company_subdomain: Yup.string().required('Required'),
            /*company_url: Yup.string()
              .url('Invalid url')
              .required('Required'),*/
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <Container className="my-5">
                <Row className="mt-5 px-5">
                  <Col>
                    <h2 className="align-items-center">
                      <Icon
                        fontSize={30}
                        name="chevron-left"
                        color={Colors.blue}
                        customStyle={{ paddingRight: '20px' }}
                        onClick={() => push('/')}
                      />
                      Sign up
                    </h2>
                  </Col>
                </Row>
                <Form onSubmit={handleSubmit}>
                  <Row className="mt-5 px-5">
                    <Col>
                      <h2>Company Information</h2>
                    </Col>
                  </Row>
                  <Row className="px-5">
                    <Col>
                      <Form.Group className="my-4" controlId="company_name">
                        <Form.Control
                          className="form-control-lg"
                          type="text"
                          name="company_name"
                          placeholder="Enter name"
                          value={values.company_name}
                          onChange={handleChange}
                          isValid={touched.company_name && !errors.company_name}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.company_name && errors.company_name}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="company_address">
                        <Form.Control
                          className="form-control-lg"
                          type="text"
                          name="company_address"
                          placeholder="Enter address"
                          value={values.company_address}
                          onChange={handleChange}
                          isValid={touched.company_address && !errors.company_address}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.company_address && errors.company_address}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="company_city">
                        <Form.Control
                          className="form-control-lg"
                          type="text"
                          name="company_city"
                          placeholder="Enter city"
                          value={values.company_city}
                          onChange={handleChange}
                          isValid={touched.company_city && !errors.company_city}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.company_city && errors.company_city}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="company_state">
                        <Form.Control
                          className="form-control-lg"
                          type="text"
                          name="company_state"
                          placeholder="Enter state"
                          value={values.company_state}
                          onChange={handleChange}
                          isValid={touched.company_state && !errors.company_state}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.company_state && errors.company_state}
                        </Form.Text>
                      </Form.Group>
                      {/*<Form.Group className="my-4" controlId="company_url">
                        <Form.Control
                          className="form-control-lg"
                          type="text"
                          name="company_url"
                          placeholder="Enter Url"
                          value={values.company_url}
                          onChange={handleChange}
                          isValid={touched.company_url && !errors.company_url}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.company_url && errors.company_url}
                        </Form.Text>
                      </Form.Group>*/}
                      <Form.Group className="my-4" controlId="company_subdomain">
                        <Form.Control
                          className="form-control-lg"
                          type="text"
                          name="company_subdomain"
                          placeholder="Enter subdomain"
                          value={values.company_subdomain}
                          onChange={e => {
                            const value = event.target.value.replace(/ /g, '');
                            setFieldValue(event.target.name, value);
                            checkSubdomain(e.target.value);
                          }}
                          isValid={
                            touched.company_subdomain &&
                            !errors.company_subdomain &&
                            !(touched.company_subdomain && !subdomainValid && !subdomainLoading)
                          }
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.company_subdomain && errors.company_subdomain}
                        </Form.Text>

                        <Form.Text className="text-danger">
                          {touched.company_subdomain &&
                            !subdomainValid &&
                            !subdomainLoading &&
                            'Subdomain is invalid'}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-5 px-5">
                    <Col>
                      <h2>User Information</h2>
                    </Col>
                  </Row>
                  <Row className="px-5">
                    <Col>
                      <Form.Group className="my-4" controlId="first_name">
                        <Form.Control
                          className="form-control-lg"
                          type="first_name"
                          first_name="first_name"
                          placeholder="Enter first name"
                          value={values.first_name}
                          onChange={handleChange}
                          isValid={touched.first_name && !errors.first_name}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.first_name && errors.first_name}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="last_name">
                        <Form.Control
                          className="form-control-lg"
                          type="text"
                          name="last_name"
                          placeholder="Enter last name"
                          value={values.last_name}
                          onChange={handleChange}
                          isValid={touched.last_name && !errors.last_name}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.last_name && errors.last_name}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="username">
                        <Form.Control
                          className="form-control-lg"
                          type="text"
                          name="username"
                          placeholder="Enter username"
                          value={values.username}
                          onChange={handleChange}
                          isValid={touched.username && !errors.username}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.username && errors.username}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="phone_number">
                        <Form.Control
                          className="form-control-lg"
                          type="phone"
                          name="phone_number"
                          placeholder="Enter phone number"
                          value={values.phone_number}
                          onChange={handleChange}
                          isValid={touched.phone_number && !errors.phone_number}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.phone_number && errors.phone_number}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="email">
                        <Form.Control
                          className="form-control-lg"
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.email && errors.email}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="confirm_email">
                        <Form.Control
                          className="form-control-lg"
                          type="email"
                          name="confirm_email"
                          placeholder="Confirm email"
                          value={values.confirm_email}
                          onChange={handleChange}
                          isValid={touched.confirm_email && !errors.confirm_email}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.confirm_email && errors.confirm_email}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="password">
                        <Form.Control
                          className="form-control-lg"
                          type="password"
                          name="password"
                          placeholder="Enter password"
                          value={values.password}
                          onChange={handleChange}
                          isValid={touched.password && !errors.password}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.password && errors.password}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="my-4" controlId="confirm_password">
                        <Form.Control
                          className="form-control-lg"
                          type="password"
                          name="confirm_password"
                          placeholder="Confirm password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          isValid={touched.confirm_password && !errors.confirm_password}
                          onBlur={handleBlur}
                        />
                        <Form.Text className="text-danger">
                          {touched.confirm_password && errors.confirm_password}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="px-5">
                    <Col className="d-flex">
                      <Button
                        variant="primary"
                        size="lg"
                        block
                        type="submit"
                        disabled={
                          !isValid ||
                          (touched.company_subdomain && !subdomainValid && !subdomainLoading)
                        }
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            textAlign: 'center',
                          }}
                        >
                          Next
                        </p>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            );
          }}
        </Formik>
      </PageWithBackgroundImage>
    );
  }
}
