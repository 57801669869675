import React, { Component } from 'react';
import { Row, Col, Navbar, Form } from 'react-bootstrap';
import { Field } from 'formik';
import { States } from 'constants/index';
import { isRequired, isZipCode } from 'utils/rules';

export default class BillingAddressView extends Component {
  render() {
    const { values, errors, handleChange, touched, handleBlur, salesmans, csrs } = this.props;

    return (
      <Row className="px-4 py-4 ml-1 mr-1" style={{ marginBottom: '10px', background: '#F8FBFF' }}>
        <Col xs={12} md={12} sm={12} xl={12} className="pl-2">
          <Navbar style={{ marginBottom: '10px' }}>
            <Navbar.Brand className="text-button">Billing adress</Navbar.Brand>
          </Navbar>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="full_name_billing">
            <Form.Label className="mt-3 input-text ">Bill to</Form.Label>
            <Form.Control
              as={Field}
              name="full_name_billing"
              type="text"
              value={values.full_name_billing}
              isInvalid={!!errors.full_name_billing && touched.full_name_billing}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {errors.full_name_billing && touched.full_name_billing && errors.full_name_billing}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="address_line_1_billing">
            <Form.Label className="mt-3 input-text ">Address 1</Form.Label>
            <Form.Control
              as={Field}
              name="address_line_1_billing"
              type="text"
              value={values.address_line_1_billing}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="address_line_2_billing">
            <Form.Label className="mt-3 input-text ">Address Line 2</Form.Label>
            <Form.Control
              as={Field}
              name="address_line_2_billing"
              type="text"
              values={values.address_line_2_billing}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>

        <Col xs={12} sm={12} md={12} xl={12}>
          <Form.Group controlId="city_billing">
            <Form.Label className="mt-3 input-text ">City</Form.Label>
            <Form.Control
              as={Field}
              name="city_billing"
              type="text"
              values={values.city_billing}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>

        <Col xs={8} sm={8} md={8} xl={8}>
          <Form.Group controlId="state_billing">
            <Form.Label className="mt-3 input-text ">State</Form.Label>
            <Field
              as="select"
              className={
                errors.state_billing && touched.state_billing
                  ? 'form-control is-invalid is-invalid-select'
                  : 'form-control'
              }
              name="state_billing"
              value={values.state_billing}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value=""></option>
              {States.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Field>
          </Form.Group>
        </Col>

        <Col xs={4} sm={4} md={4} xl={4} className="mb-5">
          <Form.Group controlId="zip_code_billing">
            <Form.Label className="mt-3 input-text ">Zip</Form.Label>
            <Form.Control
              as={Field}
              name="zip_code_billing"
              type="number"
              values={values.zip_code_billing}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  }
}
