/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';

import { Form, Col, Row } from 'react-bootstrap';
import { getHours, getMinutes, isSameDay, set } from 'date-fns';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { editEvent } from 'actions/index';
import { EVENTS_TYPES, EVENT_TYPE, userRoleId } from '../../../constants';

const removeDuplicates = participants => {
  const participantsArray = participants.split(',').map(participant => participant.trim());
  const uniqueParticipants = [...new Set(participantsArray)];
  return uniqueParticipants.join(', ');
};
const removeSalesmanAndCsr = (participants, currentJob) => {
  if (typeof participants !== 'string') {
    return [];
  }

  const participantsArray = participants.split(',').map(participant => participant.trim());

  const customerEmail = currentJob.csr_email || '';
  const salesmanEmail = currentJob?.salesmanA_email || '';
  const secondSalesmanEmail = currentJob?.salesmanB_email || '';

  const filteredParticipants = participantsArray.filter(
    participant =>
      participant !== customerEmail &&
      participant !== salesmanEmail &&
      participant !== secondSalesmanEmail,
  );

  return filteredParticipants.join(', ');
};

const FormEditEvent = forwardRef(
  ({ refSubmit, jobId, id, company_id, handleCloseEdit, setReloadEvents }) => {
    const dispatch = useDispatch();
    const { current, profile } = useSelector(({ event, auth }) => ({
      current: event.current,
      profile: auth.profile,
    }));

    const onSubmit = async values => {
      let validParticipants = values.participants;

      if (Array.isArray(validParticipants)) {
        validParticipants = validParticipants.join(', ');
      }

      validParticipants = removeDuplicates(validParticipants);
      const filteredParticipants = removeSalesmanAndCsr(validParticipants, current);

      const data = {
        ...values,
        company_id,
        job_id: jobId,
        participants: filteredParticipants,
      };
      await dispatch(editEvent(id, data));
      handleCloseEdit();
      setTimeout(() => {
        setReloadEvents(reloadEvents => !reloadEvents);
      }, 1000);
    };

    const getInitialDateTime = dateTime => (dateTime ? new Date(dateTime) : new Date());

    const getDefaultParticipants = () => {
      let defaultParticipants = [
        current?.csr_email,
        current?.salesmanA_email,
        current?.salesmanB_email,
      ].filter(Boolean);

      if (current?.participants) {
        defaultParticipants = [...defaultParticipants, ...current.participants.split(',')];
      }

      return defaultParticipants;
    };

    return (
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={{
          type: current.type || '',
          name: current.name || '',
          start_time:
            current.type === EVENTS_TYPES.appointment
              ? getInitialDateTime(current.start_time)
              : new Date(),
          end_time:
            current.type === EVENTS_TYPES.appointment
              ? getInitialDateTime(current.end_time)
              : new Date(),
          participants: getDefaultParticipants(),
          notes: current.notes || '',
          location: current.location || '',
        }}
        validationSchema={Yup.object().shape({
          end_time: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string().required('End date is required'),
          }),
          location: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string().required('Location is required'),
            otherwise: Yup.string(),
          }),
          name: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string().required('Name is required'),
          }),
          participants: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string()
              .required('Participants is required')
              .test('emails', 'Please enter valid emails separated by commas', value =>
                value
                  .split(',')
                  .every(email => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())),
              ),
            otherwise: Yup.string(),
          }),
          start_time: Yup.string().when('type', {
            is: val => val === EVENTS_TYPES.appointment,
            then: Yup.string().required('Start date is required'),
          }),
          type: Yup.string().required('Appointment is required'),
        })}
      >
        {({ values, handleChange, setFieldValue }) => (
          <FormikForm>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId="type">
                  <Form.Label className="input-text">Event type</Form.Label>
                  <Field
                    as="select"
                    className="form-control"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    disabled={
                      current.type === EVENTS_TYPES.appointment ||
                      profile.role_id !== userRoleId.OWNER
                    }
                  >
                    <option value="">Choose a event</option>
                    {EVENT_TYPE.filter(
                      ({ value }) =>
                        value !== EVENTS_TYPES.appointment ||
                        current.type === EVENTS_TYPES.appointment,
                    ).map(({ label, value }, index) => (
                      <option value={value} key={index}>
                        {label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="type" component="div" className="errorMessage" />
                </Form.Group>
              </Col>
              {values.type === EVENTS_TYPES.appointment && (
                <>
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="name">
                      <Form.Label className="input-text">Event name</Form.Label>
                      <Field
                        placeholder="Name of event"
                        name="name"
                        className="form-control"
                        value={values.name}
                        onChange={handleChange}
                        disabled={profile.role_id !== userRoleId.OWNER}
                      />
                      <ErrorMessage name="name" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="start_time">
                      <div>
                        <Form.Label className="input-text">Start date & time</Form.Label>
                      </div>
                      <div className="customDatePickerWidth">
                        <Field
                          name="start_time"
                          render={({ field }) => (
                            <DatePicker
                              autoComplete="off"
                              name="start_time"
                              placeholderText="Start date & time"
                              className="form-control"
                              selected={field.value}
                              value={values.start_time}
                              showTimeSelect
                              timeIntervals={15}
                              dateFormat="Pp"
                              onChange={date => {
                                const startDate = new Date(date.setMilliseconds(0));
                                setFieldValue('start_time', startDate);
                                const endDate = new Date(startDate);
                                endDate.setMinutes(endDate.getMinutes() + 15);
                                endDate.setMilliseconds(0);
                                setFieldValue('end_time', endDate);
                              }}
                              minDate={new Date()}
                              minTime={
                                isSameDay(field.value || new Date(), new Date())
                                  ? set(new Date(), {
                                      hours: getHours(new Date()),
                                      minutes: getMinutes(new Date()),
                                    })
                                  : set(new Date(), { hours: 0, minutes: 0 })
                              }
                              maxTime={new Date().setHours(23, 59, 59)}
                              disabled={profile.role_id !== userRoleId.OWNER}
                            />
                          )}
                        />
                      </div>
                      <ErrorMessage name="start_time" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="end_time">
                      <div>
                        <Form.Label className="input-text">End date & time</Form.Label>
                      </div>
                      <div className="customDatePickerWidth">
                        <Field
                          name="end_time"
                          render={({ field }) => (
                            <DatePicker
                              autoComplete="off"
                              name="end_time"
                              placeholderText="End date & time"
                              className="form-control"
                              selected={field.value}
                              value={values.end_time}
                              showTimeSelect
                              timeIntervals={15}
                              dateFormat="Pp"
                              onChange={date => {
                                const endDate = new Date(date.setMilliseconds(0));
                                setFieldValue('end_time', endDate);
                              }}
                              minDate={values.start_time || new Date()}
                              minTime={
                                values.start_time &&
                                values.start_time.setHours(
                                  values.start_time.getHours(),
                                  values.start_time.getMinutes(),
                                )
                              }
                              excludeTimes={[values.start_time]}
                              maxTime={new Date().setHours(23, 59, 59)}
                              disabled={!values.start_time || profile.role_id !== userRoleId.OWNER}
                            />
                          )}
                        />
                      </div>
                      <ErrorMessage name="end_time" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12}>
                    <Form.Group controlId="participants">
                      <Form.Label className="input-text">Participant's Email</Form.Label>
                      <Field
                        placeholder="Participants"
                        name="participants"
                        value={values.participants}
                        onChange={handleChange}
                        as="textarea"
                        className="textarea"
                        rows={2}
                        disabled={true}
                      />
                      <ErrorMessage name="participants" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12}>
                    <Form.Group controlId="location">
                      <Form.Label className="input-text">Location</Form.Label>
                      <Field
                        placeholder="Enter location"
                        name="location"
                        className="form-control"
                        value={values.location}
                        onChange={handleChange}
                        disabled={true}
                      />
                      <ErrorMessage name="location" component="div" className="errorMessage" />
                    </Form.Group>
                  </Col>
                </>
              )}
              <Col xs={12}>
                <Form.Group controlId="notes">
                  <Form.Label className="input-text">Notes</Form.Label>
                  <Field
                    placeholder="Enter your notes here"
                    as="textarea"
                    className="textarea"
                    name="notes"
                    value={values.notes}
                    rows={3}
                    disabled={profile.role_id !== userRoleId.OWNER}
                  />
                  <ErrorMessage name="notes" component="div" className="errorMessage" />
                </Form.Group>
              </Col>
            </Row>
            <button type="submit" ref={refSubmit} style={{ display: 'none' }} />
          </FormikForm>
        )}
      </Formik>
    );
  },
);

export default FormEditEvent;
