/**
 * @module Sagas/Job
 * @desc Job
 */

import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ActionTypes } from 'constants/index';
import { showAlert } from 'actions';
import { push } from 'modules/history';

const fileDownload = require('js-file-download');

export function* getJobs(api, action) {
  const { payload } = action;
  const { status, data } = yield call(api.getJobs, payload);

  if (status === 200) {
    const { rows, pages, currentPage } = data;

    yield put({
      type: ActionTypes.GET_JOBS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_JOBS_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
export function* getJob(api, action) {
  const {
    payload: { id },
  } = action;
  const { status, data } = yield call(api.getJob, id);
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_JOB_SUCCESS,
      payload: { job: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_JOB_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* createJob(api, action) {
  const response = yield call(api.createJob, action.payload.data);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.CREATE_JOB_SUCCESS,
    });
    yield put(showAlert('Job Created', { variant: 'success' }));
    push('/dashboard/jobs');
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.CREATE_JOB_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* createJobPdf(api, action) {
  const {
    payload: { id },
  } = action;
  const { status, data } = yield call(api.createJobPdf, id);

  if (status === 200) {
    const { fileName, url } = data;

    yield put({
      type: ActionTypes.CREATE_JOB_PDF_SUCCESS,
    });
    axios({
      url,
      method: 'GET',
      responseType: 'blob', // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.CREATE_JOB_PDF_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* acceptJob(api, action) {
  const {
    payload: { id, accepted },
  } = action;
  const { status, data } = yield call(api.editJob, id, { accepted });

  if (status === 200) {
    yield put({
      type: ActionTypes.ACCEPT_JOB_SUCCESS,
      payload: { id, accepted },
    });
    push('/dashboard/jobs');
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.ACCEPT_JOB_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* editJob(api, action) {
  const {
    payload: { id, data: job },
  } = action;
  const { status, data } = yield call(api.editJob, id, job);

  if (status === 200) {
    yield put({
      type: ActionTypes.EDIT_JOB_SUCCESS,
      payload: { id, job },
    });
    push('/dashboard/jobs');
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.EDIT_JOB_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* deleteJob(api, action) {
  const {
    payload: { id },
  } = action;
  const { status } = yield call(api.deleteJob, id);

  if (status === 200) {
    yield put({
      type: ActionTypes.DELETE_JOB_SUCCESS,
      payload: { id },
    });
    yield put(showAlert('Job Deleted', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.DELETE_JOB_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}
