import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Laptop1 from './laptop-1.png';
import RevolutionizeBackground from './revolutionize.png';

const Revolutionize = () => {
  return (
    <Row
      style={{
        backgroundImage: `url(${RevolutionizeBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        backgroundRepeat: 'no-repeat',
        padding: '100px 0',
      }}
      className="d-flex justify-content-between align-items-center "
    >
      <Col xs={12} md={1} />
      <Col xs={12} md={7}>
        <h5 className="text-white landing-h5 py-4">
          Glaziers Tool is changing the way glass professionals work
        </h5>
        <h6 className="text-white landing-h6 py-4">
          GT makes the process of gathering and submitting job field information incredibly easy.
        </h6>
        <p className="text-white landing-h8 py-5">
          It automatically emails all the job info and photos in one PDF back to your office, where
          your Team can input the data into Mainstreet Glass Avenue (or other POS provider),
          requests vendor quotes, and double-checks all the information before sending a quote to
          the customer.
        </p>
      </Col>
      <Col xs={12} md={4}>
        <img src={Laptop1} alt="Laptop" />
      </Col>
    </Row>
  );
};

export default Revolutionize;
