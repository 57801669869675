import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { Table, Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { push } from 'modules/history';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { deleteUser, editUser } from 'actions/index';

import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import ModalComponent from '../../../components/ModalComponent';
import { statusUser } from '../../../constants';

const OwnersView = () => {
  const [openDelete, setOpenDelete] = useState({ state: false, id: null });

  const dispatch = useDispatch();
  const { owners, profile } = useSelector(({ user, auth }) => ({
    owners: user.owners,
    profile: auth.profile,
  }));

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <HoverColorStyle
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </HoverColorStyle>
  ));

  const editOwner = id => {
    push(`/dashboard/settings/owner/${id}`);
  };

  const changeStatus = id => {
    dispatch(editUser(id, { status: statusUser.ACTIVE }, '/dashboard/settings'));
  };

  const handleOpenDelete = id => {
    setOpenDelete({ state: true, id });
  };

  const handleCloseDelete = () => {
    setOpenDelete({ state: false, id: null });
  };

  const onDeleteUser = () => {
    dispatch(deleteUser(openDelete.id));
    handleCloseDelete();
  };

  return (
    <>
      <div style={{ marginTop: 40 }}>
        <div
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 4,
            display: 'inline-flex',
          }}
        >
          <div style={{ width: 17.5, height: 20 }}>
            <FontAwesomeIcon
              icon={faUserTie}
              style={{
                width: 17.5,
                height: 20,
                color: '#007BFF',
              }}
            />
          </div>
          <div
            style={{
              color: '#007BFF',
              fontSize: 16,
              fontFamily: 'Lato',
              fontWeight: '700',
              wordWrap: 'break-word',
            }}
          >
            Owners
          </div>
        </div>
        <Table
          style={{ marginTop: 20, marginBottom: 20 }}
          className="table-borderless-bottom-border"
        >
          {owners.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan="11">
                  <div
                    style={{
                      color: '#71717A',
                      fontSize: 14,
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                    }}
                  >
                    No owner added
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {owners.map((owner, index) => (
                <tr key={index}>
                  <td
                    style={{
                      width: '20%',
                      color: '#71717A',
                      paddingTop: 20,
                    }}
                  >
                    <h4>{owner.username}</h4>
                  </td>
                  <td
                    style={{
                      width: '20%',
                      color: '#71717A',
                      paddingTop: 20,
                    }}
                  >
                    <h4>
                      {owner.first_name} {owner.last_name}
                    </h4>
                  </td>
                  <td
                    style={{
                      width: '20%',
                      color: '#71717A',
                      paddingTop: 20,
                    }}
                  >
                    <h4>{owner.email}</h4>
                  </td>
                  <td
                    style={{
                      width: '20%',
                      color: '#71717A',
                      paddingTop: 20,
                    }}
                  >
                    <h4>{owner.phone_number}</h4>
                  </td>
                  <td
                    style={{
                      width: '20%',
                      color: '#71717A',
                      paddingTop: 20,
                      textTransform: 'capitalize',
                    }}
                  >
                    <h4>{owner.status}</h4>
                  </td>
                  <td
                    style={{
                      width: '10%',
                      color: '#71717A',
                      textAlign: 'right',
                      display: profile.id === owner.id && 'none',
                    }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          style={{ width: 24, height: 24 }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {owner.status !== statusUser.ACTIVE && owners.length !== 1 && (
                          <Dropdown.Item onClick={() => changeStatus(owner.id)}>
                            <DropdownItemStyle>
                              <TextStyleGreen>Approve</TextStyleGreen>
                            </DropdownItemStyle>
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => editOwner(owner.id)}>
                          <DropdownItemStyle>
                            <TextStyle>Edit owner</TextStyle>
                          </DropdownItemStyle>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleOpenDelete(owner.id)}>
                          <DropdownItemStyle>
                            <TextStyleRed>Remove owner</TextStyleRed>
                          </DropdownItemStyle>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        <ButtonGroup style={{ marginTop: 20 }}>
          <Button
            variant="primary"
            size="lg"
            type="submit"
            className="button"
            onClick={() => push('/dashboard/settings/owner')}
          >
            Add owner
          </Button>
        </ButtonGroup>
      </div>
      <ModalComponent
        size="sm"
        show={openDelete.state}
        onHide={handleCloseDelete}
        textCancel="Cancel"
        textSubmit="Remove"
        variantSubmit="danger"
        onClickSubmit={onDeleteUser}
        title={
          <div
            style={{
              color: '#DC3545',
              fontSize: 20,
              fontFamily: 'Lato',
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            Remove user
          </div>
        }
        body={
          <div
            style={{
              color: '#2F3033',
              fontSize: 12,
              fontFamily: 'Lato',
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            Are you sure that want remove user?
          </div>
        }
      />
    </>
  );
};

export default OwnersView;

const HoverColorStyle = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px #e8ebf0 solid;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #425268;
  transition: background 0.3s, color 0.3s;
  &:hover {
    color: #303f4e;
    background: #f0f0f0;
  }
`;

const DropdownItemStyle = styled.div`
  width: 141px;
  height: 32px;
  padding: 4px;
  border-bottom: 1px #e8ebf0 solid;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

const TextStyle = styled.div`
  color: #425268;
  font-size: 16px;
  font-family: Lato;
  font-weight: 400;
`;

const TextStyleRed = styled.div`
  color: #e10e00;
  font-size: 16px;
  font-family: Lato;
  font-weight: 400;
`;

const TextStyleGreen = styled.div`
  color: #4caf50;
  font-size: 16px;
  font-family: Lato;
  font-weight: 400;
`;
