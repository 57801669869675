import React, { Component } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
  Breadcrumb,
  Form,
  ButtonGroup,
  Navbar,
} from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import { isRequired, isEmail } from 'utils/rules';
import { push } from 'modules/history';
import { RoleID, userRoleName } from '../../constants';
import Icon from 'components/Icon';
import { Colors } from 'modules/theme';

export default class FormUser extends Component {
  render() {
    const { onSubmit, loading, initialValues, isEdit } = this.props;

    return (
      <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <FormikForm>
            <Row className="mt-5 mb-4">
              <Col>
                <h2 className="align-items-center">
                  <Icon
                    fontSize={30}
                    name="chevron-left"
                    color={Colors.blue}
                    customStyle={{ paddingRight: '20px' }}
                    onClick={() => push(`/dashboard/users`)}
                  />
                  {isEdit ? 'Edit user' : 'Create user'}
                </h2>
              </Col>
            </Row>
            <Row
              className="px-4 py-4 ml-1 mr-1 mt-5 pb-5"
              style={{ marginBottom: '10px', background: '#F8FBFF' }}
            >
              <Col xs={12} md={12} sm={12} xl={12} className="pl-2">
                <Navbar style={{ marginBottom: '10px' }}>
                  <Navbar.Brand className="text-button">User Details</Navbar.Brand>
                </Navbar>
              </Col>
              <Col xs={12} sm={12} md={6} xl={6}>
                <Form.Group controlId="first_name">
                  <Form.Label className="mt-2 input-text ">First name</Form.Label>
                  <Form.Control
                    as={Field}
                    name="first_name"
                    type="text"
                    value={values.first_name}
                    isInvalid={!!errors.first_name && touched.first_name}
                    onChange={handleChange}
                    validate={isRequired}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name && touched.first_name && errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} xl={6}>
                <Form.Group controlId="last_name">
                  <Form.Label className="mt-2 input-text ">Last name</Form.Label>
                  <Form.Control
                    as={Field}
                    name="last_name"
                    type="text"
                    values={values.last_name}
                    isInvalid={!!errors.last_name && touched.last_name}
                    onChange={handleChange}
                    validate={isRequired}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name && touched.last_name && errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} xl={6}>
                <Form.Group controlId="email">
                  <Form.Label className="mt-3 input-text ">Email</Form.Label>
                  <Form.Control
                    as={Field}
                    name="email"
                    type="email"
                    values={values.email}
                    isInvalid={!!errors.email && touched.email}
                    onChange={handleChange}
                    validate={isEmail}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email && touched.email && errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} xl={6}>
                <Form.Group controlId="phone_number">
                  <Form.Label className="mt-3 input-text ">Phone</Form.Label>
                  <Form.Control
                    as={Field}
                    name="phone_number"
                    type="number"
                    value={values.phone_number}
                    isInvalid={!!errors.phone_number && touched.phone_number}
                    onChange={handleChange}
                    validate={isRequired}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone_number && touched.phone_number && errors.phone_number}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} xl={6}>
                <Form.Group controlId="role_id">
                  <Form.Label className="mt-3 input-text ">Role</Form.Label>
                  <Field
                    as="select"
                    className={
                      errors.salesman_id && touched.salesman_id
                        ? 'form-control is-invalid is-invalid-select '
                        : 'form-control '
                    }
                    name="role_id"
                    value={values.role_id}
                    validate={isRequired}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value=""></option>
                    {RoleID.filter(role => role.label !== userRoleName.OWNER).map(
                      ({ label, value }, index) => (
                        <option value={value} key={index}>
                          {label}
                        </option>
                      ),
                    )}
                  </Field>
                  <Form.Control.Feedback type="invalid">
                    {errors.role_id && touched.role_id && errors.role_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} xl={6}>
                <Form.Group controlId="username">
                  <Form.Label className="mt-3 input-text ">Username</Form.Label>
                  <Form.Control
                    as={Field}
                    name="username"
                    type="text"
                    value={values.username}
                    isInvalid={!!errors.username && touched.username}
                    onChange={handleChange}
                    validate={isRequired}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username && touched.username && errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} sm={12} xl={12} className="mt-4 pr-4">
                <ButtonGroup className="mr-2 float-right">
                  <Button variant="primary" size="lg" type="submit" className="float-right button">
                    Save
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
    );
  }
}
