import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

const INITIAL_COMPANY_REGISTRATION = {
  //user fields
  username: '',
  email: '',
  confirm_email: '',
  first_name: '',
  last_name: '',
  last_name: '',
  password: '',
  confirm_password: '',
  //company fields
  company_name: '',
  company_address: '',
  company_city: '',
  company_state: '',
  //company_url: '',
  company_subdomain: '',
  //plan
  product_id: null,
};
export const appState = {
  step: 1,
  register_data: INITIAL_COMPANY_REGISTRATION,
  //other
  products: [],
  //other
  subdomainValid: true,
  subdomainLoading: false,
};

export default {
  registerCompany: handleActions(
    {
      [ActionTypes.REGISTER_COMPANY_SAVE_DATA]: (draft, { payload: { values } }) => {
        draft.register_data = { ...draft.register_data, ...values };
      },
      [ActionTypes.REGISTER_COMPANY_CHANGE_STEP]: (draft, { payload: { step } }) => {
        draft.step = step;
      },
      [ActionTypes.GET_PRODUCTS_SUCCESS]: (draft, { payload: { products } }) => {
        draft.products = products;
      },
      [ActionTypes.REGISTER_COMPANY_SUCCESS]: draft => {
        draft.step = 5;
        draft.registerSuccess = true;
      },
      [ActionTypes.REGISTER_COMPANY_FAILURE]: draft => {
        draft.step = 5;
        draft.registerSuccess = false;
      },
      [ActionTypes.RESET_REGISTER_FORM]: draft => {
        draft.step = 1;
        draft.register_data = INITIAL_COMPANY_REGISTRATION;
      },
      [ActionTypes.CHECK_SUBDOMAIN]: draft => {
        draft.subdomainLoading = true;
      },
      [ActionTypes.CHECK_SUBDOMAIN_SUCCESS]: (draft, { payload: subdomainValid }) => {
        draft.subdomainValid = subdomainValid;
        draft.subdomainLoading = false;
      },
      [ActionTypes.CHECK_SUBDOMAIN_FAILURE]: draft => {
        draft.subdomainLoading = false;
      },
    },
    appState,
  ),
};
