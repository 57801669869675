import React from 'react';

import * as Yup from 'yup';

import { Formik, Field, Form as FormikForm } from 'formik';

import { Button, Row, Col, Form, Navbar, ButtonGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, editUser } from 'actions/index';

import { RoleID, userRoleId } from '../../../constants';

const FormOwner = ({ isEditMode }) => {
  const { owner } = useSelector(({ user }) => ({
    owner: user.current,
  }));

  const dispatch = useDispatch();
  const onSubmit = values => {
    if (isEditMode && owner.role_id === userRoleId.OWNER) {
      dispatch(editUser(owner.id, values, '/dashboard/settings'));
    } else {
      dispatch(createUser(values, '/dashboard/settings'));
    }
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{
        first_name: isEditMode && owner ? owner.first_name : '',
        last_name: isEditMode && owner ? owner.last_name : '',
        email: isEditMode && owner ? owner.email : '',
        phone_number: isEditMode && owner ? owner.phone_number : '',
        username: isEditMode && owner ? owner.username : '',
        role_id: userRoleId.OWNER,
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
        phone_number: Yup.string().required('Phone number is required'),
        username: Yup.string().required('Username is required'),
      })}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <FormikForm>
          <Row
            className="px-4 py-4 ml-1 mr-1 mt-5 pb-5"
            style={{ marginBottom: '10px', background: '#E9F1FC', borderRadius: 16 }}
          >
            <Col xs={12} md={12} sm={12} xl={12} className="pl-2">
              <Navbar style={{ marginBottom: '10px' }}>
                <NavbarBrand className="text-button">User Details</NavbarBrand>
              </Navbar>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId="first_name">
                <FormLabel className="mt-2 input-text ">First name</FormLabel>
                <Form.Control
                  as={Field}
                  name="first_name"
                  type="text"
                  value={values.first_name}
                  isInvalid={!!errors.first_name && touched.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Name"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name && touched.first_name && errors.first_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId="last_name">
                <FormLabel className="mt-2 input-text ">Last name</FormLabel>
                <Form.Control
                  as={Field}
                  name="last_name"
                  type="text"
                  values={values.last_name}
                  isInvalid={!!errors.last_name && touched.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Last name"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name && touched.last_name && errors.last_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId="email">
                <FormLabel className="mt-3 input-text ">Email</FormLabel>
                <Form.Control
                  as={Field}
                  name="email"
                  type="email"
                  values={values.email}
                  isInvalid={!!errors.email && touched.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email && touched.email && errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId="phone_number">
                <FormLabel className="mt-3 input-text ">Phone</FormLabel>
                <Form.Control
                  as={Field}
                  name="phone_number"
                  type="number"
                  value={values.phone_number}
                  isInvalid={!!errors.phone_number && touched.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Phone"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone_number && touched.phone_number && errors.phone_number}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId="role_id">
                <FormLabel className="mt-3 input-text " style={{ color: '#71717A' }}>
                  Role
                </FormLabel>
                <Field
                  as="select"
                  className={
                    errors.salesman_id && touched.salesman_id
                      ? 'form-control is-invalid is-invalid-select '
                      : 'form-control '
                  }
                  name="role_id"
                  value={values.role_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={true}
                >
                  <option value=""></option>
                  {RoleID.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Field>
                <Form.Control.Feedback type="invalid">
                  {errors.role_id && touched.role_id && errors.role_id}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} xl={6}>
              <Form.Group controlId="username">
                <FormLabel className="mt-3 input-text ">Username</FormLabel>
                <Form.Control
                  as={Field}
                  name="username"
                  type="text"
                  value={values.username}
                  isInvalid={!!errors.username && touched.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Username"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username && touched.username && errors.username}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={12} sm={12} xl={12} className="mt-4 pr-4">
              <ButtonGroup className="mr-2 float-right">
                <Button variant="primary" size="lg" type="submit" className="float-right button">
                  {isEditMode ? 'Edit' : 'Create'} owner
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </FormikForm>
      )}
    </Formik>
  );
};

export default FormOwner;

const FormLabel = styled(Form.Label)`
  color: #09090b;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const NavbarBrand = styled(Navbar.Brand)`
  color: #0f0504;
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;
