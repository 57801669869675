import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPassword } from 'actions/index';
import ResetPassView from './ResetPassView';

class ResetPassContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onResetPassword: PropTypes.func.isRequired,
  };

  resetPassword = password => {
    const { onResetPassword, history } = this.props;
    const {
      location: { query },
    } = history;
    onResetPassword(query.token_pass_recovery, password);
  };

  render() {
    return <ResetPassView resetPassword={this.resetPassword} loading={this.props.loading} />;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    loading: false,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onResetPassword: (token_pass_recovery, password) =>
      dispatch(resetPassword(token_pass_recovery, password)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassContainer);
