import React, { Component } from 'react';
import { Field } from 'formik';
import {
  Row,
  Col,
  Form,
  ToggleButton,
  ButtonToolbar,
  ToggleButtonGroup,
  Jumbotron,
} from 'react-bootstrap';
import { DamageWaiver, Disclaimers } from 'constants/reminderMaterials';
import { isRequired } from 'utils/rules';

export default class GlassRemindersView extends Component {
  render() {
    const { values, errors, handleChange, touched, handleBlur, setFieldValue, prefix } = this.props;

    return (
      <Jumbotron style={{ padding: '2rem 2rem', background: '#fff' }}>
        <Row>
          <Col xs={12} md={12} sm={12} xl={12} style={{ paddingBottom: '1rem' }}>
            <h3>Reminders</h3>
          </Col>
          <Col xs={12} md={12} sm={12} xl={12}>
            {/* GLASS_ELEVATOR */}
            <Form.Group controlId={`${prefix}.glass_elevator`}>
              <Form.Label className="mt-3 input-text">Materials fit in Elevator?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.glass_elevator`}
                  value={values.glass_elevator}
                  onChange={(_, event) => handleChange(event)}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>
            {/* SOLAR FILM */}
            <Form.Group controlId={`${prefix}.solar_film`}>
              <Form.Label className="mt-3 input-text">Solar film?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.solar_film`}
                  value={values.solar_film}
                  onChange={(_, event) => {
                    handleChange(event);
                    if (event.target.value == 'no') {
                      setFieldValue(`${prefix}.solar_film_option`, null);
                      setFieldValue(`${prefix}.film_type`, null);
                      setFieldValue(`${prefix}.film_source`, null);
                    } else {
                      setFieldValue(`${prefix}.solar_film_option`, 'glazier');
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>
            {values.solar_film === 'yes' && (
              <div>
                <Form.Group>
                  <Form.Check
                    inline
                    as={Field}
                    type="radio"
                    id="solar_film_option_sag"
                    label="Glazier"
                    value="glazier"
                    onChange={handleChange}
                    name={`${prefix}.solar_film_option`}
                  />
                  <Form.Check
                    inline
                    as={Field}
                    type="radio"
                    id="solar_film_option_customer"
                    label="Customer"
                    value="customer"
                    onChange={handleChange}
                    name={`${prefix}.solar_film_option`}
                  />
                </Form.Group>
                {values.solar_film_option === 'glazier' && (
                  <Row>
                    <Col xs={12} sm={12} md={6} xl={6}>
                      <Form.Group controlId={`${prefix}.film_type`}>
                        <Form.Label className="input-text">Film type</Form.Label>
                        <Form.Control
                          as={Field}
                          name={`${prefix}.film_type`}
                          type="text"
                          placeholder="Film type"
                          value={values.film_type || ''}
                          isInvalid={!!errors.film_type && touched.film_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.film_type && touched.film_type && errors.film_type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={6} xl={6}>
                      <Form.Group controlId="film_source">
                        <Form.Label className="input-text">Film source</Form.Label>
                        <Form.Control
                          as={Field}
                          name={`${prefix}.film_source`}
                          type="text"
                          placeholder="Film source"
                          value={values.film_source || ''}
                          isInvalid={!!errors.film_source && touched.film_source}
                          onChange={e => {
                            handleChange(e);
                            // setFieldValue(`${prefix}.film_source`, {});
                          }}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.film_source && touched.film_source && errors.film_source}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            )}
            {/*  WET SEAL */}
            <Form.Group controlId={`${prefix}.wet_seal`}>
              <Form.Label className="mt-3 input-text">Wetseal?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.wet_seal`}
                  value={values.wet_seal}
                  onChange={(_, event) => {
                    handleChange(event);
                    if (event.target.value == 'no') {
                      setFieldValue(`${prefix}.wet_seal_option`, null);
                    } else {
                      setFieldValue(`${prefix}.wet_seal_option`, 'glazier');
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>
            {values.wet_seal === 'yes' && (
              <Form.Group>
                <Form.Check
                  inline
                  as={Field}
                  type="radio"
                  id="wet_seal_option"
                  label="Glazier"
                  value="glazier"
                  onChange={handleChange}
                  name={`${prefix}.wet_seal_option`}
                />
                <Form.Check
                  inline
                  as={Field}
                  type="radio"
                  id="wet_seal_option"
                  label="Glazier - SUB - Half Day"
                  value="sag_sub_half"
                  onChange={handleChange}
                  name={`${prefix}.wet_seal_option`}
                />
                <Form.Check
                  inline
                  as={Field}
                  type="radio"
                  id="wet_seal_option"
                  value="sag_sub_full"
                  label="Glazier - SUB Full Day"
                  name={`${prefix}.wet_seal_option`}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  as={Field}
                  type="radio"
                  id="wet_seal_option"
                  value="customer"
                  label="Customer"
                  name={`${prefix}.wet_seal_option`}
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            {/* FURNITURE TO MOVE */}
            <Form.Group controlId={`${prefix}.furniture`}>
              <Form.Label className="mt-3 input-text">Furniture to Move?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.furniture`}
                  value={values.furniture}
                  onChange={(_, event) => {
                    handleChange(event);
                    if (event.target.value == 'no') {
                      setFieldValue(`${prefix}.furniture_comment`, null);
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            {values.furniture === 'yes' && (
              <Form.Group controlId="furniture_comment">
                <Form.Label className="input-text">Comment</Form.Label>
                <Form.Control
                  as={Field}
                  name={`${prefix}.furniture_comment`}
                  type="text"
                  placeholder="Comment"
                  value={values.furniture_comment || ''}
                  isInvalid={!!errors.furniture_comment && touched.furniture_comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.furniture_comment &&
                    touched.furniture_comment &&
                    errors.furniture_comment}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {/* WALLS/CIELINGS CUT */}

            <Form.Group controlId={`${prefix}.walls_cielings`}>
              <Form.Label className="mt-3 input-text">Walls/Ceiling cut?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.walls_cielings`}
                  value={values.walls_cielings}
                  onChange={(_, event) => {
                    handleChange(event);
                    if (event.target.value == 'no') {
                      setFieldValue(`${prefix}.walls_cielings_option`, null);
                      setFieldValue(`${prefix}.walls_cielings_comment`, null);
                    } else {
                      setFieldValue(`${prefix}.walls_cielings_option`, 'glazier');
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            {values.walls_cielings === 'yes' && (
              <div>
                <Form.Group>
                  <Form.Check
                    inline
                    id="walls_cielings_option"
                    as={Field}
                    name={`${prefix}.walls_cielings_option`}
                    value="glazier"
                    label="Glazier"
                    type="radio"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    id="walls_cielings_option"
                    as={Field}
                    name={`${prefix}.walls_cielings_option`}
                    value="customer"
                    onChange={handleChange}
                    label="Customer"
                    type="radio"
                  />
                </Form.Group>
                <Form.Group controlId={`${prefix}.walls_cielings_comment`}>
                  <Form.Label className="input-text">Comment</Form.Label>
                  <Form.Control
                    as={Field}
                    name={`${prefix}.walls_cielings_comment`}
                    type="text"
                    placeholder="Comment"
                    value={values.walls_cielings_comment || ''}
                    isInvalid={!!errors.walls_cielings_comment && touched.walls_cielings_comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.walls_cielings_comment &&
                      touched.walls_cielings_comment &&
                      errors.walls_cielings_comment}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            )}

            {/* BLIND_REMOVING */}

            <Form.Group controlId={`${prefix}.blind_remove`}>
              <Form.Label className="mt-3 input-text">Blind needs Removing?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.blind_remove`}
                  value={values.blind_remove}
                  onChange={(_, event) => handleChange(event)}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            {/* COLOR WAIVER */}
            <Form.Group controlId={`${prefix}.color_waiver`}>
              <Form.Label className="mt-3 input-text">Color Waiver?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.color_waiver`}
                  value={values.color_waiver}
                  onChange={(_, event) => handleChange(event)}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            {/* DAMAGE WAIVER */}
            <Form.Group controlId={`${prefix}.damage_waiver`}>
              <Form.Label className="mt-3 input-text">Damage Waiver?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.damage_waiver`}
                  value={values.damage_waiver}
                  onChange={(_, event) => {
                    handleChange(event);
                    if (event.target.value == 'no') {
                      setFieldValue(`${prefix}.damage_waiver_option`, null);
                      setFieldValue(`${prefix}.damage_waiver_comment`, null);
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            {values.damage_waiver === 'yes' && (
              <Form.Group controlId={`${prefix}.damage_waiver_option`}>
                <Field
                  as="select"
                  className={
                    errors.damage_waiver_option && touched.damage_waiver_option
                      ? 'form-control is-invalid is-invalid-select'
                      : 'form-control'
                  }
                  name={`${prefix}.damage_waiver_option`}
                  value={values.damage_waiver_option || ''}
                  onChange={evt => {
                    const { value, name } = evt.target;
                    setFieldValue(name, value);
                    if (value !== 'Other') {
                      setFieldValue(`${prefix}.damage_waiver_comment`, null);
                    }
                  }}
                  onBlur={handleBlur}
                >
                  <option value="">Choose a damage waiver</option>
                  {DamageWaiver.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Field>
                <Form.Control.Feedback type="invalid">
                  {errors.damage_waiver_option &&
                    touched.damage_waiver_option &&
                    errors.damage_waiver_option}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {values.damage_waiver_option === 'Other' && (
              <Form.Group controlId={`${prefix}.damage_waiver_comment`}>
                <Form.Control
                  as={Field}
                  name={`${prefix}.damage_waiver_comment`}
                  type="text"
                  placeholder="Comment"
                  value={values.damage_waiver_comment || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            )}

            {/* DISCLAIMERS */}
            <Form.Group controlId={`${prefix}.disclaimers`}>
              <Form.Label className="mt-3 input-text">Disclaimers?</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.disclaimers`}
                  value={values.disclaimers}
                  onChange={(_, event) => {
                    handleChange(event);
                    if (event.target.value == 'no') {
                      setFieldValue(`${prefix}.disclaimers_option`, null);
                      setFieldValue(`${prefix}.disclaimers_comment`, null);
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            {values.disclaimers === 'yes' && (
              <Form.Group controlId={`${prefix}.disclaimers_option`}>
                <Field
                  as="select"
                  className={
                    errors.disclaimers_option && touched.disclaimers_option
                      ? 'form-control is-invalid is-invalid-select'
                      : 'form-control'
                  }
                  name={`${prefix}.disclaimers_option`}
                  value={values.disclaimers_option || ''}
                  onChange={evt => {
                    const { value, name } = evt.target;
                    setFieldValue(name, value);
                    if (value !== 'Other') {
                      setFieldValue(`${prefix}.disclaimers_comment`, null);
                    }
                  }}
                  onBlur={handleBlur}
                >
                  <option value="">Choose a damage waiver</option>
                  {Disclaimers.map(({ label, value }, index) => (
                    <option value={value} key={index}>
                      {label}
                    </option>
                  ))}
                </Field>
                <Form.Control.Feedback type="invalid">
                  {errors.disclaimers_option &&
                    touched.disclaimers_option &&
                    errors.disclaimers_option}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {values.disclaimers_option === 'Other' && (
              <Form.Group controlId={`${prefix}.disclaimers_comment`}>
                <Form.Control
                  as={Field}
                  name={`${prefix}.disclaimers_comment`}
                  type="text"
                  placeholder="Comment"
                  value={values.disclaimers_comment || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            )}

            {/* LIFT INSIDE */}
            <Form.Group controlId={`${prefix}.lift_inside`}>
              <Form.Label className="mt-3 input-text">Lift Inside</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.lift_inside`}
                  value={values.lift_inside}
                  onChange={(_, event) => {
                    handleChange(event);
                    if (event.target.value == 'no') {
                      setFieldValue(`${prefix}.lift_inside_comment`, null);
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            {values.lift_inside === 'yes' && (
              <Form.Group controlId="lift_inside_comment">
                <Form.Label className="input-text">Comment</Form.Label>
                <Form.Control
                  as={Field}
                  name={`${prefix}.lift_inside_comment`}
                  type="text"
                  placeholder="Comment"
                  value={values.lift_inside_comment || ''}
                  isInvalid={!!errors.lift_inside_comment && touched.lift_inside_comment}
                  onChange={handleChange}
                  validate={isRequired}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lift_inside_comment &&
                    touched.lift_inside_comment &&
                    errors.lift_inside_comment}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {/* LIFT OUTSIDE */}
            <Form.Group controlId={`${prefix}.lift_outside`}>
              <Form.Label className="mt-3 input-text">Lift outside</Form.Label>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name={`${prefix}.lift_outside`}
                  value={values.lift_outside}
                  onChange={(_, event) => {
                    handleChange(event);
                    if (event.target.value == 'no') {
                      setFieldValue(`${prefix}.lift_outside_comment`, null);
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="yes">
                    Yes
                  </ToggleButton>
                  <ToggleButton className="form-control pt-3" variant="outline-primary" value="no">
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Form.Group>

            {values.lift_outside === 'yes' && (
              <Form.Group controlId="lift_outside_comment">
                <Form.Label className="input-text">Comment</Form.Label>
                <Form.Control
                  as={Field}
                  name={`${prefix}.lift_outside_comment`}
                  type="text"
                  placeholder="Comment"
                  value={values.lift_outside_comment || ' '}
                  isInvalid={!!errors.lift_outside_comment && touched.lift_outside_comment}
                  onChange={handleChange}
                  validate={isRequired}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lift_outside_comment &&
                    touched.lift_outside_comment &&
                    errors.lift_outside_comment}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Col>
        </Row>
      </Jumbotron>
    );
  }
}
