export const inquiryData = [
  {
    title: 'Have any doubts?',
    description:
      'Send us your inquiry and our team will be answering all your questions about Glaziers Tool',
  },
];

export const inquiryFormData = [
  {
    title: 'Name',
    name: 'name',
    type: 'input',
    placeholder: 'Enter your name',
  },
  {
    title: 'Email',
    name: 'email',
    type: 'input',
    placeholder: 'Enter your email',
  },
  {
    title: 'Message',
    name: 'message',
    type: 'textarea',
    placeholder: 'Enter your message',
  },
];
