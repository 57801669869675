import React from 'react';
import './styles.css';
import { Container } from 'react-bootstrap';
import Benefits from './Benefits/Benefits';
import Pricing from './Pricing';
import Inquiry from './Inquiry/InquiryContainer';
import WhyGlaziersFirst from './WhyGlaziers/WhyGTFirst';
import WhyGlaziersSecond from './WhyGlaziers/WhyGTSecond';
import Footer from './Footer';
import Navbar from './Navbar';
import Hero from './Hero';
import Revolutionize from './WhyGlaziers/Revolutionize';

const Landing = () => (
  <Container fluid>
    <Navbar />
    <Hero />
    <Benefits />
    <Revolutionize />
    <WhyGlaziersFirst />
    <WhyGlaziersSecond />
    <Pricing />
    <Inquiry />
    <Footer />
  </Container>
);

export default Landing;
