import React, { Component } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { replace } from 'modules/history';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Logo from 'components/Logo';
import PageWithBackgroundImage from 'components/PageWithBackgroundImage';
import Icon from 'components/Icon';
import { Colors } from 'modules/theme';

export default class RecoverPassView extends Component {
  static propTypes = {
    recoverPassword: PropTypes.func.isRequired,
  };

  submit = values => {
    const { recoverPassword } = this.props;
    const { email } = values;
    recoverPassword(email);
  };

  render() {
    const initialValues = {
      email: '',
    };

    return (
      <PageWithBackgroundImage>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.submit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required('Required')
              .email('Invalid Format'),
          })}
        >
          {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
            <Container className="mt-5 mb-5 p-5">
              <Row className="mb-5 pl-5">
                <Col className="col col-lg-4 mt-5 mb-5 pb-5">
                  <Logo />
                </Col>
              </Row>

              <Row className="mt-5 mb-5 px-5">
                <Col className="mt-5">
                  <h2 className="align-items-center">
                    <Icon
                      fontSize={30}
                      name="chevron-left"
                      color={Colors.blue}
                      customStyle={{ paddingRight: '20px' }}
                      onClick={() => replace('/login')}
                    />
                    Recover password
                  </h2>
                </Col>
              </Row>

              <Form onSubmit={handleSubmit}>
                <Row className="pt-4 pb-5 px-5">
                  <Col>
                    <Form.Group controlId="login">
                      <Form.Control
                        style={{ height: '50px', fontSize: 18 }}
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Text style={{ fontSize: 12, float: 'right' }} className="text-muted">
                        A link will be sent to your email address to recover your password.
                      </Form.Text>
                      <p
                        style={{
                          color: 'red',
                          fontSize: '10px',
                          marginTop: '2px',
                          marginBottom: 0,
                        }}
                      >
                        {errors.email && touched.email && errors.email}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="pt-4  px-5">
                  <Col className="d-flex">
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      type="submit"
                      style={{ height: '50px' }}
                    >
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: Colors.white,
                          fontSize: 20,
                          textAlign: 'center',
                          marginBottom: 'auto',
                        }}
                      >
                        Submit
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          )}
        </Formik>
      </PageWithBackgroundImage>
    );
  }
}
